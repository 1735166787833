import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './AddEdit.module.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import $ from 'jquery'

import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';

import { useTranslation } from 'react-i18next';
import GeneralHelpers from '../../../Services/GeneralHelpers';


const AddEdit = ({id, editSource, handleAddEdit, generalFetchingServiceRef, locale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [fieldsErrors, setFieldsErrors] = useState([]);

  const [companiesData, setCompaniesData] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])
  
  const [company_id, setCompanyId] = useState(null)
  const [name, setName] = useState("")
  const [manager_name, setManagerName] = useState("")
  const [address, setAddress] = useState("")

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData(editSource=='all'?'':editSource)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let companies=data?.companies
          let companiesOptions=companies?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setCompaniesData(companies)
          setCompaniesOptions(companiesOptions)

          setCompanyId(data?.company?.id)

          if(id){
            getById(id)
          }
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let data={
      company_id,
      name,
      manager_name,
      address,
    };
    return data;
  }
  const handleAddBtnClick=()=>{
    let item=getObject()
    handleAddEdit(item, setFieldsErrors, resetData, editSource)
    window.scrollTo(0, 0);
  }
  
  const handleEditBtnClick=()=>{
    let item=getObject()
    item.id=id
    handleAddEdit(item, setFieldsErrors, null, editSource)
    window.scrollTo(0, 0);
  }

  const resetData = () => {
    setCompanyId(null)
    setName('')
    setManagerName('')
    setAddress('')
  }

  const handleChangeCompany=(value)=>{
    setCompanyId(value)
  }
  const handleChangeName=(e)=>{
    setName(e.target.value)
  }
  const handleChangeManagerName=(e)=>{
    setManagerName(e.target.value)
  }
  const handleChangeAddress=(e)=>{
    setAddress(e.target.value)
  }
  
  
  const setAllData=(data)=>{
    let item=editSource=='all'?data[0]:data
    setCompanyId(item?.company_id)
    setName(item.name)
    setManagerName(item.manager_name)
    setAddress(item.address)
  }

  const getById = (id) => {
    generalFetchingServiceRef?.current?.getById(id, editSource=='all'?'':editSource)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setAllData(data)
          }
        }).catch(error => {
          console.log(error)
        })
  }
  useEffect(()=>{
    getAllData()
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <div className={styles.AddEdit + ` ${locale=='ar'?'text-right':'text-left'}`} data-testid="AddEdit">

    {(prefix!='company') && <div className="form-group">
      <label htmlFor="company_id">{t('Company')}</label>
      <RenderErrorMessage name='company_id' messages={fieldsErrors} />
      <SelectSearch id={styles.Select}
        onFocus={handleOnFocusSelect}
        options={companiesOptions}
        onChange={handleChangeCompany}
        value={company_id}
        placeholder={`${t('Select a')} ${t('company')}`}
        search={true}
        filterOptions={fuzzySearch}
        />
    </div>}

    <div className="form-group">
      <label htmlFor="name">{t('Name')}</label>
      <RenderErrorMessage name='name' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('name')}`} id="name" onChange={handleChangeName} value={name} />
    </div>

    <div className="form-group">
      <label htmlFor="manager_name">{t('Manager Name')}</label>
      <RenderErrorMessage name='manager_name' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('manager name')}`} id="manager_name" onChange={handleChangeManagerName} value={manager_name} />
    </div>

    <div className="form-group">
      <label htmlFor="address">{t('Address')}</label>
      <RenderErrorMessage name='address' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter an')} ${t('address')}`} id="address" onChange={handleChangeAddress} value={address} />
    </div>
    
    <div className="form-group text-center">
      {!id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleAddBtnClick}>{t('Add')}</button>}
      {id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleEditBtnClick}>{t('Edit')}</button>}
    </div>

  </div>
};

AddEdit.propTypes = {};

AddEdit.defaultProps = {};

export default AddEdit;
