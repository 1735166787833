import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './DashboardLayout.module.css';
import Layout from '../../Common/Layout/Layout';
import SideBar from '../SideBar/SideBar';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import { useTranslation } from 'react-i18next';
import GeneralFetchingService from '../../../Services/GeneralFetchingService';


const DashboardLayout = ({children, component, privilegesSideBar, locale, setLocale, dashboardRef, isSidebar=true, isPopedUP}) => {

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const generalFetchingServiceRef = useRef();

  const [topBarColor, setTopBarColor] = useState("#343a40");
  const [siteColor, setSiteColor] = useState("#fff");
  const [siteFont, setSiteFont] = useState("auto");
  const [openChats, setOpenChats] = useState('');

  if(dashboardRef){
    useImperativeHandle(dashboardRef,()=>({
      changeTopBarColor(value){
        setTopBarColor(value)
      },
      changeSiteColor(value){
        setSiteColor(value)
      },
      changeSiteFont(value){
        setSiteFont(value)
      }
    }))
  }

  const getSiteStyles = () => {
    generalFetchingServiceRef?.current?.getAllData('styles')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let styles=responseData?.data
          if(styles?.colors?.topbar_color){
            setTopBarColor(styles?.colors?.topbar_color)
          }
          if(styles?.colors?.site_color){
            setSiteColor(styles?.colors?.site_color)
          }
          if(styles?.site_font){
            setSiteFont(styles?.site_font)
          }
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getSupportOpenChats = () => {
    generalFetchingServiceRef?.current?.getAllData('open_chats')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data
          if(data?.openChats!==null){
            setOpenChats(data?.openChats)
          }
        }
      }).catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if(auth?.user){
      getSiteStyles()
      getSupportOpenChats()
    }
  },[])

  
  return <Layout className={styles.Layout} locale={locale} setLocale={setLocale} topBarColor={topBarColor} siteColor={siteColor} siteFont={siteFont} openChats={openChats} isPopedUP={isPopedUP} prefix={prefix}>
    <GeneralFetchingService generalFetchingServiceRef={generalFetchingServiceRef} prefix='general' />
    <div className={isSidebar?styles.DashboardLayout:styles.DashboardLayoutNoSideBar} data-testid="DashboardLayout">
      {(isSidebar && !isPopedUP) && <SideBar component={component} privilegesSideBar={privilegesSideBar} locale={locale} />}
      <div>{children}</div>
    </div>
  </Layout>
};

DashboardLayout.propTypes = {};

DashboardLayout.defaultProps = {};

export default DashboardLayout;
