import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './AddEdit.module.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';

import { useTranslation } from 'react-i18next';

import $ from 'jquery'
import GeneralHelpers from '../../../Services/GeneralHelpers';


const AddEdit = ({id, handleAddEdit, generalFetchingServiceRef, locale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [fieldsErrors, setFieldsErrors] = useState([]);

  const [company_id, setCompanyId] = useState(null)
  const [companiesData, setCompaniesData] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])

  const [asset_action_id, setAssetActionId] = useState(null)
  const [assetActionsData, setAssetActionsData] = useState([])
  const [assetActionsOptions, setAssetActionsOptions] = useState([])
  
  const [asset_type_id, setAssetTypeId] = useState(null)
  const [assetTypesData, setAssetTypesData] = useState([])
  const [assetTypesOptions, setAssetTypesOptions] = useState([])
  

  const [company_accountant, setCompanyAccountant] = useState("")
  const [authorized_to_act, setAuthorizedToAct] = useState("")
  const [deducted_amount, setDeductedAmount] = useState('')
  const [returned_amount, setReturnedAmount] = useState('')
  const [total_amount, setTotalAmount] = useState('')
  const [action_title, setActionTitle] = useState("")
  const [action_details, setActionDetails] = useState("") 
  const [file, setFile] = useState(null);
  const [buy_date, setBuyDate] = useState(null)

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let companies=data?.companies
          let companiesOptions=companies?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setCompaniesData(companies)
          setCompaniesOptions(companiesOptions)

          let assetActions=data?.assetActions
          let assetActionsOptions=assetActions?.map(x => {return {'value':x.id, 'name': t(x.name)}})
          setAssetActionsData(assetActions)
          setAssetActionsOptions(assetActionsOptions)

          let assetTypes=data?.assetTypes
          let assetTypesOptions=assetTypes?.map(x => {return {'value':x.id, 'name': t(x.name)}})
          setAssetTypesData(assetTypes)
          setAssetTypesOptions(assetTypesOptions)

          if(id){
            getById(id)
          }
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let buyDateF=buy_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(buy_date)).locale("es").format('yyyy-MM-DD'):null
    let data={
      company_id:(prefix=='company')?(auth?.user?.user_info?.company?.id):(companiesData.filter(x => x.id == parseInt(company_id))[0]?.id),
      asset_action_id,
      asset_type_id,
      company_accountant,
      authorized_to_act,
      deducted_amount,
      returned_amount,
      total_amount,
      action_title,
      action_details,
      file,
      buy_date:buyDateF
    };
    return data;
  }
  const handleAddBtnClick=()=>{
    let item=getObject()
    handleAddEdit(item, setFieldsErrors, resetData)
    window.scrollTo(0, 0);
  }
  
  const handleEditBtnClick=()=>{
    let item=getObject()
    item.id=id
    handleAddEdit(item, setFieldsErrors)
    window.scrollTo(0, 0);
  }

  const resetData = () => {
    setCompanyId(null)
    setAssetActionId(null)
    setAssetTypeId(null)
    setCompanyAccountant('')
    setAuthorizedToAct('')
    setDeductedAmount('')
    setReturnedAmount('')
    setTotalAmount('')
    setActionTitle('')
    setActionDetails('')
    setFile(null)
    setBuyDate(null)
  }


  const handleChangeCompany=(value)=>{
    setCompanyId(value)
  }
  const handleChangeAssetAction=(value)=>{
    setAssetActionId(value)
  }
  const handleChangeAssetType=(value)=>{
    setAssetTypeId(value)
  }

  const handleChangeCompanyAccountant=(e)=>{
    setCompanyAccountant(e.target.value)
  }
  const handleChangeAuthorizedToAct=(e)=>{
    setAuthorizedToAct(e.target.value)
  }
  const handleChangeDeductedAmount=(e)=>{
    setDeductedAmount(e.target.value)
  }
  const handleChangeReturnedAmount=(e)=>{
    setReturnedAmount(e.target.value)
  }
  const handleChangeTotalAmount=(e)=>{
    setTotalAmount(e.target.value)
  }
  const handleChangeActionTitle=(e)=>{
    setActionTitle(e.target.value)
  }
  const handleChangeActionDetails=(e)=>{
    setActionDetails(e.target.value)
  }
  const handleChangeFile=(e)=>{
    setFile(e.target.files[0])
  }
  const handleChangeBuyDate=(date)=>{
    setBuyDate(date?new Date(date):null)
    if(date){
      $("#BuyDateClearBtn").show()
    }else{
      $("#BuyDateClearBtn").hide()
    }
  }

  
  const setAllData=(data)=>{
    let item=data[0]
    setCompanyId(item?.company_id)
    setAssetActionId(item?.asset_action_id)
    setAssetTypeId(item?.asset_type_id)
    setCompanyAccountant(item?.company_accountant)
    setAuthorizedToAct(item?.authorized_to_act)
    setDeductedAmount(item?.deducted_amount)
    setReturnedAmount(item?.returned_amount)
    setTotalAmount(item?.total_amount)
    setActionTitle(item?.action_title)
    setActionDetails(item?.action_details)
    setFile(item?.file)
    setBuyDate(item?.buy_date?new Date(item?.buy_date):null)
  }

  let isLoaded=false
  const getById = (id) => {
    generalFetchingServiceRef?.current?.getById(id)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setAllData(data)
          }
        }).catch(error => {
          console.log(error)
        })
  }
  useEffect(()=>{
    getAllData()
    $("#BuyDateClearBtn").hide()
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <div className={styles.AddEdit + ` ${locale=='ar'?'text-right':'text-left'}`} data-testid="AddEdit">

    {(prefix!='company') && <div className="form-group">
      <label htmlFor="company_id">{t('Company')}</label>
      <RenderErrorMessage name='company_id' messages={fieldsErrors} />
      <SelectSearch id={styles.Select}
        onFocus={handleOnFocusSelect}
        options={companiesOptions}
        onChange={handleChangeCompany}
        value={company_id}
        placeholder={`${t('Select a')} ${t('company')}`}
        search={true}
        filterOptions={fuzzySearch}
        />
    </div>}


    <div className="form-group">
      <label htmlFor="asset_action_id">{t('Action')}</label>
      <RenderErrorMessage name='asset_action_id' messages={fieldsErrors} />
      <SelectSearch id={styles.Select}
        onFocus={handleOnFocusSelect}
        options={assetActionsOptions}
        onChange={handleChangeAssetAction}
        value={asset_action_id}
        placeholder={`${t('Select an')} ${t('action')}`}
        search={true}
        filterOptions={fuzzySearch}
        />
    </div>

    <div className="form-group">
      <label htmlFor="asset_type_id">{t('Type')}</label>
      <RenderErrorMessage name='asset_type_id' messages={fieldsErrors} />
      <SelectSearch id={styles.Select}
        onFocus={handleOnFocusSelect}
        options={assetTypesOptions}
        onChange={handleChangeAssetType}
        value={asset_type_id}
        placeholder={`${t('Select a')} ${t('type')}`}
        search={true}
        filterOptions={fuzzySearch}
        />
    </div>

    <div className="form-group">
      <label htmlFor="company_accountant">{t('Company Accountant')}</label>
      <RenderErrorMessage name='company_accountant' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('Company Accountant')}`} id="company_accountant" onChange={handleChangeCompanyAccountant} value={company_accountant} />
    </div>

    <div className="form-group">
      <label htmlFor="authorized_to_act">{t('Authorized to act')}</label>
      <RenderErrorMessage name='authorized_to_act' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('Full Name')}`} id="authorized_to_act" onChange={handleChangeAuthorizedToAct} value={authorized_to_act} />
    </div>
       

    <div className="form-group">
      <label htmlFor="deducted_amount">{t('Deducted Amount')}</label>
      <RenderErrorMessage name='deducted_amount' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter the')} ${t('amount')}`} id="deducted_amount" onChange={handleChangeDeductedAmount} value={deducted_amount} />
    </div>

    <div className="form-group">
      <label htmlFor="returned_amount">{t('Returned Amount')}</label>
      <RenderErrorMessage name='returned_amount' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter the')} ${t('amount')}`} id="returned_amount" onChange={handleChangeReturnedAmount} value={returned_amount} />
    </div>

    <div className="form-group">
      <label htmlFor="total_amount">{t('Total Amount')}</label>
      <RenderErrorMessage name='total_amount' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter the')} ${t('amount')}`} id="total_amount" onChange={handleChangeTotalAmount} value={total_amount} />
    </div>

    <div className="form-group">
      <label htmlFor="action_title">{t('Action Title')}</label>
      <RenderErrorMessage name='action_title' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter an')} ${t('title')}`} id="action_title" autoComplete="off" onChange={handleChangeActionTitle} value={action_title} />
    </div>

    <div className="form-group">
      <label htmlFor="action_details">{t('Action Details')}</label>
      <RenderErrorMessage name='action_details' messages={fieldsErrors} /> <br />
      <textarea
            className={styles.TextArea}
            value={action_details}
            onChange={handleChangeActionDetails}
            ></textarea>
    </div>

    <div className="form-group">
      <label htmlFor="file">{t('Receipt File')}</label>
      <RenderErrorMessage name='file' messages={fieldsErrors} />
      <input type="file" className="form-control" placeholder={`${t('Enter a')} ${t('file')}`} 
          id="file" autoComplete="off" onChange={handleChangeFile}
          accept="image/*, application/pdf"
      />
    </div>

    <div className="form-group">
      <label htmlFor="buy_date">{t('Buy Date')}</label>
      <RenderErrorMessage name='buy_date' messages={fieldsErrors} />
      <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignContent:'flex-start'}}>
        <DatePicker selected={buy_date} onChange={handleChangeBuyDate} />
        <button id="BuyDateClearBtn" className='btn btn-danger' style={{width:'200px'}} onClick={()=>{handleChangeBuyDate(null)}}>{t('Clear Date')}</button>
      </div>
    </div>

    

    <div className="form-group text-center">
      {!id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleAddBtnClick}>{t('Add')}</button>}
      {id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleEditBtnClick}>{t('Edit')}</button>}
    </div>

  </div>
};

AddEdit.propTypes = {};

AddEdit.defaultProps = {};

export default AddEdit;
