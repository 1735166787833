import React from 'react';
import PropTypes from 'prop-types';
import styles from './RenderSuccessMessage.module.css';

const RenderSuccessMessage = ({name, messages, alertDisplay=false, isShown=true, handleHideMessage, isDissmiss}) => {
  return messages[name] && isShown && (
    <div className={styles.success + (alertDisplay?' alert alert-success alert-dismissible':'')}>
      {messages[name]}
      {isDissmiss && <button type="button" className="close" aria-label="Close" onClick={handleHideMessage}>
        <span aria-hidden="true">&times;</span>
      </button>}
    </div>
  )
};

RenderSuccessMessage.propTypes = {};

RenderSuccessMessage.defaultProps = {};

export default RenderSuccessMessage;
