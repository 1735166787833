import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TransferMoney.module.css';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../Common/ModalComponent/ModalComponent';
import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../Services/GeneralHelpers';
import $ from 'jquery'

const TransferMoney = ({locale, show, generalFetchingServiceRef, handleWhenHidden, handleOnConfirm, isPrivileged, transferMoneySourceAccount, fieldsErrorsIn}) => {
  const { t, i18n } = useTranslation();

  const [fieldsErrors, setFieldsErrors] = useState([]);


  const [recipient_id, setRecipientId] = useState(null)
  const [accountsData, setAccountsData] = useState([])
  const [accountsOptions, setAccountsOptions] = useState([])

  const [amount, setAmount] = useState('')
  const [details, setDetails] = useState('')
  const [sender_recipient_file, setSenderRecipientFile] = useState(null)
  const [receipt_file, setReceiptFile] = useState(null)
  
  const handleChangeRecipientId=(value)=>{
    setRecipientId(value)
  }
  const handleChangeAmount=(e)=>{
    setAmount(e.target.value)
  }
  const handleChangeDetails=(e)=>{
    setDetails(e.target.value)
  }
  const handleChangeSenderRecipientFile=(e)=>{
    setSenderRecipientFile(e.target.files[0])
  }
  const handleChangeReceiptFile=(e)=>{
    setReceiptFile(e.target.files[0])
  }

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData('transfer_accounts')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let accounts=data?.accounts
          let accountsOptions=accounts?.map(x => {return {'value':x.id, 'name': x?.display_name}})
          setAccountsData(accounts)
          setAccountsOptions(accountsOptions)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let data={
      sender_id:transferMoneySourceAccount?.user?.id,
      recipient_id,
      amount,
      details,
      sender_recipient_file:sender_recipient_file,
      receipt_file:receipt_file,
    };
    return data;
  }

  const isEmptyObject = (obj) => {
    return !obj.sender_id && !obj.recipient_id &&
    (!obj.amount && obj.amount?.trim().length==0) &&
    (!obj.details && obj.details?.trim().length==0) &&
    !obj.sender_recipient_file && !obj.receipt_file
  }

  const resetData = () => {
    setRecipientId(null)
    setAmount('')
    setDetails('')
    setSenderRecipientFile(null)
    setReceiptFile(null)
  }

  const handleConfirm = (hideCallback) => {
    const obj=getObject()
    handleOnConfirm(obj, isEmptyObject(obj), hideCallback)
    resetData()
  }

  const handleHidden = () => {
    handleWhenHidden()
    resetData()
  }

  
  useEffect(()=>{
    if(show){
      getAllData()
    }
  },[show])

  useEffect(()=>{
    setFieldsErrors(fieldsErrorsIn)
  },[fieldsErrorsIn])
  
  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <ModalComponent locale={locale} show={show} 
    handleWhenHidden={handleHidden} handleOnConfirm={handleConfirm} title={t('Transfer Money Info')} 
    confirmBtnText={t('Transfer Money')} cancelBtnText={t('Cancel')} className={styles.TransferMoney} data-testid="TransferMoney"
    isDelayedConfirm={true}>
    <div className={`${styles.FieldsContainer} ${locale=='ar'?'text-right':'text-left'}`}>

      <div className="form-group">
        <label htmlFor="sender_id">{t('Sender')}</label>
        <RenderErrorMessage name='sender_id' messages={fieldsErrors} />
        <div>{`${transferMoneySourceAccount?.user?.first_name} ${transferMoneySourceAccount?.user?.last_name}`}</div>
      </div>

      <div className="form-group">
        <label htmlFor="sender_balance">{t('Sender Balance')}</label>
        <RenderErrorMessage name='sender_balance' messages={fieldsErrors} />
        <div>{`${transferMoneySourceAccount?.balance}`}</div>
      </div>

      <div className={`form-group`}>
        <label htmlFor="recipient_id">{t('Recipient')}</label>
        <RenderErrorMessage name='recipient_id' messages={fieldsErrors} />
        <div>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={accountsOptions}
            onChange={handleChangeRecipientId}
            value={recipient_id}
            placeholder={`${t('Select an')} ${t('account')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="amount">{t('Amount')}</label>
        <RenderErrorMessage name='amount' messages={fieldsErrors} />
        <input type="number" className="form-control" placeholder={`${t('Enter an')} ${t('amount')}`} id="amount" autoComplete="off" onChange={handleChangeAmount} value={amount} />
      </div>

      <div className="form-group">
        <label htmlFor="details">{t('Details')}</label>
        <RenderErrorMessage name='details' messages={fieldsErrors} /> <br />
        <textarea
              className={styles.TextArea}
              value={details}
              onChange={handleChangeDetails}
              ></textarea>
      </div>



      <div className="form-group">
        <label htmlFor="file">{t('Sender Recipient Info File')}</label>
        <RenderErrorMessage name='sender_recipient_file' messages={fieldsErrors} />
        <input type="file" className="form-control" placeholder={`${t('Enter a')} ${t('file')}`} 
            id="file" autoComplete="off" onChange={handleChangeSenderRecipientFile}
            accept="image/*, application/pdf"
        />
      </div>

      <div className="form-group">
        <label htmlFor="file">{t('Receipt File')}</label>
        <RenderErrorMessage name='receipt_file' messages={fieldsErrors} />
        <input type="file" className="form-control" placeholder={`${t('Enter a')} ${t('file')}`} 
            id="file" autoComplete="off" onChange={handleChangeReceiptFile}
            accept="image/*, application/pdf"
        />
      </div>

      
    </div>
  </ModalComponent>
};

TransferMoney.propTypes = {};

TransferMoney.defaultProps = {};

export default TransferMoney;
