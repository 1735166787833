import React, { useEffect, useState } from 'react';
import { useAuth } from '../Auth/AuthHelpers/Auth';
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import ProtectedRoute from '../Auth/AuthHelpers/ProtectedRoute';
import { useTranslation } from 'react-i18next';

import Home from '../../Home/Home'
import Statistics from '../../Statistics/Statistics'
import Mail from '../../Mail/Mail';
import Company from '../../Company/Company'
import Vendor from '../../Vendor/Vendor'
import Agent from '../../Agent/Agent'
import Commissary from '../../Commissary/Commissary'
import Customer from '../../Customer/Customer'
import EmployeeAdmin from '../../EmployeeAdmin/EmployeeAdmin'
import Employee from '../../Employee/Employee'
import Account from '../../Account/Account'
import Support from '../../Support/Support'
import Settings from '../../Settings/Settings'
import Login from '../Auth/Login/Login'
import NoPage from '../NoPage/NoPage';
import StorageComponents from '../../Storage/Storage';
import RoleComponents from '../../Role/Role';
import SubscriptionComponents from '../../Subscription/Subscription';
import Asset from '../../Asset/Asset';
import Expense from '../../Expense/Expense';
import CompanyOffer from '../../CompanyOffer/CompanyOffer';
import SubscribeHome from '../../Subscription/SubscribeHome/SubscribeHome';
import AccountSettings from '../../AccountSettings/AccountSettings';

const RoutesComponent = () => {
  const { t, i18n } = useTranslation();

  const [locale, setLocale] = useState('en');

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  useEffect(()=>{
    if(!auth?.locale){
      auth?.setLocale(locale)
      i18n.changeLanguage(locale)
    }else{
      i18n.changeLanguage(auth?.locale)
      setLocale(auth?.locale)
    }
  },[])

  return <div>
    <Routes>
        <Route exact path='/' element={<Home locale={locale} setLocale={setLocale} />} />
        <Route exact path="/auth/login" element={<Login locale={locale} setLocale={setLocale} />} />
        <Route exact path={`/subscribe`} key={`${prefix}_home_subscribe`} element={<SubscribeHome locale={locale} setLocale={setLocale} />} />
        <Route exact path={`/${prefix}/account_settings`} key={`${prefix}_account_settings`} element={<ProtectedRoute><AccountSettings  locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        
        <Route exact path={`/${prefix}/`} key={`${prefix}_home`} element={<ProtectedRoute><Navigate to={`/${prefix}/dashboard/statistics`} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard`} key={`${prefix}_dashboard`} element={<ProtectedRoute><Navigate to={`/${prefix}/dashboard/statistics`} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/statistics`} key={`${prefix}_statistics`} element={<ProtectedRoute><Statistics locale={locale} setLocale={setLocale} /></ProtectedRoute>} />

        <Route exact path={`/${prefix}/dashboard/mail`} key={`${prefix}_mail`} element={<ProtectedRoute><Mail page="General" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/mail/add`} key={`${prefix}_mail_add`} element={<ProtectedRoute><Mail page="Add" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/mail/view/:id`} key={`${prefix}_mail_view`} element={<ProtectedRoute><Mail page="View" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/mail/edit/:id`} key={`${prefix}_mail_edit`} element={<ProtectedRoute><Mail page="Edit" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/mail/deleted`} key={`${prefix}_mail_deleted`} element={<ProtectedRoute><Mail page="Deleted" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/mail/log`} key={`${prefix}_mail_log`} element={<ProtectedRoute><Mail page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/mail/cart`} key={`${prefix}_mail_cart`} element={<ProtectedRoute><Mail page="Cart" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/mail/view/cart/:id`} key={`${prefix}_mail_cart_view`} element={<ProtectedRoute><Mail page="CartView" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/mail/edit/cart/:id`} key={`${prefix}_mail_edit`} element={<ProtectedRoute><Mail page="CartEdit" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />

        <Route exact path={`/${prefix}/dashboard/company`} key={`${prefix}_company`} element={<ProtectedRoute><Company page="General" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company/add`} key={`${prefix}_company_add`} element={<ProtectedRoute><Company page="Add" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company/view/:id`} key={`${prefix}_company_view`} element={<ProtectedRoute><Company page="View" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company/edit/:id`} key={`${prefix}_company_edit`} element={<ProtectedRoute><Company page="Edit" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company/deleted`} key={`${prefix}_company_deleted`} element={<ProtectedRoute><Company page="Deleted" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company/log`} key={`${prefix}_company_log`} element={<ProtectedRoute><Company page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />


        <Route exact path={`/${prefix}/dashboard/vendor`} key={`${prefix}_vendor`} element={<ProtectedRoute><Vendor page="General" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/vendor/add`} key={`${prefix}_vendor_add`} element={<ProtectedRoute><Vendor page="Add" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/vendor/view/:id`} key={`${prefix}_vendor_view`} element={<ProtectedRoute><Vendor page="View" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/vendor/edit/:id`} key={`${prefix}_vendor_edit`} element={<ProtectedRoute><Vendor page="Edit" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/vendor/deleted`} key={`${prefix}_vendor_deleted`} element={<ProtectedRoute><Vendor page="Deleted" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/vendor/log`} key={`${prefix}_vendor_log`} element={<ProtectedRoute><Vendor page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />

        <Route exact path={`/${prefix}/dashboard/agent`} key={`${prefix}_agent`} element={<ProtectedRoute><Agent page="General" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/agent/add`} key={`${prefix}_agent_add`} element={<ProtectedRoute><Agent page="Add" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/agent/view/:id`} key={`${prefix}_agent_view`} element={<ProtectedRoute><Agent page="View" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/agent/edit/:id`} key={`${prefix}_agent_edit`} element={<ProtectedRoute><Agent page="Edit" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/agent/deleted`} key={`${prefix}_agent_deleted`} element={<ProtectedRoute><Agent page="Deleted" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/agent/log`} key={`${prefix}_agent_log`} element={<ProtectedRoute><Agent page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />

        <Route exact path={`/${prefix}/dashboard/commissary`} key={`${prefix}_commissary`} element={<ProtectedRoute><Commissary page="General" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/commissary/add`} key={`${prefix}_commissary_add`} element={<ProtectedRoute><Commissary page="Add" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/commissary/view/:id`} key={`${prefix}_commissary_view`} element={<ProtectedRoute><Commissary page="View" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/commissary/edit/:id`} key={`${prefix}_commissary_edit`} element={<ProtectedRoute><Commissary page="Edit" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/commissary/deleted`} key={`${prefix}_commissary_deleted`} element={<ProtectedRoute><Commissary page="Deleted" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/commissary/log`} key={`${prefix}_commissary_log`} element={<ProtectedRoute><Commissary page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />

        <Route exact path={`/${prefix}/dashboard/customer`} key={`${prefix}_customer`} element={<ProtectedRoute><Customer page="General" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/customer/add`} key={`${prefix}_customer_add`} element={<ProtectedRoute><Customer page="Add" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/popup/customer/add`} key={`${prefix}_customer_popup_add`} element={<ProtectedRoute><Customer page="Add" locale={locale} setLocale={setLocale} isPopedUP={true} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/customer/view/:id`} key={`${prefix}_customer_view`} element={<ProtectedRoute><Customer page="View" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/customer/edit/:id`} key={`${prefix}_customer_edit`} element={<ProtectedRoute><Customer page="Edit" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/customer/deleted`} key={`${prefix}_customer_deleted`} element={<ProtectedRoute><Customer page="Deleted" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/customer/log`} key={`${prefix}_customer_log`} element={<ProtectedRoute><Customer page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />

        <Route exact path={`/${prefix}/dashboard/employee_admin`} key={`${prefix}_employee_admin`} element={<ProtectedRoute><EmployeeAdmin page="General" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee_admin/add`} key={`${prefix}_employee_admin_add`} element={<ProtectedRoute><EmployeeAdmin page="Add" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee_admin/view/:id`} key={`${prefix}_employee_admin_view`} element={<ProtectedRoute><EmployeeAdmin page="View" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee_admin/edit/:id`} key={`${prefix}_employee_admin_edit`} element={<ProtectedRoute><EmployeeAdmin page="Edit" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee_admin/deleted`} key={`${prefix}_employee_admin_deleted`} element={<ProtectedRoute><EmployeeAdmin page="Deleted" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee_admin/log`} key={`${prefix}_employee_admin_log`} element={<ProtectedRoute><EmployeeAdmin page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />

        <Route exact path={`/${prefix}/dashboard/employee`} key={`${prefix}_employee`} element={<ProtectedRoute><Employee page="General" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee/add`} key={`${prefix}_employee_add`} element={<ProtectedRoute><Employee page="Add" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee/view/:id`} key={`${prefix}_employee_view`} element={<ProtectedRoute><Employee page="View" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee/edit/:id`} key={`${prefix}_employee_edit`} element={<ProtectedRoute><Employee page="Edit" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee/deleted`} key={`${prefix}_employee_deleted`} element={<ProtectedRoute><Employee page="Deleted" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee/log`} key={`${prefix}_employee_log`} element={<ProtectedRoute><Employee page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee/attendance`} key={`${prefix}_employee_attendance`} element={<ProtectedRoute><Employee page="Attendance" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee/:id/attendance/`} key={`${prefix}_employee_attendance`} element={<ProtectedRoute><Employee page="Attendance" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee/vacation`} key={`${prefix}_employee_vacation`} element={<ProtectedRoute><Employee page="Vacation" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee/:id/vacation/`} key={`${prefix}_employee_vacation`} element={<ProtectedRoute><Employee page="Vacation" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee/evaluation`} key={`${prefix}_employee_evaluation`} element={<ProtectedRoute><Employee page="Evaluation" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee/:id/evaluation/`} key={`${prefix}_employee_evaluation`} element={<ProtectedRoute><Employee page="Evaluation" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee/report`} key={`${prefix}_employee_report`} element={<ProtectedRoute><Employee page="Report" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee/:id/report/`} key={`${prefix}_employee_report`} element={<ProtectedRoute><Employee page="Report" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee/salary_deduction`} key={`${prefix}_employee_salary_deduction`} element={<ProtectedRoute><Employee page="SalaryDeduction" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/employee/:id/salary_deduction/`} key={`${prefix}_employee_salary_deduction`} element={<ProtectedRoute><Employee page="SalaryDeduction" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />

        <Route exact path={`/${prefix}/dashboard/account`} key={`${prefix}_account`} element={<ProtectedRoute><Account page="General" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/account/view/:id`} key={`${prefix}_account_view`} element={<ProtectedRoute><Account page="View" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/account/view_details/:id`} key={`${prefix}_account_view_details`} element={<ProtectedRoute><Account page="ViewDetails" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/account/log`} key={`${prefix}_account_log`} element={<ProtectedRoute><Account page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/account/log/accounting`} key={`${prefix}_account_log_accounting`} element={<ProtectedRoute><Account page="LogAccounting" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/account/accounting`} key={`${prefix}_account_accounting`} element={<ProtectedRoute><Account page="Accounting" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/account/vendors_debts`} key={`${prefix}_account_vendors_debts`} element={<ProtectedRoute><Account page="VendorsDebts" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        
        
        <Route exact path={`/${prefix}/dashboard/storage`} key={`${prefix}_storage`} element={<ProtectedRoute><StorageComponents page="Storage" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/storage/add`} key={`${prefix}_storage_add`} element={<ProtectedRoute><StorageComponents page="AddStorage" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/storage/view/:id`} key={`${prefix}_storage_view`} element={<ProtectedRoute><StorageComponents page="ViewStorage" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/storage/edit/:id`} key={`${prefix}_storage_edit`} element={<ProtectedRoute><StorageComponents page="EditStorage" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/storage/deleted`} key={`${prefix}_storage_deleted`} element={<ProtectedRoute><StorageComponents page="DeletedStorage" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/storage/cart`} key={`${prefix}_storage_cart`} element={<ProtectedRoute><StorageComponents page="Cart" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/storage/cart/add`} key={`${prefix}_storage_cart_add`} element={<ProtectedRoute><StorageComponents page="AddCart" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/storage/cart/view/:id`} key={`${prefix}_storage_cart_view`} element={<ProtectedRoute><StorageComponents page="ViewCart" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/storage/cart/edit/:id`} key={`${prefix}_storage_cart_edit`} element={<ProtectedRoute><StorageComponents page="EditCart" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/storage/cart/deleted`} key={`${prefix}_storage_cart_deleted`} element={<ProtectedRoute><StorageComponents page="DeletedCart" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/storage/item`} key={`${prefix}_storage_item`} element={<ProtectedRoute><StorageComponents page="CartItem" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/storage/item/add`} key={`${prefix}_storage_item_add`} element={<ProtectedRoute><StorageComponents page="AddItem" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/storage/item/view/:id`} key={`${prefix}_storage_item_view`} element={<ProtectedRoute><StorageComponents page="ViewItem" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/storage/item/edit/:id`} key={`${prefix}_storage_item_edit`} element={<ProtectedRoute><StorageComponents page="EditItem" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/storage/item/deleted`} key={`${prefix}_storage_item_deleted`} element={<ProtectedRoute><StorageComponents page="DeletedItem" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/storage/log`} key={`${prefix}_storage_log`} element={<ProtectedRoute><StorageComponents page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        
        <Route exact path={`/${prefix}/dashboard/role`} key={`${prefix}_role`} element={<ProtectedRoute><RoleComponents page="General" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/role/add`} key={`${prefix}_role_add`} element={<ProtectedRoute><RoleComponents page="Add" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/role/view/:id`} key={`${prefix}_role_view`} element={<ProtectedRoute><RoleComponents page="View" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/role/edit/:id`} key={`${prefix}_role_edit`} element={<ProtectedRoute><RoleComponents page="Edit" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/role/:id/privileges`} key={`${prefix}_role_edit`} element={<ProtectedRoute><RoleComponents page="Privileges" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/role/deleted`} key={`${prefix}_role_deleted`} element={<ProtectedRoute><RoleComponents page="Deleted" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/role/log`} key={`${prefix}_role_log`} element={<ProtectedRoute><RoleComponents page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />


        <Route exact path={`/${prefix}/dashboard/subscription`} key={`${prefix}_subscription`} element={<ProtectedRoute><SubscriptionComponents page="General" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/subscription/add`} key={`${prefix}_subscription_add`} element={<ProtectedRoute><SubscriptionComponents page="Add" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/subscription/view/:id`} key={`${prefix}_subscription_view`} element={<ProtectedRoute><SubscriptionComponents page="View" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/subscription/edit/:id`} key={`${prefix}_subscription_edit`} element={<ProtectedRoute><SubscriptionComponents page="Edit" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/subscription/:id/privileges`} key={`${prefix}_subscription_edit`} element={<ProtectedRoute><SubscriptionComponents page="Privileges" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/subscription/deleted`} key={`${prefix}_subscription_deleted`} element={<ProtectedRoute><SubscriptionComponents page="Deleted" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/subscription/log`} key={`${prefix}_subscription_log`} element={<ProtectedRoute><SubscriptionComponents page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/subscription/subscribe`} key={`${prefix}_subscription_subscribe`} element={<ProtectedRoute><SubscriptionComponents page="Subscribe" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/subscription/:id/features`} key={`${prefix}_subscription_features`} element={<ProtectedRoute><SubscriptionComponents page="ManageFeatures" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/subscription/requests`} key={`${prefix}_subscription_requests`} element={<ProtectedRoute><SubscriptionComponents page="ManageRequests" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/subscription/requests/public`} key={`${prefix}_subscription_public_requests`} element={<ProtectedRoute><SubscriptionComponents page="ManagePublicRequests" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/subscription/companies_subscriptions`} key={`${prefix}_subscription_companies_subscriptions`} element={<ProtectedRoute><SubscriptionComponents page="CompaniesSubscriptions" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/subscription/requests/public/view/:id`} key={`${prefix}_subscription_public_requests_view`} element={<ProtectedRoute><SubscriptionComponents page="ViewPublicRequest" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        
        
        <Route exact path={`/${prefix}/dashboard/asset`} key={`${prefix}_assets`} element={<ProtectedRoute><Asset page="General" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/asset/add`} key={`${prefix}_assets_add`} element={<ProtectedRoute><Asset page="Add" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/asset/view/:id`} key={`${prefix}_assets_view`} element={<ProtectedRoute><Asset page="View" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/asset/edit/:id`} key={`${prefix}_assets_edit`} element={<ProtectedRoute><Asset page="Edit" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/asset/:id/privileges`} key={`${prefix}_assets_edit`} element={<ProtectedRoute><Asset page="Privileges" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/asset/deleted`} key={`${prefix}_assets_deleted`} element={<ProtectedRoute><Asset page="Deleted" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/asset/log`} key={`${prefix}_assets_log`} element={<ProtectedRoute><Asset page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />


        <Route exact path={`/${prefix}/dashboard/expense`} key={`${prefix}_expenses`} element={<ProtectedRoute><Expense page="General" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/expense/add`} key={`${prefix}_expenses_add`} element={<ProtectedRoute><Expense page="Add" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/expense/view/:id`} key={`${prefix}_expenses_view`} element={<ProtectedRoute><Expense page="View" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/expense/edit/:id`} key={`${prefix}_expenses_edit`} element={<ProtectedRoute><Expense page="Edit" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/expense/:id/privileges`} key={`${prefix}_expenses_edit`} element={<ProtectedRoute><Expense page="Privileges" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/expense/deleted`} key={`${prefix}_expenses_deleted`} element={<ProtectedRoute><Expense page="Deleted" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/expense/log`} key={`${prefix}_expenses_log`} element={<ProtectedRoute><Expense page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />

        <Route exact path={`/${prefix}/dashboard/company_offer`} key={`${prefix}_company_offer`} element={<ProtectedRoute><CompanyOffer page="General" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company_offer/add`} key={`${prefix}_company_offer_add`} element={<ProtectedRoute><CompanyOffer page="Add" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company_offer/view/:id`} key={`${prefix}_company_offer_view`} element={<ProtectedRoute><CompanyOffer page="View" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company_offer/edit/:id`} key={`${prefix}_company_offer_edit`} element={<ProtectedRoute><CompanyOffer page="Edit" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company_offer/:id/privileges`} key={`${prefix}_company_offer_edit`} element={<ProtectedRoute><CompanyOffer page="Privileges" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company_offer/deleted`} key={`${prefix}_company_offer_deleted`} element={<ProtectedRoute><CompanyOffer page="Deleted" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company_offer/log`} key={`${prefix}_company_offer_log`} element={<ProtectedRoute><CompanyOffer page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company_offer/companies_offers`} key={`${prefix}_company_offer_companies_offers`} element={<ProtectedRoute><CompanyOffer page="CompaniesOffers" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company_offer/companies_offers/:id`} key={`${prefix}_company_offer_companies_offers_id`} element={<ProtectedRoute><CompanyOffer page="CompaniesOffers" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company_offer/sent_offers`} key={`${prefix}_company_offer_sent_offers`} element={<ProtectedRoute><CompanyOffer page="SentOffers" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company_offer/received_offers`} key={`${prefix}_company_offer_received_offers`} element={<ProtectedRoute><CompanyOffer page="ReceivedOffers" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/company_offer/sent_offer/view/:id`} key={`${prefix}_company_offer_sent_request_view`} element={<ProtectedRoute><CompanyOffer page="ViewOfferRequest" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />

        <Route exact path={`/${prefix}/dashboard/support`} key={`${prefix}_support`} element={<ProtectedRoute><Support page="General" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/support/add`} key={`${prefix}_support_add`} element={<ProtectedRoute><Support page="Add" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/support/view/:id`} key={`${prefix}_support_view`} element={<ProtectedRoute><Support page="View" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/support/edit/:id`} key={`${prefix}_support_edit`} element={<ProtectedRoute><Support page="Edit" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/support/deleted`} key={`${prefix}_support_deleted`} element={<ProtectedRoute><Support page="Deleted" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/support/log`} key={`${prefix}_support_log`} element={<ProtectedRoute><Support page="Log" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/support/open_chat`} key={`${prefix}_support_open_chat`} element={<ProtectedRoute><Support page="OpenChat" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/support/view_chat/:id`} key={`${prefix}_support_view_chat`} element={<ProtectedRoute><Support page="ViewChat" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/support/send_email`} key={`${prefix}_support_send_email`} element={<ProtectedRoute><Support page="SendEmail" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />
        <Route exact path={`/${prefix}/dashboard/support/send_sms`} key={`${prefix}_support_send_sms`} element={<ProtectedRoute><Support page="SendSMS" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />

        <Route exact path={`/${prefix}/dashboard/settings`} key={`${prefix}_settings`} element={<ProtectedRoute><Settings page="Settings" locale={locale} setLocale={setLocale} /></ProtectedRoute>} />

        <Route path="*" element={<NoPage />} />
    </Routes>
  </div>
};

RoutesComponent.propTypes = {};

RoutesComponent.defaultProps = {};

export default RoutesComponent;
