import React from 'react';
import PropTypes from 'prop-types';
import styles from './NoPrivilegesMessage.module.css';
import { useTranslation } from 'react-i18next';

const NoPrivilegesMessage = () => {
  const { t, i18n } = useTranslation();

  return <div className={styles.NoPrivilegesMessage+' alert alert-danger'} data-testid="NoPrivilegesMessage">
    {t('You don\'t have the privileges to view the content')}
  </div>
};

NoPrivilegesMessage.propTypes = {};

NoPrivilegesMessage.defaultProps = {};

export default NoPrivilegesMessage;
