import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './AddEdit.module.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';

import { useTranslation } from 'react-i18next';
import $ from 'jquery'

const AddEdit = ({id, handleAddEdit, generalFetchingServiceRef, locale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [fieldsErrors, setFieldsErrors] = useState([]);

  const [companiesData, setCompaniesData] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])

  const [governoratesData, setGovernoratesData] = useState([])
  const [governoratesOptions, setGovernoratesOptions] = useState([])

  const [districtsData, setDistrictsData] = useState([])
  const [districtsOptions, setDistrictsOptions] = useState([])
  
  const [neighborhoodsData, setNeighborhoodsData] = useState([])
  const [neighborhoodsOptions, setNeighborhoodsOptions] = useState([])
  
  
  const [company_id, setCompanyId] = useState(null)
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone1, setPhone1] = useState("")
  const [phone2, setPhone2] = useState("")
  const [governorate_id, setGovernorateId] = useState(null)
  const [district_id, setDistrictId] = useState(null)
  const [neighborhood_id, setNeighborhoodId] = useState(null)
  const [address, setAddress] = useState("")

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let companies=data?.companies
          let companiesOptions=companies?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setCompaniesData(companies)
          setCompaniesOptions(companiesOptions)

          let governorates=data?.governorates
          let governoratesOptions=governorates?.map(x => {return {'value':x.id, 'name': x.name}})
          setGovernoratesData(governorates)
          setGovernoratesOptions(governoratesOptions)

          let districts=data?.districts
          setDistrictsData(districts)

          let neighborhoods=data?.neighborhoods
          setNeighborhoodsData(neighborhoods)

          if(id){
            getById(id)
          }
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let data={
      company_id:(prefix=='company')?(auth?.user?.user_info?.company?.id):(companiesData.filter(x => x.id == parseInt(company_id))[0]?.id),
      first_name,
      last_name,
      email,
      phone1,
      phone2,
      governorate_id:governoratesData.filter(x => x.id == parseInt(governorate_id))[0]?.id,
      district_id:districtsData.filter(x => x.id == parseInt(district_id))[0]?.id,
      neighborhood_id:neighborhoodsData.filter(x => x.id == parseInt(neighborhood_id))[0]?.id,
      address,
    };
    return data;
  }
  const handleAddBtnClick=()=>{
    let item=getObject()
    handleAddEdit(item, setFieldsErrors, resetData)
    window.scrollTo(0, 0);
  }
  
  const handleEditBtnClick=()=>{
    let item=getObject()
    item.id=id
    handleAddEdit(item, setFieldsErrors)
    window.scrollTo(0, 0);
  }

  const resetData = () => {
    setCompanyId(null)
    setFirstName('')
    setLastName('')
    setEmail('')
    setPhone1('')
    setPhone2('')
    setGovernorateId(null)
    setDistrictId(null)
    setNeighborhoodId(null)
    setAddress('')
  }

  const getDistrictsOptions = (value) =>{
    return districtsData?.filter(x => x.governorate_id == value).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const getNeighborhoodsOptions = (value) =>{
    return neighborhoodsData?.filter(x => x.district_id == value).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const handleChangeCompany=(value)=>{
    setCompanyId(value)
  }
  const handleChangeFirstName=(e)=>{
    setFirstName(e.target.value)
  }
  const handleChangeLastName=(e)=>{
    setLastName(e.target.value)
  }
  const handleChangeEmail=(e)=>{
    setEmail(e.target.value)
  }
  const handleChangePhone1=(e)=>{
    setPhone1(e.target.value)
  }
  const handleChangePhone2=(e)=>{
    setPhone2(e.target.value)
  }
  const handleChangeGovernorate=(value)=>{
    const districtsOptions=getDistrictsOptions(value)
    setDistrictsOptions(districtsOptions)
    setGovernorateId(value)
  }
  const handleChangeDistrict=(value)=>{
    const neighborhoodsOptions=getNeighborhoodsOptions(value)
    setNeighborhoodsOptions(neighborhoodsOptions)
    setDistrictId(value)
  }
  const handleChangeNeighborhood=(value)=>{
    setNeighborhoodId(value)
  }
  const handleChangeAddress=(e)=>{
    setAddress(e.target.value)
  }
  
  const setAllData=(data)=>{
    let item=data[0]
    setCompanyId(item?.company_id)
    setFirstName(item?.first_name)
    setLastName(item?.last_name)
    setEmail(item?.email)
    setPhone1(item?.phone1)
    setPhone2(item?.phone2?item?.phone2:'')
    setGovernorateId(item?.governorate_id)
    setDistrictId(item?.district_id)
    setNeighborhoodId(item?.neighborhood_id)
    setAddress(item?.address?item?.address:'')
  }

  let isLoaded=false
  const getById = (id) => {
    generalFetchingServiceRef?.current?.getById(id)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setAllData(data)
          }
        }).catch(error => {
          console.log(error)
        })
  }
  useEffect(()=>{
    getAllData()
  },[])

  useEffect(()=>{
    if(!isLoaded){
      if(id){
        const districtsOptions=getDistrictsOptions(governorate_id)
        setDistrictsOptions(districtsOptions)

        const neighborhoodsOptions=getNeighborhoodsOptions(governorate_id)
        setNeighborhoodsOptions(neighborhoodsOptions)
      }
      isLoaded=true
    }
  },[governorate_id])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <div className={styles.AddEdit + ` ${locale=='ar'?'text-right':'text-left'}`} data-testid="AddEdit">

    {(prefix!='company') && <div className="form-group">
      <label htmlFor="company_id">{t('Company')}</label>
      <RenderErrorMessage name='company_id' messages={fieldsErrors} />
      <SelectSearch id={styles.Select}
        onFocus={handleOnFocusSelect}
        options={companiesOptions}
        onChange={handleChangeCompany}
        value={company_id}
        placeholder={`${t('Select a')} ${t('company')}`}
        search={true}
        filterOptions={fuzzySearch}
        />
    </div>}

    <div className="form-group">
      <label htmlFor="first_name">{t('First Name')}</label>
      <RenderErrorMessage name='first_name' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('First Name')}`} id="first_name" onChange={handleChangeFirstName} value={first_name} />
    </div>
       

    <div className="form-group">
      <label htmlFor="last_name">{t('Last Name')}</label>
      <RenderErrorMessage name='last_name' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('Last Name')}`} id="last_name" onChange={handleChangeLastName} value={last_name} />
    </div>

    <div className="form-group">
      <label htmlFor="email">{t('Email')}</label>
      <RenderErrorMessage name='email' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter an')} ${t('Email')}`} id="email" onChange={handleChangeEmail} value={email} />
    </div>

    <div className="form-group">
      <label htmlFor="phone1">{t('Phone')+' 1'}</label>
      <RenderErrorMessage name='phone1' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('Phone')} 1`} id="phone1" onChange={handleChangePhone1} value={phone1} />
    </div>

    <div className="form-group">
      <label htmlFor="phone2">{t('Phone')+' 2'}</label>
      <RenderErrorMessage name='phone2' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('Phone')} 2`} id="phone2" onChange={handleChangePhone2} value={phone2} />
    </div>

    <div className={styles.AddressGroup+" form-group card"} style={{'padding':'10px'}}>
      <label>{t('Address Details')}</label>
      <div style={{'padding':'20px'}}>
        <RenderErrorMessage name='governorate_id' messages={fieldsErrors} />
        <RenderErrorMessage name='district_id' messages={fieldsErrors} />
        <RenderErrorMessage name='address' messages={fieldsErrors} />
        <span>
          <label htmlFor="governorate_id">{t('Governorate')}</label>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={governoratesOptions}
            onChange={handleChangeGovernorate}
            value={governorate_id}
            placeholder={`${t('Select a')} ${t('governorate')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </span><br/>
        <span>
          <label htmlFor="district_id">{t('District')}</label>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={districtsOptions}
            onChange={handleChangeDistrict}
            value={district_id}
            placeholder={`${t('Select a')} ${t('district')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </span><br/>
        <span>
          <label htmlFor="neighborhood_id">{t('Neighborhood')}</label>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={neighborhoodsOptions}
            onChange={handleChangeNeighborhood}
            value={neighborhood_id}
            placeholder={`${t('Select a')} ${t('neighborhood')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </span><br/>
        <span>
          <label htmlFor="address">{t('Address')}</label> <br/>
          <input type={'text'} value={address} onChange={handleChangeAddress} />
        </span>
      </div>
    </div>

    <div className="form-group text-center">
      {!id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleAddBtnClick}>{t('Add')}</button>}
      {id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleEditBtnClick}>{t('Edit')}</button>}
    </div>

  </div>
};

AddEdit.propTypes = {};

AddEdit.defaultProps = {};

export default AddEdit;
