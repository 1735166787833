import axios from "axios";
import {useImperativeHandle, useState} from 'react';
import { useAuth } from "../components/Common/Auth/AuthHelpers/Auth";

const FetchDataService = ({fetchDataServiceRef}) => {
    const auth = useAuth();

    const _GetConfig = (isAll, isDownload=false, isMultipart=false) => {
        let axiosConfig = {
            headers: {
                'Accept': 'application/json', 
                'Content-Type': 'application/json',
                'mode': 'cors',
                'X-Requested-With':'XMLHttpRequest',
                'crossDomain':true,
                'Access-Control-Allow-Origin':'*'
            },
            baseURL:process.env.REACT_APP_API_URL
        };
        if(isDownload){
            axiosConfig.responseType= 'blob';
        }
        if(!isAll){
            let itemsPerPage=process.env.REACT_APP_ITEMS_PER_PAGE
            if(itemsPerPage){
                axiosConfig.headers['items_per_page'] = itemsPerPage
            }
        }

        if(auth?.locale){
            axiosConfig.headers['locale'] = auth?.locale
        }
        
        if(auth?.user?.access_token){
            axiosConfig.headers['Authorization']=`Bearer ${auth?.user?.access_token}`
        }

        if(isMultipart){
            axiosConfig.headers['Content-Type']='multipart/form-data'
        }
        return axiosConfig
    }

    const refreshToken = async () => {
        return await axios.post('auth/refresh',null, _GetConfig());
    }

    const GetAxios = () => {
        axios.interceptors.response.use(
            (response) => {
                return response
            },
            async (error) => {
                const originalConfig = error.config;

                if (error.response) {
                    if(error.response.status === 403){
                        auth.logout()
                    }
                    if (error.response.status === 401 && !originalConfig._retry) {
                        originalConfig._retry = true;
                        try {
                            const rs = await refreshToken();
                            let responseData=rs?.data
                            if(responseData?.status==1 && responseData?.message){
                                let token=responseData?.message
                                await auth.updateAccessToken(token)
                                originalConfig.headers['Authorization']=`Bearer ${token}`
                            }
                    
                            return axios(originalConfig);
                        } catch (_error) {
                            if (_error.response && _error.response.data) {
                                return Promise.reject(_error.response.data);
                            }
                    
                            return Promise.reject(_error);
                        }
                    }
                }

                return Promise.reject(error);
            }
        );
        return axios
    }

    useImperativeHandle(fetchDataServiceRef,()=>({
        PostRequest (path, request, isAll, isMultipart=false) {
            console.log("Axios", path, request)
            return GetAxios()
                .post(path, request, _GetConfig(isAll, false, isMultipart));
        },

        PostDownloadRequest (path, request, isAll) {
            console.log("Axios#PostDownload", path, request)
            return GetAxios()
                .post(path, request, _GetConfig(isAll, true));
        },

        async PostRequestAsync (path, request, isAll) {
            console.log("Axios", path, request)
            return await GetAxios()
                .post(path, request, _GetConfig(isAll));
        },

        GetRequest (path, request, isAll) {
            return GetAxios()
                    .get(path+'?'+ new URLSearchParams(request), _GetConfig(isAll));
        }
    }))
    

    return <span>
    </span>
}

export default FetchDataService