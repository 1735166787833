import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './Subscribe.module.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';

import { useTranslation } from 'react-i18next';

import $ from 'jquery'
import GeneralHelpers from '../../../Services/GeneralHelpers';


const Subscribe = ({id, generalFetchingServiceRef, locale, showMessage}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [fieldsErrors, setFieldsErrors] = useState([]);
  
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [currentRequestAction, setCurrentRequestAction] = useState(null);
  const [availableSubscriptions, setAvailableSubscriptions] = useState([]);
  const [isCompany, setIsCompany] = useState(false);
  const [total, setTotal] = useState(0);

  const [subscription_periods, setSubscriptionPeriods] = useState([]);
  const [subscriptionPeriodData, setSubscriptionPeriodData] = useState([]);
  const [subscriptionPeriodOptions, setSubscriptionPeriodOptions] = useState([]);

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData('feature/available')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data
          setTotal(responseData?.total)
          setCurrentSubscription(data?.subscription)
          setCurrentRequest(data?.subscriptionRequest)
          setAvailableSubscriptions(data?.subscriptions)
          setCurrentRequestAction(data?.requestAction)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const handleSubscribe = (action, subId, i) => {
    const selectedPeriod=subscription_periods[i]
    generalFetchingServiceRef?.current?.update({action, selected_period:selectedPeriod}, [subId], 'company/subscribe/request')
      .then(response => {
        let responseData=response?.data
        let message=responseData?.message
        if(responseData && responseData?.status==1){
          showMessage(true, message)
          getAllData()
        }else{
          showMessage(false, message)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  
  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }
  

  useEffect(()=>{
    getAllData()
    if(prefix=='company'){
      setIsCompany(true)
    }
  },[])

  const isShowSubscribeButton = (subscription, currentSubscription) => {
    if(currentSubscription){
      if(subscription?.id!=currentSubscription?.id){
        return false
      }
    }
    return true
  }

  const handleChangePeriod = (value, i) => {
    setSubscriptionPeriods(subscription_periods.map((x, ind)=> ind==i?value:x))
  }

  useEffect(()=>{
    if(availableSubscriptions){
      const periods=[
        {days:7},
        {days:30},
        {days:180},
        {days:365},
      ]

      const periodsOptions=periods?.map(x => {return {'value':x?.days, 'name': x?.days+' '+t('days')}})
      setSubscriptionPeriodOptions(periodsOptions)

      const amountsOfPeriods=availableSubscriptions?.map((s, i) => {
        return [
          {days:7, amount:s?.add_amount_for_7_days},
          {days:30, amount:s?.add_amount_for_30_days},
          {days:180, amount:s?.add_amount_for_180_days},
          {days:365, amount:s?.add_amount_for_365_days},
        ]
      })
      setSubscriptionPeriodData(amountsOfPeriods)

      setSubscriptionPeriods(amountsOfPeriods.map((x, ind) => {
        const sub=availableSubscriptions?.filter((s, i) => ind == i)[0]
        const type=sub?.payment_type?.name;
        if(sub?.id==currentSubscription?.id){
          return currentSubscription?.subscription_period
        }
        if(sub?.id==currentRequest?.id){
          console.log(currentRequest?.subscription_period)
          return currentRequest?.subscription_period
        }
        if(type=='monthly'){
          return 30
        }
        if(type=='yearly'){
          return 365
        }
        return 0
      }))
    }
  },[availableSubscriptions, currentSubscription, currentRequest])

  return <div className={styles.Subscribe + ` ${locale=='ar'?'text-right':'text-left'} card p-5`} data-testid="Subscribe">
    {isCompany && availableSubscriptions && <div className='mb-2 h4'>{`${t('Available Subscriptions')} (${total})`}</div>}
    {(isCompany && availableSubscriptions) && availableSubscriptions.map((subscription, i)=>{
        const amountToAdd=subscriptionPeriodData?.filter((x, ind) => ind==i)[0]?.filter(x => x?.days==subscription_periods[i])[0]?.amount
        return <div className={`card d-flex flex-column p-2
        ${(subscription?.id == currentSubscription?.id)?'mb-4 border border-success':
        (subscription?.id == currentRequest?.id)?'mb-4 border border-warning':
        'mb-3 '}`}
        key={subscription?.id}>
          <div className='d-flex flex-row'>
            <div className='p-4 flex-fill'>
              <div><span className='font-weight-bold'>{t('Name')}</span>&nbsp;&nbsp;<span>{subscription?.name}</span></div>
              <div><span className='font-weight-bold'>{t('Payment Type')}</span>&nbsp;&nbsp;<span>{t(subscription?.payment_type?.name)}</span></div>
              <div><span className='font-weight-bold'>{t('Price')}</span>&nbsp;&nbsp;<span>{subscription?.price + (amountToAdd || 0)}</span></div>
              <div><span className='font-weight-bold'>{t('Discount')}</span>&nbsp;&nbsp;<span>{subscription?.discount}</span></div>
              <div><span className='font-weight-bold'>{t('Price after discount')}</span>&nbsp;&nbsp;<span>{(subscription?.price + (amountToAdd || 0))-subscription?.discount}</span></div>
              <div><span className='font-weight-bold'>{t('Features Limit')}</span>&nbsp;&nbsp;<span>{subscription?.features_limit}</span></div>
              <div><span className='font-weight-bold'>{t('Description')}</span>&nbsp;&nbsp;<textarea className={styles.DisplayTextArea} readOnly={true} value={subscription?.description}></textarea></div>
              <div className={styles.RequestButtonContainer}>
                {isShowSubscribeButton(subscription, currentSubscription) && <div><span className='font-weight-bold'>{t('Subscription Date')}</span>&nbsp;&nbsp;<span>{
                  subscription?.subscription_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(subscription?.subscription_date)).locale('es').format('yyyy-MM-DD'):''
                }</span></div>}
                {isShowSubscribeButton(subscription, currentSubscription) && <div><span className='font-weight-bold'>{t('Expiary Date')}</span>&nbsp;&nbsp;<span>{
                  subscription?.subscription_expiary_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(subscription?.subscription_expiary_date)).locale('es').format('yyyy-MM-DD'):''
                }</span></div>}
              </div>
             

              <br/>
              {isShowSubscribeButton(subscription, currentSubscription) && <div  className={styles.RequestButtonContainer}><button className={`btn ${(subscription?.id == currentRequest?.id)?'btn-warning':
                                        (subscription?.id == currentSubscription?.id)?'btn-danger':'btn-primary'}`} 
                    onClick={()=>handleSubscribe(
                      (subscription?.id == currentRequest?.id)?'CancelRequest':
                      (subscription?.id == currentSubscription?.id)?'CancelSubscription':
                      'Subscribe', 
                      subscription?.id, i)}>
                {(subscription?.id == currentRequest?.id && currentRequestAction=='subscribe')?t('Cancel Subscription Request'):
                (subscription?.id == currentRequest?.id && currentRequestAction=='unsubscribe')?t('Cancel Subscription Canceling Request'):
                (subscription?.id == currentSubscription?.id)?t('Request Cancel Subscription'):
                t('Request Subscription')}
              </button>
              
                <SelectSearch id={styles.Select}
                    onFocus={handleOnFocusSelect}
                    menuPlacement="top"
                    options={subscriptionPeriodOptions}
                    onChange={(value)=>handleChangePeriod(value, i)}
                    value={subscription_periods[i]}
                    placeholder={`${t('Select a')} ${t('subscription period')}`}
                    search={true}
                    filterOptions={fuzzySearch}
                    disabled={subscription?.id == currentRequest?.id || subscription?.id == currentSubscription?.id}
                    />
              </div>}
            </div>
            {subscription?.features && subscription?.features?.length>0 && <div className='p-4 flex-fill'>
              <span className='card font-weight-bolder text-center p-2'>{t('Features')}</span>
              <table className='table table-stripped table-bordered text-center'>
                <thead className='thead-dark'>
                  <tr>
                  <th>{t('Feature')}</th>
                  <th>{t('Price')}</th>
                  </tr>
                </thead>
                <tbody>
                {subscription?.features?.map(feature=> {
                  return <tr key={feature?.id}>
                    <td>{feature?.name}</td>
                    <td>{feature?.price + ((amountToAdd / subscription?.features?.length) || 0)}</td>
                  </tr>
                })}
                </tbody>
              </table>
            </div>}
          </div>
          {(i==0 && currentSubscription!==null) && <small className='text-danger'><br/> {t('Note that to change subscription, you must cancel subscription, then request another one!')}</small>}
        </div>
    })}
    {isCompany && availableSubscriptions?.length==0 && <div>
      <br/><br/>
      <div className='w-75 text-center alert alert-info p-2 m-auto'>{t('No items to display')}</div>
    </div>}
    {!isCompany && availableSubscriptions?.length==0 && <div>
      <div className='w-75 text-center alert alert-danger p-2 m-auto'>{t('Current User Account is not a company!')}</div>
    </div>}
  </div>
};

Subscribe.propTypes = {};

Subscribe.defaultProps = {};

export default Subscribe;
