import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './UpdateAdd.module.css';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../Common/ModalComponent/ModalComponent';
import RenderErrorMessage from '../../../Common/RenderErrorMessage/RenderErrorMessage';
import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import { useAuth } from '../../../Common/Auth/AuthHelpers/Auth';
import GeneralHelpers from '../../../../Services/GeneralHelpers';

import $ from 'jquery'

const UpdateAdd = ({id, locale, show, generalFetchingServiceRef, handleWhenHidden, handleOnConfirm, isRequested, periods, selectedSubscriptionIndex, subscriptionPeriodData, subscriptionAmount}) => {
  const { t, i18n } = useTranslation();

  const [fieldsErrors, setFieldsErrors] = useState([]);
  
  const [governoratesData, setGovernoratesData] = useState([])
  const [governoratesOptions, setGovernoratesOptions] = useState([])

  const [districtsData, setDistrictsData] = useState([])
  const [districtsOptions, setDistrictsOptions] = useState([])

  const [neighborhoodsData, setNeighborhoodsData] = useState([])
  const [neighborhoodsOptions, setNeighborhoodsOptions] = useState([])
  
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [governorate_id, setGovernorateId] = useState(null)
  const [district_id, setDistrictId] = useState(null)
  const [neighborhood_id, setNeighborhoodId] = useState(null)
  const [address, setAddress] = useState("")
  
  const [name, setName] = useState("")
  const [holder_name, setHolderName] = useState("")
  
  const [error_message, setErrorMessage] = useState(null);

  const selectedPeriod=periods[selectedSubscriptionIndex]
  const amountToAdd=subscriptionPeriodData?.filter((x, ind) => ind==selectedSubscriptionIndex)[0]?.filter(x => x?.days==periods[selectedSubscriptionIndex])[0]?.amount


  const handleChangeFirstName=(e)=>{
    setFirstName(e.target.value)
  }
  const handleChangeLastName=(e)=>{
    setLastName(e.target.value)
  }
  const handleChangeEmail=(e)=>{
    setEmail(e.target.value)
  }
  const handleChangePhone=(e)=>{
    setPhone(e.target.value)
  }
  const handleChangeGovernorate=(value)=>{
    const districtsOptions=getDistrictsOptions(value)
    setDistrictsOptions(districtsOptions)
    setGovernorateId(value)
  }
  const handleChangeDistrict=(value)=>{
    const neighborhoodsOptions=getNeighborhoodsOptions(value)
    setNeighborhoodsOptions(neighborhoodsOptions)
    setDistrictId(value)
  }
  const handleChangeNeighborhood=(value)=>{
    setNeighborhoodId(value)
  }
  const handleChangeAddress=(e)=>{
    setAddress(e.target.value)
  }

  const handleChangeName=(e)=>{
    setName(e.target.value)
  }
  const handleChangeHolderName=(e)=>{
    setHolderName(e.target.value)
  }
  

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData('public_requests')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let governorates=data?.governorates
          let governoratesOptions=governorates?.map(x => {return {'value':x.id, 'name': x.name}})
          setGovernoratesData(governorates)
          setGovernoratesOptions(governoratesOptions)

          let districts=data?.districts
          setDistrictsData(districts)

          let neighborhoods=data?.neighborhoods
          setNeighborhoodsData(neighborhoods)

        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let data={
      subscription_id:id,
      first_name,
      last_name,
      email,
      phone,
      governorate_id:governoratesData.filter(x => x.id == parseInt(governorate_id))[0]?.id,
      district_id:districtsData.filter(x => x.id == parseInt(district_id))[0]?.id,
      neighborhood_id:neighborhoodsData.filter(x => x.id == parseInt(neighborhood_id))[0]?.id,
      address,

      name,
      holder_name,
      subscription_period:selectedPeriod
    };
    return data;
  }

  const isEmptyObject = (obj) => {
    return !obj.first_name && !obj.last_name && !obj.name && !obj.holder_name &&
    !obj.email && !obj.phone && !obj.governorate_id && !obj.district_id && !obj.address
  }

  const resetData = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setPhone('')
    setGovernorateId(null)
    setDistrictId(null)
    setNeighborhoodId(null)
    setAddress('')
    setName('')
    setHolderName('')
  }

  const getDistrictsOptions = (value) =>{
    return districtsData?.filter(x => x.governorate_id == value).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const getNeighborhoodsOptions = (value) =>{
    return neighborhoodsData?.filter(x => x.district_id == value).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const handleConfirm = (hideCallback) => {
    const obj=getObject()
    handleOnConfirm(id, obj, isEmptyObject(obj), function(isSuccess, error, errorMessage){
      if(!isSuccess){
        if(error){
          console.log(error)
          let response=error?.response
          if(response.status==422){
            setFieldsErrors(response?.data?.errors)
          }
        }else{
          setErrorMessage(errorMessage)
        }
        
      }else{
        resetData()
        hideCallback()
      }
      window.scrollTo(0, 0)
    })

  }

  const handleHidden = () => {
    handleWhenHidden()
    resetData()
  }


  useEffect(()=>{
    if(show){
      getAllData()
    }
  },[show])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

 
  return <ModalComponent locale={locale} show={show} handleWhenHidden={handleHidden} handleOnConfirmDelayed={handleConfirm} title={`${t('Request Subscription')}`} confirmBtnText={t('Request')} cancelBtnText={isRequested?t('Hide'):t('Cancel')} isConfirmVisible={!isRequested} className={styles.Update} data-testid="Update">
    {(show && !isRequested) && <div>
        <div className={`${styles.FieldsContainer} ${locale=='ar'?'text-right':'text-left'}`}>

        <RenderErrorMessage name='error_message' messages={{error_message}} alertDisplay={true} />

        

        <div className="form-group d-flex justify-content-between">
          <div className='d-flex flex-column text-center pr-2 pl-2 card'>
            <label htmlFor="name" className='font-weight-bold'>{t('Selected Subscription Period')}</label>
            <div>{periods?.filter((x, ind)=>ind==selectedSubscriptionIndex)[0]}</div>
          </div>
          <div className='d-flex flex-column text-center pr-2 pl-2 card'>
            <label htmlFor="name" className='font-weight-bold'>{t('Final Subscription Amount')}</label>
            <div>{subscriptionAmount + (amountToAdd || 0)}</div>
          </div>
        </div>
        
        <div className="form-group">
        <label htmlFor="name">{t('Company Name')}</label>
        <RenderErrorMessage name='name' messages={fieldsErrors} />
        <RenderErrorMessage name='data.name' messages={fieldsErrors} />
        <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('name')}`} id="name" onChange={handleChangeName} value={name} />
        </div>

        <div className="form-group">
        <label htmlFor="holder_name">{t('Company Holder Name')}</label>
        <RenderErrorMessage name='holder_name' messages={fieldsErrors} />
        <RenderErrorMessage name='data.holder_name' messages={fieldsErrors} />
        <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('holder name')}`} id="holder_name" onChange={handleChangeHolderName} value={holder_name} />
        </div>

        <div className="form-group">
        <label htmlFor="first_name">{t('First Name')} &nbsp;<small className='text-info'>{t('for user who sign in to company account')}</small></label>
        <RenderErrorMessage name='first_name' messages={fieldsErrors} />
        <RenderErrorMessage name='data.first_name' messages={fieldsErrors} />
        <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('First Name')}`} id="first_name" onChange={handleChangeFirstName} value={first_name} />
        </div>
        

        <div className="form-group">
        <label htmlFor="last_name">{t('Last Name')} &nbsp;<small className='text-info'>{t('for user who sign in to company account')}</small></label>
        <RenderErrorMessage name='last_name' messages={fieldsErrors} />
        <RenderErrorMessage name='data.last_name' messages={fieldsErrors} />
        <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('Last Name')}`} id="last_name" onChange={handleChangeLastName} value={last_name} />
        </div>

        <div className="form-group">
        <label htmlFor="email">{t('Email')}</label>
        <RenderErrorMessage name='email' messages={fieldsErrors} />
        <RenderErrorMessage name='data.email' messages={fieldsErrors} />
        <input type="text" className="form-control" placeholder={`${t('Enter an')} ${t('Email')}`} id="email" autoComplete="off" onChange={handleChangeEmail} value={email} />
        </div>

        <div className="form-group">
        <label htmlFor="phone">{t('Phone')}</label>
        <RenderErrorMessage name='phone' messages={fieldsErrors} />
        <RenderErrorMessage name='data.phone' messages={fieldsErrors} />
        <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('Phone')}`} id="phone" onChange={handleChangePhone} value={phone} />
        </div>


        <div className={styles.AddressGroup+" form-group card"} style={{'padding':'10px'}}>
        <label>{t('Address Details')}</label>
        <div style={{'padding':'20px'}}>
          <RenderErrorMessage name='governorate_id' messages={fieldsErrors} />
          <RenderErrorMessage name='district_id' messages={fieldsErrors} />
          <RenderErrorMessage name='address' messages={fieldsErrors} />

          <RenderErrorMessage name='data.governorate_id' messages={fieldsErrors} />
          <RenderErrorMessage name='data.district_id' messages={fieldsErrors} />
          <RenderErrorMessage name='data.address' messages={fieldsErrors} />
          <span>
            <label htmlFor="governorate_id">{t('Governorate')}</label>
            <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={governoratesOptions}
              onChange={handleChangeGovernorate}
              value={governorate_id}
              placeholder={`${t('Select a')} ${t('governorate')}`}
              search={true}
              filterOptions={fuzzySearch}
              />
          </span><br/>
          <span>
            <label htmlFor="district_id">{t('District')}</label>
            <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={districtsOptions}
              onChange={handleChangeDistrict}
              value={district_id}
              placeholder={`${t('Select a')} ${t('district')}`}
              search={true}
              filterOptions={fuzzySearch}
              />
          </span><br/>
          <span>
            <label htmlFor="neighborhood_id">{t('Neighborhood')}</label>
            <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={neighborhoodsOptions}
              onChange={handleChangeNeighborhood}
              value={neighborhood_id}
              placeholder={`${t('Select a')} ${t('neighborhood')}`}
              search={true}
              filterOptions={fuzzySearch}
              />
          </span><br/>
          <span>
            <label htmlFor="address">{t('Address')}</label> <br/>
            <input type={'text'} value={address} onChange={handleChangeAddress} />
          </span>
        </div>
        </div>

        </div>
    </div>}

    {(show && isRequested) && <div className='alert alert-success text-center p-2'>
      {t('The request is sent succesfully!')}<br />
      {t('Please, wait for our response, we will contact you as soon as possible!')}
    </div>}
    
  </ModalComponent>
};

UpdateAdd.propTypes = {};

UpdateAdd.defaultProps = {};

export default UpdateAdd;
