import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './View.module.css';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import { useTranslation } from 'react-i18next';

import {AiFillPrinter} from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import Notes from '../../Common/Notes/Notes';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

const View = ({id, generalFetchingServiceRef, handleTabChange, isPrivileged, locale}) => {
  const { t, i18n } = useTranslation();
  
  const [data, setData] = useState(null)

  const tableRef = useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name


  const GetData = () => {
    generalFetchingServiceRef?.current?.getById(id)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            if(data?.length>0){
              setData(data[0])
            }
          }
        }).catch(error => {
          console.log(error)
        })
  }

  const downloadFile = (file) => {
    generalFetchingServiceRef?.current?.Download({file: file})
        .then(response => {
          let responseData=response?.data
          if(responseData){
            const fileName= file?.substring(file.lastIndexOf('/'), file.length-1)
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(responseData)
            link.download = fileName
            link.click()
          }
        }).catch(error => {
          console.log(error)
        })
  }
  

  useEffect(()=>{
    GetData()
  },[])

  return <div className={styles.View} data-testid="View">
    <table ref={tableRef} className={styles.table+' table table-bordered table-hover'}>
      <thead className='table-active'>
        <tr>
          <th scope='col' className={styles.header_label}>{t('Property')}</th>
          <th scope='col' className={styles.header_label}>{t('Value')}</th>
        </tr>
      </thead>
      <tbody>
        <tr className="form-group">
          <td><label className={styles.label}>{t('Id')}</label></td>
          <td>{data?.id}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Type')}</label></td>
          <td>{t(data?.type)}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Company')}</label></td>
          <td>{data?.company?.name}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Company Accountant')}</label></td>
          <td>{data?.company_accountant}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Authorized to expense')}</label></td>
          <td>{data?.authorized_to_expense}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Deducted Amount')}</label></td>
          <td>{data?.deducted_amount}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Returned Amount')}</label></td>
          <td>{data?.returned_amount}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Total Amount')}</label></td>
          <td>{data?.total_amount}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Expense Date')}</label></td>
          <td>{moment(data?.expense_date).local().locale(locale).format('DD/MM/yyyy h:mm:ss a')}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Receipt File')}</label></td>
          <td><a href='#' onClick={()=>downloadFile(data?.file)}>{data?.file_name}</a></td>
        </tr>

      </tbody>
    </table>

    <br />
    <div className={styles.ToolsButtons}>
      <button className='btn btn-primary' onClick={()=>{
        handleTabChange('General')
      }}>{t('Go to all')}</button>

      {isPrivileged['print_view'] && 
        <ReactToPrint
          trigger={() => <button className={styles.ToolsBarButton+' btn btn-info'} title={t('Print')}><AiFillPrinter /></button>}
          content={() => tableRef?.current}
          documentTitle={'mail_'+data?.name}
        />
        
      }
    </div>
    <br />
    <br />
      {isPrivileged['view_notes'] && <Notes id={id} serviceGet={generalFetchingServiceRef?.current?.GetNotes} serviceAdd={generalFetchingServiceRef?.current?.AddNote} serviceDelete={generalFetchingServiceRef?.current?.DeleteNote} isPrivileged={isPrivileged} locale={locale} />}
    <br />
    <br />
  </div>
};

View.propTypes = {};

View.defaultProps = {};

export default View;
