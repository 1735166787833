import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../../Services/GeneralHelpers';
import ToolsItemsBar from '../../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({dataLength, page, toolsBarRef, companiesSubscriptionsRef, deletedRef, paginationRef, printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale}) => {
  const { t, i18n } = useTranslation();

  const isManageFeatures = page=='ManageFeatures';
  const isDeleted = page=='Deleted';


  const [nameFilter, setNameFilter] = useState("");
  const [holderNameFilter, setHolderNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");
  
  const [from_expiary_date, setExpiaryFromDateFilter] = useState(null);
  const [to_expiary_date, setExpiaryToDateFilter] = useState(null);

  const [companyFilter, setCompanyFilter] = useState(null);
  const [companiesData, setCompaniesData] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])
  
  const [is_enabled_subscription, setIsEnabledSubscription] = useState(null)
  const [isEnabledSubscriptionData, setIsEnabledSubscriptionData] = useState([])
  const [isEnabledSubscriptionOptions, setIsEnabledSubscriptionOptions] = useState([])

  const [isFilters, setIsFilters] = useState(false);
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      companiesSubscriptionsRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
      }else{
        paginationRef?.current?.loadPages()
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('Subscriptions', selecetedItems)
    },
    showUpdateAddAttendanceModal(isAdd){
      if(isManageFeatures){
        companiesSubscriptionsRef?.current?.showUpdateAddAttendanceModal(isAdd)
      }
    }
  }))


  const handleChangeName = (e) => {
    setNameFilter(e.target.value)
  }

  const handleChangeHolderName = (e) => {
    setHolderNameFilter(e.target.value)
  }

  const handleChangeEmail = (e) => {
    setEmailFilter(e.target.value)
  }

  const handleChangePhone = (e) => {
    setPhoneFilter(e.target.value)
  }

  const handleChangeExpiaryFromDate = (value) => {
    setExpiaryFromDateFilter(value)
  }

  const handleChangeExpiaryToDate= (value) => {
    setExpiaryToDateFilter(value)
  }

  const handleChangeIsEnabledSubscription = (value) => {
    setIsEnabledSubscription(value)
  }

  const handleChangeCompany = (value) => {
    setCompanyFilter(value)
  }


  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          const companies=data?.companies
          const companiesOptions=companies?.map(x => {return {'value':x?.id, 'name': x?.display_name}})
          setCompaniesData(companies)
          setCompaniesOptions(companiesOptions)

          const trueFalse=['True', 'False']

          const isEnabledSubscriptionOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsEnabledSubscriptionData(trueFalse)
          setIsEnabledSubscriptionOptions(isEnabledSubscriptionOptions)
        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    const fromExpiaryDate=(filtersS && filtersS?.from_expiary_date)?filtersS?.from_expiary_date:from_expiary_date?from_expiary_date:null
    const toExpiaryDate=(filtersS && filtersS?.to_expiary_date)?filtersS?.to_expiary_date:to_expiary_date?to_expiary_date:null
    let result={
      name: (filtersS && filtersS?.name)?filtersS?.name:nameFilter,
      email: (filtersS && filtersS?.email)?filtersS?.email:emailFilter,
      phone: (filtersS && filtersS?.phone)?filtersS?.phone:phoneFilter,
      holder_name: (filtersS && filtersS?.holder_name)?filtersS?.holder_name:holderNameFilter,
      company_id: (filtersS && filtersS?.company_id)?filtersS?.company_id:companyFilter,
      is_enabled_subscription: (filtersS && filtersS?.is_enabled_subscription)?filtersS?.is_enabled_subscription:(is_enabled_subscription?is_enabled_subscription=='True':null),
      from_expiary_date: fromExpiaryDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(fromExpiaryDate)).locale('es').format('yyyy-MM-DD'):null,
      to_expiary_date: toExpiaryDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(toExpiaryDate)).locale('es').format('yyyy-MM-DD'):null,
    };
    return result
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.company_id===null && filters.name.length===0 && filters.holder_name.length===0 &&
      filters.email.length===0 && filters.phone.length===0 && filters.is_enabled_subscription===null &&
      filters.from_expiary_date===null && filters.to_expiary_date===null
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setNameFilter('')
    setHolderNameFilter('')
    setEmailFilter('')
    setPhoneFilter('')
    setCompanyFilter(null)
    setIsEnabledSubscription(null)
    setExpiaryFromDateFilter(null)
    setExpiaryToDateFilter(null)
    setIsFilters(false)

    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
  }

  const loadFiltersApply = (filtersO) => {
    const name=filtersO?.name
    if(name){
      setNameFilter(name)
    }

    const holder_name=filtersO?.holder_name
    if(holder_name){
      setHolderNameFilter(holder_name)
    }

    const email=filtersO?.email
    if(email){
      setEmailFilter(email)
    }

    const phone=filtersO?.phone
    if(phone){
      setPhoneFilter(phone)
    }

    const company_id=filtersO?.company_id
    if(company_id){
      setCompanyFilter(company_id)
    }

    const is_enabled_subscription=filtersO?.is_enabled_subscription
    if(is_enabled_subscription){
      setIsEnabledSubscription(is_enabled_subscription?'True':'False')
    }

    const from_expiary_date=filtersO?.from_expiary_date
    if(from_expiary_date){
      setExpiaryFromDateFilter(from_expiary_date)
    }

    const to_expiary_date=filtersO?.to_expiary_date
    if(to_expiary_date){
      setExpiaryToDateFilter(to_expiary_date)
    }

    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }

  useEffect(()=>{
    getFiltersData()
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectCompany}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectCompany}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectIsEnabled}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectIsEnabled}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectCompany}
                  onFocus={handleOnFocusSelect}
                  options={companiesOptions}
                  onChange={handleChangeCompany}
                  value={companyFilter}
                  placeholder={t('Company')}
                  search={true}
                  filterOptions={fuzzySearch}
                  />

            <SelectSearch id={styles.SelectIsEnabled}
                          onFocus={handleOnFocusSelect}
                          options={isEnabledSubscriptionOptions}
                          onChange={handleChangeIsEnabledSubscription}
                          value={is_enabled_subscription}
                          placeholder={t('Is Enabled?')}
                          search={true}
                          filterOptions={fuzzySearch}
                          autoFocus={false}
                          />
          </div>

          <div className={styles.subSelectsGroup}>
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={from_expiary_date} onChange={handleChangeExpiaryFromDate} placeholderText={`${t('Start Date')} (${t('Expiary')})`} />
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={to_expiary_date} onChange={handleChangeExpiaryToDate} placeholderText={`${t('End Date')} (${t('Expiary')})`} />
          </div>
        </div>

        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <input className={styles.SelectInput}
                onChange={handleChangeName}
                value={nameFilter}
                placeholder={t('Name')}
                />

            <input className={styles.SelectInput}
                onChange={handleChangeName}
                value={nameFilter}
                placeholder={t('Company Holder Name')}
                />
          </div>

          <div className={styles.subSelectsGroup}>
            <input className={styles.SelectInput}
                  onChange={handleChangeEmail}
                  value={emailFilter}
                  placeholder={t('Email')}
                  />

            <input className={styles.SelectInput}
              onChange={handleChangePhone}
              value={phoneFilter}
              placeholder={t('Phone')}
              />
          </div>
        </div>
        
        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar page={page} dataLength={dataLength} companiesSubscriptionsRef={companiesSubscriptionsRef} deletedRef={deletedRef} isDeleted={isDeleted} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} />
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
