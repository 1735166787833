import React, {useEffect, useState, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './LogsAccounting.module.css';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import Pagination from '../../Common/Pagination/Pagination';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import ToolsBar from './ToolsBar/ToolsBar';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';


const LogsAccounting = ({logRef, generalFetchingServiceRef, isPrivileged, locale}) => {
  const { t, i18n } = useTranslation();

  const toolsBarRef = useRef();
  const paginationRef = useRef();
  const tableRef = useRef();
  
  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name
  
  const [logs, setLogs] = useState([])
  const [dataLength, setDataLength] = useState(0)

  useImperativeHandle(logRef,()=>({
    setDataTotalLength(value){
      setDataLength(value)
    },
    loadPages(){
      toolsBarRef?.current?.loadPages()
    }
  }))

  const handleDownloadExcel = (voucher_number) => {
    generalFetchingServiceRef?.current?.Download({voucher_number}, null, `accounting/download_voucher`)
      .then(response => {
        const responseData=response?.data
        if(responseData){
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(responseData)
          link.download = "Accounting_"+voucher_number+".xlsx"
          link.click()
        }
      }).catch(error => {
        console.log(error)
        setUpdateOneItem(null)
      })
  }

  const dataService = (links, isAll, filtersO) => generalFetchingServiceRef?.current?.getLogs(links, isAll, filtersO, 'accounting')
  const filtersDataService = () => generalFetchingServiceRef?.current?.getFiltersLogData('accounting')

  return <div className={styles.Deleted + ""} data-testid="Deleted">
    <ToolsBar toolsBarRef={toolsBarRef} locale={locale}
      generalFetchingServiceRef={generalFetchingServiceRef} paginationRef={paginationRef}
      identifier='AccountLog' filtersDataService={filtersDataService} 
      printComponentRef={tableRef} isPrivileged={isPrivileged} logRef={logRef}
      />
      
    <table ref={tableRef} cellPadding="0" cellSpacing="0" border="0" className={"datatable table table-striped table-bordered table-hover"}>
      <thead>
        <tr>
           <th>{t('Id')}</th>
           <th>{t('User')}</th>
           <th>{t('Action')}</th>
           <th>{t('Voucher Number')}</th>
           <th className={styles.hidableColumn}>{t('Details')}</th>
           <th>{t('Date')}</th>
        </tr>
      </thead>
      <tbody>
        {logs.map((item, id) => {
          return <tr key={item?.id}>
            <td>{item?.id}</td>
            <td><Link to={`/${prefix}/dashboard/user/view/${item?.user?.id}`}>{item?.user?.first_name+' '+item?.user?.last_name}</Link></td>
            <td>{item?.action?.display_name}</td>
            <td><button className='btn btn-success' onClick={() => handleDownloadExcel(item?.entity_value_id)}>{item?.entity_value_id}</button></td>
            <td>{item?.details}</td>
            <td>{moment(item?.created_at).local().locale(locale).format('DD/MM/yyyy h:mm:ss a')}</td>
          </tr>
        })}
      </tbody>
    </table>
    
    {logs?.length == 0 && t("No items to display")}

    
    <Pagination toolsBarRef={toolsBarRef} logRef={logRef} service={dataService} data={logs} setData={setLogs} paginationRef={paginationRef} locale={locale} />
  </div>
};

LogsAccounting.propTypes = {};

LogsAccounting.defaultProps = {};

export default LogsAccounting;
