import React from 'react';
import PropTypes from 'prop-types';
import styles from './TopBar.module.css';

import { useTranslation } from 'react-i18next';

import {BiAddToQueue} from 'react-icons/bi';
import {FaRegObjectGroup} from 'react-icons/fa';
import {AiFillEdit, AiFillDelete, AiOutlineShoppingCart} from 'react-icons/ai';
import {GrFormView} from 'react-icons/gr';
import {IoLogoBuffer} from 'react-icons/io';


const TopBar = ({handleTabChange, isPrivileged, routePage, isVisible}) => {
  const { t, i18n } = useTranslation();

  const isStorage = routePage == 'Storage';
  const isAddStorage = routePage == 'AddStorage';
  const isEditStorage = routePage == 'EditStorage';
  const isDeletedStorage = routePage == 'DeletedStorage';
  const isViewStorage = routePage == 'ViewStorage';

  const isCart = routePage == 'Cart';
  const isAddCart = routePage == 'AddCart';
  const isEditCart = routePage == 'EditCart';
  const isDeletedCart = routePage == 'DeletedCart';
  const isViewCart = routePage == 'ViewCart';

  const isItem = routePage == 'CartItem';
  const isAddItem = routePage == 'AddItem';
  const isEditItem = routePage == 'EditItem';
  const isDeletedItem = routePage == 'DeletedItem';
  const isViewItem = routePage == 'ViewItem';
  
  const isLog = routePage == 'Log';
  

  return <div>
    <nav className={styles.TopBar + " bg-white list-group list-group-horizontal"} data-testid="TopBar">
        {(isPrivileged['view_storages'] || isPrivileged['view_carts'] || isPrivileged['view_items']) && <button
            className={`list-group-item py-2 ripple ${(isStorage||isCart||isItem)?"list-group-item-dark":''} TopBarButton `}
            onClick={() => handleTabChange('Storage')}>
          <FaRegObjectGroup />&nbsp; <span>{t('All')}</span>
        </button>}

        {isPrivileged['add'] && isVisible['Add'] && <button
            className={`list-group-item py-2 ripple ${(isAddStorage||isAddCart||isAddItem)?"list-group-item-dark":''} TopBarButton `}
            onClick={() => handleTabChange('AddStorage')}>
          <FaRegObjectGroup />&nbsp; <span>{t('Add')}</span>
        </button>}

        {isPrivileged['edit'] && isVisible['Edit'] && <button
            className={`list-group-item py-2 ripple ${(isEditStorage||isEditCart||isEditItem)?"list-group-item-dark":''} TopBarButton `}
            onClick={() => handleTabChange('EditStorage')}>
          <FaRegObjectGroup />&nbsp; <span>{t('Edit')}</span>
        </button>}

        {isPrivileged['view_item'] && isVisible['View'] && <button
            className={`list-group-item py-2 ripple ${(isViewStorage||isViewCart||isViewItem)?"list-group-item-dark":''} TopBarButton `}
            onClick={() => handleTabChange('ViewStorage')}>
          <FaRegObjectGroup />&nbsp; <span>{t('View')}</span>
        </button>}

        {isPrivileged['delete'] && isVisible['Deleted'] && <button
            className={`list-group-item py-2 ripple ${(isDeletedStorage||isDeletedCart||isDeletedItem)?"list-group-item-dark":''} TopBarButton `}
            onClick={() => handleTabChange('DeletedStorage')}>
          <FaRegObjectGroup />&nbsp; <span>{t('Deleted')}</span>
        </button>}


        {isPrivileged['view_log'] && <button
            className={`list-group-item py-2 ripple ${isLog?"list-group-item-dark":''} TopBarButton`}
            onClick={() => handleTabChange('Log')}>
          <IoLogoBuffer />&nbsp; <span>{t('Log')}</span>
        </button>}
    </nav>

    {(isPrivileged['view_storages'] || isPrivileged['view_carts'] || isPrivileged['view_items']) && 

     <nav className={styles.TopBar + " bg-white list-group list-group-horizontal"} data-testid="TopBar">

      {isPrivileged['view_storages'] && isVisible['Storage'] && <button
          className={`list-group-item py-2 ripple ${(isStorage)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('Storage')}>
        <IoLogoBuffer />&nbsp; <span>{t('Storages')}</span>
      </button>}

      {isPrivileged['view_carts'] && isVisible['Cart'] && <button
          className={`list-group-item py-2 ripple ${(isCart)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('Cart')}>
        <IoLogoBuffer />&nbsp; <span>{t('Storage Carts')}</span>
      </button>}

      {isPrivileged['view_items'] && isVisible['CartItem'] && <button
          className={`list-group-item py-2 ripple ${(isItem)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('CartItem')}>
        <IoLogoBuffer />&nbsp; <span>{t('Carts Items')}</span>
      </button>}



      {isPrivileged['add'] && isVisible['AddStorage'] && <button
          className={`list-group-item py-2 ripple ${(isAddStorage)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('AddStorage')}>
        <IoLogoBuffer />&nbsp; <span>{t('Add Storage')}</span>
      </button>}

      {isPrivileged['add'] && isVisible['AddCart'] && <button
          className={`list-group-item py-2 ripple ${(isAddCart)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('AddCart')}>
        <IoLogoBuffer />&nbsp; <span>{t('Add Cart')}</span>
      </button>}

      {isPrivileged['add'] && isVisible['AddItem'] && <button
          className={`list-group-item py-2 ripple ${(isAddItem)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('AddItem')}>
        <IoLogoBuffer />&nbsp; <span>{t('Add Item')}</span>
      </button>}



      {isPrivileged['edit'] && isVisible['EditStorage'] && <button
          className={`list-group-item py-2 ripple ${(isEditStorage)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('EditStorage')}>
        <IoLogoBuffer />&nbsp; <span>{t('Edit Storage')}</span>
      </button>}

      {isPrivileged['edit'] && isVisible['EditCart'] && <button
          className={`list-group-item py-2 ripple ${(isEditCart)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('EditCart')}>
        <IoLogoBuffer />&nbsp; <span>{t('Edit Cart')}</span>
      </button>}

      {isPrivileged['edit'] && isVisible['EditItem'] && <button
          className={`list-group-item py-2 ripple ${(isEditItem)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('EditItem')}>
        <IoLogoBuffer />&nbsp; <span>{t('Edit Item')}</span>
      </button>}

      



      {isPrivileged['delete'] && isVisible['DeletedStorage'] && <button
          className={`list-group-item py-2 ripple ${(isDeletedStorage)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('DeletedStorage')}>
        <IoLogoBuffer />&nbsp; <span>{t('Deleted Storage')}</span>
      </button>}

      {isPrivileged['delete'] && isVisible['DeletedCart'] && <button
          className={`list-group-item py-2 ripple ${(isDeletedCart)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('DeletedCart')}>
        <IoLogoBuffer />&nbsp; <span>{t('Deleted Cart')}</span>
      </button>}

      {isPrivileged['delete'] && isVisible['DeletedItem'] && <button
          className={`list-group-item py-2 ripple ${(isDeletedItem)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('DeletedItem')}>
        <IoLogoBuffer />&nbsp; <span>{t('Deleted Carts Items')}</span>
      </button>}





      {isPrivileged['view_item'] && isVisible['ViewStorage'] && <button
          className={`list-group-item py-2 ripple ${(isViewStorage)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('ViewStorage')}>
        <IoLogoBuffer />&nbsp; <span>{t('View Storage')}</span>
      </button>}

      {isPrivileged['view_item'] && isVisible['ViewCart'] && <button
          className={`list-group-item py-2 ripple ${(isViewCart)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('ViewCart')}>
        <IoLogoBuffer />&nbsp; <span>{t('View Cart')}</span>
      </button>}

      {isPrivileged['view_item'] && isVisible['ViewItem'] && <button
          className={`list-group-item py-2 ripple ${(isViewItem)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('ViewItem')}>
        <IoLogoBuffer />&nbsp; <span>{t('View Item')}</span>
      </button>}




      {isPrivileged['view_deleted_storages'] && isVisible['DeleteStorage'] && <button
          className={`list-group-item py-2 ripple ${(isDeletedStorage)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('DeleteStorage')}>
        <IoLogoBuffer />&nbsp; <span>{t('Delete Storage')}</span>
      </button>}

      {isPrivileged['view_deleted_carts'] && isVisible['DeleteCart'] && <button
          className={`list-group-item py-2 ripple ${(isDeletedCart)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('DeleteCart')}>
        <IoLogoBuffer />&nbsp; <span>{t('Delete Cart')}</span>
      </button>}

      {isPrivileged['view_deleted_items'] && isVisible['DeleteItem'] && <button
          className={`list-group-item py-2 ripple ${(isDeletedItem)?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('DeleteItem')}>
        <IoLogoBuffer />&nbsp; <span>{t('Delete Item')}</span>
      </button>}

    </nav>}

  </div>
};

TopBar.propTypes = {};

TopBar.defaultProps = {};

export default TopBar;
