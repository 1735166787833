import React, {useEffect, useState, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './Accounting.module.css';

import {AiFillEdit} from 'react-icons/ai';
import {AiFillDelete} from 'react-icons/ai';
import {MdSelectAll} from 'react-icons/md';
import {MdTabUnselected} from 'react-icons/md';
import {GrFormView, GrDocumentUpdate} from 'react-icons/gr';
import {BsFillCalculatorFill} from 'react-icons/bs';
import {FcDocument} from 'react-icons/fc';

import Pagination from '../../Common/Pagination/Pagination';
import $ from 'jquery';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import ToolsBar from './ToolsBar/ToolsBar';
import { useTranslation } from 'react-i18next';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';


import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Accounting = ({page, data, viewBtnHandle, viewDetailsBtnHandle, editBtnHandle, deleteHandle, setDataItems, generalFetchingServiceRef, isPrivileged, locale, showMessage, generalFetchingServiceGeneralRef}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const toolsBarRef = useRef();
  const paginationRef= useRef();
  const tableRef= useRef();
  const accountingRef= useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [selecetedItems, setSelectedItems] = useState([])
  const [isSelectAllBtn, setIsSelectAllBtn] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [isUpdatingValues, setIsUpdatingValues] = useState(false)
  const [updateOneItem, setUpdateOneItem] = useState(null)
  const [dataLength, setDataLength] = useState(0)

  const [accountingType, setAccountingType] = useState(null)
  const [accountingTypesData, setAccountingTypesData] = useState([])
  const [accountingTypesOptions, setAccountingTypesOptions] = useState([])

  const [company, setCompany] = useState(null)
  const [companiesData, setCompaniesData] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])

  const [vendor, setVendor] = useState(null)
  const [vendorsData, setVendorsData] = useState([])
  const [vendorsOptions, setVendorsOptions] = useState([])

  const [agent, setAgent] = useState(null)
  const [agentsData, setAgentsData] = useState([])
  const [agentsOptions, setAgentsOptions] = useState([])

  const [commissary, setCommissary] = useState(null)
  const [commissariesData, setCommissariesData] = useState([])
  const [commissariesOptions, setCommissariesOptions] = useState([])

  const [commissary_type, setCommissaryType] = useState(null)

  const [employee, setEmployee] = useState(null)
  const [employeesData, setEmployeesData] = useState([])
  const [employeesOptions, setEmployeesOptions] = useState([])

  const [storage, setStorage] = useState(null)
  const [storage_vendor_id, setStorageVendorId] = useState(null)
  const [storageVendorsData, setStorageVendorsData] = useState([])
  const [storageVendorsOptions, setStorageVendorsOptions] = useState([])

  const [company_payment_type_id, setCompanyPaymentTypeId] = useState(null)
  const [companyPaymentTypesData, setCompanyPaymentTypesData] = useState([])
  const [companyPaymentTypesOptions, setCompanyPaymentTypesOptions] = useState([])
  

  const [statistics, setStatistics] = useState([])
  const [currency, setCurrency] = useState('')
  const [currency_name, setCurrencyName] = useState('')
  

  const [is_employee_applicable, setIsEmployeeApplicable] = useState(false)
  const [is_company_applicable, setIsCompanyApplicable] = useState(false)
  const [isCashing, setIsCashing] = useState(true)

  
  
  let isDataLoaded=false
  const selectedDataAction = () => {
    if(selecetedItems.length>0){
      toolsBarRef?.current?.setIsSelectItems(true)
    }else{
      toolsBarRef?.current?.setIsSelectItems(false)
    }

    if(selecetedItems?.length==data?.length){
      setIsSelectAllBtn(false)
      $("#selectAllBtn").removeClass('btn-primary')
      $("#selectAllBtn").addClass('btn-warning')
    }else{
      setIsSelectAllBtn(true)
      $("#selectAllBtn").removeClass('btn-warning')
      $("#selectAllBtn").addClass('btn-primary')
    }
  }
  useEffect(()=>{
    selectedDataAction()
    isDataLoaded=true
  },[selecetedItems, data])
  useEffect(()=>{
    if(isDataLoaded){
      selectedDataAction()
    }
  },[selecetedItems])

  useEffect(()=>{
    let types=['vendor', 'agent', 'commissary', 'employee', 'storage']
    if(isPrivileged['manage_accounting_companies']){
      types=[...types, 'company']
    }

    const typesOptions=types?.map(x => {return {'value':x, 'name': t(x)}})
    setAccountingTypesData(types)
    setAccountingTypesOptions(typesOptions)
  }, [isPrivileged])

  const handleOnBeforePrint = () => {
    setIsPrinting(true)
    $('.hidableColumn').removeClass(styles.hidableColumn)
    return Promise.resolve();
  }
  const handleOnAfterPrint = () => {
    setIsPrinting(false)
    $('.hidableColumn').addClass(styles.hidableColumn)
  }

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectType}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectType}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  const setSelectedItem = (selectedId, value) => {
    if(value){
      if(!isMonthsType(accountingType) && selecetedItems.indexOf(selectedId)==-1){
        setSelectedItems([...selecetedItems, selectedId])
      }
      if(isMonthsType(accountingType) && selecetedItems.findIndex(item => (item.year == selectedId.year && item.month == selectedId.month))==-1){
        setSelectedItems([...selecetedItems, {year: selectedId.year, month: selectedId.month}])
      }
    }else{
      if(!isMonthsType(accountingType) && selecetedItems.indexOf(selectedId)!=-1){
        setSelectedItems(selecetedItems.filter((x)=>x!=selectedId))
      }
      if(isMonthsType(accountingType) && selecetedItems.findIndex(item => (item.year == selectedId.year && item.month == selectedId.month))!=-1){
        setSelectedItems(selecetedItems.filter((x)=>(x.year!=selectedId.year && x.month!=selectedId.month)))
      }
    }
  }
  const handleOnChangeSelect= (e) => {
    let selectedId=e.target.getAttribute('tag')
    if(!isMonthsType(accountingType)){
      selectedId=parseInt(selectedId)
    }else{
      selectedId=selectedId?.split(',')
      selectedId={year: parseInt(selectedId[0]), month: parseInt(selectedId[1])}
    }
    let value=e.target.checked
    setSelectedItem(selectedId, value)
  }

  const handleWhenUpdatingHidden = () =>{
    setIsUpdatingValues(false)
    setUpdateOneItem(null)
  }

  useImperativeHandle(accountingRef,()=>({
    setDataTotalLength(value){
      setDataLength(value)
    },
    deleteSelectedItems(){
      deleteHandle(selecetedItems, paginationRef)
    },
    clearSelectedItems(){
      setSelectedItems([])
      $('.'+styles.All).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
    },
    loadPages(){
      toolsBarRef?.current?.loadPages()
    },
    showUpdateModal(){
      setIsUpdatingValues(true)
    },
    handleAccounting(id){
      handleAccounting(id)
    },
    handleIssueReceipt(id){
      handleIssueReceipt(id)
    },
    downloadCashingReceipt(id){
      downloadCashingReceipt(id)
    }
  }))


  const handleAccounting = (id) => {
    let ids=selecetedItems?.length>0?selecetedItems:null
    if(id){
      ids=[id]
    }
    let title=ids?'Account all':'Account selected items'
    let message=ids?'Do you want to account selected items?':'Do you want to account all items?'

    

    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(`${t(title)} ${t('Confirmation')}`)
    $("#ConfirmModal").find(".modal-body p").html(t(message))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      handleAccountingApply(ids)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }
  const handleAccountingApply = (ids) => {
    let data={is_all:ids?.length>0?false:true}
    let value=null
    if(accountingType=='vendor'){
      data={...data, vendor_id: vendor}
      value=vendor
    }
    if(accountingType=='commissary'){
      data={...data, commissary_id: commissary}
      value=commissary
    }
    if(accountingType=='agent'){
      data={...data, agent_id: agent}
      value=agent
    }
    if(accountingType=='employee'){
      data={...data, employee_id: employee}
      value=employee
    }
    if(accountingType=='company'){
      data={...data, company_id: company, payment_type_id: company_payment_type_id}
      value=company
    }
    if(accountingType=='storage'){
      data={...data, vendor_id:storage_vendor_id}
      value=storage_vendor_id
    }
    generalFetchingServiceRef?.current?.update(data, ids?ids:[], `accounting/${accountingType}`)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            showMessage(true, responseData?.message)
            paginationRef?.current?.loadPages();
            getStatistics(accountingType, value)
          }else{
            showMessage(false, responseData?.message)
          }
          setUpdateOneItem(null)
        }).catch(error => {
          console.log(error)
          setUpdateOneItem(null)
        })
  }


  const handleIssueReceipt = (id) => {
    let ids=selecetedItems?.length>0?selecetedItems:null
    if(id){
      ids=[id]
    }
    let title='Issue cashing receipt'
    let message=ids?t('Do you want to issue a receipt for selected items?'):t('Do you want to issue a receipt for all applicable items?')
    message+='<br /><br />'
    message+=`<div style="color:red;">${t('Note that mail\'s money will be dropped from ')} ${t(`${accountingType}\'s account!`)}</div>`
    

    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(`${t(title)} ${t('Confirmation')}`)
    $("#ConfirmModal").find(".modal-body p").html(message)
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      handleIssueReceiptCheck(ids)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }
  const handleIssueReceiptCheck = (ids) => {
    let data={is_all:ids?.length>0?false:true}
    let value=null
    if(accountingType=='vendor'){
      data={...data, vendor_id: vendor}
      value=vendor
    }
    if(accountingType=='commissary'){
      data={...data, commissary_id: commissary}
      value=commissary
    }
    if(accountingType=='agent'){
      data={...data, agent_id: agent}
      value=agent
    }
    if(accountingType=='employee'){
      data={...data, employee_id: employee}
      value=employee
    }
    if(accountingType=='company'){
      data={...data, company_id: company, payment_type_id: company_payment_type_id}
      value=company
    }
    if(accountingType=='storage'){
      data={...data, vendor_id:storage_vendor_id}
      value=storage_vendor_id
    }
    generalFetchingServiceRef?.current?.update(data, ids?ids:[], `accounting/${accountingType}_cashing_receipt`)
      .then(response => {
        const responseData=response?.data
        if(responseData){
          if(responseData?.status==0 && responseData?.message){
            showMessage(false, responseData?.message)
            return 
          }else{
            handleIssueReceiptApply(data, ids, value)
          }
        }
      }).catch(error => {
        console.log(error)
        setUpdateOneItem(null)
      })
    
  }
  const handleIssueReceiptApply = (data, ids, value) => {
    generalFetchingServiceRef?.current?.Download(data, ids?ids:[], `accounting/${accountingType}_cashing_receipt`)
      .then(response => {
        const responseData=response?.data
        if(responseData){
          paginationRef?.current?.loadPages();
          getStatistics(accountingType, value);
          
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(responseData)
          if(vendor){
            link.download = "Cashing_Vendor_"+vendor+"_Receipt.docx"
          }
          if(commissary){
            link.download = "Cashing_Commissary_"+commissary+"_Receipt.docx"
          }
          if(agent){
            link.download = "Cashing_Agent_"+agent+"_Receipt.docx"
          }
          if(employee){
            link.download = "Cashing_Employee_"+employee+"_Receipt.docx"
          }
          if(company){
            link.download = "Cashing_Company_"+company+"_Receipt.docx"
          }
          if(storage){
            link.download = "Receiving_Money_Storage_Carts_Vendor_"+storage+"_Receipt.docx"
          }
          link.click()
        }
      }).catch(error => {
        console.log(error)
        setUpdateOneItem(null)
      })
  }
  

  const downloadCashingReceipt = (id) => {
    let data={is_all:false}
    if(accountingType=='vendor'){
      data={...data, vendor_id: vendor}
    }
    if(accountingType=='commissary'){
      data={...data, commissary_id: commissary}
    }
    if(accountingType=='agent'){
      data={...data, agent_id: agent}
    }
    if(accountingType=='employee'){
      data={...data, employee_id: employee}
    }
    if(accountingType=='company'){
      data={...data, company_id: company, payment_type_id: company_payment_type_id}
    }
    if(accountingType=='storage'){
      data={...data, vendor_id:storage_vendor_id}
    }
    generalFetchingServiceRef?.current?.Download(data, [id], `accounting/${accountingType}_cashing_receipt_download`)
      .then(response => {
        const responseData=response?.data
        if(responseData){
          paginationRef?.current?.loadPages();
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(responseData)
          if(vendor){
            link.download = "Cashing_Vendor_"+vendor+"_Receipt.docx"
          }
          if(commissary){
            link.download = "Cashing_Commissary_"+commissary+"_Receipt.docx"
          }
          if(agent){
            link.download = "Cashing_Agent_"+agent+"_Receipt.docx"
          }
          if(employee){
            link.download = "Cashing_Employee_"+employee+"_Receipt.docx"
          }
          if(company){
            link.download = "Cashing_Company_"+company+"_Receipt.docx"
          }
          if(storage){
            link.download = "Receiving_Money_Storage_Carts_Vendor_"+storage+"_Receipt.docx"
          }
          link.click()
        }
      }).catch(error => {
        console.log(error)
        setUpdateOneItem(null)
      })
  }

  const getStatistics = (type, value) => {
    generalFetchingServiceRef?.current?.getAll(null, null, null,
      `accounting/${type}/${
        value
      }/statistics`)
    .then(response => {
      let responseData=response?.data
      if(responseData && responseData?.status==1){
        const data=responseData?.data
        setStatistics(data)
        if(accountingType=='commissary'){
          setTimeout(()=>{
            setCommissaryType(data?.commissary_type)
          },100)
        }
      }else{
        showMessage(false, responseData?.message)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const getCurrency = () => {
    generalFetchingServiceGeneralRef?.current?.getAllData('currency')
    .then(response => {
      let responseData=response?.data
      if(responseData && responseData?.status==1){
        const data=responseData?.data
        setCurrency(data?.currency)
        setCurrencyName(data?.currency_name)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const handleSelectAllClick = () => {
    if(selecetedItems?.length==data?.length){
      $('.'+styles.Accounting).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
      setSelectedItems([])
    }else{
      $('.'+styles.Accounting).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', true)
      setSelectedItems([...data?.map(x=>{
        if(isMonthsType(accountingType)){
          return {year: x.year, month: x.month}
        }else{
          return x.id
        }
      })])
    }
  }
  
  const getAllData=(type, additionalValue)=>{
    generalFetchingServiceRef?.current?.getAllData(`accounting/${type}${additionalValue?'/'+additionalValue:''}`)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data
          const accountingTypeO=data?.type
          switch(accountingTypeO){
            case 'vendor':
              let vendors=data?.vendors
              let vendorsOptions=vendors?.map(x => {return {'value':x.id, 'name': x?.display_name}})
              setVendorsData(vendors)
              setVendorsOptions(vendorsOptions)
              break;
            case 'commissary':
              let commissaries=data?.commissaries
              let commissariesOptions=commissaries?.map(x => {return {'value':x.id, 'name': x?.display_name}})
              setCommissariesData(commissaries)
              setCommissariesOptions(commissariesOptions)
              break;
            case 'agent':
              let agents=data?.agents
              let agentsOptions=agents?.map(x => {return {'value':x.id, 'name': x?.display_name}})
              setAgentsData(agents)
              setAgentsOptions(agentsOptions)
              break;
            case 'employee':
              let employees=data?.employees
              let employeesOptions=employees?.map(x => {return {'value':x.id, 'name': x?.display_name}})
              setEmployeesData(employees)
              setEmployeesOptions(employeesOptions)
              break;
            case 'company':
              let companies=data?.companies
              let companiesOptions=companies?.map(x => {return {'value':x.id, 'name': x?.display_name}})
              setCompaniesData(companies)
              setCompaniesOptions(companiesOptions)
              break; 
            case 'paymentTypes':
              let paymentTypes=data?.paymentTypes
              let companyPaymentTypesOptions=paymentTypes?.map(x => {return {'value':x.id, 'name': t(x?.name)}})
              setCompanyPaymentTypesData(paymentTypes)
              setCompanyPaymentTypesOptions(companyPaymentTypesOptions)
              break;
            case 'storage':
              let storageVendors=data?.storageVendors
              let storageVendorsOptions=storageVendors?.map(x => {return {'value':x.id, 'name': x?.display_name}})
              setStorageVendorsData(storageVendors)
              setStorageVendorsOptions(storageVendorsOptions)
              break;
          }
          

        }
      }).catch(error => {
        console.log(error)
      })
  }

  let dataService = (links, isAll, filtersO) => generalFetchingServiceRef?.current?.getAll(links, isAll, filtersO, 
    `accounting/${accountingType}/${
      accountingType=='vendor'?vendor:
      accountingType=='commissary'?commissary:
      accountingType=='agent'?agent:
      accountingType=='employee'?employee:
      accountingType=='company'?company:
      accountingType=='storage'?storage:
      ''
    }`)
  let filtersDataService = () => generalFetchingServiceRef?.current?.getFiltersData(null, `accounting/${accountingType}`)
  let excelService = (filters, specificItems) => generalFetchingServiceRef?.current?.ExportExcel(filters, 
    accountingType=='vendor'?`AccountingVendor${vendor}`:
    accountingType=='commissary'?`AccountingCommissary${commissary}`:
    accountingType=='agent'?`AccountingAgent${agent}`:
    accountingType=='employee'?`AccountingEmployee${employee}`:
    accountingType=='company'?`AccountingCompany${company}`:
    accountingType=='storage'?`AccountingStorage${storage}`:
    'Accounting'
  , specificItems)

  const handleChangeType=(type)=>{
    setAccountingType(type)
    setVendor(null)
    setCommissary(null)
    setAgent(null)
    setEmployee(null)
    setCompany(null)
    setCompanyPaymentTypeId(null)
    setStorageVendorId(null)
    setStorage(null)
    getAllData(type);
  }
  const handleChangeVendor=(value)=>{
    setVendor(value)
    getStatistics(accountingType, value)
    getCurrency()
  }

  const handleChangeCommissary=(value)=>{
    setCommissary(value)
    setCommissaryType(null)
    getStatistics(accountingType, value)
    getCurrency()
  }

  const handleChangeAgent=(value)=>{
    setAgent(value)
    getStatistics(accountingType, value)
    getCurrency()
  }

  const handleChangeEmployee=(value)=>{
    setEmployee(value)
    getStatistics(accountingType, value)
    getCurrency()
  }

  const handleChangeCompany=(value)=>{
    setCompany(value)
    getStatistics(accountingType, value)
    getCurrency()
  }

  const handleChangePaymentType=(value)=>{
    setCompany(null)
    setCompaniesData([])
    setCompaniesOptions([])
    setCompanyPaymentTypeId(value)
    getAllData("payment_types", value)
  }

  const handleChangeStorageVendor=(value)=>{
    setStorageVendorId(value)
    setStorage(value)
    getStatistics(accountingType, value)
    getCurrency()
  }


  useEffect(()=>{
    accountingRef?.current?.loadPages()
    setIsCashing(true)
  },[accountingType, vendor])

  useEffect(()=>{
    setTimeout(()=>{
      accountingRef?.current?.loadPages()
    },500)
    setIsCashing(true)
  },[accountingType, commissary])

  useEffect(()=>{
    accountingRef?.current?.loadPages()
  },[accountingType, agent])

  useEffect(()=>{
    accountingRef?.current?.loadPages()
    setIsCashing(true)

    const emp=employeesData?.filter(x => x.id==employee)[0]
    setIsEmployeeApplicable(emp?.hiring_date!==null)
  },[accountingType, employee])

  useEffect(()=>{
    accountingRef?.current?.loadPages()
    setIsCashing(false)

    const comp=companiesData?.filter(x => x?.id == company)[0]
    setIsCompanyApplicable(comp?.subscription_date!==null)
  },[accountingType, company_payment_type_id, company])

  useEffect(()=>{
    accountingRef?.current?.loadPages()
    setIsCashing(true)
  },[accountingType, storage_vendor_id])
  
  const isMonthsType = (type) => {
    return (type=='employee' || (type=='commissary' && commissary_type=='salary') || (type=='company' && company_payment_type_id==companyPaymentTypesData?.filter(x=> x.name=='monthly')[0]?.id))
  }

  const isYearsType = (type) => {
    return (type=='company' && company_payment_type_id==companyPaymentTypesData?.filter(x=> x.name=='yearly')[0]?.id)
  }

  return <div className={styles.Accounting + ""} data-testid="Accounting">
    
    <br />

    <div className={styles.TopSelectContainer}>
      <div className={styles.TypeContainer}>
        <label htmlFor="accountingType">{t('Accounting Type')}</label>
        <SelectSearch id={styles.SelectType}
          onFocus={handleOnFocusSelect}
          menuPlacement="top"
          options={accountingTypesOptions}
          onChange={handleChangeType}
          value={accountingType}
          placeholder={`${t('Select a')} ${t('type')}`}
          search={true}
          filterOptions={fuzzySearch}
          />
      </div>

      {accountingType && accountingType=='vendor' && <div className={styles.TypeContainer}>
        <label htmlFor="vendor">{t('Vendor')}</label>
        <SelectSearch id={styles.SelectType}
          onFocus={handleOnFocusSelect}
          menuPlacement="top"
          options={vendorsOptions}
          onChange={handleChangeVendor}
          value={vendor}
          placeholder={`${t('Select a')} ${t('vendor')}`}
          search={true}
          filterOptions={fuzzySearch}
          />
      </div>}

      {accountingType && accountingType=='commissary' && <div className={styles.TypeContainer}>
        <div className={styles.TypeContainer}>
          <label htmlFor="commissary">{t('Commissary')}</label>
          <SelectSearch id={styles.SelectType}
            onFocus={handleOnFocusSelect}
            menuPlacement="top"
            options={commissariesOptions}
            onChange={handleChangeCommissary}
            value={commissary}
            placeholder={`${t('Select a')} ${t('commissary')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </div>

        {commissary && <div className={styles.TypeContainer}>
          <label htmlFor="commissary_type">{t('Commissary Type')}</label>
          <div className='card p-2 bg-dark text-light' style={{width:'200px'}}>{t(commissary_type)}</div>
        </div>}
      </div>}

      {accountingType && accountingType=='agent' && <div className={styles.TypeContainer}>
        <label htmlFor="agent">{t('Agent')}</label>
        <SelectSearch id={styles.SelectType}
          onFocus={handleOnFocusSelect}
          menuPlacement="top"
          options={agentsOptions}
          onChange={handleChangeAgent}
          value={agent}
          placeholder={`${t('Select a')} ${t('agent')}`}
          search={true}
          filterOptions={fuzzySearch}
          />
      </div>}

      {accountingType && accountingType=='employee' && <div className={styles.TypeContainer}>
        <label htmlFor="employee">{t('Employee')}</label>
        <SelectSearch id={styles.SelectType}
          onFocus={handleOnFocusSelect}
          menuPlacement="top"
          options={employeesOptions}
          onChange={handleChangeEmployee}
          value={employee}
          placeholder={`${t('Select a')} ${t('employee')}`}
          search={true}
          filterOptions={fuzzySearch}
          />
      </div>}

      {accountingType && accountingType=='company' && <div className={styles.TypeContainer}>
      <div className={styles.TypeContainer}>
          <label htmlFor="payment_type_id">{t('Payment Type')}</label>
          <SelectSearch id={styles.SelectType}
            onFocus={handleOnFocusSelect}
            menuPlacement="top"
            options={companyPaymentTypesOptions}
            onChange={handleChangePaymentType}
            value={company_payment_type_id}
            placeholder={`${t('Select a')} ${t('payment type')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </div>

        <div className={styles.TypeContainer}>
          <label htmlFor="company">{t('Company')}</label>
          <SelectSearch id={styles.SelectType}
            onFocus={handleOnFocusSelect}
            menuPlacement="top"
            options={companiesOptions}
            onChange={handleChangeCompany}
            value={company}
            placeholder={`${t('Select a')} ${t('company')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </div>
      </div>}

      {accountingType && accountingType=='storage' && <div className={styles.TypeContainer}>
        <div className={styles.TypeContainer}>
          <label htmlFor="storage_vendor_id">{t('Vendor')}</label>
          <SelectSearch id={styles.SelectType}
            onFocus={handleOnFocusSelect}
            menuPlacement="top"
            options={storageVendorsOptions}
            onChange={handleChangeStorageVendor}
            value={storage_vendor_id}
            placeholder={`${t('Select a')} ${t('vendor')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </div>
      </div>}
    </div>

    <br />

    {accountingType && (vendor || (commissary && commissary_type) || agent || (employee && is_employee_applicable) || (company && company_payment_type_id && is_company_applicable) || storage)  && <div className={styles.Accounting}>
      {(vendor || (commissary && !isMonthsType(accountingType)) || agent || storage) && <div className={styles.StatisticsContainer}>
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                {!storage && <h3>{statistics?.mailsAll}</h3>}
                {!storage && <p className="mb-0">{t('All Mails')}</p>}

                {storage && <h3>{statistics?.storageCartsAll}</h3>}
                {storage && <p className="mb-0">{t('All Storage Carts')}</p>}
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                {!storage && <h3>{statistics?.mailsAccounted}</h3>}
                {!storage && <p className="mb-0">{t('Accounted Mails')}</p>}

                {storage && <h3>{statistics?.storageCartsAccounted}</h3>}
                {storage && <p className="mb-0">{t('Accounted Storage Carts')}</p>}
              </div>
            </div>
          </div>
        </div>
        

        {!storage && <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                <h3>{statistics?.mailsCashed}</h3>
                <p className="mb-0">{t('Cashed Mails')}</p>
              </div>
            </div>
          </div>
        </div>}


        <div className="card" title={t('accountable_mail_details')}>
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                {!storage && <h3>{statistics?.mailsAccountable}</h3>}
                {!storage && <p className="mb-0">{t('Accountable Mails')}</p>}

                {storage && <h3>{statistics?.storageCartsAccountable}</h3>}
                {storage && <p className="mb-0">{t('Accountable Storage Carts')}</p>}
              </div>
            </div>
          </div>
        </div>

        {!storage && <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                <h3>{statistics?.mailsNotAccountableYet}</h3>
                <p className="mb-0">{t('Not Accountable Mails')}</p>
              </div>
            </div>
          </div>
        </div>}

        
      </div>}

      <br />


      {(vendor || (commissary && !isMonthsType(accountingType)) || agent || storage) && <div className={styles.StatisticsContainer}>
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                {!storage && <h3>{statistics?.mailsAllValue} {currency}</h3>}
                {storage && <h3>{statistics?.storageCartsAllValue} {currency}</h3>}
                {accountingType=='vendor' && <p className="mb-0">{t('All Mails Amount')}</p>}
                {(accountingType=='commissary' || accountingType=='agent') && <p className="mb-0">{t('Shipping of All Mails Amount')}</p>}
                {storage && <p className="mb-0">{t('All Storage Carts Amount')}</p>}
              </div>
            </div>
          </div>
        </div>
        
        {accountingType=='vendor' && <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                <h3>{statistics?.mailsDeliveredValue} {currency}</h3>
                <p className="mb-0">{t('Delivered Mails Amount')}</p>
              </div>
            </div>
          </div>
        </div>}

        {(accountingType=='commissary' || accountingType=='agent') && <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                <h3>{statistics?.mailsCommissionsValue} {currency}</h3>
                <p className="mb-0">{t('Commission for Shipping of All Mails Amount')}</p>
              </div>
            </div>
          </div>
        </div>}

        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                {!storage && <h3>{statistics?.mailsAccountedValue} {currency}</h3>}
                {!storage && <p className="mb-0">{t('Accounted Mails Amount')}</p>}

                {storage && <h3>{statistics?.storageCartsAccountedValue} {currency}</h3>}
                {storage && <p className="mb-0">{t('Accounted Storage Carts Amount')}</p>}
              </div>
            </div>
          </div>
        </div>

        {!storage && <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                <h3>{statistics?.mailsCashedValue} {currency}</h3>
                <p className="mb-0">{t('Cashed Mails Amount')}</p>
              </div>
            </div>
          </div>
        </div>}

        <div className="card" title={t('accountable_mail_details')}>
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                {!storage && <h3>{statistics?.mailsAccountableValue} {currency}</h3>}
                {!storage && <p className="mb-0">{t('Accountable Mails Amount')}</p>}

                {storage && <h3>{statistics?.storageCartsAccountableValue} {currency}</h3>}
                {storage && <p className="mb-0">{t('Accountable Storage Carts Amount')}</p>}
              </div>
            </div>
          </div>
        </div>

        {!storage && <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                <h3>{statistics?.mailsNotAccountableYetValue} {currency}</h3>
                <p className="mb-0">{t('Not Accountable Mails Amount')}</p>
              </div>
            </div>
          </div>
        </div>}
      </div>}

      <br/>

      {(isMonthsType(accountingType) || isYearsType(accountingType)) && <div className={styles.StatisticsContainer}>
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                {isMonthsType(accountingType) && <h3>{statistics?.monthsAll}</h3>}
                {isMonthsType(accountingType) && <p className="mb-0">{t('All Months')}</p>}

                {isYearsType(accountingType) && <h3>{statistics?.yearsAll}</h3>}
                {isYearsType(accountingType) && <p className="mb-0">{t('All Years')}</p>}
              </div>
            </div>
          </div>
        </div>
        
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                {isMonthsType(accountingType) && <h3>{statistics?.monthsAccounted}</h3>}
                {isMonthsType(accountingType) && <p className="mb-0">{t('Accounted Months')}</p>}

                {isYearsType(accountingType) && <h3>{statistics?.yearsAccounted}</h3>}
                {isYearsType(accountingType) && <p className="mb-0">{t('Accounted Years')}</p>}
              </div>
            </div>
          </div>
        </div>

        {accountingType!='company' && <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                {isMonthsType(accountingType) && <h3>{statistics?.monthsCashed}</h3>}
                {isMonthsType(accountingType) && <p className="mb-0">{t('Cashed Months')}</p>}

                {isYearsType(accountingType) && <h3>{statistics?.yearsCashed}</h3>}
                {isYearsType(accountingType) && <p className="mb-0">{t('Cashed Years')}</p>}
              </div>
            </div>
          </div>
        </div>}

        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                {isMonthsType(accountingType) && <h3>{statistics?.monthsAccountable}</h3>}
                {isMonthsType(accountingType) && <p className="mb-0">{t('Accountable Months')}</p>}

                {isYearsType(accountingType) && <h3>{statistics?.yearsAccountable}</h3>}
                {isYearsType(accountingType) && <p className="mb-0">{t('Accountable Years')}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>}

      <br/>

      {(isMonthsType(accountingType) || isYearsType(accountingType)) && <div className={styles.StatisticsContainer}>
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                {isMonthsType(accountingType) && <h3>{statistics?.monthsAllValue} {currency}</h3>}
                {isMonthsType(accountingType) && <p className="mb-0">{t('All Months Value')}</p>}

                {isYearsType(accountingType) && <h3>{statistics?.yearsAllValue} {currency}</h3>}
                {isYearsType(accountingType) && <p className="mb-0">{t('All Years Value')}</p>}
              </div>
            </div>
          </div>
        </div>
        
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                {isMonthsType(accountingType) && <h3>{statistics?.monthsAccountedValue} {currency}</h3>}
                {isMonthsType(accountingType) && <p className="mb-0">{t('Accounted Months Value')}</p>}

                {isYearsType(accountingType) && <h3>{statistics?.yearsAccountedValue} {currency}</h3>}
                {isYearsType(accountingType) && <p className="mb-0">{t('Accounted Years Value')}</p>}
              </div>
            </div>
          </div>
        </div>
        
        {accountingType!='company' && <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                {isMonthsType(accountingType) && <h3>{statistics?.monthsCashedValue} {currency}</h3>}
                {isMonthsType(accountingType) && <p className="mb-0">{t('Cashed Months Value')}</p>}

                {isYearsType(accountingType) && <h3>{statistics?.yearsCashedValue} {currency}</h3>}
                {isYearsType(accountingType) && <p className="mb-0">{t('Cashed Years Value')}</p>}
              </div>
            </div>
          </div>
        </div>}

        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between px-md-1">
              <div className="text-end">
                {isMonthsType(accountingType) && <h3>{statistics?.monthsAccountableValue} {currency}</h3>}
                {isMonthsType(accountingType) && <p className="mb-0">{t('Accountable Months Value')}</p>}

                {isYearsType(accountingType) && <h3>{statistics?.yearsAccountableValue} {currency}</h3>}
                {isYearsType(accountingType) && <p className="mb-0">{t('Accountable Years Value')}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>}

      <br/>

      <ToolsBar toolsBarRef={toolsBarRef} paginationRef={paginationRef} isPrivileged={isPrivileged}
      ViewItemsButtons={{'Delete': true}} identifier='AccountAccounting' filtersDataService={filtersDataService} selecetedItems={selecetedItems}
      printComponentRef={tableRef} printDocumentTitle='Accounts' handleOnAfterPrint={handleOnAfterPrint} handleOnBeforePrint={handleOnBeforePrint}
      locale={locale} page={page} dataLength={dataLength} accountingType={accountingType} accountingRef={accountingRef} isCashing={isCashing}
      />

      {data?.length > 0 &&
      <table id="tableToPrint" ref={tableRef} cellPadding="0" cellSpacing="0" border="0" className={"datatable table table-striped table-bordered table-hover"}>
        <thead>
          <tr>
            {!isPrinting && <th className='hideOnPrint'>
              <button id="selectAllBtn" className='btn btn-primary' onClick={handleSelectAllClick}>
                {isSelectAllBtn && <MdSelectAll id="SelectAll" />}
                {!isSelectAllBtn && <MdTabUnselected id="UnSelectAll" />}
              </button>
            </th>}
            {(isMonthsType(accountingType) || isYearsType(accountingType)) && <th>{t('Year')}</th>}
            {isMonthsType(accountingType) && <th>{t('Month')}</th>}
            {(isMonthsType(accountingType) && accountingType!='company') && <th>{t('Salary')}</th>}
            {(isMonthsType(accountingType) && accountingType!='company') && <th>{t('Month Factor')}</th>}
            {(isMonthsType(accountingType) && (accountingType!='company' && accountingType!='commissary')) && <th>{t('Salary Deduction')}</th>}
            {(isMonthsType(accountingType) && accountingType!='company') && <th>{t('Month Amount')}</th>}
            {((isMonthsType(accountingType) || isYearsType(accountingType)) && accountingType=='company') && <th>{t('Price after discount')}</th>}

            {accountingType=='storage' && <th>{t('Storage')}</th>}
            {accountingType=='storage' && <th>{t('Storage Cart')}</th>}
            {accountingType=='storage' && <th>{t('Items Size (cm3)')}</th>}
            {accountingType=='storage' && <th>{t('Price / (cm3)')}</th>}
            {accountingType=='storage' && <th>{t('Items Wrapping Prices')}</th>}
            {accountingType=='storage' && <th>{t('Total Price')}</th>}
            

            {(!isMonthsType(accountingType) && !isYearsType(accountingType) && !storage) && <th>{t('Mail Id')}</th>}
            {(!isMonthsType(accountingType) && !isYearsType(accountingType) && !storage) && <th>{t('Mail Name')}</th>}
            {accountingType=='vendor' && <th>{t('Amount')}</th>}
            {((accountingType=='commissary' || accountingType=='agent') && (!isMonthsType(accountingType) && !isYearsType(accountingType))) && <th>{t('Shipping Total')}</th>}
            {(!isMonthsType(accountingType) && !isYearsType(accountingType) && !storage) && <th>{t('Mail Status')}</th>}
            {accountingType=='vendor' && <th>{t('Partially Delivered Value')}</th>}
            {((accountingType=='commissary' || accountingType=='agent') && (!isMonthsType(accountingType) && !isYearsType(accountingType))) && <th>{t('Shipping Commission Value')}</th>}
            <th>{t('Is Accounted')}</th>
            <th>{t('Accounted Date')}</th>
            {(accountingType!='company' && !storage) && <th>{t('Is Cashed')}</th>}
            {(accountingType!='company' && storage) && <th>{t('Is Money Received?')}</th>}
            {!isPrinting && <th>{t('Actions')}</th>}
          </tr>
        </thead>
        <tbody>
          {data?.map((item, id) => {
            return <tr key={id}>
              {!isPrinting && <td className='hideOnPrint'><input type={'checkbox'} tag={isMonthsType(accountingType)?`${item?.year},${item?.month}`:item?.id} name={styles.CheckBoxDataTable} className={styles.CheckBoxDataTable} onChange={handleOnChangeSelect} /></td>}
              {(isMonthsType(accountingType) || isYearsType(accountingType)) && <td>{item?.year}</td>}
              {isMonthsType(accountingType) && <td>{item?.month}</td>}
              {(isMonthsType(accountingType) && accountingType!='company') && <th>{item?.salary}</th>}
              {(isMonthsType(accountingType) && accountingType!='company') && <th>{(Math.round((item?.salary_factor + Number.EPSILON) * 100) / 100)}</th>}
              {(isMonthsType(accountingType) && (accountingType!='company' && accountingType!='commissary')) && <th>{(Math.round((item?.deduction_value + Number.EPSILON) * 100) / 100)}</th>}
              {(isMonthsType(accountingType) && accountingType!='company') && <th>{(Math.round((((item?.salary * item?.salary_factor)-(item?.deduction_value?item?.deduction_value:0)) + Number.EPSILON) * 100) / 100)}</th>}
              {((isMonthsType(accountingType) || isYearsType(accountingType)) && accountingType=='company') && <th>{item?.amount}</th>}
              
              {accountingType=='storage' && <td><Link to={`/${prefix}/dashboard/storage/view/${item?.storage?.id}`}>{item?.storage?.name}</Link></td>}
              {accountingType=='storage' && <td><Link to={`/${prefix}/dashboard/storage/cart/view/${item?.id}`}>{`${t('Cart')} ${item?.id}`}</Link></td>}
              {accountingType=='storage' && <td>{item?.items_size_cm3}</td>}
              {accountingType=='storage' && <td>{item?.cm3_price}</td>}
              {accountingType=='storage' && <td>{item?.wrapping_prices}</td>}
              {accountingType=='storage' && <td>{(item?.items_size_cm3 * item?.cm3_price) + item?.wrapping_prices}</td>}

              {(!isMonthsType(accountingType) && !isYearsType(accountingType) && !storage) && <td>{item?.id}</td>}
              {(!isMonthsType(accountingType) && !isYearsType(accountingType) && !storage) && <td>{item?.name}</td>}
              {accountingType=='vendor' && <td>{item?.price}</td>}
              {((accountingType=='commissary' || accountingType=='agent') && (!isMonthsType(accountingType) && !isYearsType(accountingType))) && <td>{item?.shipping_total}</td>}
              {(!isMonthsType(accountingType) && !isYearsType(accountingType) && !storage) && <td>{item?.mail_status_name}</td>}
              {accountingType=='vendor' && <td>{item?.partially_delivered_value}</td>}
              {((accountingType=='commissary' || accountingType=='agent') && (!isMonthsType(accountingType) && !isYearsType(accountingType))) && <td>{item?.shipping_commission_value}</td>}
              <td>{t(item?.is_accounted?'true':'false')}</td>
              <td>{item?.accounted_date?moment(item?.accounted_date).local().locale(locale).format('DD/MM/yyyy h:mm:ss a'):''}</td>
              {(accountingType!='company' && !storage) && <td>{t(item?.is_cashed?'true':'false')}</td>}
              {(accountingType!='company' && storage) && <td>{t(item?.is_cashed?'true':'false')}</td>}
              {!isPrinting && <td>
              <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropDownActionsBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {t('Do an action')}
                </button>
                <div className={`dropdown-menu ${locale=='ar'?'text-right':'text-left'}`} aria-labelledby="dropDownActionsBtn">
                  {(!isMonthsType(accountingType) && !isYearsType(accountingType)) &&<button className="dropdown-item " href="#" onClick={()=>accountingRef?.current?.handleAccounting(item?.id)}><BsFillCalculatorFill /> {t('Accounting')}</button>}
                  {(!item?.is_cashed && (!isMonthsType(accountingType) && !isYearsType(accountingType)) && !storage) && <button className="dropdown-item " onClick={()=>accountingRef?.current?.handleIssueReceipt(item?.id)}><FcDocument /> {t('Issue Cashing Receipt')}</button>}
                  {(item?.is_cashed && (!isMonthsType(accountingType) && !isYearsType(accountingType)) && !storage) && <button className="dropdown-item " onClick={()=>accountingRef?.current?.downloadCashingReceipt(item?.id)}><FcDocument /> {t('Download Cashing Receipt')}</button>}
               
                  {isMonthsType(accountingType) &&<button className="dropdown-item " href="#" onClick={()=>accountingRef?.current?.handleAccounting({year:item?.year, month:item?.month})}><BsFillCalculatorFill /> {t('Accounting')}</button>}
                  {(!item?.is_cashed && (isMonthsType(accountingType) && accountingType!='company')) &&<button className="dropdown-item " href="#" onClick={()=>accountingRef?.current?.handleIssueReceipt({year:item?.year, month:item?.month})}><FcDocument /> {t('Issue Cashing Receipt')}</button>}
                  {(item?.is_cashed && (isMonthsType(accountingType) && accountingType!='company')) &&<button className="dropdown-item " href="#" onClick={()=>accountingRef?.current?.downloadCashingReceipt({year:item?.year, month:item?.month})}><FcDocument /> {t('Download Cashing Receipt')}</button>}
                  
                  {isYearsType(accountingType) &&<button className="dropdown-item " href="#" onClick={()=>accountingRef?.current?.handleAccounting({year:item?.year})}><BsFillCalculatorFill /> {t('Accounting')}</button>}
                 
                  {(!item?.is_cashed && accountingType=='storage') &&<button className="dropdown-item " href="#" onClick={()=>accountingRef?.current?.handleIssueReceipt(item?.id)}><FcDocument /> {t('Issue Receipt')}</button>}
                  {(item?.is_cashed && accountingType=='storage') &&<button className="dropdown-item " href="#" onClick={()=>accountingRef?.current?.downloadCashingReceipt(item?.id)}><FcDocument /> {t('Download Receipt')}</button>}
                </div>
              </div>
              </td>}
            </tr>
          })}
        </tbody>
      </table>
      }

      <br/>
      {data?.length == 0 && t("No items to display")}
      <br/>
      <br/>
      <Pagination toolsBarRef={toolsBarRef} service={dataService} excelService={excelService} data={data} setData={setDataItems} paginationRef={paginationRef} locale={locale} />
      
    </div>}
  </div>
};

Accounting.propTypes = {};

Accounting.defaultProps = {};

export default Accounting;
