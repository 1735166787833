import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../../Services/GeneralHelpers';
import ToolsItemsBar from '../../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({dataLength, page, toolsBarRef, accountingRef, deletedRef, paginationRef, printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale, accountingType, isCashing}) => {
  const { t, i18n } = useTranslation();

  const isGeneral = page=='General';
  const isDeleted = page=='Deleted';
  const isAccounting = page=='Accounting';

  const [userNameFilter, setUserNameFilter] = useState('');

  const [accountedFilter, setAccountedFilter] = useState(null);
  const [accountedData, setAccountedData] = useState([])
  const [accountedOptions, setAccountedOptions] = useState([])

  const [cashedFilter, setCashedFilter] = useState(null);
  const [cashedData, setCashedData] = useState([])
  const [cashedOptions, setCashedOptions] = useState([])

  const [isFilters, setIsFilters] = useState(false)
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      accountingRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
      }else{
        paginationRef?.current?.loadPages()
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('financial_accounting', selecetedItems)
    },
    showUpdateModal(){
      if(isGeneral){
        // allRef?.current?.showUpdateModal()
      }
    },
    handleAccounting(){
      accountingRef?.current?.handleAccounting()
    },
    handleIssueReceipt(id){
      accountingRef?.current?.handleIssueReceipt(id)
    },
  }))

  const handleChangeAccounted = (value) => {
    setAccountedFilter(value)
  }

  const handleChangeCashed = (value) => {
    setCashedFilter(value)
  }
  

  const handleBlurMultiple = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }
  const handleFocusMultiple = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','')
    setTimeout(()=>{
      $(`#searchToolsBar_${identifier}`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  const getDistrictsOptions = (values) =>{
    if(values.length==0){
      return []
    }
    return districtsData?.filter(x => values.indexOf(x.governorate_id)!=-1).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const getFiltersData=()=>{
    const trueFalse=['True', 'False']

    const accountedOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
    setAccountedData(trueFalse)
    setAccountedOptions(accountedOptions)

    const cashedOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
    setCashedData(trueFalse)
    setCashedOptions(cashedOptions)
  }


  const getFilters = (filtersS) => {
    let result={
    };
    if(accountedFilter){
      result={...result, accounted: (filtersS && filtersS?.accounted)?filtersS?.accounted:(accountedFilter=='True')}
    }
    if(cashedFilter){
      result={...result, cashed: (filtersS && filtersS?.cashed)?filtersS?.cashed:(cashedFilter=='True')}
    }
    return result
  }

  const isEmptyFilters = (filters) => {
    return (
       (filters.accounted==undefined || filters.accounted==null) &&
       (filters.cashed==undefined || filters.cashed==null)
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setAccountedFilter(null)
    setCashedFilter(null)
    setIsFilters(false)

    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
  }

  const loadFiltersApply = (filtersO) => {
    const balanceGreater=filtersO?.balance_greater
    if(balanceGreater){
      setBalanceGreaterFilter(balanceGreater)
    }

    const accounted=filtersO?.accounted
    if(accounted!==null){
      setAccountedFilter(accounted?'True':'False')
    }

    const cashed=filtersO?.cashed
    if(cashed!==null){
      setCashedFilter(cashed?'True':'False')
    }
    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }

  useEffect(()=>{
    if(accountingType){
      getFiltersData()
    }
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectIsActivated}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectIsActivated}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }


  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectIsActivated}
                    onFocus={handleOnFocusSelect}
                    options={accountedOptions}
                    onChange={handleChangeAccounted}
                    value={accountedFilter}
                    placeholder={t('Is Accounted')}
                    search={true}
                    filterOptions={fuzzySearch}
                    autoFocus={false}
                    />

            {isCashing && <SelectSearch id={styles.SelectIsActivated}
                    onFocus={handleOnFocusSelect}
                    options={cashedOptions}
                    onChange={handleChangeCashed}
                    value={cashedFilter}
                    placeholder={t('Is Cashed')}
                    search={true}
                    filterOptions={fuzzySearch}
                    autoFocus={false}
                    />}
          </div>
        </div>
        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar page={page} dataLength={dataLength} deletedRef={deletedRef} isDeleted={isDeleted} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} isCashing={isCashing} />
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
