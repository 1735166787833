import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './UpdateAdd.module.css';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../Common/ModalComponent/ModalComponent';
import RenderErrorMessage from '../../../Common/RenderErrorMessage/RenderErrorMessage';
import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import { useAuth } from '../../../Common/Auth/AuthHelpers/Auth';
import GeneralHelpers from '../../../../Services/GeneralHelpers';

const UpdateAdd = ({id, updateOneItem, oneItemDate, isAdd, locale, show, generalFetchingServiceRef, handleWhenHidden, handleOnConfirm, isPrivileged}) => {
  const { t, i18n } = useTranslation();

  const auth=useAuth();

  const [fieldsErrors, setFieldsErrors] = useState([]);

  const [name, setName] = useState('')
  const [price, setPrice] = useState('')
  const [details, setDetails] = useState('')
  
  
  const [error_message, setErrorMessage] = useState(null);

  const handleChangeName=(e)=>{
    setName(e.target.value)
  }
  const handleChangePrice=(e)=>{
    setPrice(e.target.value)
  }
  const handleChangeDetails=(e)=>{
    setDetails(e.target.value)
  }

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData('attendance')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let data={
      name,
      price,
      details,
    };
    return data;
  }

  const isEmptyObject = (obj) => {
    return !obj.name && !obj.price && !obj.details
  }

  const resetData = () => {
    setName('')
    setPrice('')
    setDetails('')
  }

  const handleConfirm = (hideCallback) => {
    const obj=getObject()
    handleOnConfirm(obj, isEmptyObject(obj), isAdd, function(isSuccess, error, errorMessage){
      if(!isSuccess){
        if(error){
          let response=error?.response
          if(response?.status==422){
            setFieldsErrors(response?.data?.errors)
          }
        }else{
          setErrorMessage(errorMessage)
        }
        
      }else{
        resetData()
        hideCallback()
      }
    })

  }

  const handleHidden = () => {
    handleWhenHidden()
    resetData()
  }

  const setFeatureData=(data)=>{
    let item=data[0]

    
  }

  const getById = (id, customApi) => {
    generalFetchingServiceRef?.current?.getById(id, customApi)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setFeatureData(data)
          }
        }).catch(error => {
          console.log(error)
        })
  }


  useEffect(()=>{
    if(show){
      // getAllData()
      if(updateOneItem){
        getById(updateOneItem, `feature/${updateOneItem}`)
      }
    }
  },[show])

  return <ModalComponent locale={locale} show={show} handleWhenHidden={handleHidden} handleOnConfirmDelayed={handleConfirm} title={`${t(isAdd?'Add':'Update')} ${t('Subscription Feature')}`} confirmBtnText={t(isAdd?'Add':'Update')} cancelBtnText={t('Cancel')} className={styles.Update} data-testid="Update">
    <div className={`${styles.FieldsContainer} ${locale=='ar'?'text-right':'text-left'}`}>
      
      <RenderErrorMessage name='error_message' messages={{error_message}} alertDisplay={true} />

      <div className={`form-group`}>
        <label htmlFor="name">{t('Name')}</label>
        <RenderErrorMessage name='name' messages={fieldsErrors} />
        <div>
          <input className={styles.SelectInput}
                  onChange={handleChangeName}
                  value={name}
                  placeholder={t('Name')}
                  />
        </div>
      </div>

      <div className={`form-group`}>
        <label htmlFor="price">{t('Price')}</label>
        <RenderErrorMessage name='price' messages={fieldsErrors} />
        <div>
          <input className={styles.SelectInput}
                  onChange={handleChangePrice}
                  value={price}
                  placeholder={t('Price')}
                  />
        </div>
      </div>

      <div className={`form-group`}>
        <label htmlFor="details">{t('Details')}</label>
        <RenderErrorMessage name='name' messages={fieldsErrors} />
        <div>
          <textarea className={styles.TextArea}
                  onChange={handleChangeDetails}
                  value={details}
                  placeholder={t('Details')}
                  />
        </div>
      </div>

    </div>
  </ModalComponent>
};

UpdateAdd.propTypes = {};

UpdateAdd.defaultProps = {};

export default UpdateAdd;
