import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../../Services/GeneralHelpers';
import ToolsItemsBar from '../../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({dataLength, page, toolsBarRef, allRef, deletedRef, paginationRef, printComponentRef, viewDetailsRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale}) => {
  const { t, i18n } = useTranslation();

  const isGeneral = page=='General';
  const isDeleted = page=='Deleted';

  const [amountGreaterFilter, setAmountGreaterFilter] = useState('');
  const [amountLowerFilter, setAmountLowerFilter] = useState('');
  const [amountEqualFilter, setAmountEqualFilter] = useState('');

  const [financialActionFilter, setFinancialActionFilter] = useState(null);
  const [financialActionData, setFinancialActionData] = useState([])
  const [financialActionOptions, setFinancialActionOptions] = useState([])

  const [isFilters, setIsFilters] = useState(false)
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  const [holder_name, setHolderName] = useState('')
  const [balance, setBalance] = useState(0)

  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      viewDetailsRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
      }else{
        paginationRef?.current?.loadPages()
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('companies', selecetedItems)
    },
    showUpdateModal(){
      if(isGeneral){
        allRef?.current?.showUpdateModal()
      }
    }
  }))

  const handleChangeAmountGreater = (e) => {
    setAmountGreaterFilter(e.target.value)
  }
  const handleChangeAmountLower = (e) => {
    setAmountLowerFilter(e.target.value)
  }
  const handleChangeAmountEqual = (e) => {
    setAmountEqualFilter(e.target.value)
  }
  const handleChangeFinancialAction = (value) => {
    setFinancialActionFilter(value)
  }
  

  const handleBlurMultiple = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }
  const handleFocusMultiple = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','')
    setTimeout(()=>{
      $(`#searchToolsBar_${identifier}`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  const getDistrictsOptions = (values) =>{
    if(values.length==0){
      return []
    }
    return districtsData?.filter(x => values.indexOf(x.governorate_id)!=-1).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data
          
          const financial_actions=data?.financial_actions
          const financialActionsOptions=financial_actions?.map(x => {return {'value':x?.id, 'name': t(x?.name)}})
          setFinancialActionData(financial_actions)
          setFinancialActionOptions(financialActionsOptions)

          const account=data?.account
          setHolderName(account?.user?.first_name+' '+account?.user?.last_name)
          setBalance(account?.balance)
        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    let result={
      amount_greater: (filtersS && filtersS?.amount_greater)?filtersS?.amount_greater:amountGreaterFilter,
      amount_lower: (filtersS && filtersS?.amount_lower)?filtersS?.amount_lower:amountLowerFilter,
      amount_equal: (filtersS && filtersS?.amount_equal)?filtersS?.amount_equal:amountEqualFilter,
      financial_action_id: (filtersS && filtersS?.financial_action_id)?filtersS?.financial_action_id:financialActionFilter,
    };
    result.amount_greater=result.amount_greater?parseFloat(result.amount_greater):null
    result.amount_lower=result.amount_lower?parseFloat(result.amount_lower):null
    result.amount_equal=result.amount_equal?parseFloat(result.amount_equal):null
    return result
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.amount_greater?.length===0 && filters.amount_lower?.length===0 && filters.amount_equal?.length===0 &&
      filters.financial_action_id===null
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setAmountGreaterFilter('')
    setAmountLowerFilter('')
    setAmountEqualFilter('')
    setFinancialActionFilter(null)
    setIsFilters(false)

    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
  }

  const loadFiltersApply = (filtersO) => {
    const amountGreater=filtersO?.amount_greater
    if(amountGreater){
      setAmountGreaterFilter(amountGreater)
    }

    const amountLower=filtersO?.amount_lower
    if(amountLower){
      setAmountLowerFilter(amountLower)
    }

    const amountEqual=filtersO?.amount_equal
    if(amountEqual){
      setAmountEqualFilter(amountEqual)
    }

    const financialAction=filtersO?.financial_action_id
    if(financialAction){
      setFinancialActionFilter(financialAction)
    }

    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }

  const HideDropdownBoxes=()=>{
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }

  useEffect(()=>{
    getFiltersData()
    setTimeout(()=>{
      HideDropdownBoxes()
    },200)
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectFinancialAction}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectFinancialAction}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <div>
    <div className='card d-flex flex-direction-row'>
      <div>
        <div className=''>{t('Account Holder Name')}</div>
        <div>{}</div>
      </div>
    </div>
    <br/>
    <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
          <SelectSearch id={styles.SelectFinancialAction}
                    onFocus={handleOnFocusSelect}
                    options={financialActionOptions}
                    onChange={handleChangeFinancialAction}
                    value={financialActionFilter}
                    placeholder={t('Financial Action')}
                    search={true}
                    filterOptions={fuzzySearch}
                    autoFocus={false}
                    />
            <input className={styles.SelectInput} type='number'
                onChange={handleChangeAmountLower}
                value={amountLowerFilter}
                placeholder={`${t('Amount')} ${t('Lower than')}`}
                />
          </div>

          <div className={styles.subSelectsGroup}>
            <input className={styles.SelectInput} type='number'
              onChange={handleChangeAmountEqual}
              value={amountEqualFilter}
              placeholder={`${t('Amount')} ${t('Equal to')}`}
              />

            <input className={styles.SelectInput} type='number'
              onChange={handleChangeAmountGreater}
              value={amountGreaterFilter}
              placeholder={`${t('Amount')} ${t('Greater than')}`}
              />
          </div>
        </div>
        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar dataLength={dataLength} allRef={allRef} deletedRef={deletedRef} isDeleted={isDeleted} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} />
  </nav>
  </div>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
