import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Notes.module.css';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import { useTranslation } from 'react-i18next';

import {FaUserAlt} from 'react-icons/fa';
import {AiFillDelete} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useAuth } from '../Auth/AuthHelpers/Auth';

import $ from 'jquery';

const Notes = ({id, serviceGet, serviceAdd, serviceDelete, isPrivileged, locale, isShowMessages=false, showMessage}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [notes, setNotes] = useState([])
  const [noteToAdd, setNoteToAdd] = useState('')
  
  const GetNotes = () => {
    serviceGet(id)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          const notesO=responseData?.data
          setNotes(notesO)
        }else{
          if(isShowMessages){
            showMessage(false, responseData?.message)
          }
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const addNote = () => {
    serviceAdd(id, noteToAdd)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          GetNotes()
          setNoteToAdd('')
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const handleOnDeleteNote = (noteId) => {
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t('Delete Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html(t('Do you want to delete the items?'))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      serviceDelete(id, noteId)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          GetNotes()
        }
      }).catch(error => {
        console.log(error)
      })

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }

  const handleNoteAddKeyDown = (e) => {
    const keyCode=e.keyCode
    if(keyCode==13){
      addNote()
    }
  }
  const handleNoteAddChange = (e) => {
    setNoteToAdd(e?.target?.value)
  }

  useEffect(() => {
    GetNotes()
  }, [])

  return <div className={styles.Notes}>
    <div className="row d-flex justify-content-center">
      <div className="col-8">
        <div className="card shadow-0 border" style={{backgroundColor: '#f0f2f5'}}>
          <div className="card-body p-4">

            {isPrivileged['add_notes'] && 
              <div className="form-outline mb-4">
                <input type="text" id="addANote" value={noteToAdd} className="form-control" placeholder={`${t('Add a note')}...`} onKeyDown={handleNoteAddKeyDown} onChange={handleNoteAddChange} />
              </div>
            }

            {notes && notes?.length>0 && notes.map((note, id) => {
              return <div className="card mb-4" key={id}>
                  <div className="card-body">
                    <div className="d-flex flex-row align-items-center">
                      <p className="small mb-0 ms-2">{moment(note?.created_at).local().locale(locale).format('DD/MM/yyyy h:mm:ss a')}</p>
                    </div>

                    <p>{note?.value}</p>

                    <div className="d-flex justify-content-between">
                      <Link className="d-flex flex-row align-items-center card p-2" to={`/${prefix}/dashboard/user/view/${id}`}>
                        <p className="small mb-0 ms-2">{note?.user?.first_name + ' ' + note?.user?.last_name}</p>
                        &nbsp;
                        <FaUserAlt />
                      </Link>
                      
                    {(note?.user_id==auth?.user?.user_info?.id || isPrivileged['manage_notes']) && <button className='btn btn-danger' onClick={() => handleOnDeleteNote(note?.id)}><AiFillDelete /></button>}

                    </div>
                  </div>
                </div>;
            })}
          </div>
        </div>
      </div>
    </div>
  </div>
};

Notes.propTypes = {};

Notes.defaultProps = {};

export default Notes;
