import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import GeneralHelpers from '../../../../Services/GeneralHelpers';
import ToolsItemsBar from '../../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({dataLength, page, toolsBarRef, allRef, storageRef, cartRef, deletedRef, paginationRef, printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale}) => {
  const { t, i18n } = useTranslation();

  const isStorage = page=='Storage';
  const isDeleted = page=='Deleted';
  const isCart = page=='Cart';


  const [clerkNameFilter, setClerkNameFilter] = useState('');
  const [recipientNameFilter, setRecipientNameFilter] = useState('');

  const [vendorFilter, setVendorFilter] = useState(null);
  const [vendorsData, setVendorsData] = useState([])
  const [vendorsOptions, setVendorsOptions] = useState([])

  const [storageFilter, setStorageFilter] = useState(null);
  const [storagesData, setStoragesData] = useState([])
  const [storagesOptions, setStoragesOptions] = useState([])

  const [isOutFilter, setIsOutFilter] = useState(null);
  const [isOutData, setIsOutData] = useState([])
  const [isOutOptions, setIsOutOptions] = useState([])



  const [isFilters, setIsFilters] = useState(false)
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      storageRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
      }else{
        paginationRef?.current?.loadPages()
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('Storages_Carts', selecetedItems)
    },
    showUpdateModal(){

    }
  }))

  
  const handleChangeVendor = (value) => {
    setVendorFilter(value)
  }
  const handleChangeStorage = (value) => {
    setStorageFilter(value)
  }

  const handleChangeIsOut = (value) => {
    setIsOutFilter(value)
  }

  const handleChangeClerkName = (e) => {
    setClerkNameFilter(e.target.value)
  }
  const handleChangeRecipientName = (e) => {
    setRecipientNameFilter(e.target.value)
  }

  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          const vendors=data?.vendors
          const vendorsOptions=vendors?.map(x => {return {'value':x.id, 'name': x?.display_name}})
          setVendorsData(vendors)
          setVendorsOptions(vendorsOptions)

          const storages=data?.storages
          const storagesOptions=storages?.map(x => {return {'value':x.id, 'name': x?.name}})
          setStoragesData(storages)
          setStoragesOptions(storagesOptions)

          const trueFalse=['True', 'False']
          const isOutOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsOutData(trueFalse)
          setIsOutOptions(isOutOptions)
        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    let result={
      storage_id: (filtersS && filtersS?.storage_id)?filtersS?.storage_id:storageFilter,
      vendor_id: (filtersS && filtersS?.vendor_id)?filtersS?.vendor_id:vendorFilter,
      clerk_name: (filtersS && filtersS?.clerk_name)?filtersS?.clerk_name:clerkNameFilter,
      recipient_name: (filtersS && filtersS?.recipient_name)?filtersS?.recipient_name:recipientNameFilter,
    }

    if(isOutFilter){
      result={...result, is_out: (filtersS && filtersS?.is_out)?filtersS?.is_out:(isOutFilter=='True')}
    }
    return result
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.vendor_id===null && filters.storage_id===null && filters.is_out===null
      && filters.clerk_name.length===0 && filters.recipient_name.length===0
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setClerkNameFilter('')
    setRecipientNameFilter('')
    setStorageFilter(null)
    setVendorFilter(null)
    setIsOutFilter(null)
    setIsFilters(false)

    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
  }

  const loadFiltersApply = (filtersO) => {
    const clerk_name=filtersO?.clerk_name
    if(clerk_name){
      setClerkNameFilter(clerk_name)
    }

    const recipient_name=filtersO?.recipient_name
    if(recipient_name){
      setRecipientNameFilter(recipient_name)
    }

    const storage_id=filtersO?.storage_id
    if(storage_id){
      setStorageFilter(storage_id)
    }

    const vendor_id=filtersO?.vendor_id
    if(vendor_id){
      setVendorFilter(vendor_id)
    }

    const is_out=filtersO?.is_out
    if(is_out){
      setIsOutFilter(is_out?'True':'False')
    }

    

    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }


  useEffect(()=>{
    getFiltersData()
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectVendor}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectVendor}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>

        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectVendor}
                  onFocus={handleOnFocusSelect}
                  options={storagesOptions}
                  onChange={handleChangeStorage}
                  value={storageFilter}
                  placeholder={t('Storage')}
                  search={true}
                  filterOptions={fuzzySearch}
                  />

            <SelectSearch id={styles.SelectVendor}
                onFocus={handleOnFocusSelect}
                options={vendorsOptions}
                onChange={handleChangeVendor}
                value={vendorFilter}
                placeholder={t('Vendor')}
                search={true}
                filterOptions={fuzzySearch}
                />
          </div>

          <div className={styles.subSelectsGroup}>
             <input className={styles.SelectInput}
                onChange={handleChangeClerkName}
                value={clerkNameFilter}
                placeholder={t('Clerk Name')}
                />

              <input className={styles.SelectInput}
                onChange={handleChangeRecipientName}
                value={recipientNameFilter}
                placeholder={t('Recipient Name')}
                />
          </div>
        </div>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectVendor}
                  onFocus={handleOnFocusSelect}
                  options={isOutOptions}
                  onChange={handleChangeIsOut}
                  value={isOutFilter}
                  placeholder={t('Is Out?')}
                  search={true}
                  filterOptions={fuzzySearch}
                  />
          </div>
        </div>
        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar dataLength={dataLength} allRef={allRef} cartRef={cartRef} storageRef={storageRef} deletedRef={deletedRef} isDeleted={isDeleted} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} />
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
