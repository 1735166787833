import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './UpdateAdd.module.css';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../Common/ModalComponent/ModalComponent';
import RenderErrorMessage from '../../../Common/RenderErrorMessage/RenderErrorMessage';
import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import { useAuth } from '../../../Common/Auth/AuthHelpers/Auth';
import GeneralHelpers from '../../../../Services/GeneralHelpers';

import $ from 'jquery'

const UpdateAdd = ({id, updateOneItem, oneItemDate, isAdd, locale, show, generalFetchingServiceRef, handleWhenHidden, handleOnConfirm, isPrivileged}) => {
  const { t, i18n } = useTranslation();

  const auth=useAuth();

  const [fieldsErrors, setFieldsErrors] = useState([]);


  const [employeesData, setEmployeesData] = useState([])
  const [employeesOptions, setEmployeesOptions] = useState([])

  const [employeesVacationsStatusData, setEmployeesVacationsStatusData] = useState([])
  const [employeesVacationsStatusOptions, setEmployeesVacationsStatusOptions] = useState([])

  const [isLeaveData, setIsLeaveData] = useState([])
  const [isLeaveOptions, setIsLeaveOptions] = useState([])

  const [employee_name, setEmployeeName] = useState('');
  const [employee_id, setEmployeeId] = useState(null);
  const [employees_vacations_status_id, setEmployeesVacationsStatusId] = useState(null);
  const [number_of_days, setNumberOfDays] = useState('');
  const [reason, setReason] = useState('');
  const [approver_notes, setApproverNotes] = useState('');
  const [start_date, setStartDate] = useState(isAdd?Date.now():null);
  const [isEmployee, setIsEmployee] = useState(false);
  
  const [error_message, setErrorMessage] = useState(null);

  const handleChangeEmployeeId=(value)=>{
    setEmployeeId(value)
  }
  const handleChangeEmployeesVacationsStatus=(value)=>{
    setEmployeesVacationsStatusId(value)
  }
  const handleChangeReason=(e)=>{
    setReason(e.target.value)
  }
  const handleChangeNumberOfDays=(e)=>{
    setNumberOfDays(e.target.value)
  }
  const handleChangeApproverNotes=(e)=>{
    setApproverNotes(e.target.value)
  }
  const handleChangeStartDate=(value)=>{
    setStartDate(value?new Date(value):null)
  }

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData('vacation')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let employees=data?.employees
          let employeesOptions=employees?.map(x => {return {'value':x.id, 'name': x?.display_name}})
          setEmployeesData(employees)
          setEmployeesOptions(employeesOptions)

          let employeesVacationsStatus=data?.employees_vacations_status
          let employeesVacationsStatusOptionsO=employeesVacationsStatus?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setEmployeesVacationsStatusData(employeesVacationsStatus)
          setEmployeesVacationsStatusOptions(employeesVacationsStatusOptionsO)

          if(data?.employee){
            setIsEmployee(true)
            setEmployeeData(data?.employee)
          }

        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let dateF=start_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(start_date)).locale("es").format('yyyy-MM-DD hh:mm:ss'):null
    let data={
      employee_id:parseInt(employee_id),
      employees_vacations_status_id:employees_vacations_status_id?parseInt(employees_vacations_status_id):null,
      reason:(reason && reason.trim().length>0)?reason:null,
      start_date: dateF,
      number_of_days:number_of_days,
      approver_notes:(approver_notes && approver_notes.trim().length>0)?approver_notes:null,
    };
    return data;
  }

  const isEmptyObject = (obj) => {
    return !obj.employee_id && !obj.employees_vacations_status_id &&
    !obj.reason && !obj.start_date
  }

  const resetData = () => {
    setEmployeeId(null)
    setEmployeesVacationsStatusId(null)
    setReason('')
    setApproverNotes('')
    setStartDate(null)
    setFieldsErrors([])
    setErrorMessage(null)
    setEmployeeName(null)
  }

  const handleConfirm = (hideCallback) => {
    const obj=getObject()
    handleOnConfirm(obj, isEmptyObject(obj), isAdd, function(isSuccess, error, errorMessage,){
      if(!isSuccess){
        if(error){
          let response=error?.response
          if(response.status==422){
            setFieldsErrors(response?.data?.errors)
          }
        }else{
          setErrorMessage(errorMessage)
        }
        
      }else{
        resetData()
        hideCallback()
      }
      window.scrollTo(0, 0)
    })

  }

  const handleHidden = () => {
    handleWhenHidden()
    resetData()
  }

  const setEmployeeData=(data)=>{
    setEmployeeId(data?.id)
    setEmployeeName(`${data?.user?.first_name} ${data?.user?.last_name}`)
  }

  const setVacationData=(data)=>{
    setStartDate(data?.start_date)
    setNumberOfDays(data?.number_of_days?data?.number_of_days:'')
    setReason(data?.reason?data?.reason:'')
    setEmployeesVacationsStatusId(data?.employees_vacations_status_id)
    setApproverNotes(data?.approver_notes?data?.approver_notes:'')
  }

  const getById = (id) => {
    generalFetchingServiceRef?.current?.getById(id)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setEmployeeData(data[0])
          }
        }).catch(error => {
          console.log(error)
        })
  }

  const getVacationById = (id) => {
    generalFetchingServiceRef?.current?.getById(id, 'vacation')
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setEmployeeData(data[0]?.employee)
            setVacationData(data[0])
          }
        }).catch(error => {
          console.log(error)
        })
  }


  useEffect(()=>{
    if(show){
      getAllData()
      if(id){
        getById(id)
      }
      if(updateOneItem){
        getVacationById(updateOneItem)
      }
    }
  },[show])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <ModalComponent locale={locale} show={show} handleWhenHidden={handleHidden} handleOnConfirmDelayed={handleConfirm} title={`${t(isAdd?'Add':'Update')} ${t('Vacation')}`} confirmBtnText={t(isAdd?'Add':'Update')} cancelBtnText={t('Cancel')} className={styles.Update} data-testid="Update">
    <div className={`${styles.FieldsContainer} ${locale=='ar'?'text-right':'text-left'}`}>

      <RenderErrorMessage name='error_message' messages={{error_message}} alertDisplay={true} />


      {isPrivileged['manage'] && isAdd && !id && <div className={`form-group`}>
        <label htmlFor="employee_id">{t('Employee')}</label>
        <RenderErrorMessage name='employee_id' messages={fieldsErrors} />
        <RenderErrorMessage name='data.employee_id' messages={fieldsErrors} />
        <div>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={employeesOptions}
            onChange={handleChangeEmployeeId}
            value={employee_id}
            placeholder={`${t('Select an')} ${t('employee')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </div>
      </div>}

      {!isAdd && employee_id && <div className={`form-group`}>
        <label htmlFor="employee_id">{t('Employee')}</label>
        <div className='card'>
          {employee_name}
        </div>
      </div>}


      


      {(isPrivileged['vacation_add']) && isAdd && <div className={`form-group`}>
        <label htmlFor="start_date">{t('Start Date')}</label>
        <RenderErrorMessage name='start_date' messages={fieldsErrors} />
        <RenderErrorMessage name='data.start_date' messages={fieldsErrors} />
        <DatePicker wrapperClassName={styles.datePickerWrap} maxDate={Date.now()}
              selected={start_date} onChange={handleChangeStartDate} placeholderText={t('Start Date')} 
              disabled={(!isEmployee && !isAdd) || employees_vacations_status_id!=null}
              />
      </div>}

      {isPrivileged['vacation_update'] && !isAdd && oneItemDate && <div className={`form-group`}>
        <label htmlFor="start_date">{t('Start Date')}</label>
        <div className='card'>
          {oneItemDate && moment(oneItemDate).local().locale(locale).format('yyyy-MM-DD')}
        </div>
      </div>}

      {<div className={`form-group`}>
        <label htmlFor="number_of_days">{t('Number of days')}</label>
        <RenderErrorMessage name='number_of_days' messages={fieldsErrors} />
        <RenderErrorMessage name='data.number_of_days' messages={fieldsErrors} />
        <div>
          <input
          type={'number'}
          className={styles.SelectInput}
          value={number_of_days}
          onChange={handleChangeNumberOfDays}
          disabled={(!isEmployee && !isAdd) || employees_vacations_status_id!=null}
          ></input>
        </div>
      </div>}

      {isPrivileged['vacation_request'] && isEmployee && <div className={`form-group`}>
        <label htmlFor="reason">{t('Vacation Reason')}</label>
        <RenderErrorMessage name='reason' messages={fieldsErrors} />
        <RenderErrorMessage name='data.reason' messages={fieldsErrors} />
        <div>
          <textarea
          className={styles.TextArea}
          value={reason}
          onChange={handleChangeReason}
          disabled={(!isEmployee && !isAdd) || employees_vacations_status_id!=null}
          ></textarea>
        </div>
      </div>}




      {isPrivileged['vacation_approve'] && <div className={`form-group`}>
        <label htmlFor="employees_vacations_status_id">{t('Vacation Status')}</label>
        <RenderErrorMessage name='employees_vacations_status_id' messages={fieldsErrors} />
        <RenderErrorMessage name='data.employees_vacations_status_id' messages={fieldsErrors} />
        <div>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={employeesVacationsStatusOptions}
            onChange={handleChangeEmployeesVacationsStatus}
            value={employees_vacations_status_id}
            placeholder={`${t('Select a')} ${t('vacation status')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </div>
      </div>}


      {isPrivileged['vacation_approve'] && <div className={`form-group`}>
        <label htmlFor="approver_notes">{t('Approver Notes')}</label>
        <RenderErrorMessage name='approver_notes' messages={fieldsErrors} />
        <RenderErrorMessage name='data.approver_notes' messages={fieldsErrors} />
        <div>
          <textarea
          className={styles.TextArea}
          value={approver_notes}
          onChange={handleChangeApproverNotes}
          ></textarea>
        </div>
      </div>}


    </div>
  </ModalComponent>
};

UpdateAdd.propTypes = {};

UpdateAdd.defaultProps = {};

export default UpdateAdd;
