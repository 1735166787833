import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './PrivilegesManage.module.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';

import { useTranslation } from 'react-i18next';

import $ from 'jquery'
import GeneralHelpers from '../../../Services/GeneralHelpers';


const PrivilegesManage = ({id, generalFetchingServiceRef, locale, showMessage}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [fieldsErrors, setFieldsErrors] = useState([]);
  

  const [data, setData] = useState(null)
  const [total, setTotal] = useState(0)
  const [role, setRole] = useState(null)
  const [privileges_all, setPrivilegesAll] = useState([])
  const [privileges_all_grouped, setPrivilegesAllGrouped] = useState([])
  const [privileges_role, setPrivilegesRole] = useState([])
  const [isPrivilegedRole, setIsPrivilegedRole] = useState([])
  const [entities, setEntities] = useState([])
  const [isChanges, setIsChanges] = useState(false)
  

  const getAll=(id)=>{
    generalFetchingServiceRef?.current?.getAll(null, null, null, id+'/get_privileges')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data
          setData(data)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const selectAll = () => {
    let mapPriv = {};
    privileges_all?.forEach(x => {
      mapPriv={...mapPriv, [x?.name]:true}
    });
    setIsPrivilegedRole(mapPriv)
  }
  const DeselectAll = () => {
    let mapPriv = {};
    privileges_all?.forEach(x => {
      mapPriv={...mapPriv, [x?.name]:false}
    });
    setIsPrivilegedRole(mapPriv)
  }
  const Reset = (allData, roleData) => {
    let mapPriv = {};
    allData?.forEach(x => {
      mapPriv={...mapPriv, [x?.name]:roleData?.filter(o=> o?.name == x?.name)?.length>0}
    });
    setIsPrivilegedRole(mapPriv)
  }
  const applyData = () => {
    Reset(data?.privilegesAll, data?.privilegesRole)
    setTotal(data?.total_privileges)
    setRole(data?.role)
    setPrivilegesRole(data?.privilegesRole)
    setPrivilegesAll(data?.privilegesAll)
    setPrivilegesAllGrouped(data?.privilegesAllGrouped)
    setEntities(data?.entities)
  }

  useEffect(()=>{
    applyData()
  },[data])

  const handleApplyBtnClick=()=>{
    generalFetchingServiceRef?.current?.update(isPrivilegedRole, [id], id+'/set_privileges')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          showMessage(true, responseData?.message)
        }else{
          showMessage(false, responseData?.message)
        }
      }).catch(error => {
        console.log(error)
      })
    // window.scrollTo(0, 0);
  }

  const handleClearSelectBtnClick=()=>{
    if(Object.values(isPrivilegedRole)?.filter(x => x)?.length<total){
      selectAll()
    }else{
      DeselectAll()
    }
    setIsChanges(true)
  }

  const handleResetDefaultBtnClick=()=>{
    Reset(privileges_all, privileges_role)
    setIsChanges(false)
  }

  const handleChangeCheck=(value, name)=>{
    setIsPrivilegedRole({...isPrivilegedRole, [name]:value})
    setIsChanges(true)
  }


  useEffect(()=>{
    if(id){
      getAll(id)
    }
  },[])

  return <div className={styles.PrivilegesManage + ` ${locale=='ar'?'text-right':'text-left'}`} data-testid="PrivilegesManage">
    <div>
      <div className='card mb-2 p-2 text-center'>
        {t('Role Name')+` (${t(role?.name)})`}
      </div>
      {entities && entities.length>0 && entities.map(entity=>{
        return <div className={`card ${styles.EntityCard}`} key={entity?.id}>
          <div className={styles.EntityCardTitle}>{t(entity?.name)}</div>
          <div className={styles.PrivilegesContainer}>
            {privileges_all_grouped[entity?.id] && privileges_all_grouped[entity?.id].map(privilege => {
              return <span key={privilege?.id} >
                <input type={'checkbox'} className={styles.CheckBoxDataTable} checked={isPrivilegedRole[privilege?.name]} onChange={(e)=>{handleChangeCheck(e?.target?.checked, privilege?.name)}} /> &nbsp;
                <span>{t(privilege?.name?.replace((entity?.name=='financial_account'?'account':entity?.name)+'_','').replaceAll('_',' '))}</span>
              </span>
            })}
          </div>
        </div>
      })}
    </div>

    <div className={`form-group text-center ${styles.ButtonsContainer}`}>
      {role && <button className={`${styles.PrivilegesManageBtn} btn btn-warning`} onClick={handleResetDefaultBtnClick}>{t('Reset To Default')}</button>}
      {role && <button className={`${styles.PrivilegesManageBtn} btn ${Object.values(isPrivilegedRole)?.filter(x => x)?.length==total?'btn-danger':'btn-success'}`} onClick={handleClearSelectBtnClick}>{Object.values(isPrivilegedRole)?.filter(x => x)?.length==total?t('Clear All'):t('Select All')}</button>}
      {role && <button className={`${styles.PrivilegesManageBtn} btn btn-primary`} onClick={handleApplyBtnClick} disabled={!isChanges}>{t('Save')}</button>}
    </div>

  </div>
};

PrivilegesManage.propTypes = {};

PrivilegesManage.defaultProps = {};

export default PrivilegesManage;
