import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../../Services/GeneralHelpers';
import ToolsItemsBar from '../../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({id, dataLength, page, toolsBarRef, allRef, salaryDeductionRef, deletedRef, paginationRef, printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale}) => {
  const { t, i18n } = useTranslation();

  const isGeneral = page=='General';
  const isDeleted = page=='Deleted';
  const isSalaryDeduction = page=='SalaryDeduction';

  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);

  const [employeeFilter, setEmployeeFilter] = useState(id?id:null);
  const [employeesData, setEmployeesData] = useState([])
  const [employeesOptions, setEmployeesOptions] = useState([])

  const [isFilters, setIsFilters] = useState(false)
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      allRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        filtersH.employee_id=id;
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
      }else{
        if(id){
          const filters={employee_id:id}
          paginationRef?.current?.loadPagesWithFilters(getFilters(filters))
        }else{
          paginationRef?.current?.loadPages()
        }
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('employees_salary_deduction', selecetedItems)
    },
    showUpdateAddSalaryDeductionModal(isAdd){
      if(isSalaryDeduction){
        salaryDeductionRef?.current?.showUpdateAddModal(isAdd)
      }
    }
  }))

  const handleChangeYear = (e) => {
    setYear(e.target.value)
  }
  const handleChangeMonth = (e) => {
    setMonth(e.target.value)
  }
  const handleChangeEmployee = (value) => {
    setEmployeeFilter(value)
  }

  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          const employees=data?.employees
          const employeesOptions=employees?.map(x => {return {'value':x?.id, 'name': x?.display_name}})
          setEmployeesData(employees)
          setEmployeesOptions(employeesOptions)

        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    let result={
      employee_id: (filtersS && filtersS?.employee_id)?filtersS?.employee_id:employeeFilter,
      year: (filtersS && filtersS?.year)?filtersS?.year:year,
      month: (filtersS && filtersS?.month)?filtersS?.month:month,
    };
    return result
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.employee_id===null && 
      filters.year===null && filters.month===null
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setYear('')
    setMonth('')
    setEmployeeFilter(null)

    setIsFilters(false)
    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
  }

  const loadFiltersApply = (filtersO) => {
    if(filtersO?.employee_id){
      setEmployeeFilter(filtersO?.employee_id)
    }

    if(filtersO?.year){
      setYear(filtersO?.year)
    }

    if(filtersO?.month){
      setMonth(filtersO?.month)
    }


    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }

  const HideDropdownBoxes=()=>{
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }

  useEffect(()=>{
    getFiltersData()
    setTimeout(()=>{
      HideDropdownBoxes()
    },200)
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectEmployee}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectEmployee}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <input
              type={'number'}
              className={styles.SelectInput}
              value={year}
              onChange={handleChangeYear}
              placeholder={t('Year')}
              ></input>

            <input
              type={'number'}
              className={styles.SelectInput}
              value={month}
              onChange={handleChangeMonth}
              placeholder={t('Month')}
              ></input>
          </div>
          {isPrivileged['manage'] && !id && <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectEmployee}
                      onFocus={handleOnFocusSelect}
                      options={employeesOptions}
                      onChange={handleChangeEmployee}
                      value={employeeFilter}
                      placeholder={t('Employee')}
                      search={true}
                      filterOptions={fuzzySearch}
                      />
          </div>}
        </div>

        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar dataLength={dataLength} allRef={allRef} deletedRef={deletedRef} salaryDeductionRef={salaryDeductionRef} page={page} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} />
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
