import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Settings.module.css';
import DashboardLayout from '../Dashboard/DashboardLayout/DashboardLayout';
import { useTranslation } from 'react-i18next';
import RenderErrorMessage from '../Common/RenderErrorMessage/RenderErrorMessage';
import GeneralFetchingService from '../../Services/GeneralFetchingService';
import Privileges from '../Common/Privileges/Privileges';
import { useAuth } from '../Common/Auth/AuthHelpers/Auth';
import 'react-select-search/style.css'

import { BlockPicker, SketchPicker } from 'react-color'
import RenderSuccessMessage from '../Common/RenderSuccessMessage/RenderSuccessMessage';
import SelectSearch from 'react-select-search';
import { fuzzySearch } from 'react-select-search';

import countryList from 'react-select-country-list'

import $ from 'jquery'

const Settings = ({page, locale, setLocale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const generalFetchingServiceRef = useRef();
  const privilegesRef= useRef();
  const dashboardRef = useRef();

  const [fieldsErrors, setFieldsErrors] = useState([]);

  const [isPrivileges, setIsPrivileges] = useState(false)
  const [privilegesSideBar, setPrivilegesSideBar] = useState([])
  const [isPrivileged, setIsPrivileged] = useState({})

  const [topBarColor, setTopBarColor] = useState("#343a40");
  const [siteColor, setSiteColor] = useState("#fff");
  const [siteColorVisible, setSiteColorVisible] = useState(false);
  const [topBarColorVisible, setTopBarColorVisible] = useState(false);

  const [siteFont, setSiteFont] = useState("auto");

  const [shipping_price_internal_center, setShippingPriceInternalCenter] = useState(0)
  const [shipping_price_internal_margin, setShippingPriceInternalMargin] = useState(0)
  const [shipping_price_outer, setShippingPriceOuter] = useState(0)

  const [storage_cm3_price, setStorageCm3Price] = useState(0)
  const [storage_wrapping_price, setStorageWrappingPrice] = useState(0)

  const [currency, setCurrency] = useState('')
  const [currency_name, setCurrencyName] = useState('')


  const [generalErrorMessage, setGeneralErrorMessage] = useState(null);
  const [generalSuccessMessage, setGeneralSuccessMessage] = useState(null);
  const [isShownGeneralError, setIsShownGeneralError] = useState(false);
  const [isShownGeneralSuccess, setIsShownGeneralSuccess] = useState(false);

  const [fontsData, setFontsData] = useState([])
  const [fontsOptions, setFontsOptions] = useState([])

  const [country, setCountry] = useState(null)
  const [countriesData, setCountriesData] = useState([])
  const [countriesOptions, setCountriesOptions] = useState([])

  const [day_of_month_cashing_salary, setDayOfMonthCashingSalary] = useState(5)
  const [daysOfMonthCashingSalaryData, setDaysOfMonthCashingSalaryData] = useState([])
  const [daysOfMonthCashingSalaryOptions, setDaysOfMonthCashingSalaryOptions] = useState([])

  const [admin_financial_account_accounting, setAdminFinancialAccountAccountingUser] = useState(5)
  const [adminFinancialAccountsAccountingData, setAdminFinancialAccountsAccountingData] = useState([])
  const [adminFinancialAccountsAccountingOptions, setAdminFinancialAccountsAccountingOptions] = useState([])
  


  const handleChangeShippingPriceInternalCenter=(e)=>{
    setShippingPriceInternalCenter(e.target.value)
  }
  const handleChangeShippingPriceInternalMargin=(e)=>{
    setShippingPriceInternalMargin(e.target.value)
  }
  const handleChangeShippingPriceOuter=(e)=>{
    setShippingPriceOuter(e.target.value)
  }

  const handleChangeStorageCm3Price=(e)=>{
    setStorageCm3Price(e.target.value)
  }
  const handleChangeStorageWrappingPrice=(e)=>{
    setStorageWrappingPrice(e.target.value)
  }

  const handleChangeFont=(value)=>{
    setSiteFont(value)
  }

  const handleChangeCountry=(value)=>{
    setCountry(value)
  }
  const handleChangeCurrency=(e)=>{
    setCurrency(e.target.value)
  }
  const handleChangeCurrencyName=(e)=>{
    setCurrencyName(e.target.value)
  }

  const handleChangeDayOfMonthCashingSalary=(value)=>{
    setDayOfMonthCashingSalary(value)
  }

  const handleChangeAdminFinancialAccountAccounting=(value)=>{
    setAdminFinancialAccountAccountingUser(value)
  }

  const getObject = () =>{
    let data={
      topbar_color:topBarColor,
      site_color:siteColor,
      site_font:siteFont
    };
    if(['company', 'vendor'].indexOf(prefix)!=-1){
      data={...data, 
        shipping_price_internal_center,
        shipping_price_internal_margin,
        shipping_price_outer,
      }
    }
    if(['company'].indexOf(prefix)!=-1){
      data={...data, 
        storage_cm3_price,
        storage_wrapping_price,
        day_of_month_cashing_salary,
      }
    }
    if(['admin'].indexOf(prefix)!=-1){
      data={...data, 
        country,
        currency,
        currency_name,
        admin_financial_account_accounting,
      }
    }
    return data;
  }

  const setAllData = (data) =>{
    data.forEach(setting => {
      switch(setting?.name){
        case 'topbar_color':
          setTopBarColor(setting?.value)
          break;
        case 'site_color':
          setSiteColor(setting?.value)
          break;
        case 'site_font':
          setting?.value=='auto'?'':setSiteFont(setting?.value)
          break;
        case 'shipping_price_internal_center':
            setShippingPriceInternalCenter(setting?.value)
            break;
        case 'shipping_price_internal_margin':
          setShippingPriceInternalMargin(setting?.value)
          break;
        case 'shipping_price_outer':
          setShippingPriceOuter(setting?.value)
          break;
        case 'storage_cm3_price':
          setStorageCm3Price(setting?.value)
          break;
        case 'storage_wrapping_price':
          setStorageWrappingPrice(setting?.value)
          break;
        case 'country':
          setCountry(setting?.value)
          break;
        case 'currency':
          if(setting?.value){
            setCurrency(setting?.value)
          }
          break;
        case 'currency_name':
          if(setting?.value){
            setCurrencyName(setting?.value)
          }
          break;
        case 'day_of_month_cashing_salary':
          if(setting?.value){
            setDayOfMonthCashingSalary(setting?.value)
          }
          break;
        case 'admin_financial_account_accounting':
          if(setting?.value){
            setAdminFinancialAccountAccountingUser(parseInt(setting?.value))
          }
          break;
      }
    });
  }

  const getOptions = () => {
    let fonts=[
      'auto',
      'Arial',
      'Verdana',
      'Tahoma',
      'Trebuchet MS',
      'Times New Roman',
      'Georgia',
      'Garamond',
      'Courier New',
      'Brush Script MT',
    ]
    let fontsOptions=fonts?.map(x => {return {'value':x, 'name': x}})
    setFontsData(fonts)
    setFontsOptions(fontsOptions)

    if(['admin'].indexOf(prefix)!=-1){
      const countries=countryList().getData();
      let countriesOptions=countries?.map(x => {return {'value':x?.value, 'name': x?.label}})
      setCountriesData(countries)
      setCountriesOptions(countriesOptions)
    }

    if(['company'].indexOf(prefix)!=-1){
      const daysOfMonth = Array(28).fill().map((v,i)=>i+1);
      let daysOfMonthOptions=daysOfMonth?.map(x => {return {'value':x, 'name': x+''}})
      setDaysOfMonthCashingSalaryData(daysOfMonth)
      setDaysOfMonthCashingSalaryOptions(daysOfMonthOptions)
    }

    generalFetchingServiceRef?.current?.getAllData('general_data')
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data

            let adminsAccounts=data?.adminsAccounts
            let adminsAccountsOptions=adminsAccounts?.map(x => {return {'value':x?.user?.id, 'name': x?.display_name}})
            setAdminFinancialAccountsAccountingData(adminsAccounts)
            setAdminFinancialAccountsAccountingOptions(adminsAccountsOptions)

          }
        }).catch(error => {
          console.log(error)
        })
  }

  const getData = () => {
    generalFetchingServiceRef?.current?.getAllData()
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            setAllData(responseData?.data)
          }
        }).catch(error => {
          console.log(error)
        })

    generalFetchingServiceRef?.current?.getAllData('general')
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            setAllData(responseData?.data)
          }
        }).catch(error => {
          console.log(error)
        })
  }

  const handleSave = (data) => {
    generalFetchingServiceRef?.current?.update(data, null, 'set')
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            dashboardRef?.current?.changeTopBarColor(topBarColor)
            dashboardRef?.current?.changeSiteColor(siteColor)
            dashboardRef?.current?.changeSiteFont(siteFont)
            showMessage(true, responseData?.message)
          }else{
            showMessage(false, responseData?.message)
          }
        }).catch(error => {
          console.log(error)
        })
  }

  const handleSaveBtnClick=()=>{
    let data=getObject()
    handleSave(data)
    window.scrollTo(0, 0);
  }

  const handleResetBtnClick=()=>{
    setTopBarColor("#343a40")
    setSiteColor("#fff")
    setSiteFont("auto")
    setShippingPriceInternalCenter(0)
    setShippingPriceInternalMargin(0)
    setShippingPriceOuter(0)
    setStorageCm3Price(0)
    setStorageWrappingPrice(0)
    setCountry(null)
    setCurrency(null)
    setCurrencyName(null)
    setDayOfMonthCashingSalary(5)
    setAdminFinancialAccountAccountingUser(null)
  }
  
  const showMessage = (isSuccess, message) => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
    if(isSuccess){
      setGeneralSuccessMessage(message)
      setIsShownGeneralSuccess(true)
    }else{
      setGeneralErrorMessage(message)
      setIsShownGeneralError(true)
    }
    window.scrollTo(0, 0)
  }

  const hideMessage = () => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
  }

  

  

  useEffect(()  => {
    const sysEnt='setting'
    privilegesRef?.current?.getPrivileges(sysEnt).then(privilegesO=>{
      if(privilegesO && privilegesO?.privileges?.length>0){
        let mapPriv = {};
        privilegesO?.privileges?.forEach(x => {
          const key=x?.replace(`${sysEnt}_`, '')
          mapPriv={...mapPriv, [key]:true}
        });
        setIsPrivileged(mapPriv)
        setIsPrivileges(true)
      }
      if(privilegesO && privilegesO?.sideBarPrivileges?.length>0){
        setPrivilegesSideBar(privilegesO?.sideBarPrivileges)
      }
    })
  }, [])

  useEffect(() => {
    getData()
    getOptions()
  },[isPrivileged])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <DashboardLayout component='Settings' locale={locale} privilegesSideBar={privilegesSideBar} setLocale={setLocale} dashboardRef={dashboardRef}>
    <br/>
    <div>
      <strong>{t('User Account Type')}</strong>
      <div>{t(prefix)}</div>
    </div>
    <br/>
    <br/>
    <GeneralFetchingService generalFetchingServiceRef={generalFetchingServiceRef} prefix='setting' />
    <Privileges generalFetchingServiceRef={generalFetchingServiceRef} privilegesRef={privilegesRef}></Privileges>
    <div className={`${styles.Settings} card`} data-testid="Settings">

    <RenderErrorMessage name='general' messages={generalErrorMessage!=null?{'general':generalErrorMessage}:{}} alertDisplay={true} isShown={isShownGeneralError} setIsShownGeneralError={setIsShownGeneralError} isDissmiss={true} handleHideMessage={hideMessage} />
    <RenderSuccessMessage name='general' messages={generalSuccessMessage!=null?{'general':generalSuccessMessage}:{}} alertDisplay={true} isShown={isShownGeneralSuccess} setIsShownGeneralSuccess={setIsShownGeneralSuccess} isDissmiss={true} handleHideMessage={hideMessage} />

    <div className="form-group d-flex flex-column align-items-center">
      <label htmlFor="topbar_color" style={{fontWeight:'bold'}}>{`${t('Top Bar Color')}`}</label>
      <RenderErrorMessage name='topbar_color' messages={fieldsErrors} />
      <div 
      style={{'backgroundColor':topBarColor, 'border':'1px solid black', 'width': '200px', 'height':'50px', 'cursor':'pointer'}}
      onClick={()=>{
        if(topBarColorVisible){
          setTopBarColorVisible(false)
        }else{
          setTopBarColorVisible(true)
        }
        setSiteColorVisible(false)
      }}
      ></div>
      {topBarColorVisible && <BlockPicker
          color={topBarColor}
          onChange={(color) => {
            setTopBarColor(color.hex);
            setTopBarColorVisible(false)
          }}
          className={'mt-2'}
          onBlur={() => setTopBarColorVisible(false)}
        />}
    </div>

    <div className="form-group d-flex flex-column align-items-center">
      <label htmlFor="site_color" style={{fontWeight:'bold'}}>{`${t('Site Color')}`}</label>
      <RenderErrorMessage name='site_color' messages={fieldsErrors} />
      <div 
      style={{'backgroundColor':siteColor, 'border':'1px solid black', 'width': '200px', 'height':'50px', 'cursor':'pointer'}}
      onClick={()=>{
        if(siteColorVisible){
          setSiteColorVisible(false)
        }else{
          setSiteColorVisible(true)
        }
        setTopBarColorVisible(false)
      }}
      ></div>
      {siteColorVisible && <BlockPicker
          color={siteColor}
          onChange={(color) => {
            setSiteColor(color.hex);
            setSiteColorVisible(false)
          }}
          className={'mt-2'}
          onBlur={() => setSiteColorVisible(false)}
        />}
    </div>


    <div className="form-group d-flex flex-column align-items-center">
      <label htmlFor="site_font" style={{fontWeight:'bold'}}>{t('Font')}</label>
      <RenderErrorMessage name='site_font' messages={fieldsErrors} />
      <div style={{fontFamily:siteFont}}>{'sample text نص مثال'}</div>
      <div>
        <SelectSearch id={styles.Select}
          onFocus={handleOnFocusSelect}
          options={fontsOptions}
          onChange={handleChangeFont}
          value={siteFont}
          placeholder={`${t('Select a')} ${t('font')}`}
          search={true}
          filterOptions={fuzzySearch}
          />
      </div> 
    </div>

    {['company'].indexOf(prefix)!=-1 && <div>
      <br/>
      <div className="form-group">
        <label htmlFor="shipping_price_internal_center" style={{fontWeight:'bold'}}>{`${t('Shipping Price')} _ ${t('Internal Governorate _ Center')}`}</label>
        <RenderErrorMessage name='shipping_price_internal_center' messages={fieldsErrors} />
        <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('price')}`} id="shipping_price_internal_center" onChange={handleChangeShippingPriceInternalCenter} value={shipping_price_internal_center} />
      </div>

      <div className="form-group">
        <label htmlFor="shipping_price_internal_margin" style={{fontWeight:'bold'}}>{`${t('Shipping Price')} _ ${t('Internal Governorate _ Margin')}`}</label>
        <RenderErrorMessage name='shipping_price_internal_margin' messages={fieldsErrors} />
        <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('price')}`} id="shipping_price_internal_margin" onChange={handleChangeShippingPriceInternalMargin} value={shipping_price_internal_margin} />
      </div>

      <div className="form-group">
        <label htmlFor="shipping_price_outer" style={{fontWeight:'bold'}}>{`${t('Shipping Price')} _ ${t('Outer Governorate')}`}</label>
        <RenderErrorMessage name='shipping_price_outer' messages={fieldsErrors} />
        <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('price')}`} id="shipping_price_outer" onChange={handleChangeShippingPriceOuter} value={shipping_price_outer} />
      </div>
    </div>}
      
      <br/>

      {['company'].indexOf(prefix)!=-1 && <div className="d-flex flex-column align-items-center">
      <br/>
      <div className="form-group">
        <label htmlFor="storage_cm3_price" style={{fontWeight:'bold'}}>{`${t('Storage Price / cm3')}`}</label>
        <RenderErrorMessage name='storage_cm3_price' messages={fieldsErrors} />
        <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('price')}`} id="storage_cm3_price" onChange={handleChangeStorageCm3Price} value={storage_cm3_price} />
      </div>

      <div className="form-group">
        <label htmlFor="storage_wrapping_price" style={{fontWeight:'bold'}}>{`${t('Storage Wrapping Price / cm2')}`}</label>
        <RenderErrorMessage name='storage_wrapping_price' messages={fieldsErrors} />
        <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('price')}`} id="storage_wrapping_price" onChange={handleChangeStorageWrappingPrice} value={storage_wrapping_price} />
      </div>

      <br/>
      <br/>

      <div className="form-group">
        <label htmlFor="country" style={{fontWeight:'bold'}}>{t('Employee Cashing Salary Day of Month')}</label>
        <RenderErrorMessage name='country' messages={fieldsErrors} />
        <div>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={daysOfMonthCashingSalaryOptions}
            onChange={handleChangeDayOfMonthCashingSalary}
            value={day_of_month_cashing_salary}
            placeholder={`${t('Select a')} ${t('day')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </div> 
      </div>
    </div>}

    {['admin'].indexOf(prefix)!=-1 && <div className="d-flex flex-column align-items-center">
      <div className="form-group">
        <label htmlFor="country" style={{fontWeight:'bold'}}>{t('Country')}</label>
        <RenderErrorMessage name='country' messages={fieldsErrors} />
        <div>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={countriesOptions}
            onChange={handleChangeCountry}
            value={country}
            placeholder={`${t('Select a')} ${t('country')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </div> 
      </div>

      <div className="form-group">
        <label htmlFor="currency" style={{fontWeight:'bold'}}>{`${t('Currency')}`}</label>
        <RenderErrorMessage name='currency' messages={fieldsErrors} />
        <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('currency')}`} id="currency" onChange={handleChangeCurrency} value={currency} />
      </div>

      <div className="form-group">
        <label htmlFor="currency_name" style={{fontWeight:'bold'}}>{`${t('Currency Name')}`}</label>
        <RenderErrorMessage name='currency_name' messages={fieldsErrors} />
        <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('name')}`} id="currency_name" onChange={handleChangeCurrencyName} value={currency_name} />
      </div>

      <div className="form-group">
        <label htmlFor="admin_financial_account_accounting" style={{fontWeight:'bold'}}>{t('Admin Financial Account for Accounting')}</label>
        <RenderErrorMessage name='admin_financial_account_accounting' messages={fieldsErrors} />
        <div>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={adminFinancialAccountsAccountingOptions}
            onChange={handleChangeAdminFinancialAccountAccounting}
            value={admin_financial_account_accounting}
            placeholder={`${t('Select an')} ${t('account')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </div> 
      </div>
    </div>}

      <div className="form-group text-center">
        <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleSaveBtnClick}>{t('Save')}</button> &nbsp;
        <button className={styles.ResetBtn + ' btn btn-danger'} onClick={handleResetBtnClick}>{t('Reset')}</button>
      </div>
    </div>
  </DashboardLayout>
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;
