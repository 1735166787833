import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './Auth';

const ProtectedRoute = ({ children }) => {
  const auth = useAuth();
  const location= useLocation()

  if(!auth?.user){
    return <Navigate to="/auth/login" state={{ path: location.pathname }} />
  }

  return children;
};

export default ProtectedRoute;
