import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './AddEditCart.module.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'


import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';

import { useTranslation } from 'react-i18next';
import $ from 'jquery'

const AddEditCart = ({id, editSource, handleAddEdit, generalFetchingServiceRef, locale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [fieldsErrors, setFieldsErrors] = useState([]);

  
  const [vendorsData, setVendorsData] = useState([])
  const [vendorsOptions, setVendorsOptions] = useState([])

  const [storagesData, setStoragesData] = useState([])
  const [storagesOptions, setStoragesOptions] = useState([])
  
  const [vendor_id, setVendorId] = useState(null)
  const [storage_id, setStorageId] = useState(null)
  const [items_number, setItemsNumber] = useState('')
  const [items_size_cm3, setItemsAreaCm3] = useState('')
  const [cm3_price, setCm3Price] = useState('')
  const [clerk_name, setClerkName] = useState('')
  const [recipient_name, setRecipientName] = useState('')
  
  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData(editSource=='all'?'':editSource)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          const vendors=data?.vendors
          const vendorsOptions=vendors?.map(x => {return {'value':x.id, 'name': x?.display_name}})
          setVendorsData(vendors)
          setVendorsOptions(vendorsOptions)

          const storages=data?.storages
          const storagesOptions=storages?.map(x => {return {'value':x.id, 'name': x?.name}})
          setStoragesData(storages)
          setStoragesOptions(storagesOptions)

          if(id){
            getById(id)
          }
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let data={
      vendor_id,
      storage_id,
      items_number,
      items_size_cm3,
      cm3_price,
      clerk_name,
      recipient_name
    };
    return data;
  }
  const handleAddBtnClick=()=>{
    let item=getObject()
    handleAddEdit(item, setFieldsErrors, resetData, editSource)
    window.scrollTo(0, 0);
  }
  
  const handleEditBtnClick=()=>{
    let item=getObject()
    item.id=id
    handleAddEdit(item, setFieldsErrors, null, editSource)
    window.scrollTo(0, 0);
  }

  const resetData = () => {
    setVendorId(null)
    setStorageId(null)
    setItemsNumber('')
    setItemsAreaCm3('')
    setCm3Price('')
    setClerkName('')
    setRecipientName('')
  }

  const handleChangeVendor = (value) => {
    setVendorId(value)
  }
  const handleChangeStorage = (value) => {
    setStorageId(value)
  }
  const handleChangeItemsNumber=(e)=>{
    setItemsNumber(e.target.value)
  }
  const handleChangeItemsAreaCm3=(e)=>{
    setItemsAreaCm3(e.target.value)
  }
  const handleChangeCm3Price=(e)=>{
    setCm3Price(e.target.value)
  }
  const handleChangeClerkName=(e)=>{
    setClerkName(e.target.value)
  }
  const handleChangeRecipientName=(e)=>{
    setRecipientName(e.target.value)
  }
  
  
  const setAllData=(data)=>{
    let item=data[0]
    setVendorId(item?.vendor_id)
    setStorageId(item?.storage_id)
    setItemsNumber(item?.items_number?item?.items_number:'')
    setItemsAreaCm3(item?.items_size_cm3?item?.items_size_cm3:'')
    setCm3Price(item?.cm3_price?item?.cm3_price:'')
    setClerkName(item?.clerk_name?item?.clerk_name:'')
    setRecipientName(item?.recipient_name?item?.recipient_name:'')
  }

  const getById = (id) => {
    generalFetchingServiceRef?.current?.getById(id, editSource=='all'?'':editSource)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setAllData(data)
          }
        }).catch(error => {
          console.log(error)
        })
  }
  useEffect(()=>{
    getAllData()
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectVendor}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectVendor}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <div className={styles.AddEditCart + ` ${locale=='ar'?'text-right':'text-left'}`} data-testid="AddEditCart">
    <div className="form-group">
      <label htmlFor="vendor_id">{t('Vendor')}</label>
      <RenderErrorMessage name='vendor_id' messages={fieldsErrors} />
      <SelectSearch id={styles.SelectVendor}
                onFocus={handleOnFocusSelect}
                options={vendorsOptions}
                onChange={handleChangeVendor}
                value={vendor_id}
                placeholder={t('Vendor')}
                search={true}
                filterOptions={fuzzySearch}
                />
    </div>

    <div className="form-group">
      <label htmlFor="storage_id">{t('Storage')}</label>
      <RenderErrorMessage name='storage_id' messages={fieldsErrors} />
      <SelectSearch id={styles.SelectVendor}
                onFocus={handleOnFocusSelect}
                options={storagesOptions}
                onChange={handleChangeStorage}
                value={storage_id}
                placeholder={t('Storage')}
                search={true}
                filterOptions={fuzzySearch}
                />
    </div>

    <div className="form-group">
      <label htmlFor="clerk_name">{t('Clerk Name')}</label>
      <RenderErrorMessage name='clerk_name' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('clerk name')}`} id="clerk_name" onChange={handleChangeClerkName} value={clerk_name} />
    </div>

    <div className="form-group">
      <label htmlFor="recipient_name">{t('Recipient Name')}</label>
      <RenderErrorMessage name='recipient_name' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('recipient name')}`} id="recipient_name" onChange={handleChangeRecipientName} value={recipient_name} />
    </div>

    <div className="form-group">
      <label htmlFor="items_number">{t('Items Number')}</label>
      <RenderErrorMessage name='items_number' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter')} ${t('items number')}`} id="items_number" onChange={handleChangeItemsNumber} value={items_number} />
    </div>

    <div className="form-group">
      <label htmlFor="items_size_cm3">{t('Items Size (cm3)')}</label>
      <RenderErrorMessage name='items_size_cm3' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter')} ${t('items size')}`} id="items_size_cm3" onChange={handleChangeItemsAreaCm3} value={items_size_cm3} />
    </div>

    <div className="form-group">
      <label htmlFor="cm3_price">{t('Price / (cm3)')}</label>
      <RenderErrorMessage name='cm3_price' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('price')}`} id="cm3_price" onChange={handleChangeCm3Price} value={cm3_price} />
    </div>
    
    <div className="form-group text-center">
      {!id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleAddBtnClick}>{t('Add')}</button>}
      {id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleEditBtnClick}>{t('Edit')}</button>}
    </div>

  </div>
};

AddEditCart.propTypes = {};

AddEditCart.defaultProps = {};

export default AddEditCart;
