import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './CompaniesOffers.module.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';

import { useTranslation } from 'react-i18next';

import $ from 'jquery'
import GeneralHelpers from '../../../Services/GeneralHelpers';
import { Link } from 'react-router-dom';
import ToolsBar from './ToolsBar/ToolsBar';
import SendOffer from './SendOffer/SendOffer';


const CompaniesOffers = ({id, generalFetchingServiceRef, locale, showMessage, handleTabChange}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const toolsBarRef = useRef();
  const companyOffersRef = useRef();

  const [fieldsErrors, setFieldsErrors] = useState([]);
  
  const [isSendOffer, setIsSendOffer] = useState(false)
  const [offerToSend, setOfferToSend] = useState(null)
  
  const [isCompany, setIsCompany] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [availableOffers, setAvailableOffers] = useState([]);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [total, setTotal] = useState(0);
  
  const [sendOfferFieldsErrors, setSendOfferFieldsErrors] = useState([])

  useImperativeHandle(companyOffersRef,()=>({
    loadPages(filters){
      getAllData(filters)
    }
  }))

  const getAllData=(filters)=>{
    generalFetchingServiceRef?.current?.getAllData('offers/available', filters)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data
          setTotal(data?.total)
          setAvailableOffers(data?.availableOffers)
          setCurrentCompany(data?.currentCompany)

          if(id){
            handleShowSendOffer(data?.availableOffers?.filter(x => x?.id==id)[0])
          }
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const handleWhenSendOfferHidden = () =>{
    setIsSendOffer(false)
  }

  const handleShowSendOffer = (offer) => {
    setOfferToSend(offer)
    setIsSendOffer(true)
  }

  const handleSendOffer = (data, isEmpty, hideCallback, resetData, id) => {
    if(isEmpty){
      return;
    }
    data.company_id=currentCompany;
    data.company_target_id=offerToSend?.company_id;
    data.companies_offer_id=offerToSend?.id;
    generalFetchingServiceRef?.current?.update(data, [offerToSend?.id], !id?'offer/request':`offer/request/edit/${id}`)
      .then(response => {
        let responseData=response?.data
          let message=responseData?.message
          if(responseData && responseData?.status==1){
            setIsSendOffer(false)
            setOfferToSend(null)
            window.scrollTo(0, 0)
            hideCallback()

            if(!id){
              showMessage(true, message)
              resetData()
            }else{
              handleTabChange('SentOffers')
              setTimeout(()=>{
                showMessage(true, message)
              },200)
            }
          }else{
            showMessage(false, message)
          }
      }).catch(error => {
        console.log(error)
        let response=error.response
        if(response.status==422){
          setSendOfferFieldsErrors(response.data.errors)
        }
      })
  }

  



  useEffect(()=>{
    if(prefix=='company'){
      setIsCompany(true)
    }
    if(prefix=='employee'){
      setIsEmployee(true)
    }
  },[])

  const isShowSendOfferRequestButton = (offer) => {
    if(offer?.company_id==currentCompany){
      return false
    }
    return true
  }

  const filtersDataService = () => generalFetchingServiceRef?.current?.getFiltersData(null, 'offers')

  return <div className={styles.CompaniesOffers + ` ${locale=='ar'?'text-right':'text-left'} card p-5`} data-testid="CompaniesOffers">
    <ToolsBar toolsBarRef={toolsBarRef}
     identifier='CompaniesOffers' filtersDataService={filtersDataService}
     locale={locale} companyOffersRef={companyOffersRef}
     />
    <br/>
    <br/>

  <SendOffer id={id} locale={locale} show={isSendOffer} generalFetchingServiceRef={generalFetchingServiceRef} handleWhenHidden={handleWhenSendOfferHidden} handleOnConfirm={handleSendOffer} fieldsErrorsIn={sendOfferFieldsErrors} handleTabChange={handleTabChange} />

    {availableOffers && <div className='mb-2 h4'>{`${t('Available Offers')} (${total})`}</div>}
    <br/>
    {availableOffers && availableOffers.map((offer, i)=>{
        return <div className={`card d-flex flex-column p-2 mb-3 '}`}
        key={offer?.id}>
          <div className='d-flex flex-row'>
            <div className='p-4 flex-fill'>
              <div><span className='font-weight-bold'>{t('Company')}</span>&nbsp;&nbsp;<span><Link to={`/${prefix}/dashboard/company/view/${offer?.company?.id}`}>{offer?.company?.name}</Link></span></div>
              <div><span className='font-weight-bold'>{t('Name')}</span>&nbsp;&nbsp;<span>{offer?.title}</span></div>
              <div><span className='font-weight-bold'>{t('Max Orders Number')}</span>&nbsp;&nbsp;<span>{offer?.max_orders_number}</span></div>
              <div><span className='font-weight-bold'>{t('Min Orders Number')}</span>&nbsp;&nbsp;<span>{offer?.min_orders_number}</span></div>
              <div><span className='font-weight-bold'>{t('Transfer Price')}</span>&nbsp;&nbsp;<span>{offer?.transfer_price}</span></div>
              <div><span className='font-weight-bold'>{t('Details')}</span>&nbsp;&nbsp;<textarea className={styles.DisplayTextArea} readOnly={true} value={offer?.details}></textarea></div>
              <br/>
              {(isCompany || isEmployee) && <div>
                {isShowSendOfferRequestButton(offer) && <div><button className={`btn btn-primary`} onClick={()=>handleShowSendOffer(offer)}>
                {t('Send Offer')}
                </button></div>}
              </div>}
            </div>
          </div>
        </div>
    })}
    {isCompany && availableOffers?.length==0 && <div>
      <br/><br/>
      <div className='w-75 text-center alert alert-info p-2 m-auto'>{t('No items to display')}</div>
    </div>}
  </div>
};

CompaniesOffers.propTypes = {};

CompaniesOffers.defaultProps = {};

export default CompaniesOffers;
