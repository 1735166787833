import React from 'react';
import PropTypes from 'prop-types';
import styles from './TopBar.module.css';

import { useTranslation } from 'react-i18next';

import {BiAddToQueue} from 'react-icons/bi';
import {FaRegObjectGroup} from 'react-icons/fa';
import {AiFillEdit} from 'react-icons/ai';
import {AiFillDelete} from 'react-icons/ai';
import {GrFormView} from 'react-icons/gr';
import {IoLogoBuffer} from 'react-icons/io';
import {MdAssessment} from 'react-icons/md';
import {TbReport} from 'react-icons/tb';
import {RiFileReduceFill} from 'react-icons/ri';


const TopBar = ({handleTabChange, isPrivileged, routePage, isVisible}) => {
  const { t, i18n } = useTranslation();

  const isGeneral = routePage == 'General';
  const isAdd = routePage == 'Add';
  const isEdit = routePage == 'Edit';
  const isDeleted = routePage == 'Deleted';
  const isView = routePage == 'View';
  const isLog = routePage == 'Log';
  const isAttendance = routePage == 'Attendance';
  const isVacation = routePage == 'Vacation';
  const isEvaluation = routePage == 'Evaluation';
  const isReport = routePage == 'Report';
  const isSalaryDeduction = routePage == 'SalaryDeduction';
  

  return <div>
    <nav className={styles.TopBar + " bg-white list-group list-group-horizontal"} data-testid="TopBar">
      {isPrivileged['view_all'] && <button
          className={`list-group-item py-2 ripple ${isGeneral?"list-group-item-dark":''} TopBarButton `}
          onClick={() => handleTabChange('General')}>
        <FaRegObjectGroup />&nbsp; <span>{t('All')}</span>
      </button>}
      {isPrivileged['add'] && isVisible['Add'] && <button
          className={`list-group-item py-2 ripple ${isAdd?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('Add')}>
        <BiAddToQueue />&nbsp; <span>{t('Add')}</span>
      </button>}
      {isPrivileged['edit'] && isVisible['Edit'] && <button
          className={`list-group-item py-2 ripple ${isEdit?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('Edit')}>
        <AiFillEdit />&nbsp; <span>{t('Edit')}</span>
      </button>}
      {isPrivileged['view_deleted'] && <button
          className={`list-group-item py-2 ripple ${isDeleted?"list-group-item-dark":''} TopBarButton `}
          onClick={() => handleTabChange('Deleted')}>
        <AiFillDelete />&nbsp; <span>{t('Deleted')}</span>
      </button>}
      {isPrivileged['view_item'] && isVisible['View'] && <button
          className={`list-group-item py-2 ripple ${isView?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('View')}>
        <GrFormView />&nbsp; <span>{t('View')}</span>
      </button>}
      {(isPrivileged['view_attendance']) 
        && <button className={`list-group-item py-2 ripple ${((isAttendance || isVacation || isEvaluation || isReport || isSalaryDeduction))?"list-group-item-dark":''} TopBarButton `}
        onClick={() => handleTabChange('Attendance')}>
        <AiFillDelete />&nbsp; <span>{t('Employee Management')}</span>
      </button>}
      {isPrivileged['view_log'] && <button
          className={`list-group-item py-2 ripple ${isLog?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('Log')}>
        <IoLogoBuffer />&nbsp; <span>{t('Log')}</span>
      </button>}
  </nav>
  {(isPrivileged['view_attendance']) && (isVisible['Attendance'] || isVacation || isEvaluation || isReport || isSalaryDeduction) && <nav className={styles.TopBar + " bg-white list-group list-group-horizontal"} data-testid="TopBar">
    {isPrivileged['view_attendance'] && <button
        className={`list-group-item py-2 ripple ${(isAttendance)?"list-group-item-dark":''} TopBarButton`}
        onClick={() => handleTabChange('Attendance')}>
      <IoLogoBuffer />&nbsp; <span>{t('Attendance & Leave')}</span>
    </button>}

    {isPrivileged['view_vacation'] && <button
        className={`list-group-item py-2 ripple ${(isVacation)?"list-group-item-dark":''} TopBarButton`}
        onClick={() => handleTabChange('Vacation')}>
      <IoLogoBuffer />&nbsp; <span>{t('Vacations')}</span>
    </button>}

    {isPrivileged['view_evaluation'] && <button
        className={`list-group-item py-2 ripple ${(isEvaluation)?"list-group-item-dark":''} TopBarButton`}
        onClick={() => handleTabChange('Evaluation')}>
      <MdAssessment />&nbsp; <span>{t('Evaluations')}</span>
    </button>}

    {isPrivileged['view_report'] && <button
        className={`list-group-item py-2 ripple ${(isReport)?"list-group-item-dark":''} TopBarButton`}
        onClick={() => handleTabChange('Report')}>
      <TbReport />&nbsp; <span>{t('Reports')}</span>
    </button>}

    {isPrivileged['view_salary_deduction'] && <button
        className={`list-group-item py-2 ripple ${(isSalaryDeduction)?"list-group-item-dark":''} TopBarButton`}
        onClick={() => handleTabChange('SalaryDeduction')}>
      <RiFileReduceFill />&nbsp; <span>{t('Salary Deductions')}</span>
    </button>}
  </nav>}
  </div>

};

TopBar.propTypes = {};

TopBar.defaultProps = {};

export default TopBar;
