import React, {useEffect, useState, useRef, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';
import styles from './Subscription.module.css';
import DashboardLayout from '../Dashboard/DashboardLayout/DashboardLayout';
import TopBar from './TopBar/TopBar';
import { useParams, useNavigate } from 'react-router-dom';
import All from './All/All';
import AddEdit from './AddEdit/AddEdit';
import GeneralFetchingService from '../../Services/GeneralFetchingService';

import { useTranslation } from 'react-i18next';

import $ from 'jquery';
import RenderErrorMessage from '../Common/RenderErrorMessage/RenderErrorMessage';
import RenderSuccessMessage from '../Common/RenderSuccessMessage/RenderSuccessMessage';
import { useAuth } from '../Common/Auth/AuthHelpers/Auth';

import Privileges from '../Common/Privileges/Privileges';
import View from './View/View';
import NoPrivilegesMessage from '../Common/NoPrivilegesMessage/NoPrivilegesMessage';
import Deleted from './Deleted/Deleted';
import Logs from './Logs/Logs';
import Subscribe from './Subscribe/Subscribe';
import Features from './Features/Features';
import Requests from './Requests/Requests';
import CompaniesSubscriptions from './CompaniesSubscriptions/CompaniesSubscriptions';
import RequestsPublic from './RequestsPublic/RequestsPublic';
import ViewPublicRequest from './ViewPublicRequest/ViewPublicRequest';

const SubscriptionComponents = ({page, locale, setLocale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name
  const navigate = useNavigate();

  const isGeneral = page=='General';
  const isView = page=='View';
  const isAdd = page=='Add';
  const isEdit = page=='Edit';
  const isDeletd= page=='Deleted';
  const isLog= page=='Log';
  const isSubscribe= page=='Subscribe';
  const isManageFeatures= page=='ManageFeatures';
  const isManageRequests= page=='ManageRequests';
  const isManagePublicRequests= page=='ManagePublicRequests';
  const isViewPublicRequest= page=='ViewPublicRequest';
  const isCompaniesSubscriptions= page=='CompaniesSubscriptions';
  
  const params = useParams();
  const [routePage, setRoutePage] = useState(page)

  const [dataItems, setDataItems] = useState([])
  

  const [isPrivileges, setIsPrivileges] = useState(false)
  const [privilegesSideBar, setPrivilegesSideBar] = useState([])
  const [isPrivileged, setIsPrivileged] = useState({})
  const [isTopBarButtonsVisible, setIsTopBarButtonsVisible] = useState({'View':false, 'Add':true, 'Edit':false, 'ManageFeatures':isManageFeatures})
  const [isFetchingServiceLoaded, setIsFetchingServiceLoaded] = useState(false)
  

  const [generalErrorMessage, setGeneralErrorMessage] = useState(null);
  const [generalSuccessMessage, setGeneralSuccessMessage] = useState(null);
  const [isShownGeneralError, setIsShownGeneralError] = useState(false);
  const [isShownGeneralSuccess, setIsShownGeneralSuccess] = useState(false);
  
  const generalRef = useRef();
  const allRef = useRef();
  const featuresRef = useRef();
  const requestsRef = useRef();
  const requestsPublicRef = useRef();
  const companiesSubscriptionsRef= useRef();
  const generalFetchingServiceRef = useRef();
  const privilegesRef= useRef();
  const deletedRef= useRef();
  const logRef= useRef();
  
  useImperativeHandle(generalRef,()=>({
    fetchingServiceLoaded(){
      setIsFetchingServiceLoaded(true)
    }
  }))

  const showMessage = (isSuccess, message) => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
    if(isSuccess){
      setGeneralSuccessMessage(message)
      setIsShownGeneralSuccess(true)
    }else{
      setGeneralErrorMessage(message)
      setIsShownGeneralError(true)
    }
    window.scrollTo(0, 0)
  }

  const hideMessage = () => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
  }

  const deleteApply = (ids, paginationRef, customApi) => {
    generalFetchingServiceRef?.current?.delete(ids, customApi)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          paginationRef?.current?.loadPages()
          showMessage(true, responseData?.message)
        }else{
          showMessage(false, responseData?.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }
  const deleteHandle=(ids, paginationRef, customApi=null)=>{
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t('Delete Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html(t('Do you want to delete the items?'))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      deleteApply(ids, paginationRef, customApi)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }

  const restoreDeletedApply = (ids, paginationRef) => {
    generalFetchingServiceRef?.current?.restoreDeleted(ids)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          paginationRef?.current?.loadPages()
          showMessage(true, responseData?.message)
        }else{
          showMessage(false, responseData?.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }
  const restoreDeletedHandle=(ids, paginationRef)=>{
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t('Restore Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html(t('Do you want to restore deleted items?'))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      restoreDeletedApply(ids, paginationRef)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }

  const editBtnHandle = (id) => {
    handleTabChange('Edit', id)
  }

  const viewBtnHandle = (id) => {
    handleTabChange('View', id)
  }

  const viewBtnPublicRequestHandle = (id) => {
    handleTabChange('ViewPublicRequest', id)
  }

  const manageFeatures = (id) => {
    handleTabChange('ManageFeatures', id)
  }

  const handleAddEdit=(item, setFieldsErrors, resetData)=>{
    if(!item?.id){
      generalFetchingServiceRef?.current?.add(item)
        .then(response => {
          if(response?.response){
            response=response?.response
          }
          const responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setDataItems([...dataItems, data])
            setFieldsErrors([])
            showMessage(true, responseData?.message)
            resetData()
          }else{
            showMessage(false, responseData?.message)
          }
        }).catch(error => {
          let response=error?.response
          if(response.status==422){
            setFieldsErrors(response?.data?.errors)
          }
        })
    }else{
      generalFetchingServiceRef?.current?.edit(item)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setDataItems(dataItems.map(x => {
              if(x.id==data.id){
                return {...data}
              }
            }))
            showMessage(true, responseData?.message)
          }else{
            showMessage(false, responseData?.message)
          }
        }).catch(error => {
          console.log(error)
          let response=error.response
          if(response.status==422){
            setFieldsErrors(response.data.errors)
          }
        })
    }
    
  }

  const handleTabChange=(pageR, id=null)=>{
    if(pageR){
      switch(pageR){
        case 'General':
          navigate(`/${prefix}/dashboard/subscription`)
          setIsTopBarButtonsVisible({'View':false, 'Add':true, 'Edit': false, 'ManageFeatures':false})
          break;
        case 'Add':
          navigate(`/${prefix}/dashboard/subscription/add`)
          setIsTopBarButtonsVisible({'View':false, 'Add':true, 'Edit': false, 'ManageFeatures':false})
          break;
        case 'Deleted':
          navigate(`/${prefix}/dashboard/subscription/deleted`)
          setIsTopBarButtonsVisible({'View':false, 'Add':true, 'Edit': false, 'ManageFeatures':false})
          break;
        case 'View':
          if(id){
            navigate(`/${prefix}/dashboard/subscription/view/${id}`)
          }
          setIsTopBarButtonsVisible({'View':true, 'Add':true, 'Edit': false, 'ManageFeatures':false})
          break;
        case 'ViewPublicRequest':
          if(id){
            navigate(`/${prefix}/dashboard/subscription/requests/public/view/${id}`)
          }
          setIsTopBarButtonsVisible({'View':true, 'Add':true, 'Edit': false, 'ManageFeatures':false})
          break;
        case 'Edit':
          if(id){
            navigate(`/${prefix}/dashboard/subscription/edit/${id}`)
          }
          setIsTopBarButtonsVisible({'View':false, 'Add':false, 'Edit': true, 'ManageFeatures':false})
          break;
        case 'Subscribe':
          navigate(`/${prefix}/dashboard/subscription/subscribe`)
          setIsTopBarButtonsVisible({'View':false, 'Add':true, 'Edit': false, 'ManageFeatures':false})
          break;
        case 'ManageFeatures':
          navigate(`/${prefix}/dashboard/subscription/${id}/features`)
          setIsTopBarButtonsVisible({'View':false, 'Add':true, 'Edit': false, 'ManageFeatures':true})
          break;
        case 'ManageRequests':
          navigate(`/${prefix}/dashboard/subscription/requests`)
          setIsTopBarButtonsVisible({'View':false, 'Add':true, 'Edit': false, 'ManageFeatures':false})
          break;
        case 'ManagePublicRequests':
          navigate(`/${prefix}/dashboard/subscription/requests/public`)
          setIsTopBarButtonsVisible({'View':false, 'Add':true, 'Edit': false, 'ManageFeatures':false})
          break;
        case 'CompaniesSubscriptions':
            navigate(`/${prefix}/dashboard/subscription/companies_subscriptions`)
            setIsTopBarButtonsVisible({'View':false, 'Add':true, 'Edit': false, 'ManageFeatures':false})
            break;
        case 'Log':
            navigate(`/${prefix}/dashboard/subscription/log`)
            setIsTopBarButtonsVisible({'View':false, 'Add':true, 'Edit': false, 'ManageFeatures':false})
            break;
      }
      setRoutePage(pageR)
    }
    hideMessage()
  }
  
  useEffect(()  => {
    if(!isFetchingServiceLoaded){
      return;
    }
    const sysEnt='subscription'
    privilegesRef?.current?.getPrivileges(sysEnt).then(privilegesO=>{
      if(privilegesO && privilegesO?.privileges?.length>0){
        let mapPriv = {};
        privilegesO?.privileges?.forEach(x => {
          const key=x?.replace(`${sysEnt}_`, '')
          mapPriv={...mapPriv, [key]:true}
        });
        setIsPrivileged(mapPriv)
        setIsPrivileges(true)
      }
      if(privilegesO && privilegesO?.sideBarPrivileges?.length>0){
        setPrivilegesSideBar(privilegesO?.sideBarPrivileges)
      }
    })
  }, [isFetchingServiceLoaded])

  useEffect(() => {
    loadPages()
  },[isPrivileged])

  useEffect(() => {
    loadPages()
  },[routePage])

  const loadPages = () => {
    if(isPrivileged && Object.keys(isPrivileged).length>0){
      if(isGeneral){
        allRef?.current?.loadPages()
      }
      if(isDeletd){
        deletedRef?.current?.loadPages()
      }
      if(isLog){
        logRef?.current?.loadPages()
      }
      if(isManageFeatures){
        featuresRef?.current?.loadPages()
      }
      if(isManageRequests){
        requestsRef?.current?.loadPages()
      }
      if(isManagePublicRequests){
        requestsPublicRef?.current?.loadPages()
      }
      if(isCompaniesSubscriptions){
        companiesSubscriptionsRef?.current?.loadPages()
      }
    }
  }
  

  return <DashboardLayout component='Subscription' privilegesSideBar={privilegesSideBar} locale={locale} setLocale={setLocale}>
    <GeneralFetchingService generalFetchingServiceRef={generalFetchingServiceRef} prefix='subscription' generalRef={generalRef} />
    <Privileges generalFetchingServiceRef={generalFetchingServiceRef} privilegesRef={privilegesRef}></Privileges>
    {isPrivileges && <div className={styles.Subscription} data-testid="Subscription">
      <h1>{t('Subscriptions')}</h1>
      <TopBar routePage={routePage} isPrivileged={isPrivileged} handleTabChange={handleTabChange} setRoutePage={setRoutePage} isVisible={isTopBarButtonsVisible} />
      <RenderErrorMessage name='general' messages={generalErrorMessage!=null?{'general':generalErrorMessage}:{}} alertDisplay={true} isShown={isShownGeneralError} setIsShownGeneralError={setIsShownGeneralError} isDissmiss={true} handleHideMessage={hideMessage} />
      <RenderSuccessMessage name='general' messages={generalSuccessMessage!=null?{'general':generalSuccessMessage}:{}} alertDisplay={true} isShown={isShownGeneralSuccess} setIsShownGeneralSuccess={setIsShownGeneralSuccess} isDissmiss={true} handleHideMessage={hideMessage} />
      {(isGeneral && isPrivileged['view_all']) && <All page={page} data={dataItems} viewBtnHandle={viewBtnHandle} editBtnHandle={editBtnHandle} deleteHandle={deleteHandle} manageFeatures={manageFeatures} setDataItems={setDataItems} generalFetchingServiceRef={generalFetchingServiceRef} allRef={allRef} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {((isAdd && isPrivileged['add']) || (isEdit && isPrivileged['edit'])) && <AddEdit id={params.id} handleAddEdit={handleAddEdit} generalFetchingServiceRef={generalFetchingServiceRef} locale={locale} />}
      {(isView && isPrivileged['view_item']) && <View id={params.id} generalFetchingServiceRef={generalFetchingServiceRef} handleTabChange={handleTabChange} isPrivileged={isPrivileged} locale={locale} />}
      {(isDeletd && isPrivileged['view_deleted']) && <Deleted page={page} allRef={allRef} deletedRef={deletedRef} viewBtnHandle={viewBtnHandle} restoreDeletedHandle={restoreDeletedHandle} generalFetchingServiceRef={generalFetchingServiceRef} isPrivileged={isPrivileged} locale={locale} />}
      {(isLog && isPrivileged['view_log']) && <Logs generalFetchingServiceRef={generalFetchingServiceRef} logRef={logRef} isPrivileged={isPrivileged} locale={locale} />}
      {(isSubscribe && isPrivileged['view_subscribe']) && <Subscribe generalFetchingServiceRef={generalFetchingServiceRef} handleTabChange={handleTabChange} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {(isManageFeatures && isPrivileged['manage'] && isPrivileged['manage_features']) && <Features id={params.id} page={page} viewBtnHandle={viewBtnHandle} editBtnHandle={editBtnHandle} deleteHandle={deleteHandle} manageFeatures={manageFeatures} setDataItems={setDataItems} generalFetchingServiceRef={generalFetchingServiceRef} featuresRef={featuresRef} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {(isManageRequests && isPrivileged['manage'] && isPrivileged['manage_requests']) && <Requests page={page} viewBtnHandle={viewBtnHandle} editBtnHandle={editBtnHandle} deleteHandle={deleteHandle} manageFeatures={manageFeatures} setDataItems={setDataItems} generalFetchingServiceRef={generalFetchingServiceRef} requestsRef={requestsRef} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {(isManagePublicRequests && isPrivileged['manage'] && isPrivileged['manage_public_requests']) && <RequestsPublic page={page} viewBtnHandle={viewBtnPublicRequestHandle} editBtnHandle={editBtnHandle} deleteHandle={deleteHandle} manageFeatures={manageFeatures} setDataItems={setDataItems} generalFetchingServiceRef={generalFetchingServiceRef} requestsPublicRef={requestsPublicRef} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {(isViewPublicRequest && isPrivileged['view_item']) && <ViewPublicRequest id={params.id} generalFetchingServiceRef={generalFetchingServiceRef} handleTabChange={handleTabChange} isPrivileged={isPrivileged} locale={locale} />}
      {(isCompaniesSubscriptions && isPrivileged['manage'] && isPrivileged['manage_companies_subscriptions']) && <CompaniesSubscriptions page={page} viewBtnHandle={viewBtnHandle} editBtnHandle={editBtnHandle} deleteHandle={deleteHandle} setDataItems={setDataItems} generalFetchingServiceRef={generalFetchingServiceRef} companiesSubscriptionsRef={companiesSubscriptionsRef} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      
    </div>}
    {(!isPrivileges || 
      (isGeneral && !isPrivileged['view_all']) ||
      (isAdd && !isPrivileged['add']) ||
      (isEdit && !isPrivileged['edit']) ||
      (isView && !isPrivileged['view_item']) ||
      (isManageFeatures && !isPrivileged['manage'] && isPrivileged['manage_features']) ||
      (isManageRequests && !isPrivileged['manage'] && isPrivileged['manage_requests']) ||
      (isManagePublicRequests && !isPrivileged['manage'] && isPrivileged['manage_public_requests']) ||
      (isViewPublicRequest && !isPrivileged['manage'] && isPrivileged['manage_public_requests']) ||
      (isCompaniesSubscriptions && !isPrivileged['manage'] && isPrivileged['manage_companies_subscriptions']) ||
      (isSubscribe && !isPrivileged['view_subscribe']) ||
      (isLog && !isPrivileged['view_log'])
    )&& <NoPrivilegesMessage />}
  </DashboardLayout>
};

SubscriptionComponents.propTypes = {};

SubscriptionComponents.defaultProps = {};

export default SubscriptionComponents;
