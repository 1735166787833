import React, {useEffect, useState, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './Features.module.css';

import {AiFillEdit, AiFillDelete} from 'react-icons/ai';
import {MdSelectAll, MdTabUnselected} from 'react-icons/md';
import {GrFormView} from 'react-icons/gr';
import {BsCardChecklist} from 'react-icons/bs';

import Pagination from '../../Common/Pagination/Pagination';
import $ from 'jquery';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import ToolsBar from './ToolsBar/ToolsBar';
import { useTranslation } from 'react-i18next';


import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import UpdateAdd from './UpdateAdd/UpdateAdd';

const Features = ({id, page, editBtnHandle, deleteHandle, generalFetchingServiceRef, featuresRef, isPrivileged, locale, showMessage}) => {
  const { t, i18n } = useTranslation();

  const toolsBarRef = useRef();
  const paginationRef= useRef();
  const tableRef= useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [data, setDataItems] = useState([])
  
  const [selecetedItems, setSelectedItems] = useState([])
  const [isSelectAllBtn, setIsSelectAllBtn] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [isAddItem, setIsAddItem] = useState(false)
  const [isAddingUpdatingValues, setIsAddingUpdatingValues] = useState(false)
  const [updateOneItem, setUpdateOneItem] = useState(null)
  const [dataLength, setDataLength] = useState(0)
  

  let isDataLoaded=false
  const selectedDataAction = () => {
    if(selecetedItems.length>0){
      toolsBarRef?.current?.setIsSelectItems(true)
    }else{
      toolsBarRef?.current?.setIsSelectItems(false)
    }

    if(selecetedItems?.length==data?.length){
      setIsSelectAllBtn(false)
      $("#selectAllBtn").removeClass('btn-primary')
      $("#selectAllBtn").addClass('btn-warning')
    }else{
      setIsSelectAllBtn(true)
      $("#selectAllBtn").removeClass('btn-warning')
      $("#selectAllBtn").addClass('btn-primary')
    }
  }
  useEffect(()=>{
    selectedDataAction()
    isDataLoaded=true
  },[selecetedItems, data])
  useEffect(()=>{
    if(isDataLoaded){
      selectedDataAction()
    }
  },[selecetedItems])

  const handleOnBeforePrint = () => {
    setIsPrinting(true)
    $('.hidableColumn').removeClass(styles.hidableColumn)
    return Promise.resolve();
  }
  const handleOnAfterPrint = () => {
    setIsPrinting(false)
    $('.hidableColumn').addClass(styles.hidableColumn)
  }

  const setSelectedItem = (selectedId, value) => {
    if(value){
      if(selecetedItems.indexOf(selectedId)==-1){
        setSelectedItems([...selecetedItems, selectedId])
      }
    }else{
      if(selecetedItems.indexOf(selectedId)!=-1){
        setSelectedItems(selecetedItems.filter((x)=>x!=selectedId))
      }
    }
  }
  const handleOnChangeSelect= (e) => {
    let selectedId=parseInt(e.target.getAttribute('tag'))
    let value=e.target.checked
    setSelectedItem(selectedId, value)
  }

  useImperativeHandle(featuresRef,()=>({
    setDataTotalLength(value){
      setDataLength(value)
    },
    deleteSelectedItems(){
      deleteHandle(selecetedItems, paginationRef, 'feature')
    },
    clearSelectedItems(){
      setSelectedItems([])
      $('.'+styles.Features).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
    },
    loadPages(){
      toolsBarRef?.current?.loadPages()
    },
    showUpdateAddAttendanceModal(isAdd, id=null){
      setIsAddItem(isAdd)
      if(id){
        setUpdateOneItem(id)
      }
      setIsAddingUpdatingValues(true)
    }
  }))

  const handleSelectAllClick = () => {
    if(selecetedItems?.length==data?.length){
      $('.'+styles.Features).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
      setSelectedItems([])
    }else{
      $('.'+styles.Features).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', true)
      setSelectedItems([...data?.map(x=>x.id)])
    }
  }

  const handleWhenUpdatingHidden = () =>{
    setIsAddingUpdatingValues(false)
    setUpdateOneItem(null)
  }

  const handleOnUpdateAddConfirm = (data, isEmpty, isAdd, callback) => {
    if(isEmpty){
      showMessage(false, t('Nothing to update!'))
    }else{
      data['subscription_id']=id
      if(isAdd){
        generalFetchingServiceRef?.current?.add(data, 'feature')
          .then(response => {
            let responseData=response?.data
            if(responseData && responseData?.status==1){
              showMessage(true, responseData?.message)
              setUpdateOneItem(null)
              callback(true)
              setIsAddingUpdatingValues(false)
            }else{
              callback(false, null,responseData?.message)
            }
          }).catch(error => {
            callback(false, error)
            console.log(error)
            setUpdateOneItem(null)
          })
      }else{
        generalFetchingServiceRef?.current?.update(data, [updateOneItem], 'feature')
          .then(response => {
            let responseData=response?.data
            if(responseData && responseData?.status==1){
              showMessage(true, responseData?.message)
            }else{
              showMessage(false, responseData?.message)
            }
            setUpdateOneItem(null)
            callback(true)
            setIsAddingUpdatingValues(false)
          }).catch(error => {
            callback(false, error)
            console.log(error)
            setUpdateOneItem(null)
          })
      }
      
      paginationRef?.current?.loadPages()
      featuresRef?.current?.clearSelectedItems()
    }
  }

  const dataService = (links, isAll, filtersO) => generalFetchingServiceRef?.current?.getAll(links, isAll, filtersO, `${id}/feature/all`)
  const filtersDataService = () => generalFetchingServiceRef?.current?.getFiltersData()
  const excelService = (filters, specificItems) => generalFetchingServiceRef?.current?.ExportExcel(filters, 'Features', specificItems,`${id}/feature`)


  return <div className={styles.Features + ""} data-testid="Features">
    <ToolsBar toolsBarRef={toolsBarRef} featuresRef={featuresRef} paginationRef={paginationRef} isPrivileged={isPrivileged}
     ViewItemsButtons={{'Delete': true}} identifier='SubscriptionFeatures' filtersDataService={filtersDataService} selecetedItems={selecetedItems}
     printComponentRef={tableRef} printDocumentTitle='Subscriptions' handleOnAfterPrint={handleOnAfterPrint} handleOnBeforePrint={handleOnBeforePrint}
     locale={locale} page={page} dataLength={dataLength}
     />

    <UpdateAdd locale={locale} isPrivileged={isPrivileged} isAdd={isAddItem} show={isAddingUpdatingValues} generalFetchingServiceRef={generalFetchingServiceRef} updateOneItem={updateOneItem} handleWhenHidden={handleWhenUpdatingHidden} handleOnConfirm={handleOnUpdateAddConfirm} />
    
    {data?.length > 0 &&
    <table id="tableToPrint" ref={tableRef} cellPadding="0" cellSpacing="0" border="0" className={"datatable table table-striped table-bordered table-hover"}>
      <thead>
        <tr>
          {!isPrinting && <th className='hideOnPrint'>
            <button id="selectAllBtn" className='btn btn-primary' onClick={handleSelectAllClick}>
              {isSelectAllBtn && <MdSelectAll id="SelectAll" />}
              {!isSelectAllBtn && <MdTabUnselected id="UnSelectAll" />}
            </button>
          </th>}
          <th>{t('Id')}</th>
          <th>{t('Name')}</th>
          <th>{t('Price')}</th>
          <th className={styles.hidableColumn + ' hidableColumn'}>{t('Details')}</th>
          {!isPrinting && <th>{t('Actions')}</th>}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, id) => {
          return <tr key={id}>
            {!isPrinting && <td className='hideOnPrint'><input type={'checkbox'} tag={item?.id} name={styles.CheckBoxDataTable} className={styles.CheckBoxDataTable} onChange={handleOnChangeSelect} /></td>}
            <td>{item?.id}</td>
            <td>{item?.name}</td>
            <td>{item?.price}</td>
            <td className={styles.hidableColumn + ' hidableColumn'}>{item?.details}</td>
            {!isPrinting && <td>
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropDownActionsBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {t('Do an action')}
              </button>
              <div className={`dropdown-menu ${locale=='ar'?'text-right':'text-left'}`} aria-labelledby="dropDownActionsBtn">
                {isPrivileged['edit'] && <button className="dropdown-item btn btn-primary" onClick={()=>featuresRef?.current?.showUpdateAddAttendanceModal(false, item.id)}><AiFillEdit /> {t('Edit')}</button>}
                {isPrivileged['delete'] && <button className="dropdown-item btn btn-danger" onClick={()=>deleteHandle([item.id], paginationRef, 'feature')}><AiFillDelete /> {t('Delete')}</button>}
              </div>
            </div>
            </td>}
          </tr>
        })}
      </tbody>
    </table>
    }

    <br/>{data?.length == 0 && t("No items to display")}

    <Pagination toolsBarRef={toolsBarRef} service={dataService} excelService={excelService} data={data} setData={setDataItems} paginationRef={paginationRef} locale={locale} />
  </div>
};

Features.propTypes = {};

Features.defaultProps = {};

export default Features;
