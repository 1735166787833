import React from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsItemsBar.module.css';

import { useTranslation } from 'react-i18next';

import {AiFillDelete, AiFillPrinter, AiOutlineExport} from 'react-icons/ai';
import {FaTrashRestoreAlt} from 'react-icons/fa';
import {SiMicrosoftexcel} from 'react-icons/si';
import {GrDocumentUpdate} from 'react-icons/gr';
import ReactToPrint from 'react-to-print';

const ToolsItemsBar = ({dataLength, isPrivileged, isDeleted, cartRef, storageRef, itemRef, allRef, deletedRef, toolsBarRef, 
  printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, 
  ViewItemsButtons, isSelectItems}) => {
  const { t, i18n } = useTranslation();

  return <span className={styles.ToolsItemsBar} data-testid="ToolsItemsBar">
    <span className={styles.ToolsItemsBarButtons}>

    {isPrivileged['download_excel'] && !isDeleted &&  
      <button className={styles.ToolsBarButton+' btn btn-success'} onClick={()=>toolsBarRef?.current?.downloadExcel()} title={t('Export to excel')}><SiMicrosoftexcel /></button>
    }

    {isPrivileged['print_all_table'] && 
      <ReactToPrint
        trigger={() => <button className={styles.ToolsBarButton+' btn btn-info'} title={t('Print')}><AiFillPrinter /></button>}
        content={() => printComponentRef?.current}
        onBeforeGetContent={handleOnBeforePrint}
        onAfterPrint={handleOnAfterPrint}
        documentTitle={printDocumentTitle}
      />
    }

    {isSelectItems && <span className={styles.ToolsItemsBar}>
        {isPrivileged['delete'] && ViewItemsButtons['Delete'] && 
          <button className={styles.ToolsBarButton+' btn btn-danger'} onClick={()=>{
            if(storageRef){
              storageRef?.current?.deleteSelectedItems()
            }
            if(cartRef){
              cartRef?.current?.deleteSelectedItems()
            }
            if(itemRef){
              itemRef?.current?.deleteSelectedItems()
            }
          }}><AiFillDelete /></button>
        }

        {isPrivileged['restore_deleted'] && ViewItemsButtons['RestoreDeleted'] && 
          <button className={styles.ToolsBarButton+' btn btn-danger'} onClick={()=>deletedRef?.current?.restoreSelectedItems()}><FaTrashRestoreAlt /></button>
        }

        {/* {(isPrivileged['update_status'] || isPrivileged['update_shipping_date'] || isPrivileged['update_related_people']) && !isDeleted
          && <button className={styles.ToolsBarButton+' btn btn-warning'} onClick={()=>toolsBarRef?.current?.showUpdateModal()} title={t('Update Info')}><GrDocumentUpdate /></button>
        } */}
      </span>}
    </span>
    <span className={`card ${styles.ResultsCard}`}>{`${t('Results Number')}: ${dataLength}`}</span>
    
  </span>
};

ToolsItemsBar.propTypes = {};

ToolsItemsBar.defaultProps = {};

export default ToolsItemsBar;
