import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './AddEdit.module.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';

import { useTranslation } from 'react-i18next';

import $ from 'jquery'
import GeneralHelpers from '../../../Services/GeneralHelpers';


const AddEdit = ({id, handleAddEdit, generalFetchingServiceRef, locale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [fieldsErrors, setFieldsErrors] = useState([]);
  

  const [name, setName] = useState("")

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let data={
      name,
    };
    return data;
  }
  const handleAddBtnClick=()=>{
    let item=getObject()
    handleAddEdit(item, setFieldsErrors, resetData)
    window.scrollTo(0, 0);
  }
  
  const handleEditBtnClick=()=>{
    let item=getObject()
    item.id=id
    handleAddEdit(item, setFieldsErrors)
    window.scrollTo(0, 0);
  }

  const resetData = () => {
    setName('')
  }


  const handleChangeName=(e)=>{
    setName(e.target.value)
  }

  
  const setAllData=(data)=>{
    let item=data
    setName(item?.name)
  }

  const getById = (id) => {
    generalFetchingServiceRef?.current?.getById(id)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setAllData(data)
          }
        }).catch(error => {
          console.log(error)
        })
  }
  useEffect(()=>{
    // getAllData()
    if(id){
      getById(id)
    }
  },[])

  return <div className={styles.AddEdit + ` ${locale=='ar'?'text-right':'text-left'}`} data-testid="AddEdit">
    <div className="form-group">
      <label htmlFor="name">{t('Role Name')}</label>
      <RenderErrorMessage name='name' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('Role Name')}`} id="name" onChange={handleChangeName} value={name} />
    </div>

    <div className="form-group text-center">
      {!id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleAddBtnClick}>{t('Add')}</button>}
      {id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleEditBtnClick}>{t('Edit')}</button>}
    </div>

  </div>
};

AddEdit.propTypes = {};

AddEdit.defaultProps = {};

export default AddEdit;
