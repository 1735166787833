import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Messages.module.css';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import { useTranslation } from 'react-i18next';

import {FaUserAlt} from 'react-icons/fa';
import {AiFillDelete} from 'react-icons/ai';
import { Link } from 'react-router-dom';

import $ from 'jquery';
import { useAuth } from '../../../Common/Auth/AuthHelpers/Auth';

const Messages = ({id, serviceGet, serviceAdd, serviceDelete, isPrivileged, locale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [messages, setMessages] = useState([])
  const [messageToAdd, setMessageToAdd] = useState('')
  const [is_closed, setIsClosed] = useState(false)
  
  const GetMessages = () => {
    serviceGet(id, 'messages')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          const messagesO=responseData?.data?.messages
          const isClosedO=responseData?.data?.chat?.is_closed
          setMessages(messagesO)
          setIsClosed(isClosedO)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const addMessage = () => {
    serviceAdd(id, messageToAdd, 'messages')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          GetMessages()
          setMessageToAdd('')
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const handleOnDeleteMessage = (messageId) => {
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t('Delete Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html(t('Do you want to delete the items?'))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      serviceDelete(id, messageId, 'messages')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          GetMessages()
        }
      }).catch(error => {
        console.log(error)
      })

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }

  const handleMessageAddKeyDown = (e) => {
    const keyCode=e.keyCode
    if(keyCode==13){
      addMessage()
    }
  }
  const handleMessageAddChange = (e) => {
    setMessageToAdd(e?.target?.value)
  }

  useEffect(() => {
    GetMessages()
  }, [])

  return <div className={styles.Messages}>
    <div className="row d-flex justify-content-center">
      <div className="col-8">
        <div className="card shadow-0 border" style={{backgroundColor: '#f0f2f5'}}>
          <div className="card-body p-4">

            {!is_closed && <div className="form-outline mb-4">
              <textarea type="text" id="addAMessage" value={messageToAdd} className="form-control" placeholder={`${t('Add a message')}...`} onKeyDown={handleMessageAddKeyDown} onChange={handleMessageAddChange} />
            </div>}

            {(messages && messages?.length>0) && messages.map((message, id) => {
              return <div className="card mb-4" key={id}>
                  <div className="card-body">
                    <div className="d-flex flex-row align-items-center">
                      <p className="small mb-0 ms-2">{moment(message?.created_at).local().locale(locale).format('DD/MM/yyyy h:mm:ss a')}</p>
                    </div>

                    <p>{message?.value}</p>

                    <div className="d-flex justify-content-between" dir={(message?.user_id==auth?.user?.user_info?.id)?'rtl':'ltr'}>
                      <Link className="d-flex flex-row align-items-center card p-2" to={`/${prefix}/dashboard/user/view/${id}`}>
                        <p className="small mb-0 ms-2">{message?.user?.first_name + ' ' + message?.user?.last_name}</p>
                        &nbsp;
                        <FaUserAlt />
                      </Link>
                      
                    {((message?.user_id==auth?.user?.user_info?.id) && !is_closed) && <button className='btn btn-danger' onClick={() => handleOnDeleteMessage(message?.id)}><AiFillDelete /></button>}

                    </div>
                  </div>
                </div>;
            })}
          </div>
        </div>
      </div>
    </div>
  </div>
};

Messages.propTypes = {};

Messages.defaultProps = {};

export default Messages;
