import React from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsItemsBar.module.css';

import { useTranslation } from 'react-i18next';

import {AiFillDelete, AiFillPrinter, AiOutlineFileAdd} from 'react-icons/ai';
import {BsFillCalculatorFill} from 'react-icons/bs';
import {FaTrashRestoreAlt} from 'react-icons/fa';
import {FcDocument} from 'react-icons/fc';
import {SiMicrosoftexcel} from 'react-icons/si';
import {GiPayMoney} from 'react-icons/gi';
import ReactToPrint from 'react-to-print';

const ToolsItemsBar = ({dataLength, isPrivileged, isDeleted, allRef, deletedRef, toolsBarRef, vendorDebtsRef,
  printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, 
  ViewItemsButtons, isSelectItems, page, isCashing}) => {
  const { t, i18n } = useTranslation();
  
  const isAccounting = page=='Accounting';
  const isVendorsDebts= page=='VendorsDebts';

  return <span className={styles.ToolsItemsBar} data-testid="ToolsItemsBar">
    <span className={styles.ToolsItemsBarButtons}>
    {isPrivileged['download_excel'] && !isDeleted &&  
      <button className={styles.ToolsBarButton+' btn btn-success'} onClick={()=>toolsBarRef?.current?.downloadExcel()} title={t('Export to excel')}><SiMicrosoftexcel /></button>
    }

    {isPrivileged['print_all_table'] && 
      <ReactToPrint
        trigger={() => <button className={styles.ToolsBarButton+' btn btn-info'} title={t('Print')}><AiFillPrinter /></button>}
        content={() => printComponentRef?.current}
        onBeforeGetContent={handleOnBeforePrint}
        onAfterPrint={handleOnAfterPrint}
        documentTitle={printDocumentTitle}
      />
    }

    {isSelectItems && <span className={styles.ToolsItemsBar}>
      {isPrivileged['delete'] && ViewItemsButtons['Delete'] && 
        <button className={styles.ToolsBarButton+' btn btn-danger'} onClick={()=>allRef?.current?.deleteSelectedItems()}><AiFillDelete /></button>
      }

      {isPrivileged['restore_deleted'] && ViewItemsButtons['RestoreDeleted'] && 
        <button className={styles.ToolsBarButton+' btn btn-danger'} onClick={()=>deletedRef?.current?.restoreSelectedItems()}><FaTrashRestoreAlt /></button>
      }
    </span>}

    {(isPrivileged['manage_accounting']) && isAccounting && <span className={styles.ToolsItemsBar}>
      <button className={styles.ToolsBarButton+' btn btn-warning'} onClick={()=>toolsBarRef?.current?.handleAccounting()} title={t('Accounting')}><BsFillCalculatorFill /></button> &nbsp;
      {isCashing && <button className={styles.ToolsBarButton+' btn btn-secondary'} onClick={()=>toolsBarRef?.current?.handleIssueReceipt()} title={t('Issue Cashing Receipt')}><FcDocument /></button>}
    </span>}

    {(isPrivileged['manage_vendor_debts']) && isVendorsDebts && <span className={styles.ToolsItemsBar}>
      <button className={styles.ToolsBarButton+' btn btn-secondary'} onClick={()=>vendorDebtsRef?.current?.handleAddVendorDebt()} title={t('Add Debt')}><AiOutlineFileAdd /></button> &nbsp;
    </span>}


    </span>
    <span className={`card ${styles.ResultsCard}`}>{`${t('Results Number')}: ${dataLength}`}</span>
    
  </span>
};

ToolsItemsBar.propTypes = {};

ToolsItemsBar.defaultProps = {};

export default ToolsItemsBar;
