import React from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsItemsBar.module.css';

import { useTranslation } from 'react-i18next';

import {AiFillDelete} from 'react-icons/ai';
import {FaTrashRestoreAlt} from 'react-icons/fa';
import {SiMicrosoftexcel} from 'react-icons/si';
import {AiFillPrinter, AiOutlineFileAdd} from 'react-icons/ai';
import ReactToPrint from 'react-to-print';

const ToolsItemsBar = ({page, dataLength, isPrivileged, isDeleted, featuresRef, deletedRef, toolsBarRef, allRef,
  printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, 
  ViewItemsButtons, isSelectItems}) => {
  const { t, i18n } = useTranslation();

  const isManageFeatures = page=='ManageFeatures';

  return <span className={styles.ToolsItemsBar} data-testid="ToolsItemsBar">
    <span className={styles.ToolsItemsBarButtons}>
    {isPrivileged['download_excel'] && !isDeleted &&  
      <button className={styles.ToolsBarButton+' btn btn-success'} onClick={()=>toolsBarRef?.current?.downloadExcel()} title={t('Export to excel')}><SiMicrosoftexcel /></button>
    }

    {isPrivileged['print_all_table'] && 
      <ReactToPrint
        trigger={() => <button className={styles.ToolsBarButton+' btn btn-info'} title={t('Print')}><AiFillPrinter /></button>}
        content={() => printComponentRef?.current}
        onBeforeGetContent={handleOnBeforePrint}
        onAfterPrint={handleOnAfterPrint}
        documentTitle={printDocumentTitle}
      />
    }

    {isSelectItems && <span className={styles.ToolsItemsBar}>
      {isPrivileged['delete'] && ViewItemsButtons['Delete'] && 
        <button className={styles.ToolsBarButton+' btn btn-danger'} onClick={()=>{
          if(allRef){
            allRef?.current?.deleteSelectedItems()
          }
          if(featuresRef){
            featuresRef?.current?.deleteSelectedItems()
          }
        }}><AiFillDelete /></button>
      }

      {isPrivileged['restore_deleted'] && ViewItemsButtons['RestoreDeleted'] && 
        <button className={styles.ToolsBarButton+' btn btn-danger'} onClick={()=>deletedRef?.current?.restoreSelectedItems()}><FaTrashRestoreAlt /></button>
      }

     

    </span>}

    {(isPrivileged['manage']) && isManageFeatures
      && <button className={styles.ToolsBarButton+' btn btn-warning'} onClick={()=>toolsBarRef?.current?.showUpdateAddAttendanceModal(true)} title={t('Add')}><AiOutlineFileAdd /></button>
    }
    </span>
    <span className={`card ${styles.ResultsCard}`}>{`${t('Results Number')}: ${dataLength}`}</span>
    
  </span>
};

ToolsItemsBar.propTypes = {};

ToolsItemsBar.defaultProps = {};

export default ToolsItemsBar;
