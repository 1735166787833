import React from 'react';
import PropTypes from 'prop-types';
import styles from './SideBar.module.css';
import { Link, matchPath, useLocation } from 'react-router-dom';

import {AiFillMail, AiFillSetting, AiFillShop} from 'react-icons/ai';
import {BiBarChartSquare} from 'react-icons/bi';
import {BsFillInboxesFill} from 'react-icons/bs';
import {CiPercent} from 'react-icons/ci';
import {FaBuilding, FaRegHandPointer, FaUserFriends} from 'react-icons/fa';
import {MdOutlineAccountBalance, MdRealEstateAgent} from 'react-icons/md';
import {BiSupport} from 'react-icons/bi';
import {GiBuyCard, GiExpense} from 'react-icons/gi';
import {GrUserAdmin, GrUserWorker} from 'react-icons/gr';
import {TbAsset} from 'react-icons/tb';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import { useTranslation } from 'react-i18next';



const SideBar = ({component, privilegesSideBar, locale}) => {
  const { t, i18n } = useTranslation();
  
  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const isStatistics = component=='Statistics';
  const isMail = component=='Mail';
  const isCompany = component=='Company';
  const isVendor = component=='Vendor';
  const isAgent = component=='Agent';
  const isCommissary = component=='Commissary';
  const isCustomer = component=='Customer';
  const isEmployeeAdmin = component=='EmployeeAdmin';
  const isEmployee = component=='Employee';
  const isStorage = component=='Storage';
  const isRole = component=='Role';
  const isSubscription = component=='Subscription';
  const isAccounts = component=='Account';
  const isAsset = component=='Asset';
  const isExpense = component=='Expense';
  const isCompanyOffer = component=='CompanyOffer';
  const isSupport = component=='Support';
  const isSettings = component=='Settings';
  
  const isPrivilegedSideBar = (name) => {
    return privilegesSideBar?.filter(x => x==name)?.length>0
  }


  return <nav className={styles.SideBar + " collapse d-lg-block sidebar collapse bg-white"} data-testid="SideBar" id="sidebarMenu" dir={locale=='ar'?'rtl':'ltr'}>
    <div className="position-sticky">
      <div className={`list-group list-group-flush mx-3 mt-4 ${locale=='ar'?'text-right':'text-left'}`}>
        {isPrivilegedSideBar('statistics') && <Link
           to={`/${prefix}/dashboard/statistics`}
           className={`list-group-item list-group-item-action py-2 ripple ${isStatistics?"active":''}`}>
          <BiBarChartSquare />&nbsp; <span>{t('Statistics')}</span>
        </Link>}

        {isPrivilegedSideBar('mail') && <Link
           to={`/${prefix}/dashboard/mail`}
           className={`list-group-item list-group-item-action py-2 ripple ${isMail?"active":''}`}>
          <AiFillMail />&nbsp; <span>{t('Mail')}</span>
        </Link>}

        {isPrivilegedSideBar('company') && <Link
           to={`/${prefix}/dashboard/company`}
           className={`list-group-item list-group-item-action py-2 ripple ${isCompany?"active":''}`}>
          <FaBuilding />&nbsp; <span>{t('Companies')}</span>
        </Link>}

        {isPrivilegedSideBar('vendor') && <Link
           to={`/${prefix}/dashboard/vendor`}
           className={`list-group-item list-group-item-action py-2 ripple ${isVendor?"active":''}`}>
          <AiFillShop />&nbsp; <span>{t('Vendors')}</span>
        </Link>}

        {isPrivilegedSideBar('agent') && <Link
           to={`/${prefix}/dashboard/agent`}
           className={`list-group-item list-group-item-action py-2 ripple ${isAgent?"active":''}`}>
          <MdRealEstateAgent />&nbsp; <span>{t('Agents')}</span>
        </Link>}

        {isPrivilegedSideBar('commissary') && <Link
           to={`/${prefix}/dashboard/commissary`}
           className={`list-group-item list-group-item-action py-2 ripple ${isCommissary?"active":''}`}>
          <CiPercent />&nbsp; <span>{t('Commissaries')}</span>
        </Link>}

        {isPrivilegedSideBar('customer') && <Link
           to={`/${prefix}/dashboard/customer`}
           className={`list-group-item list-group-item-action py-2 ripple ${isCustomer?"active":''}`}>
          <GiBuyCard />&nbsp; <span>{t('Customers')}</span>
        </Link>}

        {isPrivilegedSideBar('employee_admin') && <Link
           to={`/${prefix}/dashboard/employee_admin`}
           className={`list-group-item list-group-item-action py-2 ripple ${isEmployeeAdmin?"active":''}`}>
          <GrUserAdmin />&nbsp; <span>{t('Admin Employees')}</span>
        </Link>}

        {isPrivilegedSideBar('employee') && <Link
           to={`/${prefix}/dashboard/employee`}
           className={`list-group-item list-group-item-action py-2 ripple ${isEmployee?"active":''}`}>
          <GrUserWorker />&nbsp; <span>{t('Company Employees')}</span>
        </Link>}

        {isPrivilegedSideBar('storage') && <Link
           to={`/${prefix}/dashboard/storage`}
           className={`list-group-item list-group-item-action py-2 ripple ${isStorage?"active":''}`}>
          <BsFillInboxesFill />&nbsp; <span>{t('Storages')}</span>
        </Link>}

        {isPrivilegedSideBar('role') && <Link
           to={`/${prefix}/dashboard/role`}
           className={`list-group-item list-group-item-action py-2 ripple ${isRole?"active":''}`}>
          <BsFillInboxesFill />&nbsp; <span>{t('User Roles')}</span>
        </Link>}

        {isPrivilegedSideBar('financial_account') && <Link
           to={`/${prefix}/dashboard/account`}
            className={`list-group-item list-group-item-action py-2 ripple ${isAccounts?"active":''}`}>
           <MdOutlineAccountBalance />&nbsp; <span>{t('Financial Accounts')}</span>
        </Link>}

        {isPrivilegedSideBar('asset') && <Link
           to={`/${prefix}/dashboard/asset`}
            className={`list-group-item list-group-item-action py-2 ripple ${isAsset?"active":''}`}>
            <TbAsset />&nbsp; <span>{t('Assets')}</span>
        </Link>}

        {isPrivilegedSideBar('expense') && <Link
           to={`/${prefix}/dashboard/expense`}
            className={`list-group-item list-group-item-action py-2 ripple ${isExpense?"active":''}`}>
            <GiExpense />&nbsp; <span>{t('Expenses')}</span>
        </Link>}

        {isPrivilegedSideBar('subscription') && <Link
           to={`/${prefix}/dashboard/subscription`}
            className={`list-group-item list-group-item-action py-2 ripple ${isSubscription?"active":''}`}>
          <FaRegHandPointer />&nbsp; <span>{t('Subscriptions')}</span>
        </Link>}

        {isPrivilegedSideBar('company_friend') && <Link
           to={`/${prefix}/dashboard/company_offer`}
            className={`list-group-item list-group-item-action py-2 ripple ${isCompanyOffer?"active":''}`}>
            <FaUserFriends />&nbsp; <span>{t('Company Offers')}</span>
        </Link>}
        
        {isPrivilegedSideBar('support') && <Link
           to={`/${prefix}/dashboard/support`}
            className={`list-group-item list-group-item-action py-2 ripple ${isSupport?"active":''}`}>
            <BiSupport />&nbsp; <span>{t('Support')}</span>
        </Link>}
        
        {isPrivilegedSideBar('setting') && <Link
           to={`/${prefix}/dashboard/settings`}
            className={`list-group-item list-group-item-action py-2 ripple ${isSettings?"active":''}`}>
            <AiFillSetting />&nbsp; <span>{t('Settings')}</span>
        </Link>}
      </div>
    </div>
  </nav>
};

SideBar.propTypes = {};

SideBar.defaultProps = {};

export default SideBar;
