import React, {useEffect, useState, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './All.module.css';

import {AiFillEdit} from 'react-icons/ai';
import {AiFillDelete} from 'react-icons/ai';
import {MdSelectAll} from 'react-icons/md';
import {MdTabUnselected} from 'react-icons/md';
import {GrFormView, GrDocumentUpdate} from 'react-icons/gr';

import Pagination from '../../Common/Pagination/Pagination';
import $ from 'jquery';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import ToolsBar from '../ToolsBar/ToolsBar';
import { useTranslation } from 'react-i18next';


import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

const All = ({page, data, viewBtnHandle, editBtnHandle, deleteHandle, setDataItems, generalFetchingServiceRef, allRef, isPrivileged, locale, showMessage}) => {
  const { t, i18n } = useTranslation();

  const toolsBarRef = useRef();
  const paginationRef= useRef();
  const tableRef= useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [selecetedItems, setSelectedItems] = useState([])
  const [isSelectAllBtn, setIsSelectAllBtn] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [isUpdatingValues, setIsUpdatingValues] = useState(false)
  const [updateOneItem, setUpdateOneItem] = useState(null)
  const [dataLength, setDataLength] = useState(0)
  

  let isDataLoaded=false
  const selectedDataAction = () => {
    if(selecetedItems.length>0){
      toolsBarRef?.current?.setIsSelectItems(true)
    }else{
      toolsBarRef?.current?.setIsSelectItems(false)
    }

    if(selecetedItems?.length==data?.length){
      setIsSelectAllBtn(false)
      $("#selectAllBtn").removeClass('btn-primary')
      $("#selectAllBtn").addClass('btn-warning')
    }else{
      setIsSelectAllBtn(true)
      $("#selectAllBtn").removeClass('btn-warning')
      $("#selectAllBtn").addClass('btn-primary')
    }
  }
  useEffect(()=>{
    selectedDataAction()
    isDataLoaded=true
  },[selecetedItems, data])
  useEffect(()=>{
    if(isDataLoaded){
      selectedDataAction()
    }
  },[selecetedItems])

  const handleOnBeforePrint = () => {
    setIsPrinting(true)
    $('.hidableColumn').removeClass(styles.hidableColumn)
    return Promise.resolve();
  }
  const handleOnAfterPrint = () => {
    setIsPrinting(false)
    $('.hidableColumn').addClass(styles.hidableColumn)
  }

  const setSelectedItem = (selectedId, value) => {
    if(value){
      if(selecetedItems.indexOf(selectedId)==-1){
        setSelectedItems([...selecetedItems, selectedId])
      }
    }else{
      if(selecetedItems.indexOf(selectedId)!=-1){
        setSelectedItems(selecetedItems.filter((x)=>x!=selectedId))
      }
    }
  }
  const handleOnChangeSelect= (e) => {
    let selectedId=parseInt(e.target.getAttribute('tag'))
    let value=e.target.checked
    setSelectedItem(selectedId, value)
  }

  const handleWhenUpdatingHidden = () =>{
    setIsUpdatingValues(false)
    setUpdateOneItem(null)
  }

  useImperativeHandle(allRef,()=>({
    setDataTotalLength(value){
      setDataLength(value)
    },
    deleteSelectedItems(){
      deleteHandle(selecetedItems, paginationRef)
    },
    clearSelectedItems(){
      setSelectedItems([])
      $('.'+styles.All).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
    },
    loadPages(){
      toolsBarRef?.current?.loadPages()
    },
    showUpdateModal(){
      setIsUpdatingValues(true)
    }
  }))

  const handleSelectAllClick = () => {
    if(selecetedItems?.length==data?.length){
      $('.'+styles.All).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
      setSelectedItems([])
    }else{
      $('.'+styles.All).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', true)
      setSelectedItems([...data?.map(x=>x.id)])
    }
  }

  const dataService = (links, isAll, filtersO) => generalFetchingServiceRef?.current?.getAll(links, isAll, filtersO)
  const filtersDataService = () => generalFetchingServiceRef?.current?.getFiltersData()
  const excelService = (filters, specificItems) => generalFetchingServiceRef?.current?.ExportExcel(filters, 'All', specificItems)


  return <div className={styles.All + ""} data-testid="All">
    <ToolsBar toolsBarRef={toolsBarRef} allRef={allRef} paginationRef={paginationRef} isPrivileged={isPrivileged}
     ViewItemsButtons={{'Delete': true}} identifier='CommissaryAll' filtersDataService={filtersDataService} selecetedItems={selecetedItems}
     printComponentRef={tableRef} printDocumentTitle='Commissaries' handleOnAfterPrint={handleOnAfterPrint} handleOnBeforePrint={handleOnBeforePrint}
     locale={locale} page={page} dataLength={dataLength}
     />

    {data?.length > 0 &&
    <table id="tableToPrint" ref={tableRef} cellPadding="0" cellSpacing="0" border="0" className={"datatable table table-striped table-bordered table-hover"}>
      <thead>
        <tr>
          {!isPrinting && <th className='hideOnPrint'>
            <button id="selectAllBtn" className='btn btn-primary' onClick={handleSelectAllClick}>
              {isSelectAllBtn && <MdSelectAll id="SelectAll" />}
              {!isSelectAllBtn && <MdTabUnselected id="UnSelectAll" />}
            </button>
          </th>}
          <th>{t('Id')}</th>
          <th>{t('Name')}</th>
          <th>{t('Commissary Type')}</th>
          <th>{t('Company')}</th>
          {/* <th className={styles.hidableColumn + ' hidableColumn'}>{t('Email')}</th> */}
          <th className={styles.hidableColumn + ' hidableColumn'}>{t('Phone')}</th>
          {/* <th className={styles.hidableColumn + ' hidableColumn'}>{t('Governorate')}</th> */}
          {/* <th className={styles.hidableColumn + ' hidableColumn'}>{t('District')}</th> */}
          <th>{t('Is Activated')}</th>
          <th>{t('Expiary Date')}</th>
          {!isPrinting && <th>{t('Actions')}</th>}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, id) => {
          return <tr key={id}>
            {!isPrinting && <td className='hideOnPrint'><input type={'checkbox'} tag={item?.id} name={styles.CheckBoxDataTable} className={styles.CheckBoxDataTable} onChange={handleOnChangeSelect} /></td>}
            <td>{item?.id}</td>
            <td>{`${item?.user?.first_name} ${item?.user?.last_name}`}</td>
            <td>{t(item?.type?.name)}</td>
            <td>{item?.company?.name}</td>
            {/* <td className={styles.hidableColumn + ' hidableColumn'}>{(item?.user?(item?.user?.email):'')}</td> */}
            <td className={styles.hidableColumn + ' hidableColumn'}>{(item?.user?(item?.user?.phone):'')}</td>
            {/* <td className={styles.hidableColumn + ' hidableColumn'}>{(item?.user?(item?.user?.governorate?.name):'')}</td> */}
            {/* <td className={styles.hidableColumn + ' hidableColumn'}>{(item?.user?(item?.user?.district?.name):'')}</td> */}
            <td>{(item?.user?(t(item?.user?.is_activated?'true':'false')):'')}</td>
            <td>{(item?.user?.expiary_date?(moment(item?.user?.expiary_date).local().locale(locale).format('DD/MM/yyyy h:mm:ss a')):'')}</td>
            {!isPrinting && <td>
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropDownActionsBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {t('Do an action')}
              </button>
              <div className={`dropdown-menu ${locale=='ar'?'text-right':'text-left'}`} aria-labelledby="dropDownActionsBtn">
                {isPrivileged['view_item'] && <button className="dropdown-item " href="#" onClick={()=>viewBtnHandle(item.id)}><GrFormView /> {t('View')}</button>}
                {isPrivileged['edit'] && <button className="dropdown-item btn btn-primary" onClick={()=>editBtnHandle(item.id)}><AiFillEdit /> {t('Edit')}</button>}
                {isPrivileged['delete'] && <button className="dropdown-item btn btn-danger" onClick={()=>deleteHandle([item.id], paginationRef)}><AiFillDelete /> {t('Delete')}</button>}
                {(isPrivileged['update_status'] || isPrivileged['update_shipping_date'] || isPrivileged['update_related_people']) && <button className="dropdown-item btn btn-warning" onClick={()=>{setUpdateOneItem(item?.id); allRef?.current?.showUpdateModal()}}><GrDocumentUpdate /> {t('Update')}</button>}
              </div>
            </div>
            </td>}
          </tr>
        })}
      </tbody>
    </table>
    }

    <br/>{data?.length == 0 && t("No items to display")}

    <Pagination toolsBarRef={toolsBarRef} service={dataService} excelService={excelService} data={data} setData={setDataItems} paginationRef={paginationRef} locale={locale} />
  </div>
};

All.propTypes = {};

All.defaultProps = {};

export default All;
