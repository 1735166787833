import React, {useEffect, useState, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './Requests.module.css';

import {ImCheckmark} from 'react-icons/im';
import {MdSelectAll, MdTabUnselected} from 'react-icons/md';
import {FcCancel} from 'react-icons/fc';

import Pagination from '../../Common/Pagination/Pagination';
import $ from 'jquery';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import ToolsBar from './ToolsBar/ToolsBar';
import { useTranslation } from 'react-i18next';


import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import UpdateAdd from './UpdateAdd/UpdateAdd';
import { Link } from 'react-router-dom';

const Requests = ({id, page, editBtnHandle, deleteHandle, generalFetchingServiceRef, requestsRef, isPrivileged, locale, showMessage}) => {
  const { t, i18n } = useTranslation();

  const toolsBarRef = useRef();
  const paginationRef= useRef();
  const tableRef= useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [data, setDataItems] = useState([])
  
  const [selecetedItems, setSelectedItems] = useState([])
  const [isSelectAllBtn, setIsSelectAllBtn] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [isAddItem, setIsAddItem] = useState(false)
  const [isAddingUpdatingValues, setIsAddingUpdatingValues] = useState(false)
  const [updateOneItem, setUpdateOneItem] = useState(null)
  const [dataLength, setDataLength] = useState(0)
  

  let isDataLoaded=false
  const selectedDataAction = () => {
    if(selecetedItems.length>0){
      toolsBarRef?.current?.setIsSelectItems(true)
    }else{
      toolsBarRef?.current?.setIsSelectItems(false)
    }

    if(selecetedItems?.length==data?.length){
      setIsSelectAllBtn(false)
      $("#selectAllBtn").removeClass('btn-primary')
      $("#selectAllBtn").addClass('btn-warning')
    }else{
      setIsSelectAllBtn(true)
      $("#selectAllBtn").removeClass('btn-warning')
      $("#selectAllBtn").addClass('btn-primary')
    }
  }
  useEffect(()=>{
    selectedDataAction()
    isDataLoaded=true
  },[selecetedItems, data])
  useEffect(()=>{
    if(isDataLoaded){
      selectedDataAction()
    }
  },[selecetedItems])

  const handleOnBeforePrint = () => {
    setIsPrinting(true)
    $('.hidableColumn').removeClass(styles.hidableColumn)
    return Promise.resolve();
  }
  const handleOnAfterPrint = () => {
    setIsPrinting(false)
    $('.hidableColumn').addClass(styles.hidableColumn)
  }

  const setSelectedItem = (selectedId, value) => {
    if(value){
      if(selecetedItems.indexOf(selectedId)==-1){
        setSelectedItems([...selecetedItems, selectedId])
      }
    }else{
      if(selecetedItems.indexOf(selectedId)!=-1){
        setSelectedItems(selecetedItems.filter((x)=>x!=selectedId))
      }
    }
  }
  const handleOnChangeSelect= (e) => {
    let selectedId=parseInt(e.target.getAttribute('tag'))
    let value=e.target.checked
    setSelectedItem(selectedId, value)
  }

  useImperativeHandle(requestsRef,()=>({
    setDataTotalLength(value){
      setDataLength(value)
    },
    deleteSelectedItems(){
      deleteHandle(selecetedItems, paginationRef, 'feature')
    },
    clearSelectedItems(){
      setSelectedItems([])
      $('.'+styles.Requests).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
    },
    loadPages(){
      toolsBarRef?.current?.loadPages()
    },
    showUpdateAddAttendanceModal(isAdd, id=null){
      setIsAddItem(isAdd)
      if(id){
        setUpdateOneItem(id)
      }
      setIsAddingUpdatingValues(true)
    }
  }))

  const handleSelectAllClick = () => {
    if(selecetedItems?.length==data?.length){
      $('.'+styles.Requests).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
      setSelectedItems([])
    }else{
      $('.'+styles.Requests).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', true)
      setSelectedItems([...data?.map(x=>x.id)])
    }
  }

  const handleWhenUpdatingHidden = () =>{
    setIsAddingUpdatingValues(false)
    setUpdateOneItem(null)
  }

  const handleOnUpdateAddConfirm = (data, isEmpty, isAdd, callback) => {
    if(isEmpty){
      showMessage(false, t('Nothing to update!'))
    }else{
      data['subscription_id']=id
      if(isAdd){
        generalFetchingServiceRef?.current?.add(data, 'feature')
          .then(response => {
            let responseData=response?.data
            if(responseData && responseData?.status==1){
              showMessage(true, responseData?.message)
              setUpdateOneItem(null)
              callback(true)
              setIsUpdatingAdding(false)
            }else{
              callback(false, null,responseData?.message)
            }
          }).catch(error => {
            callback(false, error)
            console.log(error)
            setUpdateOneItem(null)
          })
      }else{
        generalFetchingServiceRef?.current?.update(data, updateOneItem?[updateOneItem]:selecetedItems, 'feature')
          .then(response => {
            let responseData=response?.data
            if(responseData && responseData?.status==1){
              showMessage(true, responseData?.message)
            }else{
              showMessage(false, responseData?.message)
            }
            setUpdateOneItem(null)
            callback(true)
            setIsUpdatingAdding(false)
          }).catch(error => {
            callback(false, error)
            console.log(error)
            setUpdateOneItem(null)
          })
      }
      
      paginationRef?.current?.loadPages()
      requestsRef?.current?.clearSelectedItems()
    }
  }

  const handleRequestReponse = (response, company_id, subscription_id) => {
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(response?t('Acceptance Confirmation'):t('Denial Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html(t(`Do you want to ${response?'accept':'deny'} the action?`))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      handleRequestReponseApply(response, company_id, subscription_id)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }
  const handleRequestReponseApply = (response, company_id, subscription_id) => {
    generalFetchingServiceRef?.current?.update({response}, [company_id, subscription_id], 'requests/response')
    .then(response => {
      let responseData=response?.data
      if(responseData && responseData?.status==1){
        showMessage(true, responseData?.message)
        paginationRef?.current?.loadPages();
      }else{
        showMessage(false, responseData?.message)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const dataService = (links, isAll, filtersO) => generalFetchingServiceRef?.current?.getAll(links, isAll, filtersO, `requests/all`)
  const filtersDataService = () => generalFetchingServiceRef?.current?.getFiltersData()
  const excelService = (filters, specificItems) => generalFetchingServiceRef?.current?.ExportExcel(filters, 'Requests', specificItems,`requests/all`)


  return <div className={styles.Requests + ""} data-testid="Requests">
    <ToolsBar toolsBarRef={toolsBarRef} requestsRef={requestsRef} paginationRef={paginationRef} isPrivileged={isPrivileged}
     ViewItemsButtons={{'Delete': true}} identifier='SubscriptionRequests' filtersDataService={filtersDataService} selecetedItems={selecetedItems}
     printComponentRef={tableRef} printDocumentTitle='Subscriptions' handleOnAfterPrint={handleOnAfterPrint} handleOnBeforePrint={handleOnBeforePrint}
     locale={locale} page={page} dataLength={dataLength}
     />

    <UpdateAdd locale={locale} isPrivileged={isPrivileged} isAdd={isAddItem} show={isAddingUpdatingValues} generalFetchingServiceRef={generalFetchingServiceRef} updateOneItem={updateOneItem} handleWhenHidden={handleWhenUpdatingHidden} handleOnConfirm={handleOnUpdateAddConfirm} />
    
    {data?.length > 0 &&
    <table id="tableToPrint" ref={tableRef} cellPadding="0" cellSpacing="0" border="0" className={"datatable table table-striped table-bordered table-hover"}>
      <thead>
        <tr>
          {!isPrinting && <th className='hideOnPrint'>
            <button id="selectAllBtn" className='btn btn-primary' onClick={handleSelectAllClick}>
              {isSelectAllBtn && <MdSelectAll id="SelectAll" />}
              {!isSelectAllBtn && <MdTabUnselected id="UnSelectAll" />}
            </button>
          </th>}
          <th>{t('Company Name')}</th>
          <th>{t('Subscription Name')}</th>
          <th>{t('Action')}</th>
          <th>{t('Period')}</th>
          {!isPrinting && <th>{t('Actions')}</th>}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, id) => {
          return <tr key={id}>
            {!isPrinting && <td className='hideOnPrint'><input type={'checkbox'} tag={item?.id} name={styles.CheckBoxDataTable} className={styles.CheckBoxDataTable} onChange={handleOnChangeSelect} /></td>}
            <td><Link to={`/${prefix}/dashboard/company/view/${item?.company?.id}`}>{item?.company?.name}</Link></td>
            <td><Link to={`/${prefix}/dashboard/subscription/view/${item?.subscription?.id}`}>{item?.subscription?.name}</Link></td>
            <td>{t(item?.action)}</td>
            <td>{t(item?.subscription_period)}</td>
            {!isPrinting && <td>
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropDownActionsBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {t('Do an action')}
              </button>
              <div className={`dropdown-menu ${locale=='ar'?'text-right':'text-left'}`} aria-labelledby="dropDownActionsBtn">
                {isPrivileged['manage_requests'] && <button className="dropdown-item btn btn-primary" onClick={()=>handleRequestReponse(true, item?.company_id, item?.subscription_id)}><ImCheckmark /> {t('Accept')}</button>}
                {isPrivileged['manage_requests'] && <button className="dropdown-item btn btn-danger" onClick={()=>handleRequestReponse(false, item?.company_id, item?.subscription_id)}><FcCancel /> {t('Deny')}</button>}
              </div>
            </div>
            </td>}
          </tr>
        })}
      </tbody>
    </table>
    }

    <br/>{data?.length == 0 && t("No items to display")}

    <Pagination toolsBarRef={toolsBarRef} service={dataService} excelService={excelService} data={data} setData={setDataItems} paginationRef={paginationRef} locale={locale} />
  </div>
};

Requests.propTypes = {};

Requests.defaultProps = {};

export default Requests;
