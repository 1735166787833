import React, {useEffect, useState, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './Attendance.module.css';

import {AiFillEdit} from 'react-icons/ai';
import {AiFillDelete} from 'react-icons/ai';
import {MdSelectAll} from 'react-icons/md';
import {MdTabUnselected} from 'react-icons/md';
import {GrFormView, GrDocumentUpdate} from 'react-icons/gr';

import Pagination from '../../Common/Pagination/Pagination';
import $ from 'jquery';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import ToolsBar from './ToolsBar/ToolsBar';
import UpdateAdd from './UpdateAdd/UpdateAdd';
import { useTranslation } from 'react-i18next';


import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

const Attendance = ({id, page, data, deleteHandle, setDataItems, generalFetchingServiceRef, attendanceRef, isPrivileged, locale, showMessage}) => {
  const { t, i18n } = useTranslation();

  const toolsBarRef = useRef();
  const paginationRef= useRef();
  const tableRef= useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [selecetedItems, setSelectedItems] = useState([])
  const [isSelectAttendanceBtn, setIsSelectAttendanceBtn] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [isUpdatingAdding, setIsUpdatingAdding] = useState(false)
  const [updateOneItem, setUpdateOneItem] = useState(null)
  const [updateOneItemDate, setUpdateOneItemDate] = useState(null)
  const [isAddItem, setIsAddItem] = useState(false)
  const [dataLength, setDataLength] = useState(0)


  let isDataLoaded=false
  const selectedDataAction = () => {
    if(selecetedItems.length>0){
      toolsBarRef?.current?.setIsSelectItems(true)
    }else{
      toolsBarRef?.current?.setIsSelectItems(false)
    }

    if(selecetedItems?.length==data?.length){
      setIsSelectAttendanceBtn(false)
      $("#selectAttendanceBtn").removeClass('btn-primary')
      $("#selectAttendanceBtn").addClass('btn-warning')
    }else{
      setIsSelectAttendanceBtn(true)
      $("#selectAttendanceBtn").removeClass('btn-warning')
      $("#selectAttendanceBtn").addClass('btn-primary')
    }
  }
  useEffect(()=>{
    selectedDataAction()
    isDataLoaded=true
  },[selecetedItems, data])
  useEffect(()=>{
    if(isDataLoaded){
      selectedDataAction()
    }
  },[selecetedItems])

  const handleOnBeforePrint = () => {
    setIsPrinting(true)
    $('.hidableColumn').removeClass(styles.hidableColumn)
    return Promise.resolve();
  }
  const handleOnAfterPrint = () => {
    setIsPrinting(false)
    $('.hidableColumn').addClass(styles.hidableColumn)
  }

  const setSelectedItem = (selectedId, value) => {
    if(value){
      if(selecetedItems.indexOf(selectedId)==-1){
        setSelectedItems([...selecetedItems, selectedId])
      }
    }else{
      if(selecetedItems.indexOf(selectedId)!=-1){
        setSelectedItems(selecetedItems.filter((x)=>x!=selectedId))
      }
    }
  }
  const handleOnChangeSelect= (e) => {
    let selectedId=parseInt(e.target.getAttribute('tag'))
    let value=e.target.checked
    setSelectedItem(selectedId, value)
  }

  const handleWhenUpdatingHidden = () =>{
    setIsUpdatingAdding(false)
    setUpdateOneItemDate(null)
    setUpdateOneItem(null)
    setIsAddItem(false)
  }

  useImperativeHandle(attendanceRef,()=>({
    setDataTotalLength(value){
      setDataLength(value)
    },
    deleteSelectedItems(ids){
      deleteHandle(ids?ids:selecetedItems, paginationRef, 'attendance')
    },
    clearSelectedItems(){
      setSelectedItems([])
      $('.'+styles.Attendance).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
    },
    loadPages(){
      toolsBarRef?.current?.loadPages()
    },
    showUpdateAddModal(isAdd, date){
      setIsUpdatingAdding(true)
      setIsAddItem(isAdd)
      setUpdateOneItemDate(date)
    }
  }))

  const handleSelectAttendanceClick = () => {
    if(selecetedItems?.length==data?.length){
      $('.'+styles.Attendance).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
      setSelectedItems([])
    }else{
      $('.'+styles.Attendance).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', true)
      setSelectedItems([...data?.map(x=>x.id)])
    }
  }

  const handleOnUpdateAddConfirm = (data, isEmpty, isAdd, callback) => {
    if(isEmpty){
      showMessage(false, t('Nothing to update!'))
    }else{
      if(isAdd){
        generalFetchingServiceRef?.current?.add(data, 'attendance')
          .then(response => {
            let responseData=response?.data
            if(responseData && responseData?.status==1){
              showMessage(true, responseData?.message)
              setUpdateOneItem(null)
              callback(true)
              setIsUpdatingAdding(false)
            }else{
              callback(false, null,responseData?.message)
            }
          }).catch(error => {
            callback(false, error)
            console.log(error)
            setUpdateOneItem(null)
          })
      }else{
        generalFetchingServiceRef?.current?.update(data, updateOneItem?[updateOneItem]:selecetedItems, 'attendance')
          .then(response => {
            let responseData=response?.data
            if(responseData && responseData?.status==1){
              showMessage(true, responseData?.message)
            }else{
              showMessage(false, responseData?.message)
            }
            setUpdateOneItem(null)
            callback(true)
            setIsUpdatingAdding(false)
          }).catch(error => {
            callback(false, error)
            console.log(error)
            setUpdateOneItem(null)
          })
      }
      
      paginationRef?.current?.loadPages()
      attendanceRef?.current?.clearSelectedItems()
    }
  }

  const dataService = (links, isAll, filtersO) => generalFetchingServiceRef?.current?.getAll(links, isAll, filtersO, 'attendance')
  const filtersDataService = () => generalFetchingServiceRef?.current?.getFiltersData(null, 'attendance')
  const excelService = (filters, specificItems) => generalFetchingServiceRef?.current?.ExportExcel(filters, 'Attendance', specificItems)


  return <div className={styles.Attendance + ""} data-testid="Attendance">
    <ToolsBar toolsBarRef={toolsBarRef} attendanceRef={attendanceRef} paginationRef={paginationRef} isPrivileged={isPrivileged}
     ViewItemsButtons={{'Delete': false}} identifier='EmployeeAttendance' filtersDataService={filtersDataService} selecetedItems={selecetedItems}
     printComponentRef={tableRef} printDocumentTitle='Employees' handleOnAfterPrint={handleOnAfterPrint} handleOnBeforePrint={handleOnBeforePrint}
     locale={locale} page={page} dataLength={dataLength} id={id}
     />

    {(isPrivileged['attendance_add'] || isPrivileged['attendance_update'])
      &&
      <UpdateAdd id={id} updateOneItem={updateOneItem} oneItemDate={updateOneItemDate} locale={locale} isPrivileged={isPrivileged} isAdd={isAddItem} show={isUpdatingAdding} generalFetchingServiceRef={generalFetchingServiceRef} handleWhenHidden={handleWhenUpdatingHidden} handleOnConfirm={handleOnUpdateAddConfirm} />
    }

    {data?.length > 0 &&
    <table id="tableToPrint" ref={tableRef} cellPadding="0" cellSpacing="0" border="0" className={"datatable table table-striped table-bordered table-hover"}>
      <thead>
        <tr>
          {!isPrinting && <th className='hideOnPrint'>
            <button id="selectAttendanceBtn" className='btn btn-primary' onClick={handleSelectAttendanceClick}>
              {isSelectAttendanceBtn && <MdSelectAll id="SelectAttendance" />}
              {!isSelectAttendanceBtn && <MdTabUnselected id="UnSelectAttendance" />}
            </button>
          </th>}
          <th>{t('Id')}</th>
          <th>{t('Name')}</th>
          <th className={styles.hidableColumn + ' hidableColumn'}>{t('Job Title')}</th>
          <th>{t('Is Attended')}</th>
          <th>{t('Requested Leave?')}</th>
          <th className={styles.hidableColumn + ' hidableColumn'}>{t('Employee Notes')}</th>
          <th>{t('Leave Approve Status')}</th>
          <th className={styles.hidableColumn + ' hidableColumn'}>{t('Approver Notes')}</th>
          <th>{t('Date')}</th>
          {!isPrinting && <th>{t('Actions')}</th>}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, id) => {
          return <tr key={id}>
            {!isPrinting && <td className='hideOnPrint'><input type={'checkbox'} tag={item?.id} name={styles.CheckBoxDataTable} className={styles.CheckBoxDataTable} onChange={handleOnChangeSelect} /></td>}
            <td>{item?.id}</td>
            <td>{`${item?.employee?.user?.first_name} ${item?.employee?.user?.last_name}`}</td>
            <td className={styles.hidableColumn + ' hidableColumn'}>{item?.employee?.job_title}</td>
            <td>{t(item?.is_attended?'true':'false')}</td>
            <td>{t(item?.is_leave?'true':'false')}</td>
            <td className={styles.hidableColumn + ' hidableColumn'}>{item?.employee_notes}</td>
            <td>{item?.leave_approve_status?.display_name}</td>
            <td className={styles.hidableColumn + ' hidableColumn'}>{item?.approver_notes}</td>
            <td>{(item?.date?(moment(item?.date).local().locale(locale).format('DD/MM/yyyy')):'')}</td>
            {!isPrinting && <td>
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropDownActionsBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {t('Do an action')}
              </button>
              <div className={`dropdown-menu ${locale=='ar'?'text-right':'text-left'}`} aria-labelledby="dropDownActionsBtn">
                {(isPrivileged['attendance_update'] && (isPrivileged['attendance_approve_leave'] || (!item?.leave_approve_status?.name || item?.leave_approve_status?.name=='processing'))) && <button className="dropdown-item btn btn-warning" onClick={()=>{setUpdateOneItem(item?.id); attendanceRef?.current?.showUpdateAddModal(false, item?.date)}}><GrDocumentUpdate /> {t('Update')}</button>}
                {isPrivileged['attendance_delete']&& (isPrivileged['attendance_approve_leave'] || (!item?.leave_approve_status?.name || item?.leave_approve_status?.name=='processing')) && <button className="dropdown-item btn btn-warning" onClick={()=>{setUpdateOneItem(item?.id); attendanceRef?.current?.deleteSelectedItems([item?.id])}}><AiFillDelete /> {t('Delete')}</button>}
              </div>
            </div>
            </td>}
          </tr>
        })}
      </tbody>
    </table>
    }

    <br/>{data?.length == 0 && t("No items to display")}

    <Pagination toolsBarRef={toolsBarRef} service={dataService} excelService={excelService} data={data} setData={setDataItems} paginationRef={paginationRef} locale={locale} />
  </div>
};

Attendance.propTypes = {};

Attendance.defaultProps = {};

export default Attendance;
