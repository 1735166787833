
import PropTypes from 'prop-types';
import FetchDataService from './FetchDataService';
import * as emailValidator from 'react-email-validator';
import {useImperativeHandle, useRef} from 'react';


const AuthService = ({authServiceRef}) => {
    const fetchDataServiceRef = useRef();

    useImperativeHandle(authServiceRef,()=>({
        Login (input) {
            let request={password: input.password}
            if(emailValidator.validate(input.emailOrPhone)){
                request.email=input.emailOrPhone
            }else{
                request.phone=input.emailOrPhone
            }
            return fetchDataServiceRef?.current?.PostRequest('/auth/login', request)
        }
    }))

    return <span>
        <FetchDataService fetchDataServiceRef={fetchDataServiceRef} />
    </span>
}

export default AuthService