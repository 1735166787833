import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './ViewOfferRequest.module.css';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import { useTranslation } from 'react-i18next';

import {AiFillPrinter} from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import Notes from '../../Common/Notes/Notes';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralFetchingService from '../../../Services/GeneralFetchingService';

const ViewOfferRequest = ({id, generalFetchingServiceRef, showMessage, isPrivileged, locale}) => {
  const { t, i18n } = useTranslation();
  
  const [data, setData] = useState(null)

  const tableRef = useRef();
  const generalFetchingOfferSentServiceRef = useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [governoratesData, setGovernoratesData] = useState([])
  const [districtsData, setDistrictsData] = useState([])
  const [neighborhoodsData, setNeighborhoodsData] = useState([])


  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let governorates=data?.governorates
          setGovernoratesData(governorates)

          let districts=data?.districts
          setDistrictsData(districts)

          let neighborhoods=data?.neighborhoods
          setNeighborhoodsData(neighborhoods)

          GetData()
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const GetData = () => {
    generalFetchingServiceRef?.current?.getById(id, 'offer/sent')
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setData(data)
          }
        }).catch(error => {
          console.log(error)
        })
  }

  useEffect(()=>{
    getAllData()
  },[])

  return <div className={styles.ViewOfferRequest} data-testid="ViewOfferRequest">
    <GeneralFetchingService generalFetchingServiceRef={generalFetchingOfferSentServiceRef} prefix='company_offer_sent' />
    <table ref={tableRef} className={styles.table+' table table-bordered table-hover'}>
      <thead className='table-active'>
        <tr>
          <th scope='col' className={styles.header_label}>{t('Property')}</th>
          <th scope='col' className={styles.header_label}>{t('Value')}</th>
        </tr>
      </thead>
      <tbody>
        <tr className="form-group">
          <td><label className={styles.label}>{t('Id')}</label></td>
          <td>{data?.id}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Name')}</label></td>
          <td>{data?.title}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Details')}</label></td>
          <td>{data?.details}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Max Orders Number')}</label></td>
          <td>{data?.max_orders_number}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Min Orders Number')}</label></td>
          <td>{data?.min_orders_number}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Transfer Type')}</label></td>
          <td>{data?.transfer_type?.display_name}</td>
        </tr>
        
        <tr className="form-group">
          <td><label className={styles.label}>{t('Transfer Price')}</label></td>
          <td>{data?.transfer_price}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Governorate')}</label></td>
          <td>{data?.governorate?.name}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('District')}</label></td>
          <td>{data?.district?.name}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Neighborhood')}</label></td>
          <td>{data?.neighborhood?.name}</td>
        </tr>


      </tbody>
    </table>

    <br />
    <div className={styles.ToolsButtons}>
      {isPrivileged['print_view'] && 
        <ReactToPrint
          trigger={() => <button className={styles.ToolsBarButton+' btn btn-info'} title={t('Print')}><AiFillPrinter /></button>}
          content={() => tableRef?.current}
          documentTitle={'mail_'+data?.name}
        />
      }
    </div>
    <br />
    <br />
       {generalFetchingOfferSentServiceRef?.current && <Notes id={id} isShowMessages={true} showMessage={showMessage} serviceGet={generalFetchingOfferSentServiceRef?.current?.GetNotes} serviceAdd={generalFetchingOfferSentServiceRef?.current?.AddNote} serviceDelete={generalFetchingOfferSentServiceRef?.current?.DeleteNote} isPrivileged={isPrivileged} locale={locale} />}
    <br />
    <br />
  </div>
};

ViewOfferRequest.propTypes = {};

ViewOfferRequest.defaultProps = {};

export default ViewOfferRequest;
