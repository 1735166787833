import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../Services/GeneralHelpers';
import ToolsItemsBar from '../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({dataLength, page, toolsBarRef, allRef, deletedRef, paginationRef, printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale}) => {
  const { t, i18n } = useTranslation();

  const isGeneral = page=='General';
  const isDeleted = page=='Deleted';

  const [from_expiary_date, setExpiaryFromDateFilter] = useState(null);
  const [to_expiary_date, setExpiaryToDateFilter] = useState(null);

  const [userNameFilter, setUserNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [phone1Filter, setPhone1Filter] = useState("");
  const [phone2Filter, setPhone2Filter] = useState("");

  const [companyFilter, setCompanyFilter] = useState(null);
  const [companiesData, setCompaniesData] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])

  const [governoratesFilter, setGovernoratesFilter] = useState(null);
  const [governoratesData, setGovernoratesData] = useState([])
  const [governoratesOptions, setGovernoratesOptions] = useState([])

  const [districtsFilter, setDistrictsFilter] = useState(null);
  const [districtsData, setDistrictsData] = useState([])
  const [districtsOptions, setDistrictsOptions] = useState([])

  const [neighborhoodsFilter, setNeighborhoodsFilter] = useState(null)
  const [neighborhoodsData, setNeighborhoodsData] = useState([])
  const [neighborhoodsOptions, setNeighborhoodsOptions] = useState([])

  const [isActivatedFilter, setIsActivatedFilter] = useState(null);
  const [isActivatedData, setIsActivatedData] = useState([])
  const [isActivatedOptions, setIsActivatedOptions] = useState([])

  const [isFilters, setIsFilters] = useState(false)
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      allRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
      }else{
        paginationRef?.current?.loadPages()
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('customers', selecetedItems)
    },
    showUpdateModal(){
      if(isGeneral){
        allRef?.current?.showUpdateModal()
      }
    }
  }))

  const handleChangeEmail = (e) => {
    setEmailFilter(e.target.value)
  }
  const handleChangeUserName = (e) => {
    setUserNameFilter(e.target.value)
  }
  const handleChangePhone1 = (e) => {
    setPhone1Filter(e.target.value)
  }
  const handleChangePhone2 = (e) => {
    setPhone2Filter(e.target.value)
  }
  const handleChangeGovernorate=(values)=>{
    const districtsOptions=getDistrictsOptions(values)
    setDistrictsOptions(districtsOptions)
    setGovernoratesFilter(values)
  }
  const handleChangeDistrict=(values)=>{
    const neighborhoodsOptions=getNeighborhoodsOptions(values)
    setNeighborhoodsOptions(neighborhoodsOptions)
    setDistrictsFilter(values)
  }
  const handleChangeNeighborhood=(value)=>{
    setNeighborhoodsFilter(value)
  }
  const handleChangeCompany = (value) => {
    setCompanyFilter(value)
  }


  const handleBlurMultiple = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }
  const handleFocusMultiple = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','')
    setTimeout(()=>{
      $(`#searchToolsBar_${identifier}`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }


  const getDistrictsOptions = (values) =>{
    if(values.length==0){
      return []
    }
    return districtsData?.filter(x => values.indexOf(x.governorate_id)!=-1).map(x => {return {'value':x.id, 'name': x.name}})
  }
  const getNeighborhoodsOptions = (values) =>{
    if(values.length==0){
      return []
    }
    return neighborhoodsData?.filter(x => values.indexOf(x.district_id)!=-1).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          const companies=data?.companies
          const companiesOptions=companies?.map(x => {return {'value':x?.id, 'name': x?.display_name}})
          setCompaniesData(companies)
          setCompaniesOptions(companiesOptions)

          const governorates=data?.governorates
          const governoratesOptions=governorates?.map(x => {return {'value':x.id, 'name': x.name}})
          setGovernoratesData(governorates)
          setGovernoratesOptions(governoratesOptions)

          const districts=data?.districts
          setDistrictsData(districts)

          let neighborhoods=data?.neighborhoods
          setNeighborhoodsData(neighborhoods)

          const isActivated=['True', 'False']
          const isActivatedOptions=isActivated?.map(x => {return {'value':x, 'name': t(x)}})
          setIsActivatedData(isActivated)
          setIsActivatedOptions(isActivatedOptions)
        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    const fromExpiaryDate=(filtersS && filtersS?.from_expiary_date)?filtersS?.from_expiary_date:from_expiary_date?from_expiary_date:null
    const toExpiaryDate=(filtersS && filtersS?.to_expiary_date)?filtersS?.to_expiary_date:to_expiary_date?to_expiary_date:null
    let result={
      user_name: (filtersS && filtersS?.user_name)?filtersS?.user_name:userNameFilter,
      email: (filtersS && filtersS?.email)?filtersS?.email:emailFilter,
      phone1: (filtersS && filtersS?.phone1)?filtersS?.phone1:phone1Filter,
      phone2: (filtersS && filtersS?.phone2)?filtersS?.phone2:phone2Filter,
      company_id: (filtersS && filtersS?.company_id)?filtersS?.company_id:companyFilter,
      governorates: (filtersS && filtersS?.governorates && filtersS?.governorates?.length>0)?filtersS?.governorates:governoratesFilter,
      districts: (filtersS && filtersS?.districts && filtersS?.districts?.length>0)?filtersS?.districts:districtsFilter,
      from_expiary_date: fromExpiaryDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(fromExpiaryDate)).locale('es').format('yyyy-MM-DD'):null,
      to_expiary_date: toExpiaryDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(toExpiaryDate)).locale('es').format('yyyy-MM-DD'):null,
    };
    if(isActivatedFilter){
      result={...result, is_activated: (filtersS && filtersS?.is_activated)?filtersS?.is_activated:(isActivatedFilter=='True')}
    }
    return result
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.company_id===null && filters.user_name.length===0 && filters.email.length===0 && filters.phone1.length===0 && filters.phone2.length===0 && (filters.is_activated==undefined || filters.is_activated==null) &&
      (filters.governorates===null || filters?.governorates.length==0) &&
      (filters.districts===null || filters?.districts.length==0) &&
      (filters.from_expiary_date===null && filters.to_expiary_date===null)
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setExpiaryFromDateFilter(null)
    setExpiaryToDateFilter(null)
    setUserNameFilter('')
    setEmailFilter('')
    setPhone1Filter('')
    setPhone2Filter('')
    setGovernoratesFilter([])
    setDistrictsFilter([])
    setNeighborhoodsFilter([])
    setIsActivatedFilter(null)
    setIsFilters(false)
    setDistrictsOptions([])
    setNeighborhoodsOptions([])
    setCompanyFilter(null)

    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
  }

  const loadFiltersApply = (filtersO) => {
    const email=filtersO?.email
    if(email){
      setEmailFilter(email)
    }

    const user_name=filtersO?.user_name
    if(user_name){
      setUserNameFilter(user_name)
    }

    const phone1=filtersO?.phone1
    if(phone1){
      setPhone1Filter(phone1)
    }

    const phone2=filtersO?.phone2
    if(phone2){
      setPhone2Filter(phone2)
    }

    const from_expiary_date=filtersO?.from_expiary_date
    if(from_expiary_date){
      setExpiaryFromDateFilter(new Date(from_expiary_date))
    }

    const to_expiary_date=filtersO?.to_expiary_date
    if(to_expiary_date){
      setExpiaryToDateFilter(new Date(to_expiary_date))
    }

    const is_activated=filtersO?.is_activated
    if(is_activated){
      setIsActivatedFilter(is_activated)
    }

    const governoratesIds=filtersO?.governorates
    if(governoratesIds && governoratesIds.length>0){
      handleChangeGovernorate(governoratesIds)
    }

    const districtsIds=filtersO?.districts
    if(districtsIds && districtsIds.length>0){
      setDistrictsFilter(districtsIds)
    }

    const neighborhoodsIds=filtersO?.districts
    if(neighborhoodsIds && neighborhoodsIds.length>0){
      setNeighborhoodsFilter(neighborhoodsIds)
    }

    const companyId=filtersO?.company_id
    if(companyId){
      setCompanyFilter(companyId)
    }
    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }

  const HideDropdownBoxes=()=>{
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }

  useEffect(()=>{
    getFiltersData()
    setTimeout(()=>{
      HideDropdownBoxes()
    },200)
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectCompany}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectCompany}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectMultiple}
                  options={governoratesOptions}
                  onBlur={handleBlurMultiple}
                  onFocus={handleFocusMultiple}
                  onChange={handleChangeGovernorate}
                  value={governoratesFilter}
                  placeholder={t('Governorates')}
                  search={true}
                  filterOptions={fuzzySearch}
                  multiple={true}
                  autoFocus={false}
                  />

            <SelectSearch id={styles.SelectMultiple}
                  options={districtsOptions}
                  onBlur={handleBlurMultiple}
                  onFocus={handleFocusMultiple}
                  onChange={handleChangeDistrict}
                  value={districtsFilter}
                  placeholder={t('Districts')}
                  search={true}
                  filterOptions={fuzzySearch}
                  multiple={true}
                  autoFocus={false}
                  />

            <SelectSearch id={styles.SelectMultiple}
                  options={neighborhoodsOptions}
                  onBlur={handleBlurMultiple}
                  onFocus={handleFocusMultiple}
                  onChange={handleChangeNeighborhood}
                  value={neighborhoodsFilter}
                  placeholder={t('Neighborhoods')}
                  search={true}
                  filterOptions={fuzzySearch}
                  multiple={true}
                  autoFocus={false}
                  />
          </div>
          <div className={styles.subSelectsGroup}>
            <input className={styles.SelectInput}
                    onChange={handleChangePhone1}
                    value={phone1Filter}
                    placeholder={t('Phone')+' 1'}
                    />

            <input className={styles.SelectInput}
                    onChange={handleChangePhone2}
                    value={phone2Filter}
                    placeholder={t('Phone')+' 2'}
                    />
          </div>
        </div>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
          <input className={styles.SelectInput}
                onChange={handleChangeUserName}
                value={userNameFilter}
                placeholder={t('Customer Name')}
                />
                
            <input className={styles.SelectInput}
                onChange={handleChangeEmail}
                value={emailFilter}
                placeholder={t('Email')}
                />
          </div>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectCompany}
                  onFocus={handleOnFocusSelect}
                  options={companiesOptions}
                  onChange={handleChangeCompany}
                  value={companyFilter}
                  placeholder={t('Company')}
                  search={true}
                  filterOptions={fuzzySearch}
                  />
          </div>
        </div>
        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar dataLength={dataLength} allRef={allRef} deletedRef={deletedRef} isDeleted={isDeleted} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} />
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
