import { useState, useContext, createContext } from 'react';
import { useLocalStorage } from './useLocalStorage'
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

const AuthContext = createContext(null)

export const AuthProvider = ({children}) => {
    const [user, setUser] = useLocalStorage("user", null)
    const [locale, setLocale] = useLocalStorage("locale", null)

    const login = user => {
        setUser(user)
    }

    const updateAccessToken = (accessToken) => {
        return Promise.resolve().then(()=>{
            setUser({...user, access_token:accessToken})
        })
    }

    const logout = () => {
        setUser(null)
    }

    return <AuthContext.Provider value={{ user, login, logout, updateAccessToken, locale, setLocale}}>{children}</AuthContext.Provider>
}


export const useAuth = () => {
    return useContext(AuthContext)
}

