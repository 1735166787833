import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Update.module.css';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../Common/ModalComponent/ModalComponent';
import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../Services/GeneralHelpers';
import $ from 'jquery'

const Update = ({locale, show, generalFetchingServiceRef, handleWhenHidden, handleOnConfirm, isPrivileged}) => {
  const { t, i18n } = useTranslation();

  const [fieldsErrors, setFieldsErrors] = useState([]);


  const [mailStatusData, setMailStatusData] = useState([])
  const [mailStatusOptions, setMailStatusOptions] = useState([])

  const [customersData, setCustomersData] = useState([])
  const [customersOptions, setCustomersOptions] = useState([])

  const [vendorsData, setVendorsData] = useState([])
  const [vendorsOptions, setVendorsOptions] = useState([])

  const [agentsData, setAgentsData] = useState([])
  const [agentsOptions, setAgentsOptions] = useState([])

  const [commissariesData, setCommissariesData] = useState([])
  const [commissariesOptions, setCommissariesOptions] = useState([])

  const [mail_status_id, setMailStatusId] = useState(null)
  const [company_id, setCompanyId] = useState(null)
  const [customer_id, setCustomerId] = useState(null)
  const [vendor_id, setVendorId] = useState(null)
  const [agent_id, setAgentId] = useState(null)
  const [commissary_id, setCommissaryId] = useState(null)
  const [shipping_date, setShippingDate] = useState(null);
  

  const handleChangeMailStatus=(value)=>{
    setMailStatusId(value)
  }
  const handleChangeCustomer=(value)=>{
    setCustomerId(value)
  }
  const handleChangeVendor=(value)=>{
    setVendorId(value)
  }
  const handleChangeAgent=(value)=>{
    setAgentId(value)
  }
  const handleChangeCommissary=(value)=>{
    setCommissaryId(value)
  }
  const handleChangeShippingDate=(date)=>{
    setShippingDate(date?new Date(date):null)
  }

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let mailStatus=data?.mailStatus
          let mailStatusOptions=mailStatus?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setMailStatusData(mailStatus)
          setMailStatusOptions(mailStatusOptions)

          let customers=data?.customers
          let customersOptions=customers?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setCustomersData(customers)
          setCustomersOptions(customersOptions)

          let vendors=data?.vendors
          let vendorsOptions=vendors?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setVendorsData(vendors)
          setVendorsOptions(vendorsOptions)

          let agents=data?.agents
          let agentsOptions=agents?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setAgentsData(agents)
          setAgentsOptions(agentsOptions)

          let commissaries=data?.commissaries
          let commissariesOptions=commissaries?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setCommissariesData(commissaries)
          setCommissariesOptions(commissariesOptions)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let shippingDateF=shipping_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(shipping_date)).locale("es").format('yyyy-MM-DD'):null
    let data={
      mail_status_id:mailStatusData.filter(x => x.id == parseInt(mail_status_id))[0]?.id,
      customer_id:customersData.filter(x => x.id == parseInt(customer_id))[0]?.id,
      vendor_id:vendorsData.filter(x => x.id == parseInt(vendor_id))[0]?.id,
      agent_id:agentsData.filter(x => x.id == parseInt(agent_id))[0]?.id,
      commissary_id:commissariesData.filter(x => x.id == parseInt(commissary_id))[0]?.id,
      shipping_date:shippingDateF,
    };
    return data;
  }

  const isEmptyObject = (obj) => {
    return !obj.mail_status_id && !obj.customer_id &&
    !obj.vendor_id && !obj.agent_id &&
    !obj.commissary_id && !obj.shipping_date
  }

  const resetData = () => {
    setMailStatusId(null)
    setCustomerId(null)
    setVendorId(null)
    setAgentId(null)
    setCommissaryId(null)
    setShippingDate(null)
  }

  const handleConfirm = () => {
    const obj=getObject()
    handleOnConfirm(obj, isEmptyObject(obj))
    resetData()
  }

  const handleHidden = () => {
    handleWhenHidden()
    resetData()
  }


  useEffect(()=>{
    if(show){
      getAllData()
    }
  },[show])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <ModalComponent locale={locale} show={show} handleWhenHidden={handleHidden} handleOnConfirm={handleConfirm} title={t('Update Info')} confirmBtnText={t('Update')} cancelBtnText={t('Cancel')} className={styles.Update} data-testid="Update">
    <div className={`${styles.FieldsContainer} ${locale=='ar'?'text-right':'text-left'}`}>

      {isPrivileged['update_status'] && <div className={`form-group`}>
        <label htmlFor="mail_status_id">{t('Mail Status')}</label>
        <RenderErrorMessage name='mail_status_id' messages={fieldsErrors} />
        <div>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={mailStatusOptions}
            onChange={handleChangeMailStatus}
            value={mail_status_id}
            placeholder={`${t('Select a')} ${t('mail status')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </div>
      </div>}

      {isPrivileged['update_related_people'] && <div>
        <div className={`form-group`}>
          <label htmlFor="customer_id">{t('Customer')}</label>
          <RenderErrorMessage name='customer_id' messages={fieldsErrors} />
          <div>
            <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={customersOptions}
              onChange={handleChangeCustomer}
              value={customer_id}
              placeholder={`${t('Select a')} ${t('customer')}`}
              search={true}
              filterOptions={fuzzySearch}
              />
          </div>
        </div>

        <div className={`form-group`}>
          <label htmlFor="commissary_id">{t('Commissary')}</label>
          <RenderErrorMessage name='commissary_id' messages={fieldsErrors} />
          <div>
            <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={commissariesOptions}
              onChange={handleChangeCommissary}
              value={commissary_id}
              placeholder={`${t('Select a')} ${t('commissary')}`}
              search={true}
              filterOptions={fuzzySearch}
              />
          </div>
        </div>

        <div className={`form-group`}>
          <label htmlFor="agent_id">{t('Agent')}</label>
          <RenderErrorMessage name='agent_id' messages={fieldsErrors} />
          <div>
            <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={agentsOptions}
              onChange={handleChangeAgent}
              value={agent_id}
              placeholder={`${t('Select an')} ${t('agent')}`}
              search={true}
              filterOptions={fuzzySearch}
              />
          </div>
        </div>

        <div className={`form-group`}>
          <label htmlFor="vendor_id">{t('Vendor')}</label>
          <RenderErrorMessage name='vendor_id' messages={fieldsErrors} />
          <div>
            <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={vendorsOptions}
              onChange={handleChangeVendor}
              value={vendor_id}
              placeholder={`${t('Select a')} ${t('vendor')}`}
              search={true}
              filterOptions={fuzzySearch}
              />
          </div>
        </div>
      </div>}

      {isPrivileged['update_shipping_date'] && <div className="form-group">
        <label htmlFor="shipping_date">{t('Shipping Date')}</label>
        <RenderErrorMessage name='shipping_date' messages={fieldsErrors} />
        <div>
          <DatePicker selected={shipping_date} onChange={handleChangeShippingDate} />
        </div>
      </div>}


    </div>
  </ModalComponent>
};

Update.propTypes = {};

Update.defaultProps = {};

export default Update;
