import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './AddEdit.module.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';

import { useTranslation } from 'react-i18next';

import $ from 'jquery'
import GeneralHelpers from '../../../Services/GeneralHelpers';


const AddEdit = ({id, handleAddEdit, generalFetchingServiceRef, locale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [fieldsErrors, setFieldsErrors] = useState([]);

  const [company_id, setCompanyId] = useState(null)
  const [companiesData, setCompaniesData] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])
  
  const [type, setType] = useState('')
  const [company_accountant, setCompanyAccountant] = useState("")
  const [authorized_to_expense, setAuthorizedToAct] = useState("")
  const [deducted_amount, setDeductedAmount] = useState('')
  const [returned_amount, setReturnedAmount] = useState('')
  const [total_amount, setTotalAmount] = useState('')
  const [address, setAddress] = useState("")
  const [details, setDetails] = useState("") 
  const [file, setFile] = useState(null);
  const [expense_date, setExpenseDate] = useState(null)

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let companies=data?.companies
          let companiesOptions=companies?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setCompaniesData(companies)
          setCompaniesOptions(companiesOptions)

          if(id){
            getById(id)
          }
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let expenseDateF=expense_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(expense_date)).locale("es").format('yyyy-MM-DD'):null
    let data={
      company_id:(prefix=='company')?(auth?.user?.user_info?.company?.id):(companiesData.filter(x => x.id == parseInt(company_id))[0]?.id),
      type,
      company_accountant,
      authorized_to_expense,
      deducted_amount,
      returned_amount,
      total_amount,
      address,
      details,
      file,
      expense_date:expenseDateF
    };
    return data;
  }
  const handleAddBtnClick=()=>{
    let item=getObject()
    handleAddEdit(item, setFieldsErrors, resetData)
    window.scrollTo(0, 0);
  }
  
  const handleEditBtnClick=()=>{
    let item=getObject()
    item.id=id
    handleAddEdit(item, setFieldsErrors)
    window.scrollTo(0, 0);
  }

  const resetData = () => {
    setCompanyId(null)
    setType(null)
    setCompanyAccountant('')
    setAuthorizedToAct('')
    setDeductedAmount('')
    setReturnedAmount('')
    setTotalAmount('')
    setAddress('')
    setDetails('')
    setFile(null)
    setExpenseDate(null)
  }


  const handleChangeCompany=(value)=>{
    setCompanyId(value)
  }
  const handleChangeType=(e)=>{
    setType(e.target.value)
  }

  const handleChangeCompanyAccountant=(e)=>{
    setCompanyAccountant(e.target.value)
  }
  const handleChangeAuthorizedToAct=(e)=>{
    setAuthorizedToAct(e.target.value)
  }
  const handleChangeDeductedAmount=(e)=>{
    setDeductedAmount(e.target.value)
  }
  const handleChangeReturnedAmount=(e)=>{
    setReturnedAmount(e.target.value)
  }
  const handleChangeTotalAmount=(e)=>{
    setTotalAmount(e.target.value)
  }
  const handleChangeAddress=(e)=>{
    setAddress(e.target.value)
  }
  const handleChangeDetails=(e)=>{
    setDetails(e.target.value)
  }
  const handleChangeFile=(e)=>{
    setFile(e.target.files[0])
  }
  const handleChangeExpenseDate=(date)=>{
    setExpenseDate(date?new Date(date):null)
    if(date){
      $("#ExpenseDateClearBtn").show()
    }else{
      $("#ExpenseDateClearBtn").hide()
    }
  }

  
  const setAllData=(data)=>{
    let item=data[0]
    setCompanyId(item?.company_id)
    setType(item?.type)
    setCompanyAccountant(item?.company_accountant)
    setAuthorizedToAct(item?.authorized_to_expense)
    setDeductedAmount(item?.deducted_amount)
    setReturnedAmount(item?.returned_amount)
    setTotalAmount(item?.total_amount)
    setAddress(item?.address)
    setDetails(item?.details)
    setFile(item?.file)
    setExpenseDate(item?.expense_date?new Date(item?.expense_date):null)
  }

  let isLoaded=false
  const getById = (id) => {
    generalFetchingServiceRef?.current?.getById(id)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setAllData(data)
          }
        }).catch(error => {
          console.log(error)
        })
  }
  useEffect(()=>{
    getAllData()
    $("#ExpenseDateClearBtn").hide()
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <div className={styles.AddEdit + ` ${locale=='ar'?'text-right':'text-left'}`} data-testid="AddEdit">

    {(prefix!='company') && <div className="form-group">
      <label htmlFor="company_id">{t('Company')}</label>
      <RenderErrorMessage name='company_id' messages={fieldsErrors} />
      <SelectSearch id={styles.Select}
        onFocus={handleOnFocusSelect}
        options={companiesOptions}
        onChange={handleChangeCompany}
        value={company_id}
        placeholder={`${t('Select a')} ${t('company')}`}
        search={true}
        filterOptions={fuzzySearch}
        />
    </div>}

    <div className="form-group">
      <label htmlFor="type">{t('Type')}</label>
      <RenderErrorMessage name='type' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('expense type')}`} id="type" onChange={handleChangeType} value={type} />
    </div>

    <div className="form-group">
      <label htmlFor="company_accountant">{t('Company Accountant')}</label>
      <RenderErrorMessage name='company_accountant' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('Company Accountant')}`} id="company_accountant" onChange={handleChangeCompanyAccountant} value={company_accountant} />
    </div>

    <div className="form-group">
      <label htmlFor="authorized_to_expense">{t('Authorized to expense')}</label>
      <RenderErrorMessage name='authorized_to_expense' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('Full Name')}`} id="authorized_to_expense" onChange={handleChangeAuthorizedToAct} value={authorized_to_expense} />
    </div>
       

    <div className="form-group">
      <label htmlFor="deducted_amount">{t('Deducted Amount')}</label>
      <RenderErrorMessage name='deducted_amount' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter the')} ${t('amount')}`} id="deducted_amount" onChange={handleChangeDeductedAmount} value={deducted_amount} />
    </div>

    <div className="form-group">
      <label htmlFor="returned_amount">{t('Returned Amount')}</label>
      <RenderErrorMessage name='returned_amount' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter the')} ${t('amount')}`} id="returned_amount" onChange={handleChangeReturnedAmount} value={returned_amount} />
    </div>

    <div className="form-group">
      <label htmlFor="total_amount">{t('Total Amount')}</label>
      <RenderErrorMessage name='total_amount' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter the')} ${t('amount')}`} id="total_amount" onChange={handleChangeTotalAmount} value={total_amount} />
    </div>

    <div className="form-group">
      <label htmlFor="address">{t('Address')}</label>
      <RenderErrorMessage name='address' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter an')} ${t('title')}`} id="address" autoComplete="off" onChange={handleChangeAddress} value={address} />
    </div>

    <div className="form-group">
      <label htmlFor="details">{t('Details')}</label>
      <RenderErrorMessage name='details' messages={fieldsErrors} /> <br />
      <textarea
            className={styles.TextArea}
            value={details}
            onChange={handleChangeDetails}
            ></textarea>
    </div>

    <div className="form-group">
      <label htmlFor="file">{t('Receipt File')}</label>
      <RenderErrorMessage name='file' messages={fieldsErrors} />
      <input type="file" className="form-control" placeholder={`${t('Enter a')} ${t('file')}`} 
          id="file" autoComplete="off" onChange={handleChangeFile}
          accept="image/*, application/pdf"
      />
    </div>

    <div className="form-group">
      <label htmlFor="expense_date">{t('Expense Date')}</label>
      <RenderErrorMessage name='expense_date' messages={fieldsErrors} />
      <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignContent:'flex-start'}}>
        <DatePicker selected={expense_date} onChange={handleChangeExpenseDate} />
        <button id="ExpenseDateClearBtn" className='btn btn-danger' style={{width:'200px'}} onClick={()=>{handleChangeExpenseDate(null)}}>{t('Clear Date')}</button>
      </div>
    </div>

    

    <div className="form-group text-center">
      {!id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleAddBtnClick}>{t('Add')}</button>}
      {id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleEditBtnClick}>{t('Edit')}</button>}
    </div>

  </div>
};

AddEdit.propTypes = {};

AddEdit.defaultProps = {};

export default AddEdit;
