import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Navigation.module.css';
import {Link, useLocation} from 'react-router-dom';
import { matchPath } from "react-router";
import { useAuth } from '../../Auth/AuthHelpers/Auth';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import {FaUser, FaSignOutAlt, FaLanguage} from 'react-icons/fa';
import {AiFillDashboard} from 'react-icons/ai';
import {GrLanguage} from 'react-icons/gr';
import {TbMessageLanguage} from 'react-icons/tb';
import {MdOutlineContactSupport} from 'react-icons/md';

const Navigation = ({locale, setLocale, topBarColor, openChats, prefix}) => {
  const { t, i18n } = useTranslation();

  const path = useLocation().pathname;
  const isHome = !!matchPath({path: "/",exact: true,strict: false}, path);
  const isLogin = !!matchPath({path: "/auth/login",exact: true,strict: false}, path);
  const isDashboard = !!matchPath({path: "/dashboard",exact: true,strict: false}, path);
  const isSubscribe = !!matchPath({path: "/subscribe",exact: true,strict: false}, path);
  
  const auth = useAuth();
  const navigate = useNavigate();

  const isAuth=(auth.user!==null)
  const isAdmin=(auth.user?.user_info?.role.name=="admin")

  const LogoutHandle = () => {
    auth.logout()
    navigate('/', {replace: true})
  }

  const handleChangeLanguage = (value) => {
    auth?.setLocale(value)
    i18n.changeLanguage(value)
    setLocale(value)
  }

  

  let Role=auth.user?.user_info?.role?.name
  if(Role)
    Role=Role.charAt(0).toUpperCase()+Role.slice(1)
  
  return <nav className={styles.Navigation+' navbar navbar-expand-md sticky-top navbar-dark'} dir={locale=='ar'?'rtl':'ltr'}
          style={{'backgroundColor':topBarColor}}>
    <div className="container-fluid">
      <a className="navbar-brand" href="#">{t('Sager')} <span className={styles['title-description']} dir={locale=='ar'?'rtl':'ltr'}>{isAuth?(locale=='ar'?t('Panel')+' '+t(Role):t(Role)+' '+t('Panel')):''}</span></a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarScroll">
      <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" css="--bs-scroll-height: 100px;">
      {!auth.user && (
        <li className="nav-item">
          <Link className={`nav-link ${isHome?"active":''}`} aria-current="page" to="/">{t('Home')}</Link>
        </li>
      )}
      {!auth.user && (
        <li className="nav-item">
          <Link className={`nav-link ${isSubscribe?"active":''}`} aria-current="page" to="/subscribe">{t('Subscribe')}</Link>
        </li>
      )}
      {auth.user && (
        <li className="nav-item">
          <Link className={`nav-link ${isDashboard?"active":''}`+ styles.DashboardLabel} aria-current="page" to={`/${auth.user?.user_info?.role?.name}/dashboard`}><AiFillDashboard /> {t('Dashboard')}</Link>
        </li>
      )}
        
      </ul>
      <div className={styles.CenterDivTopBar}></div>
      
      <div className="dropdown">
          <a className="dropdown-toggle" href="#" id="LanguageDropdown" role="button" data-toggle="dropdown" aria-expanded="false" title={t('Language')}>
              <FaLanguage size={30} />
          </a>

          <ul className="dropdown-menu" aria-labelledby="LanguageDropdown">
              <li>
                  <button className={`dropdown-item `+(locale=='en'?'active':'')} href="#" onClick={()=>handleChangeLanguage('en')}><GrLanguage /> English <i className="fa fa-check text-success ms-2"></i></button>
              </li>
              <li><hr className="dropdown-divider" /></li>
              <li>
                  <button className={`dropdown-item `+(locale=='ar'?'active':'')} href="#" onClick={()=>handleChangeLanguage('ar')}><TbMessageLanguage /> العربية</button>
              </li>
          </ul>
      </div>
      &nbsp;
      &nbsp;
      
      {auth.user && <table border={'0'} role={'button'}>
        <tbody className='card d-flex flex-row'>
          <tr><td className='p-1'>{openChats}</td></tr>
          <tr><td className='bg-warning p-1' title={t('Open Support Chats')}><MdOutlineContactSupport /></td></tr>
        </tbody>
      </table>}

      &nbsp;
      &nbsp;
      

      {!auth.user && (
        <span>
          <Link className={`btn btn-outline-warning ${isLogin?'active':''}`} to="/auth/login">{t('Log In')}</Link>
        </span>
      )}
      {auth.user && (<span className="dropdown">
        <button className={`btn btn-outline-info dropdown-toggle`} id="userDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <FaUser /> {t('Welcome')}, {auth?.user?.user_info?.first_name + ' ' + auth?.user?.user_info?.last_name}
        </button>
        <ul className={"dropdown-menu dropdown-menu-end"} aria-labelledby="userDropdownMenuButton">
          <li><Link className="dropdown-item" to={`/${prefix}/account_settings`}>{t('Account Settings')}</Link></li>
          <li><a className="dropdown-item" href="#" onClick={LogoutHandle}><FaSignOutAlt /> {t('Logout')}</a></li>
        </ul>
      </span>)}
      
    </div>

    </div>
  </nav>
};

Navigation.propTypes = {};

Navigation.defaultProps = {};

export default Navigation;
