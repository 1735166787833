import React, {useEffect, useState, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './Deleted.module.css';

import {FaTrashRestoreAlt} from 'react-icons/fa';
import {MdSelectAll} from 'react-icons/md';
import {MdTabUnselected} from 'react-icons/md';
import {GrFormView} from 'react-icons/gr';

import Pagination from '../../Common/Pagination/Pagination';
import $ from 'jquery';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import ToolsItemsBar from '../ToolsItemsBar/ToolsItemsBar';
import ToolsBar from '../ToolsBar/ToolsBar';
import { useTranslation } from 'react-i18next';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

const Deleted = ({page, restoreDeletedHandle, viewBtnHandle, deletedRef, generalFetchingServiceRef, isPrivileged, locale}) => {
  const { t, i18n } = useTranslation();

  const toolsBarRef = useRef();
  const paginationRef = useRef();
  const tableRef= useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name
  
  const [selecetedItems, setSelectedItems] = useState([])
  const [itemsDeleted, setItemsDeleted] = useState([])
  const [itemsLength, setItemsLength] = useState([])
  const [isPrinting, setIsPrinting] = useState(false)
  const [isSelectAllBtn, setIsSelectAllBtn] = useState(false)
  
  let isDataLoaded=false
  const selectedDataAction = () => {
    if(selecetedItems.length>0){
      toolsBarRef?.current?.setIsSelectItems(true)
    }else{
      toolsBarRef?.current?.setIsSelectItems(false)
    }
    
    if(selecetedItems.length==itemsDeleted.length){
      setIsSelectAllBtn(false)
      $("#selectAllBtn").removeClass('btn-primary')
      $("#selectAllBtn").addClass('btn-warning')
    }else{
      setIsSelectAllBtn(true)
      $("#selectAllBtn").removeClass('btn-warning')
      $("#selectAllBtn").addClass('btn-primary')
    }
  }
  useEffect(()=>{
    selectedDataAction()
    isDataLoaded=true
  },[selecetedItems, itemsDeleted])
  useEffect(()=>{
    if(isDataLoaded){
      selectedDataAction()
    }
  },[selecetedItems])

  const handleOnBeforePrint = () => {
    setIsPrinting(true)
    $('.hidableColumn').removeClass(styles.hidableColumn)
    return Promise.resolve();
  }
  const handleOnAfterPrint = () => {
    setIsPrinting(false)
    $('.hidableColumn').addClass(styles.hidableColumn)
  }

  const handleOnChangeSelect= (e) => {
    let selectedId=parseInt(e.target.getAttribute('tag'))
    let value=e.target.checked
    if(value){
      if(selecetedItems?.indexOf(selectedId)==-1){
        setSelectedItems([...selecetedItems, selectedId])
      }
    }else{
      if(selecetedItems?.indexOf(selectedId)!=-1){
        setSelectedItems(selecetedItems?.filter((x)=>x!=selectedId))
      }
    }
  }

  useImperativeHandle(deletedRef,()=>({
    setDeletedItemsLength(value){
      setItemsLength(value)
    },
    restoreSelectedItems(){
      restoreDeletedHandle(selecetedItems, paginationRef)
    },
    clearSelectedItems(){
      setSelectedItems([])
    },
    loadPages(){
      toolsBarRef?.current?.loadPages()
    }
  }))

  

  const handleSelectAllClick = () => {
    if(selecetedItems.length==itemsDeleted.length){
      $('.'+styles.Deleted).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
      setSelectedItems([])
    }else{
      $('.'+styles.Deleted).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', true)
      setSelectedItems([...itemsDeleted.map(x=>x.id)])
    }
  }

  const dataService = (links, isAll, filtersO) => generalFetchingServiceRef?.current?.getDeleted(links, isAll, filtersO)
  const filtersDataService = () => generalFetchingServiceRef?.current?.getFiltersDeletedData()

  return <div className={styles.Deleted + ""} data-testid="Deleted">
    <ToolsBar toolsBarRef={toolsBarRef} deletedRef={deletedRef} paginationRef={paginationRef} isPrivileged={isPrivileged}
      ViewItemsButtons={{'RestoreDeleted':true}} identifier='AgentDeleted' filtersDataService={filtersDataService} selecetedItems={selecetedItems}
      printComponentRef={tableRef} printDocumentTitle='Deleted_Agents' handleOnAfterPrint={handleOnAfterPrint} handleOnBeforePrint={handleOnBeforePrint}
      locale={locale} page={page} dataLength={itemsLength}
    />

    <table ref={tableRef} cellPadding="0" cellSpacing="0" border="0" className={"datatable table table-striped table-bordered table-hover"}>
      <thead>
        <tr>
          {!isPrinting && <th>
            <button id="selectAllBtn" className='btn btn-primary' onClick={handleSelectAllClick}>
              {isSelectAllBtn && <MdSelectAll id="SelectAll" />}
              {!isSelectAllBtn && <MdTabUnselected id="UnSelectAll" />}
            </button>
          </th>}
          <th>{t('Id')}</th>
          <th>{t('Company')}</th>
          <th className={styles.hidableColumn + ' hidableColumn'}>{t('Email')}</th>
          <th className={styles.hidableColumn + ' hidableColumn'}>{t('Phone')}</th>
          <th className={styles.hidableColumn + ' hidableColumn'}>{t('Governorate')}</th>
          <th className={styles.hidableColumn + ' hidableColumn'}>{t('District')}</th>
          <th>{t('Is Activated')}</th>
          <th>{t('Expiary Date')}</th>
          {!isPrinting && <th>{t('Actions')}</th>}
        </tr>
      </thead>
      <tbody>
        {itemsDeleted.map((item, id) => {
          return <tr key={item?.id}>
            {!isPrinting && <td><input type={'checkbox'} tag={item.id} name={styles.CheckBoxDataTable} className={styles.CheckBoxDataTable} onChange={handleOnChangeSelect} /></td>}
            <td>{item?.id}</td>
            <td>{item?.company?.name}</td>
            <td className={styles.hidableColumn + ' hidableColumn'}>{(item?.user?(item?.user?.email):'')}</td>
            <td className={styles.hidableColumn + ' hidableColumn'}>{(item?.user?(item?.user?.phone):'')}</td>
            <td className={styles.hidableColumn + ' hidableColumn'}>{(item?.user?(item?.user?.governorate?.name):'')}</td>
            <td className={styles.hidableColumn + ' hidableColumn'}>{(item?.user?(item?.user?.district?.name):'')}</td>
            <td>{(item?.user?(t(item?.user?.is_activated?'true':'false')):'')}</td>
            <td>{(item?.user?.expiary_date?(moment(item?.user?.expiary_date).locale(locale).format('DD/MM/yyyy h:mm:ss a')):'')}</td>
            {!isPrinting && <td>
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropDownActionsBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {t('Do an action')}
              </button>
              <div className={`dropdown-menu ${locale=='ar'?'text-right':'text-left'}`} aria-labelledby="dropDownActionsBtn" dir={locale=='ar'?'rtl':'ltr'}>
                {isPrivileged['view_item'] && <button className="dropdown-item " href="#" onClick={()=>viewBtnHandle(item.id)}><GrFormView /> {t('View')}</button>}
                {isPrivileged['restore_deleted'] && <button className="dropdown-item btn btn-danger" onClick={()=>restoreDeletedHandle([item.id], paginationRef)}><FaTrashRestoreAlt /> {t('Restore')}</button>}
              </div>
            </div>
            </td>}
          </tr>
        })}
      </tbody>
    </table>
    
    {itemsDeleted?.length == 0 && t("No items to display")}

    
    <Pagination deletedRef={deletedRef} service={dataService} data={itemsDeleted} setData={setItemsDeleted} paginationRef={paginationRef} locale={locale} />
  </div>
};

Deleted.propTypes = {};

Deleted.defaultProps = {};

export default Deleted;
