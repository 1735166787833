import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './AddEdit.module.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';

import { useTranslation } from 'react-i18next';

import $ from 'jquery'
import GeneralHelpers from '../../../Services/GeneralHelpers';


const AddEdit = ({id, handleAddEdit, generalFetchingServiceRef, locale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [fieldsErrors, setFieldsErrors] = useState([]);

  const [governoratesData, setGovernoratesData] = useState([])
  const [governoratesOptions, setGovernoratesOptions] = useState([])

  const [districtsData, setDistrictsData] = useState([])
  const [districtsOptions, setDistrictsOptions] = useState([])
  
  const [neighborhoodsData, setNeighborhoodsData] = useState([])
  const [neighborhoodsOptions, setNeighborhoodsOptions] = useState([])
  
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirm_password, setConfirmPassword] = useState("")
  const [phone, setPhone] = useState("")
  const [governorate_id, setGovernorateId] = useState(null)
  const [district_id, setDistrictId] = useState(null)
  const [neighborhood_id, setNeighborhoodId] = useState(null)
  const [address, setAddress] = useState("")
  const [is_activated, setIsActivated] = useState(false)
  const [expiary_date, setExpiaryDate] = useState("") 
  
  const [name, setName] = useState("")
  const [holder_name, setHolderName] = useState("")

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let governorates=data?.governorates
          let governoratesOptions=governorates?.map(x => {return {'value':x.id, 'name': x.name}})
          setGovernoratesData(governorates)
          setGovernoratesOptions(governoratesOptions)

          let districts=data?.districts
          setDistrictsData(districts)

          let neighborhoods=data?.neighborhoods
          setNeighborhoodsData(neighborhoods)

          if(id){
            getById(id)
          }
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let expiaryDateF=expiary_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(expiary_date)).locale("es").format('yyyy-MM-DD'):null
    let data={
      first_name,
      last_name,
      email,
      phone,
      governorate_id:governoratesData.filter(x => x.id == parseInt(governorate_id))[0]?.id,
      district_id:districtsData.filter(x => x.id == parseInt(district_id))[0]?.id,
      neighborhood_id:neighborhoodsData.filter(x => x.id == parseInt(neighborhood_id))[0]?.id,
      address,
      expiary_date:expiaryDateF,
      is_activated,

      name,
      holder_name
    };
    if(!id){
      data={...data, password, confirm_password}
    }
    return data;
  }
  const handleAddBtnClick=()=>{
    let item=getObject()
    handleAddEdit(item, setFieldsErrors, resetData)
    window.scrollTo(0, 0);
  }
  
  const handleEditBtnClick=()=>{
    let item=getObject()
    item.id=id
    handleAddEdit(item, setFieldsErrors)
    window.scrollTo(0, 0);
  }

  const resetData = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setPassword('')
    setPhone('')
    setGovernorateId(null)
    setDistrictId(null)
    setNeighborhoodId(null)
    setAddress('')
    setIsActivated(false)
    setExpiaryDate(null)
    setName('')
    setHolderName('')
    setShippingPriceInternalCenter(0)
    setShippingPriceInternalMargin(0)
    setShippingPriceOuter(0)
  }

  const getDistrictsOptions = (value) =>{
    return districtsData?.filter(x => x.governorate_id == value).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const getNeighborhoodsOptions = (value) =>{
    return neighborhoodsData?.filter(x => x.district_id == value).map(x => {return {'value':x.id, 'name': x.name}})
  }
  
  const handleChangeFirstName=(e)=>{
    setFirstName(e.target.value)
  }
  const handleChangeLastName=(e)=>{
    setLastName(e.target.value)
  }
  const handleChangeEmail=(e)=>{
    setEmail(e.target.value)
  }
  const handleChangePassword=(e)=>{
    setPassword(e.target.value)
  }
  const handleChangeConfirmPassword=(e)=>{
    setConfirmPassword(e.target.value)
  }
  const handleChangePhone=(e)=>{
    setPhone(e.target.value)
  }
  const handleChangeGovernorate=(value)=>{
    const districtsOptions=getDistrictsOptions(value)
    setDistrictsOptions(districtsOptions)
    setGovernorateId(value)
  }
  const handleChangeDistrict=(value)=>{
    const neighborhoodsOptions=getNeighborhoodsOptions(value)
    setNeighborhoodsOptions(neighborhoodsOptions)
    setDistrictId(value)
  }
  const handleChangeNeighborhood=(value)=>{
    setNeighborhoodId(value)
  }
  const handleChangeAddress=(e)=>{
    setAddress(e.target.value)
  }
  const handleChangeIsActivated=()=>{
    setIsActivated(!is_activated)
  }
  const handleChangeExpiaryDate=(date)=>{
    setExpiaryDate(date?new Date(date):null)
    if(date){
      $("#expiaryDateClearBtn").show()
    }else{
      $("#expiaryDateClearBtn").hide()
    }
  }

  const handleChangeName=(e)=>{
    setName(e.target.value)
  }
  const handleChangeHolderName=(e)=>{
    setHolderName(e.target.value)
  }

  
  const setAllData=(data)=>{
    let item=data[0]
    setFirstName(item?.user?.first_name)
    setLastName(item?.user?.last_name)
    setEmail(item?.user?.email)
    setPhone(item?.user?.phone)
    setGovernorateId(item?.user?.governorate_id)
    setDistrictId(item?.user?.district_id)
    setNeighborhoodId(item?.user?.neighborhood_id)
    setAddress(item?.user?.address)
    setIsActivated(item?.user?.is_activated)
    setExpiaryDate(item?.user?.expiary_date?new Date(item?.user?.expiary_date):null)
    setName(item?.name)
    setHolderName(item?.holder_name)
  }

  let isLoaded=false
  const getById = (id) => {
    generalFetchingServiceRef?.current?.getById(id)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setAllData(data)
          }
        }).catch(error => {
          console.log(error)
        })
  }
  useEffect(()=>{
    getAllData()
    $("#expiaryDateClearBtn").hide()
  },[])

  useEffect(()=>{
    if(!isLoaded){
      if(id){
        const districtsOptions=getDistrictsOptions(governorate_id)
        setDistrictsOptions(districtsOptions)

        const neighborhoodsOptions=getNeighborhoodsOptions(governorate_id)
        setNeighborhoodsOptions(neighborhoodsOptions)
      }
      isLoaded=true
    }
  },[governorate_id])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <div className={styles.AddEdit + ` ${locale=='ar'?'text-right':'text-left'}`} data-testid="AddEdit">
    <div className="form-group">
      <label htmlFor="name">{t('Company Name')}</label>
      <RenderErrorMessage name='name' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('name')}`} id="name" onChange={handleChangeName} value={name} />
    </div>

    <div className="form-group">
      <label htmlFor="holder_name">{t('Company Holder Name')}</label>
      <RenderErrorMessage name='holder_name' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('holder name')}`} id="holder_name" onChange={handleChangeHolderName} value={holder_name} />
    </div>

    <div className="form-group">
      <label htmlFor="first_name">{t('First Name')} &nbsp;<small className='text-info'>{t('for user who sign in to company account')}</small></label>
      <RenderErrorMessage name='first_name' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('First Name')}`} id="first_name" onChange={handleChangeFirstName} value={first_name} />
    </div>
       

    <div className="form-group">
      <label htmlFor="last_name">{t('Last Name')} &nbsp;<small className='text-info'>{t('for user who sign in to company account')}</small></label>
      <RenderErrorMessage name='last_name' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('Last Name')}`} id="last_name" onChange={handleChangeLastName} value={last_name} />
    </div>

    <div className="form-group">
      <label htmlFor="email">{t('Email')}</label>
      <RenderErrorMessage name='email' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter an')} ${t('Email')}`} id="email" autoComplete="off" onChange={handleChangeEmail} value={email} />
    </div>

    {!id && <div>
        <div className="form-group">
        <label htmlFor="password">{t('Password')}</label>
        <RenderErrorMessage name='password' messages={fieldsErrors} />
        <input type="password" className="form-control" placeholder={`${t('Enter a')} ${t('Password')}`} autoComplete="off" id="password" onChange={handleChangePassword} value={password} />
      </div>

      <div className="form-group">
        <label htmlFor="confirm_password">{`${t('Confirm')} ${t('Password')}`}</label>
        <RenderErrorMessage name='confirm_password' messages={fieldsErrors} />
        <input type="password" className="form-control" placeholder={`${t('Confirm')} ${t('Password')}`} id="confirm_password" onChange={handleChangeConfirmPassword} value={confirm_password} />
      </div>
    </div>}

    <div className="form-group">
      <label htmlFor="phone">{t('Phone')}</label>
      <RenderErrorMessage name='phone' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('Phone')}`} id="phone" onChange={handleChangePhone} value={phone} />
    </div>
     

    <div className={styles.AddressGroup+" form-group card"} style={{'padding':'10px'}}>
      <label>{t('Address Details')}</label>
      <div style={{'padding':'20px'}}>
        <RenderErrorMessage name='governorate_id' messages={fieldsErrors} />
        <RenderErrorMessage name='district_id' messages={fieldsErrors} />
        <RenderErrorMessage name='address' messages={fieldsErrors} />
        <span>
          <label htmlFor="governorate_id">{t('Governorate')}</label>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={governoratesOptions}
            onChange={handleChangeGovernorate}
            value={governorate_id}
            placeholder={`${t('Select a')} ${t('governorate')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </span><br/>
        <span>
          <label htmlFor="district_id">{t('District')}</label>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={districtsOptions}
            onChange={handleChangeDistrict}
            value={district_id}
            placeholder={`${t('Select a')} ${t('district')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </span><br/>
        <span>
          <label htmlFor="neighborhood_id">{t('Neighborhood')}</label>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={neighborhoodsOptions}
            onChange={handleChangeNeighborhood}
            value={neighborhood_id}
            placeholder={`${t('Select a')} ${t('neighborhood')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </span><br/>
        <span>
          <label htmlFor="address">{t('Address')}</label> <br/>
          <input type={'text'} value={address} onChange={handleChangeAddress} />
        </span>
      </div>
    </div>

    <div className="form-group">
      <label htmlFor="expiary_date">{t('Expiary Date')}</label>
      <RenderErrorMessage name='expiary_date' messages={fieldsErrors} />
      <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start', alignContent:'flex-start'}}>
        <DatePicker selected={expiary_date} onChange={handleChangeExpiaryDate} />
        <button id="expiaryDateClearBtn" className='btn btn-danger' style={{width:'200px'}} onClick={()=>{handleChangeExpiaryDate(null)}}>{t('Clear Date')}</button>
      </div>
    </div>

    <div className="form-group">
      <label htmlFor="is_activated">{t('Is Activated')}</label>
      <RenderErrorMessage name='is_activated' messages={fieldsErrors} />
      <div>
        <input type="checkbox" checked={is_activated} onChange={handleChangeIsActivated} />
      </div>
    </div>

    <div className="form-group text-center">
      {!id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleAddBtnClick}>{t('Add')}</button>}
      {id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleEditBtnClick}>{t('Edit')}</button>}
    </div>

  </div>
};

AddEdit.propTypes = {};

AddEdit.defaultProps = {};

export default AddEdit;
