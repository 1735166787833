import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import GeneralHelpers from '../../../../Services/GeneralHelpers';
import ToolsItemsBar from '../../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({dataLength, page, toolsBarRef, allRef, storageRef, cartRef, itemRef, deletedRef, paginationRef, printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale}) => {
  const { t, i18n } = useTranslation();

  const isStorage = page=='Storage';
  const isDeleted = page=='Deleted';
  const isCart = page=='Cart';


  const [itemNameFilter, setItemNameFilter] = useState('');

  const [storageCartFilter, setStorageCartFilter] = useState(null);
  const [storageCartsData, setStorageCartsData] = useState([])
  const [storageCartsOptions, setStorageCartsOptions] = useState([])

  const [isOutFilter, setIsOutFilter] = useState(null);
  const [isOutData, setIsOutData] = useState([])
  const [isOutOptions, setIsOutOptions] = useState([])

  const [isDamagableFilter, setIsDamagableFilter] = useState(null);
  const [isDamagableData, setIsDamagableData] = useState([])
  const [isDamagableOptions, setIsDamagableOptions] = useState([])

  const [isBreakableFilter, setIsBreakableFilter] = useState(null);
  const [isBreakableData, setIsBreakableData] = useState([])
  const [isBreakableOptions, setIsBreakableOptions] = useState([])

  const [isWrappableFilter, setIsWrappableFilter] = useState(null);
  const [isWrappableData, setIsWrappableData] = useState([])
  const [isWrappableOptions, setIsWrappableOptions] = useState([])


  const [isFilters, setIsFilters] = useState(false)
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      storageRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
      }else{
        paginationRef?.current?.loadPages()
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('Storages_Items', selecetedItems)
    },
    showUpdateModal(){

    }
  }))

  
  const handleChangeStorageCart = (value) => {
    setStorageCartFilter(value)
  }

  const handleChangeIsOut = (value) => {
    setIsOutFilter(value)
  }

  const handleChangeIsDamagable = (value) => {
    setIsDamagableFilter(value)
  }

  const handleChangeIsBreakable = (value) => {
    setIsBreakableFilter(value)
  }

  const handleChangeIsWrappable = (value) => {
    setIsWrappableFilter(value)
  }

  const handleChangeItemName = (e) => {
    setItemNameFilter(e.target.value)
  }
 
  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data


          const storageCarts=data?.storageCarts
          const storageCartsOptions=storageCarts?.map(x => {return {'value':x?.id,  'name': t('Storage')+' '+t('Id')+' ('+x?.id+')'}})
          setStorageCartsData(storageCarts)
          setStorageCartsOptions(storageCartsOptions)

          const trueFalse=['True', 'False']

          const isOutOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsOutData(trueFalse)
          setIsOutOptions(isOutOptions)
          
          const isDamagableOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsDamagableData(trueFalse)
          setIsDamagableOptions(isDamagableOptions)

          const isBreakableOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsBreakableData(trueFalse)
          setIsBreakableOptions(isBreakableOptions)

          const isWrappableOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsWrappableData(trueFalse)
          setIsWrappableOptions(isWrappableOptions)
        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    let result={
      storage_cart_id: (filtersS && filtersS?.storage_cart_id)?filtersS?.storage_cart_id:storageCartFilter,
      item_name: (filtersS && filtersS?.item_name)?filtersS?.item_name:itemNameFilter,
    }

    if(isOutFilter){
      result={...result, is_out: (filtersS && filtersS?.is_out)?filtersS?.is_out:(isOutFilter=='True')}
    }
    if(isDamagableFilter){
      result={...result, is_damagable: (filtersS && filtersS?.is_damagable)?filtersS?.is_damagable:(isDamagableFilter=='True')}
    }
    if(isBreakableFilter){
      result={...result, is_breakable: (filtersS && filtersS?.is_breakable)?filtersS?.is_breakable:(isBreakableFilter=='True')}
    }
    if(isWrappableFilter){
      result={...result, is_wrappable: (filtersS && filtersS?.is_wrappable)?filtersS?.is_wrappable:(isWrappableFilter=='True')}
    }
    return result
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.storage_cart_id===null && filters.item_name.length===0 && filters.is_out===null &&
      filters.is_damagable===null && filters.is_breakable===null && filters.is_wrappable===null 
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setItemNameFilter('')
    setStorageCartFilter(null)
    setIsOutFilter(null)
    setIsDamagableFilter(null)
    setIsBreakableFilter(null)
    setIsWrappableFilter(null)
    setIsFilters(false)

    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
  }

  const loadFiltersApply = (filtersO) => {
    const item_name=filtersO?.item_name
    if(item_name){
      setItemNameFilter(item_name)
    }

    const storage_cart_id=filtersO?.storage_cart_id
    if(storage_cart_id){
      setStorageCartFilter(storage_cart_id)
    }

    const is_out=filtersO?.is_out
    if(is_out){
      setIsOutFilter(is_out?'True':'False')
    }

    const is_damagable=filtersO?.is_damagable
    if(is_damagable){
      setIsDamagableFilter(is_damagable?'True':'False')
    }

    const is_breakable=filtersO?.is_breakable
    if(is_breakable){
      setIsBreakableFilter(is_breakable?'True':'False')
    }

    const is_wrappable=filtersO?.is_wrappable
    if(is_wrappable){
      setIsWrappableFilter(is_wrappable?'True':'False')
    }

    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }


  useEffect(()=>{
    getFiltersData()
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectVendor}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectVendor}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>

        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectVendor}
                  onFocus={handleOnFocusSelect}
                  options={storageCartsOptions}
                  onChange={handleChangeStorageCart}
                  value={storageCartFilter}
                  placeholder={t('Storage Cart')}
                  search={true}
                  filterOptions={fuzzySearch}
                  />

            <SelectSearch id={styles.SelectVendor}
                  onFocus={handleOnFocusSelect}
                  options={isOutOptions}
                  onChange={handleChangeIsOut}
                  value={isOutFilter}
                  placeholder={t('Is Out?')}
                  search={true}
                  filterOptions={fuzzySearch}
                  />
          </div>

          <div className={styles.subSelectsGroup}>
             <input className={styles.SelectInput}
                onChange={handleChangeItemName}
                value={itemNameFilter}
                placeholder={t('Item Name')}
                />

          <SelectSearch id={styles.SelectVendor}
                  onFocus={handleOnFocusSelect}
                  options={isDamagableOptions}
                  onChange={handleChangeIsDamagable}
                  value={isDamagableFilter}
                  placeholder={t('Is Damagable?')}
                  search={true}
                  filterOptions={fuzzySearch}
                  />
          </div>
        </div>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
          <SelectSearch id={styles.SelectVendor}
                  onFocus={handleOnFocusSelect}
                  options={isBreakableOptions}
                  onChange={handleChangeIsBreakable}
                  value={isBreakableFilter}
                  placeholder={t('Is Breakable?')}
                  search={true}
                  filterOptions={fuzzySearch}
                  />

          <SelectSearch id={styles.SelectVendor}
                  onFocus={handleOnFocusSelect}
                  options={isWrappableOptions}
                  onChange={handleChangeIsWrappable}
                  value={isWrappableFilter}
                  placeholder={t('Is Wrappable?')}
                  search={true}
                  filterOptions={fuzzySearch}
                  />
          </div>
        </div>
        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar dataLength={dataLength} allRef={allRef} itemRef={itemRef} storageRef={storageRef} deletedRef={deletedRef} isDeleted={isDeleted} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} />
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
