import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './OpenChat.module.css';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import { useTranslation } from 'react-i18next';

import {BiMessageRoundedAdd} from 'react-icons/bi';
import ReactToPrint from 'react-to-print';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';

const OpenChat = ({id, generalFetchingServiceRef, handleTabChange, isPrivileged, locale, showMessage}) => {
  const { t, i18n } = useTranslation();
  
  const [data, setData] = useState(null)

  const tableRef = useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name
  
  const [fieldsErrors, setFieldsErrors] = useState([]);


  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')


  const GetData = () => {
    generalFetchingServiceRef?.current?.getById(id)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            if(data?.length>0){
              setData(data[0])
            }
          }
        }).catch(error => {
          console.log(error)
        })
  }

  const handleChangeTitle = (e) => {
    setTitle(e?.target?.value)
  }


  const handleChangeMessage= (e) => {
    setMessage(e?.target?.value)
  }


  const getObject = () =>{
    let data={
      title,
      message,
    };
    return data;
  }

  const handleAddBtnClick=()=>{
    let data=getObject()
    generalFetchingServiceRef?.current?.update(data, [], 'open_chat')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data
          handleTabChange('ViewChat', data?.id);
        }else{
          showMessage(false, responseData?.message)
        }
      }).catch(error => {
        let response=error.response
        if(response.status==422){
          setFieldsErrors(response.data.errors)
        }
      })
  }
  

  useEffect(()=>{
    GetData()
  },[])

  return <div className={styles.OpenChat} data-testid="OpenChat">

    <br />
    <div className="form-group">
      <label htmlFor="data.title">{`${t('Title')}`}</label>
      <RenderErrorMessage name='data.title' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('title')}`} id="title" 
      onChange={handleChangeTitle} 
      value={title} />
    </div>

    <div className={`form-group`}>
      <label htmlFor="data.message">{t('Message')}</label>
      <RenderErrorMessage name='data.message' messages={fieldsErrors} />
      <div>
        <textarea
        className={styles.TextArea}
        value={message}
        onChange={handleChangeMessage}
        ></textarea>
      </div>
    </div>

    <div className={`form-group`}>
      {<button className='btn btn-primary' onClick={() => handleAddBtnClick()}><BiMessageRoundedAdd /> {t('Start Chat')}</button>}
    </div>


  </div>
};

OpenChat.propTypes = {};

OpenChat.defaultProps = {};

export default OpenChat;
