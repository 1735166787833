
export default class GeneralHelpers {

    static getHashVariable(variable){
        const hashParams=window.location.hash.replace("#","?")
        const urlParams = hashParams?new URLSearchParams(hashParams):null;
        let hashVar=urlParams?urlParams.get(variable):null
        return hashVar
    }

    static isHashVariableExists(variable){
        const hashParams=window.location.hash.replace("#","?")
        const urlParams = hashParams?new URLSearchParams(hashParams):null;
        return urlParams?urlParams.has('all'):false
    }

  static setHashVariable(variable,value, isEmptyAllow=false){
    const hash=window.location.hash
    if(value!==null || isEmptyAllow){
        if(!hash){
            location.hash=variable+(value && !isEmptyAllow?'='+value:'')
          }else{
            const hashParams=hash.replace("#","?")
            const urlParams = hashParams?new URLSearchParams(hashParams):null;
            const varH=urlParams?urlParams.get(variable):null
            if(varH){
              urlParams.set(variable, value)
              location.hash=urlParams.toString()
            }else{
              location.hash=location.hash+'&'+variable+'='+value
            }
          }
    }else{
        const hashParams=hash.replace("#","?")
        const urlParams = hashParams?new URLSearchParams(hashParams):null;
        if(urlParams?.has(variable)){
          urlParams.delete(variable)
          location.hash=urlParams.toString()
        }
    }
  }

  static cleanObject (obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === null) {
        delete obj[key];
      }
      if (Array.isArray(obj[key]) && obj[key]?.length==0) {
        delete obj[key];
      }
    });
    return obj
  }

  static FixDateAddCurrentTime(date){
    date=new Date(date)
    date.setHours(new Date().getHours())
    date.setMinutes(new Date().getMinutes())
    date.setSeconds(new Date().getSeconds())
    return date;
  }
}
