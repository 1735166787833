import {useRef, useImperativeHandle} from 'react';
import FetchDataService from './FetchDataService';

const TotalService = ({totalServiceRef}) => {
    const fetchDataServiceRef = useRef();

    useImperativeHandle(totalServiceRef,()=>({
        getAllData(){
            return fetchDataServiceRef?.current?.PostRequest('/total/all', null)
        },

        async getPrivileges(){
            return await fetchDataServiceRef?.current?.PostRequestAsync('/total/get_privileges', null)
        },
      }))

      return <span>
        <FetchDataService fetchDataServiceRef={fetchDataServiceRef} />
      </span>
}

export default TotalService