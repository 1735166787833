import React from 'react';
import PropTypes from 'prop-types';
import styles from './TopBar.module.css';

import { useTranslation } from 'react-i18next';

import {BiAddToQueue} from 'react-icons/bi';
import {FaRegObjectGroup} from 'react-icons/fa';
import {AiFillEdit} from 'react-icons/ai';
import {AiFillDelete} from 'react-icons/ai';
import {GrFormView} from 'react-icons/gr';
import {IoLogoBuffer} from 'react-icons/io';


const TopBar = ({handleTabChange, isPrivileged, routePage, isVisible}) => {
  const { t, i18n } = useTranslation();

  const isGeneral = routePage == 'General';
  const isAdd = routePage == 'Add';
  const isEdit = routePage == 'Edit';
  const isDeleted = routePage == 'Deleted';
  const isView = routePage == 'View';
  const isLog = routePage == 'Log';


  return <nav className={styles.TopBar + " bg-white list-group list-group-horizontal"} data-testid="TopBar">
      {isPrivileged['view_all'] && <button
          className={`list-group-item py-2 ripple ${isGeneral?"list-group-item-dark":''} TopBarButton `}
          onClick={() => handleTabChange('General')}>
        <FaRegObjectGroup />&nbsp; <span>{t('All')}</span>
      </button>}
      {isPrivileged['add'] && isVisible['Add'] && <button
          className={`list-group-item py-2 ripple ${isAdd?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('Add')}>
        <BiAddToQueue />&nbsp; <span>{t('Add')}</span>
      </button>}
      {isPrivileged['edit'] && isVisible['Edit'] && <button
          className={`list-group-item py-2 ripple ${isEdit?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('Edit')}>
        <AiFillEdit />&nbsp; <span>{t('Edit')}</span>
      </button>}
      {isPrivileged['view_deleted'] && <button
          className={`list-group-item py-2 ripple ${isDeleted?"list-group-item-dark":''} TopBarButton `}
          onClick={() => handleTabChange('Deleted')}>
        <AiFillDelete />&nbsp; <span>{t('Deleted')}</span>
      </button>}
      {isPrivileged['view_item'] && isVisible['View'] && <button
          className={`list-group-item py-2 ripple ${isView?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('View')}>
        <GrFormView />&nbsp; <span>{t('View')}</span>
      </button>}
      {isPrivileged['view_log'] && <button
          className={`list-group-item py-2 ripple ${isLog?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('Log')}>
        <IoLogoBuffer />&nbsp; <span>{t('Log')}</span>
      </button>}
  </nav>
};

TopBar.propTypes = {};

TopBar.defaultProps = {};

export default TopBar;
