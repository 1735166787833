import React from 'react';
import PropTypes from 'prop-types';
import styles from './NoPage.module.css';
import Layout from '../Layout/Layout';

const NoPage = () => (
  <Layout backgroundColor="#FFCCCB" centerVertically={true}>
    <div className={styles.NoPage}>
      <div className="alert alert-danger w-75 text-center text-muted"><h1>404</h1> Page Not Found</div>
    </div>
  </Layout>
);

NoPage.propTypes = {};

NoPage.defaultProps = {};

export default NoPage;
