import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './AddDebt.module.css';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../Common/ModalComponent/ModalComponent';
import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../Services/GeneralHelpers';
import $ from 'jquery'

const AddDebt = ({locale, show, generalFetchingServiceRef, handleWhenHidden, handleOnConfirm, isPrivileged, fieldsErrorsIn}) => {
  const { t, i18n } = useTranslation();

  const [fieldsErrors, setFieldsErrors] = useState([]);


  const [vendor_id, setVendorId] = useState(null)
  const [vendorsData, setVendorsData] = useState([])
  const [vendorsOptions, setVendorsOptions] = useState([])

  const [amount, setAmount] = useState('')
  const [lend_date, setLendDate] = useState(null)
  const [payment_date, setPaymentDate] = useState(null)
  
  const handleChangeVendorId=(value)=>{
    setVendorId(value)
  }
  const handleChangeAmount=(e)=>{
    setAmount(e.target.value)
  }
  const handleChangeLendDate=(value)=>{
    setLendDate(value)
  }
  const handleChangePaymentDate=(value)=>{
    setPaymentDate(value)
  }

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData('vendor_debts')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let vendors=data?.vendors
          let vendorsOptions=vendors?.map(x => {return {'value':x.id, 'name': x?.display_name}})
          setVendorsData(vendors)
          setVendorsOptions(vendorsOptions)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let lendDateF=lend_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(lend_date)).locale("es").format('yyyy-MM-DD'):null
    let paymentDateF=payment_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(payment_date)).locale("es").format('yyyy-MM-DD'):null
    let data={
      vendor_id,
      amount,
      lend_date:lendDateF,
      payment_date:paymentDateF
    };
    return data;
  }

  const isEmptyObject = (obj) => {
    return !obj.vendor_id && !obj.lend_date && !obj.payment_date &&
    (!obj.amount && obj.amount?.trim().length==0)
  }

  const resetData = () => {
    setVendorId(null)
    setAmount('')
    setLendDate(null)
    setPaymentDate(null)
  }

  const handleConfirm = (hideCallback) => {
    const obj=getObject()
    handleOnConfirm(obj, isEmptyObject(obj), hideCallback)
    resetData()
  }

  const handleHidden = () => {
    handleWhenHidden()
    resetData()
  }

  
  useEffect(()=>{
    if(show){
      getAllData()
    }
  },[show])

  useEffect(()=>{
    setFieldsErrors(fieldsErrorsIn)
  },[fieldsErrorsIn])
  
  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <ModalComponent locale={locale} show={show} 
    handleWhenHidden={handleHidden} handleOnConfirm={handleConfirm} title={t('Add Vendor Debt')} 
    confirmBtnText={t('Add Debt')} cancelBtnText={t('Cancel')} className={styles.AddDebt} data-testid="AddDebt"
    isDelayedConfirm={true}>
    <div className={`${styles.FieldsContainer} ${locale=='ar'?'text-right':'text-left'}`}>

      <div className={`form-group`}>
        <label htmlFor="vendor_id">{t('Vendor')}</label>
        <RenderErrorMessage name='vendor_id' messages={fieldsErrors} />
        <div>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={vendorsOptions}
            onChange={handleChangeVendorId}
            value={vendor_id}
            placeholder={`${t('Select a')} ${t('vendor')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="amount">{t('Amount')}</label>
        <RenderErrorMessage name='amount' messages={fieldsErrors} />
        <input type="number" className="form-control" placeholder={`${t('Enter an')} ${t('amount')}`} id="amount" autoComplete="off" onChange={handleChangeAmount} value={amount} />
      </div>


      <div className="form-group">
        <label htmlFor="lend_date">{t('Lend Date')}</label>
        <RenderErrorMessage name='lend_date' messages={fieldsErrors} />
        <div>
          <DatePicker selected={lend_date} onChange={handleChangeLendDate} />
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="payment_date">{t('Payment Date')}</label>
        <RenderErrorMessage name='payment_date' messages={fieldsErrors} />
        <div>
          <DatePicker selected={payment_date} onChange={handleChangePaymentDate} />
        </div>
      </div>
    </div>
  </ModalComponent>
};

AddDebt.propTypes = {};

AddDebt.defaultProps = {};

export default AddDebt;
