import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './View.module.css';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import { useTranslation } from 'react-i18next';

import {AiFillPrinter} from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import Notes from '../../Common/Notes/Notes';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

const View = ({id, generalFetchingServiceRef, handleTabChange, isPrivileged, locale}) => {
  const { t, i18n } = useTranslation();
  
  const [data, setData] = useState(null)

  const tableRef = useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name


  const GetData = () => {
    generalFetchingServiceRef?.current?.getById(id)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            if(data?.length>0){
              setData(data[0])
            }
          }
        }).catch(error => {
          console.log(error)
        })
  }

  useEffect(()=>{
    GetData()
  },[])

  return <div className={styles.View} data-testid="View">
    <table ref={tableRef} className={styles.table+' table table-bordered table-hover'}>
      <thead className='table-active'>
        <tr>
          <th scope='col' className={styles.header_label}>{t('Property')}</th>
          <th scope='col' className={styles.header_label}>{t('Value')}</th>
        </tr>
      </thead>
      <tbody>
        <tr className="form-group">
          <td><label className={styles.label}>{t('Id')}</label></td>
          <td>{data?.id}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('First Name')}</label></td>
          <td>{data?.user?.first_name}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Last Name')}</label></td>
          <td>{data?.user?.last_name}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Email')}</label></td>
          <td>{data?.user?.email}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Phone')}</label></td>
          <td>{data?.user?.phone}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Governorate')}</label></td>
          <td>{data?.user?.governorate?.name}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('District')}</label></td>
          <td>{data?.user?.district?.name}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Neighborhood')}</label></td>
          <td>{data?.user?.neighborhood?.name}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Address')}</label></td>
          <td>{data?.user?.address}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Is Activated')}</label></td>
          <td>{t(data?.user?.is_activated?'True':'False')}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Expiary Date')}</label></td>
          <td>{moment(data?.user?.expiay_date).local().locale(locale).format('DD/MM/yyyy h:mm:ss a')}</td>
        </tr>
      </tbody>
    </table>

    <br />
    <div className={styles.ToolsButtons}>
      <button className='btn btn-primary' onClick={()=>{
        handleTabChange('General')
      }}>{t('Go to all')}</button>

      {isPrivileged['print_view'] && 
        <ReactToPrint
          trigger={() => <button className={styles.ToolsBarButton+' btn btn-info'} title={t('Print')}><AiFillPrinter /></button>}
          content={() => tableRef?.current}
          documentTitle={'mail_'+data?.name}
        />
        
      }
    </div>
    <br />
    <br />
      {isPrivileged['view_notes'] && <Notes id={id} serviceGet={generalFetchingServiceRef?.current?.GetNotes} serviceAdd={generalFetchingServiceRef?.current?.AddNote} serviceDelete={generalFetchingServiceRef?.current?.DeleteNote} isPrivileged={isPrivileged} locale={locale} />}
    <br />
    <br />
  </div>
};

View.propTypes = {};

View.defaultProps = {};

export default View;
