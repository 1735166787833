import React, {useEffect, useState, useRef, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';
import styles from './Support.module.css';
import DashboardLayout from '../Dashboard/DashboardLayout/DashboardLayout';
import TopBar from './TopBar/TopBar';
import { useParams, useNavigate } from 'react-router-dom';
import All from './All/All';
import AddEdit from './AddEdit/AddEdit';
import GeneralFetchingService from '../../Services/GeneralFetchingService';

import { useTranslation } from 'react-i18next';

import $ from 'jquery';
import RenderErrorMessage from '../Common/RenderErrorMessage/RenderErrorMessage';
import RenderSuccessMessage from '../Common/RenderSuccessMessage/RenderSuccessMessage';
import { useAuth } from '../Common/Auth/AuthHelpers/Auth';

import Privileges from '../Common/Privileges/Privileges';
import Chat from './Chat/Chat';
import NoPrivilegesMessage from '../Common/NoPrivilegesMessage/NoPrivilegesMessage';
import Logs from './Logs/Logs';
import OpenChat from './OpenChat/OpenChat';
import Email from './Email/Email';
import SMS from './SMS/SMS';

const SupportComponents = ({page, locale, setLocale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name
  const navigate = useNavigate();

  const isGeneral = page=='General';
  const isAdd = page=='Add';
  const isEdit = page=='Edit';
  const isLog= page=='Log';
  const isOpenChat= page=='OpenChat';
  const isViewChat= page=='ViewChat';
  const isSendEmail= page=='SendEmail';
  const isSendSMS= page=='SendSMS';
  
  const params = useParams();
  const [routePage, setRoutePage] = useState(page)

  const [dataItems, setDataItems] = useState([])
  

  const [isPrivileges, setIsPrivileges] = useState(false)
  const [privilegesSideBar, setPrivilegesSideBar] = useState([])
  const [isPrivileged, setIsPrivileged] = useState({})
  const [isTopBarButtonsVisible, setIsTopBarButtonsVisible] = useState({'Chat':false, 'Add':true, 'Edit':false})
  const [isFetchingServiceLoaded, setIsFetchingServiceLoaded] = useState(false)
  

  const [generalErrorMessage, setGeneralErrorMessage] = useState(null);
  const [generalSuccessMessage, setGeneralSuccessMessage] = useState(null);
  const [isShownGeneralError, setIsShownGeneralError] = useState(false);
  const [isShownGeneralSuccess, setIsShownGeneralSuccess] = useState(false);
  
  const generalRef = useRef();
  const allRef = useRef();
  const generalFetchingServiceRef = useRef();
  const privilegesRef= useRef();
  const logRef= useRef();

  useImperativeHandle(generalRef,()=>({
    fetchingServiceLoaded(){
      setIsFetchingServiceLoaded(true)
    }
  }))

  const showMessage = (isSuccess, message) => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
    if(isSuccess){
      setGeneralSuccessMessage(message)
      setIsShownGeneralSuccess(true)
    }else{
      setGeneralErrorMessage(message)
      setIsShownGeneralError(true)
    }
    window.scrollTo(0, 0)
  }

  const hideMessage = () => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
  }

  const editBtnHandle = (id) => {
    handleTabChange('Edit', id)
  }

  const chatBtnHandle = (id) => {
    handleTabChange('Chat', id)
  }

  const handleAddEdit=(item, setFieldsErrors, resetData)=>{
    if(!item?.id){
      generalFetchingServiceRef?.current?.add(item)
        .then(response => {
          if(response?.response){
            response=response?.response
          }
          const responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setDataItems([...dataItems, data])
            setFieldsErrors([])
            showMessage(true, responseData?.message)
            resetData()
          }else{
            showMessage(false, responseData?.message)
          }
        }).catch(error => {
          let response=error?.response
          if(response.status==422){
            setFieldsErrors(response?.data?.errors)
          }
        })
    }else{
      generalFetchingServiceRef?.current?.edit(item)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setDataItems(dataItems.map(x => {
              if(x.id==data.id){
                return {...data}
              }
            }))
            showMessage(true, responseData?.message)
          }else{
            showMessage(false, responseData?.message)
          }
        }).catch(error => {
          console.log(error)
          let response=error.response
          if(response.status==422){
            setFieldsErrors(response.data.errors)
          }
        })
    }
    
  }

  const handleTabChange=(pageR, id=null)=>{
    if(pageR){
      switch(pageR){
        case 'General':
          navigate(`/${prefix}/dashboard/support`)
          setIsTopBarButtonsVisible({'ViewChat':false})
          break;
        case 'Log':
            navigate(`/${prefix}/dashboard/support/log`)
            setIsTopBarButtonsVisible({'ViewChat':false})
            break;
        case 'OpenChat':
          navigate(`/${prefix}/dashboard/support/open_chat`)
          setIsTopBarButtonsVisible({'ViewChat':false})
          break;
        case 'ViewChat':
          navigate(`/${prefix}/dashboard/support/view_chat/${id}`)
          setIsTopBarButtonsVisible({'ViewChat':true})
          break;
        case 'SendEmail':
          navigate(`/${prefix}/dashboard/support/send_email`)
          setIsTopBarButtonsVisible({'ViewChat':false})
          break;
        case 'SendSMS':
          navigate(`/${prefix}/dashboard/support/send_sms`)
          setIsTopBarButtonsVisible({'ViewChat':false})
          break;
      }
      setRoutePage(pageR)
    }
    hideMessage()
  }
  
  useEffect(()  => {
    if(!isFetchingServiceLoaded){
      return;
    }
    const sysEnt='support'
    privilegesRef?.current?.getPrivileges(sysEnt).then(privilegesO=>{
      if(privilegesO && privilegesO?.privileges?.length>0){
        let mapPriv = {};
        privilegesO?.privileges?.forEach(x => {
          const key=x?.replace(`${sysEnt}_`, '')
          mapPriv={...mapPriv, [key]:true}
        });
        setIsPrivileged(mapPriv)
        setIsPrivileges(true)
      }
      if(privilegesO && privilegesO?.sideBarPrivileges?.length>0){
        setPrivilegesSideBar(privilegesO?.sideBarPrivileges)
      }
    })
  }, [isFetchingServiceLoaded])

  useEffect(() => {
    loadPages()
  },[isPrivileged])

  useEffect(() => {
    loadPages()
  },[routePage])

  const loadPages = () => {
    if(isPrivileged && Object.keys(isPrivileged).length>0){
      if(isGeneral){
        allRef?.current?.loadPages()
      }
      if(isLog){
        logRef?.current?.loadPages()
      }
    }
  }


  return <DashboardLayout component='Support' privilegesSideBar={privilegesSideBar} locale={locale} setLocale={setLocale}>
    <GeneralFetchingService generalFetchingServiceRef={generalFetchingServiceRef} prefix='support' generalRef={generalRef} />
    <Privileges generalFetchingServiceRef={generalFetchingServiceRef} privilegesRef={privilegesRef}></Privileges>
    {isPrivileges && <div className={styles.Support} data-testid="Support">
      <h1>{t('Support')}</h1>
      <TopBar routePage={routePage} isPrivileged={isPrivileged} handleTabChange={handleTabChange} setRoutePage={setRoutePage} isVisible={isTopBarButtonsVisible} />
       <RenderErrorMessage name='general' messages={generalErrorMessage!=null?{'general':generalErrorMessage}:{}} alertDisplay={true} isShown={isShownGeneralError} setIsShownGeneralError={setIsShownGeneralError} isDissmiss={true} handleHideMessage={hideMessage} />
      <RenderSuccessMessage name='general' messages={generalSuccessMessage!=null?{'general':generalSuccessMessage}:{}} alertDisplay={true} isShown={isShownGeneralSuccess} setIsShownGeneralSuccess={setIsShownGeneralSuccess} isDissmiss={true} handleHideMessage={hideMessage} />
      {(isGeneral && isPrivileged['view_all']) && <All page={page} data={dataItems} chatBtnHandle={chatBtnHandle} editBtnHandle={editBtnHandle} setDataItems={setDataItems} generalFetchingServiceRef={generalFetchingServiceRef} allRef={allRef} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} handleTabChange={handleTabChange} />}
      {((isAdd && isPrivileged['add']) || (isEdit && isPrivileged['edit'])) && <AddEdit id={params.id} handleAddEdit={handleAddEdit} generalFetchingServiceRef={generalFetchingServiceRef} locale={locale} />}
      {(isOpenChat && isPrivileged['open_chat'] && (prefix=='company' || prefix=='vendor')) && <OpenChat id={params.id} generalFetchingServiceRef={generalFetchingServiceRef} handleTabChange={handleTabChange} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {(isViewChat && isPrivileged['view_chat']) && <Chat id={params.id} generalFetchingServiceRef={generalFetchingServiceRef} handleTabChange={handleTabChange} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {(isSendEmail && isPrivileged['send_email']) && <Email id={params.id} generalFetchingServiceRef={generalFetchingServiceRef} handleTabChange={handleTabChange} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {(isSendSMS && isPrivileged['send_sms']) && <SMS id={params.id} generalFetchingServiceRef={generalFetchingServiceRef} handleTabChange={handleTabChange} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {(isLog && isPrivileged['view_log']) && <Logs generalFetchingServiceRef={generalFetchingServiceRef} logRef={logRef} isPrivileged={isPrivileged} locale={locale} />}
    </div>}
    {(!isPrivileges || 
      (isGeneral && !isPrivileged['view_all']) ||
      (isAdd && !isPrivileged['add']) ||
      (isEdit && !isPrivileged['edit']) ||
      (isOpenChat && !isPrivileged['open_chat']) ||
      (isViewChat && !isPrivileged['view_chat']) ||
      (isSendEmail && !isPrivileged['send_email']) ||
      (isSendSMS && !isPrivileged['send_sms']) ||
      (isLog && !isPrivileged['view_log'])
    )&& <NoPrivilegesMessage />}
  </DashboardLayout>
};

SupportComponents.propTypes = {};

SupportComponents.defaultProps = {};

export default SupportComponents;
