import React from 'react';
import PropTypes from 'prop-types';
import styles from './TopBar.module.css';

import { useTranslation } from 'react-i18next';

import {BiAddToQueue} from 'react-icons/bi';
import {FaRegObjectGroup} from 'react-icons/fa';
import {AiFillEdit} from 'react-icons/ai';
import {AiFillDelete} from 'react-icons/ai';
import {GrFormView} from 'react-icons/gr';
import {IoLogoBuffer} from 'react-icons/io';
import {BsFillCalculatorFill} from 'react-icons/bs';
import {FcDebt} from 'react-icons/fc';


const TopBar = ({handleTabChange, isPrivileged, routePage, isVisible}) => {
  const { t, i18n } = useTranslation();

  const isGeneral = routePage == 'General';
  const isView = routePage == 'View';
  const isViewDetails = routePage == 'ViewDetails';
  const isLog = routePage == 'Log';
  const isLogAccounting = routePage == 'LogAccounting';
  const isAccounting= routePage=='Accounting';
  const isVendorsDebts= routePage=='VendorsDebts';
  
  
  return <nav className={styles.TopBar + " bg-white list-group list-group-horizontal"} data-testid="TopBar">
      {isPrivileged['view_all'] && <button
          className={`list-group-item py-2 ripple ${isGeneral?"list-group-item-dark":''} TopBarButton `}
          onClick={() => handleTabChange('General')}>
        <FaRegObjectGroup />&nbsp; <span>{t('All')}</span>
      </button>}
      {isPrivileged['view_item'] && isVisible['View'] && <button
          className={`list-group-item py-2 ripple ${isView?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('View')}>
        <GrFormView />&nbsp; <span>{t('View')}</span>
      </button>}
      {isPrivileged['manage_accounting'] && <button
          className={`list-group-item py-2 ripple ${isAccounting?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('Accounting')}>
        <BsFillCalculatorFill />&nbsp; <span>{t('Accounting')}</span>
      </button>}
      {isPrivileged['view_details'] && isVisible['ViewDetails'] && <button
          className={`list-group-item py-2 ripple ${isViewDetails?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('ViewDetails')}>
        <GrFormView />&nbsp; <span>{t('Account Details')}</span>
      </button>}
      {isPrivileged['manage_vendor_debts'] && <button
          className={`list-group-item py-2 ripple ${isVendorsDebts?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('VendorsDebts')}>
        <FcDebt />&nbsp; <span>{t('Vendors Debts')}</span>
      </button>}
      {isPrivileged['view_log'] && <button
          className={`list-group-item py-2 ripple ${isLog?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('Log')}>
        <IoLogoBuffer />&nbsp; <span>{t('Log')}</span>
      </button>}
      {isPrivileged['view_log'] && <button
          className={`list-group-item py-2 ripple ${isLogAccounting?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('LogAccounting')}>
        <IoLogoBuffer />&nbsp; <span>{t('Log Accounting')}</span>
      </button>}
  </nav>
};

TopBar.propTypes = {};

TopBar.defaultProps = {};

export default TopBar;
