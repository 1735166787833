import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../Services/GeneralHelpers';
import ToolsItemsBar from '../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({dataLength, page, toolsBarRef, allRef, deletedRef, paginationRef, printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale}) => {
  const { t, i18n } = useTranslation();

  const isGeneral = page=='General';
  const isDeleted = page=='Deleted';

  const [balanceGreaterFilter, setBalanceGreaterFilter] = useState('');
  const [balanceLowerFilter, setBalanceLowerFilter] = useState('');
  const [balanceEqualFilter, setBalanceEqualFilter] = useState('');
  const [userNameFilter, setUserNameFilter] = useState('');

  const [isActivatedFilter, setIsActivatedFilter] = useState(null);
  const [isActivatedData, setIsActivatedData] = useState([])
  const [isActivatedOptions, setIsActivatedOptions] = useState([])

  const [isFilters, setIsFilters] = useState(false)
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      allRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
      }else{
        paginationRef?.current?.loadPages()
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('financial_accounts', selecetedItems)
    },
    showUpdateModal(){
      if(isGeneral){
        allRef?.current?.showUpdateModal()
      }
    }
  }))

  const handleChangeBalanceGreater = (e) => {
    setBalanceGreaterFilter(e.target.value)
  }
  const handleChangeBalanceLower = (e) => {
    setBalanceLowerFilter(e.target.value)
  }
  const handleChangeBalanceEqual = (e) => {
    setBalanceEqualFilter(e.target.value)
  }
  const handleChangeUserName = (e) => {
    setUserNameFilter(e.target.value)
  }
  const handleChangeIsActivated = (value) => {
    setIsActivatedFilter(value)
  }
  

  const handleBlurMultiple = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }
  const handleFocusMultiple = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','')
    setTimeout(()=>{
      $(`#searchToolsBar_${identifier}`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }
  
  const getDistrictsOptions = (values) =>{
    if(values.length==0){
      return []
    }
    return districtsData?.filter(x => values.indexOf(x.governorate_id)!=-1).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          const isActivated=['True', 'False']
          const isActivatedOptions=isActivated?.map(x => {return {'value':x, 'name': t(x)}})
          setIsActivatedData(isActivated)
          setIsActivatedOptions(isActivatedOptions)
        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    let result={
      balance_greater: (filtersS && filtersS?.balance_greater)?filtersS?.balance_greater:balanceGreaterFilter,
      balance_lower: (filtersS && filtersS?.balance_lower)?filtersS?.balance_lower:balanceLowerFilter,
      balance_equal: (filtersS && filtersS?.balance_equal)?filtersS?.balance_equal:balanceEqualFilter,
      user_name: (filtersS && filtersS?.user_name)?filtersS?.user_name:userNameFilter,
    };
    result.balance_greater=result.balance_greater?parseFloat(result.balance_greater):null
    result.balance_lower=result.balance_lower?parseFloat(result.balance_lower):null
    result.balance_equal=result.balance_equal?parseFloat(result.balance_equal):null
    if(isActivatedFilter){
      result={...result, is_activated: (filtersS && filtersS?.is_activated)?filtersS?.is_activated:(isActivatedFilter=='True')}
    }
    return result
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.balance_greater && 
      filters.balance_lower && 
      filters.balance_equal && 
      filters.user_name && 
      (filters.is_activated==undefined || filters.is_activated==null)
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setBalanceGreaterFilter('')
    setBalanceLowerFilter('')
    setBalanceEqualFilter('')
    setUserNameFilter('')
    setIsActivatedFilter(null)
    setIsFilters(false)

    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
  }

  const loadFiltersApply = (filtersO) => {
    const balanceGreater=filtersO?.balance_greater
    if(balanceGreater){
      setBalanceGreaterFilter(balanceGreater)
    }

    const balanceLower=filtersO?.balance_lower
    if(balanceLower){
      setBalanceLowerFilter(balanceLower)
    }

    const balanceEqual=filtersO?.balance_equal
    if(balanceEqual){
      setBalanceEqualFilter(balanceEqual)
    }

    const userName=filtersO?.user_name
    if(userName){
      setUserNameFilter(userName)
    }

    const is_activated=filtersO?.is_activated
    if(is_activated){
      setIsActivatedFilter(is_activated)
    }

    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }

  const HideDropdownBoxes=()=>{
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }

  useEffect(()=>{
    getFiltersData()
    setTimeout(()=>{
      HideDropdownBoxes()
    },200)
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectIsActivated}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectIsActivated}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectIsActivated}
                    onFocus={handleOnFocusSelect}
                    options={isActivatedOptions}
                    onChange={handleChangeIsActivated}
                    value={isActivatedFilter}
                    placeholder={t('Is Activated')}
                    search={true}
                    filterOptions={fuzzySearch}
                    autoFocus={false}
                    />

            <input className={styles.SelectInput} type='number'
              onChange={handleChangeBalanceGreater}
              value={balanceGreaterFilter}
              placeholder={`${t('Balance')} ${t('Greater than')}`}
              />
          </div>

          <div className={styles.subSelectsGroup}>
            <input className={styles.SelectInput} type='number'
                onChange={handleChangeBalanceLower}
                value={balanceLowerFilter}
                placeholder={`${t('Balance')} ${t('Lower than')}`}
                />

            <input className={styles.SelectInput} type='number'
              onChange={handleChangeBalanceEqual}
              value={balanceEqualFilter}
              placeholder={`${t('Balance')} ${t('Equal to')}`}
              />
          </div>
        </div>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <input className={styles.SelectInput}
                onChange={handleChangeUserName}
                value={userNameFilter}
                placeholder={t('User Name')}
                />
          </div>
        </div>
        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar page={page} dataLength={dataLength} allRef={allRef} deletedRef={deletedRef} isDeleted={isDeleted} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} />
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
