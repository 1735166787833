import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './UpdateAdd.module.css';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../Common/ModalComponent/ModalComponent';
import RenderErrorMessage from '../../../Common/RenderErrorMessage/RenderErrorMessage';
import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import { useAuth } from '../../../Common/Auth/AuthHelpers/Auth';
import GeneralHelpers from '../../../../Services/GeneralHelpers';

import $ from 'jquery'

const UpdateAdd = ({id, updateOneItem, oneItemDate, isAdd, locale, show, generalFetchingServiceRef, handleWhenHidden, handleOnConfirm, isPrivileged}) => {
  const { t, i18n } = useTranslation();

  const auth=useAuth();

  const [fieldsErrors, setFieldsErrors] = useState([]);


  const [employeesData, setEmployeesData] = useState([])
  const [employeesOptions, setEmployeesOptions] = useState([])


  const [employee_name, setEmployeeName] = useState('');
  const [employee_id, setEmployeeId] = useState(null);
  const [value, setValue] = useState('');
  const [notes, setNotes] = useState('');
  const [date, setDate] = useState(isAdd?Date.now():null);
  const [isEmployee, setIsEmployee] = useState(false);
  
  const [error_message, setErrorMessage] = useState(null);

  const handleChangeEmployeeId=(value)=>{
    setEmployeeId(value)
  }
  const handleChangeNotes=(e)=>{
    setNotes(e.target.value)
  }
  const handleChangeValue=(e)=>{
    setValue(e.target.value)
  }
  const handleChangeDate=(value)=>{
    setDate(value?new Date(value):null)
  }

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData('evaluation')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let employees=data?.employees
          let employeesOptions=employees?.map(x => {return {'value':x.id, 'name': x?.display_name}})
          setEmployeesData(employees)
          setEmployeesOptions(employeesOptions)

          if(data?.employee){
            setIsEmployee(true)
            setEmployeeData(data?.employee)
          }

        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let dateF=date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(date)).locale("es").format('yyyy-MM-DD hh:mm:ss'):null
    let data={
      employee_id:parseInt(employee_id),
      notes:(notes && notes.trim().length>0)?notes:null,
      value:(value && value.trim().length>0)?value:null,
      date: dateF,
    };
    return data;
  }

  const isEmptyObject = (obj) => {
    return !obj.employee_id && !obj.value &&
    !obj.notes && !obj.date
  }

  const resetData = () => {
    setEmployeeId(null)
    setNotes('')
    setValue('')
    setDate(null)
    setFieldsErrors([])
    setErrorMessage(null)
    setEmployeeName(null)
  }

  const handleConfirm = (hideCallback) => {
    const obj=getObject()
    handleOnConfirm(obj, isEmptyObject(obj), isAdd, function(isSuccess, error, errorMessage,){
      if(!isSuccess){
        if(error){
          let response=error?.response
          if(response.status==422){
            setFieldsErrors(response?.data?.errors)
          }
        }else{
          setErrorMessage(errorMessage)
        }
        
      }else{
        resetData()
        hideCallback()
      }
      window.scrollTo(0, 0)
    })

  }

  const handleHidden = () => {
    handleWhenHidden()
    resetData()
  }

  const setEmployeeData=(data)=>{
    setEmployeeId(data?.id)
    setEmployeeName(`${data?.user?.first_name} ${data?.user?.last_name}`)
  }

  const setEvaluationData=(data)=>{
    setDate(data?.date)
    setValue(data?.value?data?.value:'')
    setNotes(data?.notes?data?.notes:'')
  }

  const getById = (id) => {
    generalFetchingServiceRef?.current?.getById(id)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setEmployeeData(data[0])
          }
        }).catch(error => {
          console.log(error)
        })
  }

  const getEvaluationById = (id) => {
    generalFetchingServiceRef?.current?.getById(id, 'evaluation')
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setEmployeeData(data[0]?.employee)
            setEvaluationData(data[0])
          }
        }).catch(error => {
          console.log(error)
        })
  }


  useEffect(()=>{
    if(show){
      getAllData()
      if(id){
        getById(id)
      }
      if(updateOneItem){
        getEvaluationById(updateOneItem)
      }
    }
  },[show])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <ModalComponent locale={locale} show={show} handleWhenHidden={handleHidden} handleOnConfirmDelayed={handleConfirm} title={`${t(isAdd?'Add':'Update')} ${t('Evaluation')}`} confirmBtnText={t(isAdd?'Add':'Update')} cancelBtnText={t('Cancel')} className={styles.Update} data-testid="Update">
    <div className={`${styles.FieldsContainer} ${locale=='ar'?'text-right':'text-left'}`}>

      <RenderErrorMessage name='error_message' messages={{error_message}} alertDisplay={true} />


      {isPrivileged['manage'] && isAdd && !id && <div className={`form-group`}>
        <label htmlFor="employee_id">{t('Employee')}</label>
        <RenderErrorMessage name='employee_id' messages={fieldsErrors} />
        <RenderErrorMessage name='data.employee_id' messages={fieldsErrors} />
        <div>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={employeesOptions}
            onChange={handleChangeEmployeeId}
            value={employee_id}
            placeholder={`${t('Select an')} ${t('employee')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </div>
      </div>}

      {!isAdd && employee_id && <div className={`form-group`}>
        <label htmlFor="employee_id">{t('Employee')}</label>
        <div className='card'>
          {employee_name}
        </div>
      </div>}


      


      {isAdd && <div className={`form-group`}>
        <label htmlFor="date">{t('Date')}</label>
        <RenderErrorMessage name='date' messages={fieldsErrors} />
        <RenderErrorMessage name='data.date' messages={fieldsErrors} />
        <DatePicker wrapperClassName={styles.datePickerWrap} maxDate={Date.now()}
              selected={date} onChange={handleChangeDate} placeholderText={t('Date')} 
              disabled={(!isEmployee && !isAdd)}
              />
      </div>}

      {!isAdd && oneItemDate && <div className={`form-group`}>
        <label htmlFor="date">{t('Date')}</label>
        <div className='card'>
          {oneItemDate && moment(oneItemDate).local().locale(locale).format('yyyy-MM-DD')}
        </div>
      </div>}

      {<div className={`form-group`}>
        <label htmlFor="value">{t('Value')}</label>
        <RenderErrorMessage name='value' messages={fieldsErrors} />
        <RenderErrorMessage name='data.value' messages={fieldsErrors} />
        <div>
          <input
          type={'number'}
          className={styles.SelectInput}
          value={value}
          onChange={handleChangeValue}
          disabled={(!isEmployee && !isAdd)}
          ></input>
        </div>
      </div>}

      {<div className={`form-group`}>
        <label htmlFor="notes">{t('Notes')}</label>
        <RenderErrorMessage name='notes' messages={fieldsErrors} />
        <RenderErrorMessage name='data.notes' messages={fieldsErrors} />
        <div>
          <textarea
          className={styles.TextArea}
          value={notes}
          onChange={handleChangeNotes}
          disabled={(!isEmployee && !isAdd)}
          ></textarea>
        </div>
      </div>}


    </div>
  </ModalComponent>
};

UpdateAdd.propTypes = {};

UpdateAdd.defaultProps = {};

export default UpdateAdd;
