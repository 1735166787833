import React from 'react';
import PropTypes from 'prop-types';
import styles from './AccountSettings.module.css';
import Layout from '../Common/Layout/Layout';
import DashboardLayout from '../Dashboard/DashboardLayout/DashboardLayout';

const AccountSettings = ({locale, setLocale}) => (
  <DashboardLayout className={styles.AccountSettings} data-testid="AccountSettings" centerVertically={true} centerHorizontally={true} locale={locale} setLocale={setLocale} isSidebar={false}>
    Account Settings
  </DashboardLayout>
  
);

AccountSettings.propTypes = {};

AccountSettings.defaultProps = {};

export default AccountSettings;
