import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../../Services/GeneralHelpers';
import ToolsItemsBar from '../../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({dataLength, page, toolsBarRef, requestsRef, deletedRef, paginationRef, printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale}) => {
  const { t, i18n } = useTranslation();

  const isManageFeatures = page=='ManageFeatures';
  const isDeleted = page=='Deleted';

  const [isFilters, setIsFilters] = useState(false);
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  const [nameFilter, setNameFilter] = useState("");
  const [detailsFilter, setDetailsFilter] = useState("");
  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      requestsRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
      }else{
        paginationRef?.current?.loadPages()
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('Subscriptions', selecetedItems)
    },
    showUpdateAddAttendanceModal(isAdd){
      if(isManageFeatures){
        requestsRef?.current?.showUpdateAddAttendanceModal(isAdd)
      }
    }
  }))


  const handleChangeName = (e) => {
    setNameFilter(e.target.value)
  }
  const handleChangeDetails = (e) => {
    setDetailsFilter(e.target.value)
  }

  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    let result={
      name: (filtersS && filtersS?.name)?filtersS?.name:nameFilter,
      details: (filtersS && filtersS?.details)?filtersS?.details:detailsFilter,
    };
    return result
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.name.length===0 && filters.details.length===0
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setNameFilter('')
    setDetailsFilter('')
    setIsFilters(false)

    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
  }

  const loadFiltersApply = (filtersO) => {
    const name=filtersO?.name
    if(name){
      setNameFilter(name)
    }

    const details=filtersO?.details
    if(details){
      setDetailsFilter(details)
    }

    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }

  useEffect(()=>{
    // getFiltersData()
  },[])



  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <input className={styles.SelectInput}
                onChange={handleChangeName}
                value={nameFilter}
                placeholder={t('Name')}
                />

            <input className={styles.SelectInput}
                onChange={handleChangeDetails}
                value={detailsFilter}
                placeholder={t('Details')}
                />
          </div>
        </div>
        
        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar page={page} dataLength={dataLength} requestsRef={requestsRef} deletedRef={deletedRef} isDeleted={isDeleted} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} />
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
