import React from 'react';
import PropTypes from 'prop-types';
import styles from './Home.module.css';
import Layout from '../Common/Layout/Layout';
import DashboardLayout from '../Dashboard/DashboardLayout/DashboardLayout';

const Home = ({locale, setLocale}) => (
  <DashboardLayout className={styles.Home} data-testid="Home" centerVertically={true} centerHorizontally={true} locale={locale} setLocale={setLocale} isSidebar={false}>
    Home
  </DashboardLayout>
  
);

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
