import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './AddEdit.module.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';

import { useTranslation } from 'react-i18next';

import $ from 'jquery'
import GeneralHelpers from '../../../Services/GeneralHelpers';

import {AiFillDelete} from 'react-icons/ai';

const AddEdit = ({id, handleAddEdit, generalFetchingServiceRef, locale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [fieldsErrors, setFieldsErrors] = useState([]);
  

  const [title, setTitle] = useState("")
  const [details, setDetails] = useState("")
  const [max_orders_number, setMaxOrdersNumber] = useState("")
  const [min_orders_number, setMinOrdersNumber] = useState("")
  const [transfer_price, setTransferPrice] = useState("")

  const [company_id, setCompanyId] = useState(null)
  const [companiesData, setCompaniesData] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])

  const [governorates, setGovernorates] = useState([])
  const [governorateO, setGovernorateO] = useState([])
  const [governoratesData, setGovernoratesData] = useState([])
  const [governoratesOptions, setGovernoratesOptions] = useState([])

  const [districts, setDistricts] = useState([])
  const [districtO, setDistrictO] = useState([])
  const [districtsData, setDistrictsData] = useState([])
  const [districtsOptions, setDistrictsOptions] = useState([])

  const [neighborhoods, setNeighborhoods] = useState([])
  const [neighborhoodO, setNeighborhoodO] = useState([])
  const [neighborhoodsData, setNeighborhoodsData] = useState([])
  const [neighborhoodsOptions, setNeighborhoodsOptions] = useState([])
  
  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let companies=data?.companies
          let companiesOptions=companies?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setCompaniesData(companies)
          setCompaniesOptions(companiesOptions)

          let governorates=data?.governorates
          let governoratesOptions=governorates?.map(x => {return {'value':x.id, 'name': x.name}})
          setGovernoratesData(governorates)
          setGovernoratesOptions(governoratesOptions)

          let districts=data?.districts
          setDistrictsData(districts)

          let neighborhoods=data?.neighborhoods
          setNeighborhoodsData(neighborhoods)

        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let data={
      company_id:(prefix=='company')?(auth?.user?.user_info?.company?.id):(companiesData.filter(x => x.id == parseInt(company_id))[0]?.id),
      title,
      details,
      max_orders_number,
      min_orders_number,
      transfer_price,
      governorates,
      districts,
      neighborhoods
    };
    return data;
  }
  const handleAddBtnClick=()=>{
    let item=getObject()
    handleAddEdit(item, setFieldsErrors, resetData)
    window.scrollTo(0, 0);
  }
  
  const handleEditBtnClick=()=>{
    let item=getObject()
    item.id=id
    handleAddEdit(item, setFieldsErrors)
    window.scrollTo(0, 0);
  }

  const getDistrictsOptions = (value) =>{
    return districtsData?.filter(x => x.governorate_id == value).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const getNeighborhoodsOptions = (value) =>{
    return neighborhoodsData?.filter(x => x.district_id == value).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const resetData = () => {
    setCompanyId(null)
    setTitle('')
    setDetails('')
    setMaxOrdersNumber('')
    setMinOrdersNumber('')
    setTransferPrice('')
    setGovernorates([])
    setDistricts([])
    setDistrictsOptions([])
    setNeighborhoods([])
    setNeighborhoodsOptions([])
    setGovernorateO(null)
    setDistrictO(null)
    setNeighborhoodO(null)
  }

  const handleChangeCompany=(value)=>{
    setCompanyId(value)
  }
  const handleChangeTitle=(e)=>{
    setTitle(e.target.value)
  }
  const handleChangeDetails=(e)=>{
    setDetails(e.target.value)
  }
  const handleChangeMaxOrdersNumber=(e)=>{
    setMaxOrdersNumber(e.target.value)
  }
  const handleChangeMinOrdersNumber=(e)=>{
    setMinOrdersNumber(e.target.value)
  }
  const handleChangeTransferPrice=(e)=>{
    setTransferPrice(e.target.value)
  }
  const handleChangeGovernorates=(value)=>{
    const districtsOptions=getDistrictsOptions(value)
    setDistrictsOptions(districtsOptions)
    setGovernorateO(value)
  }
  const handleChangeDistricts=(value)=>{
    const neighborhoodsOptions=getNeighborhoodsOptions(value)
    setNeighborhoodsOptions(neighborhoodsOptions)
    setDistrictO(value)
  }
  const handleChangeNeighborhoods=(value)=>{
    setNeighborhoodO(value)
  }
  

  
  const setAllData=(data)=>{
    let item=data
    setCompanyId(item?.company_id)
    setTitle(item?.title)
    setDetails(item?.details)
    setMaxOrdersNumber(item?.max_orders_number)
    setMinOrdersNumber(item?.min_orders_number)
    setTransferPrice(item?.transfer_price)
    setGovernorates(item?.governorates)
    setDistricts(item?.districts)
    setNeighborhoods(item?.neighborhoods)
  }

  const getById = (id) => {
    generalFetchingServiceRef?.current?.getById(id)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setAllData(data)
          }
        }).catch(error => {
          console.log(error)
        })
  }


  const addAddressToList = () => {
    if(governorateO===null || districtO===null || neighborhoodO ===null){
      return;
    }
    setGovernorates([...governorates, governorateO])
    setDistricts([...districts, districtO])
    setNeighborhoods([...neighborhoods, neighborhoodO])
  }

  const handleDeleteAddress = (id) => {
   const ind=governorates?.filter((v,i) => v==id).map((v, i)=>i)[0]
   setGovernorates(governorates.filter((v, i)=>i!=ind))
   setDistricts(districts.filter((v, i)=>i!=ind))
   setNeighborhoods(neighborhoods.filter((v, i)=>i!=ind))
  }


  
  
  useEffect(()=>{
    setGovernorateO(null)
    setDistrictO(null)
    setNeighborhoodO(null)
    setDistrictsOptions([])
    setNeighborhoodsOptions([])
  },[governorates, districts, neighborhoods])

  useEffect(()=>{
    getAllData()
    if(id){
      getById(id)
    }
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <div className={styles.AddEdit + ` ${locale=='ar'?'text-right':'text-left'}`} data-testid="AddEdit">

    {(prefix!='company') && <div className="form-group">
      <label htmlFor="company_id">{t('Company')}</label>
      <RenderErrorMessage name='company_id' messages={fieldsErrors} />
      <SelectSearch id={styles.Select}
        onFocus={handleOnFocusSelect}
        options={companiesOptions}
        onChange={handleChangeCompany}
        value={company_id}
        placeholder={`${t('Select a')} ${t('company')}`}
        search={true}
        filterOptions={fuzzySearch}
        />
    </div>}
    
    <div className="form-group">
      <label htmlFor="title">{t('Name')}</label>
      <RenderErrorMessage name='title' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('name')}`} id="title" onChange={handleChangeTitle} value={title} />
    </div>
    
    <div className="form-group">
      <label htmlFor="details">{t('Details')}</label>
      <RenderErrorMessage name='details' messages={fieldsErrors} />
      <textarea type="text" id="details" value={details} className={`form-control ${styles.TextArea}`} placeholder={`${t('Add details')}...`} onChange={handleChangeDetails} />
    </div>

    <div className="form-group">
      <label htmlFor="max_orders_number">{t('Max Orders Number')}</label>
      <RenderErrorMessage name='max_orders_number' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('number')}`} id="max_orders_number" autoComplete="off" onChange={handleChangeMaxOrdersNumber} value={max_orders_number} />
    </div>

    <div className="form-group">
      <label htmlFor="min_orders_number">{t('Min Orders Number')}</label>
      <RenderErrorMessage name='min_orders_number' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('number')}`} id="min_orders_number" autoComplete="off" onChange={handleChangeMinOrdersNumber} value={min_orders_number} />
    </div>

    <div className="form-group">
      <label htmlFor="transfer_price">{t('Transfer Price')}</label>
      <RenderErrorMessage name='transfer_price' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('number')}`} id="transfer_price" autoComplete="off" onChange={handleChangeTransferPrice} value={transfer_price} />
    </div>

    <div className={styles.AddressGroup+" form-group card"} style={{'padding':'10px'}}>
      <label>{t('Valid Addresses for Receivement')}</label>
      <div style={{'padding':'20px'}}>
        <RenderErrorMessage name='governorate_id' messages={fieldsErrors} />
        <RenderErrorMessage name='district_id' messages={fieldsErrors} />
        <RenderErrorMessage name='neighborhood_id' messages={fieldsErrors} />
        <RenderErrorMessage name='address' messages={fieldsErrors} />

        <div className={styles.AddressesList}>
          {governorates?.map((item, id) => {
            return <div key={id} className={`card bg-dark text-light ${styles.AddressListItem}`}>
              <span className={`${styles.AddressListItemInternal}`}>
                <span>{governoratesData[id]?.name}</span> &nbsp; -  &nbsp;
                <span>{districtsData[id]?.name}</span> &nbsp; -  &nbsp;
                <span>{neighborhoodsData[id]?.name}</span>
              </span>
              <span><AiFillDelete color='red' onClick={() => handleDeleteAddress(item)} /></span>
            </div>
          })}
        </div>
        <div className={`${styles.AddressesBox} card`}>
          <span>
            <label htmlFor="governorate_id">{t('Governorate')}</label>
            <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={governoratesOptions}
              onChange={handleChangeGovernorates}
              value={governorateO}
              placeholder={`${t('Select a')} ${t('governorate')}`}
              search={true}
              filterOptions={fuzzySearch}
              />
          </span><br/>
          <span>
            <label htmlFor="district_id">{t('District')}</label>
            <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={districtsOptions}
              onChange={handleChangeDistricts}
              value={districtO}
              placeholder={`${t('Select a')} ${t('district')}`}
              search={true}
              filterOptions={fuzzySearch}
              />
          </span><br/>
          <span>
            <label htmlFor="neighborhood_id">{t('Neighborhood')}</label>
            <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={neighborhoodsOptions}
              onChange={handleChangeNeighborhoods}
              value={neighborhoodO}
              placeholder={`${t('Select a')} ${t('neighborhood')}`}
              search={true}
              filterOptions={fuzzySearch}
              />
          </span>
          <br/>
          <span>
            <button className='btn btn-primary' onClick={addAddressToList}>{t('Add to list')}</button>
          </span>
          <br/>
        </div>
      </div>
    </div>

    <br/>
    <br/>
    <div className="form-group text-center">
      {!id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleAddBtnClick}>{t('Add')}</button>}
      {id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleEditBtnClick}>{t('Edit')}</button>}
    </div>

  </div>
};

AddEdit.propTypes = {};

AddEdit.defaultProps = {};

export default AddEdit;
