import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './i18n';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import NoPage from './components/Common/NoPage/NoPage';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import { AuthProvider, useAuth } from './components/Common/Auth/AuthHelpers/Auth';
import RoutesComponent from './components/Common/RoutesComponent/RoutesComponent';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="*" element={<RoutesComponent />} />
        </Routes>
      </Router>
    </AuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
