import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './SubscribeHome.module.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';

import { useTranslation } from 'react-i18next';

import $ from 'jquery'
import GeneralHelpers from '../../../Services/GeneralHelpers';
import GeneralFetchingService from '../../../Services/GeneralFetchingService';
import DashboardLayout from '../../Dashboard/DashboardLayout/DashboardLayout';
import UpdateAdd from './UpdateAdd/UpdateAdd';
import RenderSuccessMessage from '../../Common/RenderSuccessMessage/RenderSuccessMessage';


const SubscribeHome = ({locale, setLocale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [fieldsErrors, setFieldsErrors] = useState([]);

  const [generalErrorMessage, setGeneralErrorMessage] = useState(null);
  const [generalSuccessMessage, setGeneralSuccessMessage] = useState(null);
  const [isShownGeneralError, setIsShownGeneralError] = useState(false);
  const [isShownGeneralSuccess, setIsShownGeneralSuccess] = useState(false);

  const generalRef = useRef();
  const generalFetchingServiceRef = useRef();
  
  const [isFetchingServiceLoaded, setIsFetchingServiceLoaded] = useState(false)
  const [availableSubscriptions, setAvailableSubscriptions] = useState([]);
  const [total, setTotal] = useState(0);
  const [subscription_id, setSubscriptionId] = useState(null);
  const [isRequestShow, setIsRequestShow] = useState(false);
  const [isRequested, setIsRequested] = useState(false);

  const [subscription_periods, setSubscriptionPeriods] = useState([]);
  const [subscriptionPeriodData, setSubscriptionPeriodData] = useState([]);
  const [subscriptionPeriodOptions, setSubscriptionPeriodOptions] = useState([]);

  const [selectedSubscriptionIndex, setSelectedSubscriptionIndex] = useState(null);


  useImperativeHandle(generalRef,()=>({
    fetchingServiceLoaded(){
      setIsFetchingServiceLoaded(true)
    }
  }))


  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData('public_requests/available')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data
          setTotal(responseData?.total)
          setAvailableSubscriptions(data?.subscriptions)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const handleSubscribe = (subId, i) => {
    setSubscriptionId(subId)
    setIsRequestShow(true)
    setIsRequested(false)
    setSelectedSubscriptionIndex(i)
  }

  const handleWhenUpdatingHidden = () =>{
    setSubscriptionId(null)
    setIsRequestShow(false)
    setIsRequested(false)
  }

  const handleChangePeriod = (value, i) => {
    setSubscriptionPeriods(subscription_periods.map((x, ind)=> ind==i?value:x))
  }

  const handleOnUpdateRequestSubsctiptionConfirm = (subId, data, isEmpty, callback) => {
    if(isEmpty){
      return
    }
    generalFetchingServiceRef?.current?.update(data, [subId], 'public_requests/company/subscribe/request')
      .then(response => {
        let responseData=response?.data
          let message=responseData?.message
          if(responseData && responseData?.status==1){
            setIsRequested(true)
          }else{
            showMessage(false, message)
          }
          setSubscriptionId(null)
      }).catch(error => {
        callback(false, error)
      })
  }

  const showMessage = (isSuccess, message) => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
    if(isSuccess){
      setGeneralSuccessMessage(message)
      setIsShownGeneralSuccess(true)
    }else{
      setGeneralErrorMessage(message)
      setIsShownGeneralError(true)
    }
    window.scrollTo(0, 0)
  }

  const hideMessage = () => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
  }

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }
  
  useEffect(()=>{
    getAllData()
    if(prefix=='company'){
      setIsCompany(true)
    }
  },[])

  useEffect(()=>{
    if(availableSubscriptions){
      const periods=[
        {days:7},
        {days:30},
        {days:180},
        {days:365},
      ]

      const periodsOptions=periods?.map(x => {return {'value':x?.days, 'name': x?.days+' '+t('days')}})
      setSubscriptionPeriodOptions(periodsOptions)

      const amountsOfPeriods=availableSubscriptions?.map(s => [
        {days:7, amount:s?.add_amount_for_7_days},
        {days:30, amount:s?.add_amount_for_30_days},
        {days:180, amount:s?.add_amount_for_180_days},
        {days:365, amount:s?.add_amount_for_365_days},
      ])
      setSubscriptionPeriodData(amountsOfPeriods)

      setSubscriptionPeriods(amountsOfPeriods.map((x, ind) => {
        const type=availableSubscriptions?.filter((s, i) => ind == i)[0]?.payment_type?.name;
        if(type=='monthly'){
          return 30
        }
        if(type=='yearly'){
          return 365
        }
        return 0
      }))
    }
  },[availableSubscriptions])

  return <DashboardLayout component='Subscription' locale={locale} setLocale={setLocale} isSidebar={false}>
  <GeneralFetchingService generalFetchingServiceRef={generalFetchingServiceRef} prefix='subscription' generalRef={generalRef} />
  
  <UpdateAdd id={subscription_id} 
    periods={subscription_periods}
    selectedSubscriptionIndex={selectedSubscriptionIndex}
    subscriptionPeriodData={subscriptionPeriodData}
    subscriptionAmount={availableSubscriptions?.filter((x, ind)=>ind==selectedSubscriptionIndex)[0]?.price - availableSubscriptions?.filter((x, ind)=>ind==selectedSubscriptionIndex)[0]?.discount}
    locale={locale} show={isRequestShow} isRequested={isRequested} generalFetchingServiceRef={generalFetchingServiceRef} handleWhenHidden={handleWhenUpdatingHidden} handleOnConfirm={handleOnUpdateRequestSubsctiptionConfirm} />
 
  <RenderErrorMessage name='general' messages={generalErrorMessage!=null?{'general':generalErrorMessage}:{}} alertDisplay={true} isShown={isShownGeneralError} setIsShownGeneralError={setIsShownGeneralError} isDissmiss={true} handleHideMessage={hideMessage} />
  <RenderSuccessMessage name='general' messages={generalSuccessMessage!=null?{'general':generalSuccessMessage}:{}} alertDisplay={true} isShown={isShownGeneralSuccess} setIsShownGeneralSuccess={setIsShownGeneralSuccess} isDissmiss={true} handleHideMessage={hideMessage} />

  <div className={styles.Subscribe + ` ${locale=='ar'?'text-right':'text-left'} card p-5`} data-testid="Subscribe">
    {availableSubscriptions && <div className='mb-2 h4'>{`${t('Available Subscriptions')} (${total})`}</div>}
    {(availableSubscriptions) && availableSubscriptions.map((subscription, i)=>{
        const amountToAdd=subscriptionPeriodData?.filter((x, ind) => ind==i)[0]?.filter(x => x?.days==subscription_periods[i])[0]?.amount
        return <div className={`card d-flex flex-column p-2`}
        key={subscription?.id}>
          <div className='d-flex flex-row'>
            <div className='p-4 flex-fill'>
              <div><span className='font-weight-bold'>{t('Name')}</span>&nbsp;&nbsp;<span>{subscription?.name}</span></div>
              <div><span className='font-weight-bold'>{t('Payment Type')}</span>&nbsp;&nbsp;<span>{t(subscription?.payment_type?.name)}</span></div>
              <div><span className='font-weight-bold'>{t('Price')}</span>&nbsp;&nbsp;<span>{subscription?.price + (amountToAdd || 0)}</span></div>
              <div><span className='font-weight-bold'>{t('Discount')}</span>&nbsp;&nbsp;<span>{subscription?.discount}</span></div>
              <div><span className='font-weight-bold'>{t('Price after discount')}</span>&nbsp;&nbsp;<span>{(subscription?.price + (amountToAdd || 0))-subscription?.discount}</span></div>
              <div><span className='font-weight-bold'>{t('Features Limit')}</span>&nbsp;&nbsp;<span>{subscription?.features_limit}</span></div>
              <div><span className='font-weight-bold'>{t('Description')}</span>&nbsp;&nbsp;<textarea className={styles.DisplayTextArea} readOnly={true} value={subscription?.description}></textarea></div>
              <br/>
              {<div className={styles.RequestButtonContainer}>
                <button className={`btn btn-primary`} onClick={()=>handleSubscribe(subscription?.id, i)}>{t('Request Subscription')}</button>
                <SelectSearch id={styles.Select}
                  onFocus={handleOnFocusSelect}
                  menuPlacement="top"
                  options={subscriptionPeriodOptions}
                  onChange={(value)=>handleChangePeriod(value, i)}
                  value={subscription_periods[i]}
                  placeholder={`${t('Select a')} ${t('subscription period')}`}
                  search={true}
                  filterOptions={fuzzySearch}
                  />
              </div>}
            </div>
            {subscription?.features && subscription?.features?.length>0 && <div className='p-4 flex-fill'>
              <span className='card font-weight-bolder text-center p-2'>{t('Features')}</span>
              <table className='table table-stripped table-bordered text-center'>
                <thead className='thead-dark'>
                  <tr>
                  <th>{t('Feature')}</th>
                  <th>{t('Price')}</th>
                  </tr>
                </thead>
                <tbody>
                {subscription?.features?.map(feature=> {
                  return <tr key={feature?.id}>
                    <td>{feature?.name}</td>
                    <td>{feature?.price + ((amountToAdd / subscription?.features?.length) || 0)}</td>
                  </tr>
                })}
                </tbody>
              </table>
            </div>}
          </div>
        </div>
    })}
    {availableSubscriptions?.length==0 && <div>
      <br/><br/>
      <div className='w-75 text-center alert alert-info p-2 m-auto'>{t('No items to display')}</div>
    </div>}
  </div>
  </DashboardLayout>
};

SubscribeHome.propTypes = {};

SubscribeHome.defaultProps = {};

export default SubscribeHome;
