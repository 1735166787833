import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../Services/GeneralHelpers';
import ToolsItemsBar from '../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({dataLength, page, toolsBarRef, allRef, deletedRef, paginationRef, printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale}) => {
  const { t, i18n } = useTranslation();

  const isGeneral = page=='General';
  const isDeleted = page=='Deleted';

  const [isFilters, setIsFilters] = useState(false);
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  const [nameFilter, setNameFilter] = useState("");
  const [descriptionFilter, setDescriptionFilter] = useState("");

  const [rolesFilter, setRolesFilter] = useState(null);
  const [rolesData, setRolesData] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);

  const [is_enabled, setIsEnabled] = useState(null)
  const [isEnabledData, setIsEnabledData] = useState([])
  const [isEnabledOptions, setIsEnabledOptions] = useState([])

  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      allRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
      }else{
        paginationRef?.current?.loadPages()
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('Subscriptions', selecetedItems)
    },
    showUpdateModal(){
      if(isGeneral){
        allRef?.current?.showUpdateModal()
      }
    }
  }))


  const handleChangeName = (e) => {
    setNameFilter(e.target.value)
  }
  const handleChangeDescription = (e) => {
    setDescriptionFilter(e.target.value)
  }
  const handleChangeRole = (value) => {
    setRolesFilter(value)
  }
  const handleChangeIsEnabled = (value) => {
    setIsEnabled(value)
  }

  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          const roles=data?.roles
          const rolesOptions=roles?.map(x => {return {'value':x?.id, 'name': x?.name}})
          setRolesData(roles)
          setRolesOptions(rolesOptions)

          const trueFalse=['True', 'False']

          const isEnabledOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsEnabledData(trueFalse)
          setIsEnabledOptions(isEnabledOptions)

        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    let result={
      name: (filtersS && filtersS?.name)?filtersS?.name:nameFilter,
      description: (filtersS && filtersS?.description)?filtersS?.description:descriptionFilter,
      is_enabled: (filtersS && filtersS?.is_enabled)?filtersS?.is_enabled:(is_enabled?is_enabled=='True':null),
      user_role_id: (filtersS && filtersS?.user_role_id)?filtersS?.user_role_id:rolesFilter,
    };
    return result
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.name.length===0 && filters.description.length===0 &&  filters.is_enabled===null &&  filters.user_role_id===null
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setNameFilter('')
    setDescriptionFilter('')
    setIsEnabled(false)
    setIsFilters(false)

    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
  }

  const loadFiltersApply = (filtersO) => {
    const name=filtersO?.name
    if(name){
      setNameFilter(name)
    }

    const description=filtersO?.description
    if(description){
      setDescriptionFilter(description)
    }

    const is_enabled=filtersO?.is_enabled
    if(is_enabled){
      setIsEnabled(is_enabled?'True':'False')
    }

    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }

  useEffect(()=>{
    getFiltersData()
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectIsEnabled}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectIsEnabled}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectRole}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectRole}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <input className={styles.SelectInput}
                onChange={handleChangeName}
                value={nameFilter}
                placeholder={t('Name')}
                />

            <input className={styles.SelectInput}
                onChange={handleChangeDescription}
                value={descriptionFilter}
                placeholder={t('Description')}
                />
          </div>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectRole}
                      onFocus={handleOnFocusSelect}
                      options={rolesOptions}
                      onChange={handleChangeRole}
                      value={rolesFilter}
                      placeholder={t('User Role')}
                      search={true}
                      filterOptions={fuzzySearch}
                      autoFocus={false}
                      />

            <SelectSearch id={styles.SelectIsEnabled}
                        onFocus={handleOnFocusSelect}
                        options={isEnabledOptions}
                        onChange={handleChangeIsEnabled}
                        value={is_enabled}
                        placeholder={t('Is Enabled?')}
                        search={true}
                        filterOptions={fuzzySearch}
                        autoFocus={false}
                        />
          </div>
        </div>
        
        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar dataLength={dataLength} allRef={allRef} deletedRef={deletedRef} isDeleted={isDeleted} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} />
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
