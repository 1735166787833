import React, {useEffect, useState, useRef, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';
import styles from './Account.module.css';
import DashboardLayout from '../Dashboard/DashboardLayout/DashboardLayout';
import TopBar from './TopBar/TopBar';
import { useParams, useNavigate } from 'react-router-dom';
import All from './All/All';
import GeneralFetchingService from '../../Services/GeneralFetchingService';

import { useTranslation } from 'react-i18next';

import $ from 'jquery';
import RenderErrorMessage from '../Common/RenderErrorMessage/RenderErrorMessage';
import RenderSuccessMessage from '../Common/RenderSuccessMessage/RenderSuccessMessage';
import { useAuth } from '../Common/Auth/AuthHelpers/Auth';

import Privileges from '../Common/Privileges/Privileges';
import View from './View/View';
import ViewDetails from './ViewDetails/ViewDetails';
import NoPrivilegesMessage from '../Common/NoPrivilegesMessage/NoPrivilegesMessage';
import Logs from './Logs/Logs';
import Accounting from './Accounting/Accounting';
import VendorsDebts from './VendorsDebts/VendorsDebts';
import LogsAccounting from './LogsAccounting/LogsAccounting';

const AccountComponents = ({page, locale, setLocale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name
  const navigate = useNavigate();

  const isGeneral = page=='General';
  const isView = page=='View';
  const isViewDetails = page=='ViewDetails';
  const isLog= page=='Log';
  const isLogAccounting= page=='LogAccounting';
  const isAccounting= page=='Accounting';
  const isVendorsDebts= page=='VendorsDebts';

  const params = useParams();
  const [routePage, setRoutePage] = useState(page)

  const [dataItems, setDataItems] = useState([])
  

  const [isPrivileges, setIsPrivileges] = useState(false)
  const [privilegesSideBar, setPrivilegesSideBar] = useState([])
  const [isPrivileged, setIsPrivileged] = useState({})
  const [isTopBarButtonsVisible, setIsTopBarButtonsVisible] = useState({'View':false, 'ViewDetails': isViewDetails})
  const [isFetchingServiceLoaded, setIsFetchingServiceLoaded] = useState(false)
  

  const [generalErrorMessage, setGeneralErrorMessage] = useState(null);
  const [generalSuccessMessage, setGeneralSuccessMessage] = useState(null);
  const [isShownGeneralError, setIsShownGeneralError] = useState(false);
  const [isShownGeneralSuccess, setIsShownGeneralSuccess] = useState(false);
  
  const generalRef = useRef();
  const allRef = useRef();
  const viewDetailsRef = useRef();
  const generalFetchingServiceAllRef = useRef();
  const generalFetchingServiceDetailsRef = useRef();
  const generalFetchingServiceGeneralRef = useRef();
  const privilegesRef= useRef();
  const logRef= useRef();
  const logAccountingRef= useRef();
  const vendorDebtsRef = useRef();
  
  useImperativeHandle(generalRef,()=>({
    fetchingServiceLoaded(){
      setIsFetchingServiceLoaded(true)
    }
  }))

  const showMessage = (isSuccess, message) => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
    if(isSuccess){
      setGeneralSuccessMessage(message)
      setIsShownGeneralSuccess(true)
    }else{
      setGeneralErrorMessage(message)
      setIsShownGeneralError(true)
    }
    window.scrollTo(0, 0)
  }

  const hideMessage = () => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
  }

  const deleteDetailsApply = (ids, paginationRef) => {
    generalFetchingServiceDetailsRef?.current?.delete(ids)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          paginationRef?.current?.loadPages()
          showMessage(true, responseData?.message)
        }else{
          showMessage(false, responseData?.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }
  const deleteDetailsHandle=(ids, paginationRef)=>{
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t('Delete Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html(t('Do you want to delete the items?'))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      deleteApply(ids, paginationRef)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }

  const restoreDeletedDetailsApply = (ids, paginationRef) => {
    generalFetchingServiceRef?.current?.restoreDeleted(ids)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          paginationRef?.current?.loadPages()
          showMessage(true, responseData?.message)
        }else{
          showMessage(false, responseData?.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }
  const restoreDeletedDetailsHandle=(ids, paginationRef)=>{
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t('Restore Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html(t('Do you want to restore deleted items?'))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      restoreDeletedDetailsApply(ids, paginationRef)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }

  const editDetailsBtnHandle = (id) => {
    handleTabChange('EditDetail', id)
  }

  const viewBtnHandle = (id) => {
    handleTabChange('View', id)
  }

  const viewDetailsBtnHandle = (id) => {
    handleTabChange('ViewDetails', id)
  }


  const handleAddEditDetail=(item, setFieldsErrors, resetData)=>{
    if(!item?.id){
      generalFetchingServiceDetailsRef?.current?.add(item)
        .then(response => {
          if(response?.response){
            response=response?.response
          }
          const responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setDataItems([...dataItems, data])
            showMessage(true, responseData?.message)
            resetData()
            setFieldsErrors([])
          }else{
            showMessage(false, responseData?.message)
          }
        }).catch(error => {
          let response=error?.response
          if(response.status==422){
            setFieldsErrors(response?.data?.errors)
          }
        })
    }else{
      generalFetchingServiceDetailsRef?.current?.edit(item)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setDataItems(dataItems.map(x => {
              if(x.id==data.id){
                return {...data}
              }
            }))
            showMessage(true, responseData?.message)
          }else{
            showMessage(false, responseData?.message)
          }
        }).catch(error => {
          console.log(error)
          let response=error.response
          if(response.status==422){
            setFieldsErrors(response.data.errors)
          }
        })
    }
    
  }

  const handleTabChange=(pageR, id=null)=>{
    if(pageR){
      switch(pageR){
        case 'General':
          navigate(`/${prefix}/dashboard/account`)
          setIsTopBarButtonsVisible({'View':false, 'ViewDetails':false})
          break;
        case 'View':
          if(id){
            navigate(`/${prefix}/dashboard/account/view/${id}`)
          }
          setIsTopBarButtonsVisible({'View':true, 'ViewDetails':false})
          break;
        case 'ViewDetails':
          if(id){
            navigate(`/${prefix}/dashboard/account/view_details/${id}`)
          }
          setIsTopBarButtonsVisible({'View':false, 'ViewDetails':true})
          break;
        case 'Accounting':
          navigate(`/${prefix}/dashboard/account/accounting`)
          setIsTopBarButtonsVisible({'View':false, 'ViewDetails':false})
          break;
        case 'VendorsDebts':
          navigate(`/${prefix}/dashboard/account/vendors_debts`)
          setIsTopBarButtonsVisible({'View':false, 'ViewDetails':false})
          break;
        case 'Log':
          navigate(`/${prefix}/dashboard/account/log`)
          setIsTopBarButtonsVisible({'View':false, 'ViewDetails':false})
          break;
        case 'LogAccounting':
          navigate(`/${prefix}/dashboard/account/log/accounting`)
          setIsTopBarButtonsVisible({'View':false, 'ViewDetails':false})
          break;
      }
      setRoutePage(pageR)
    }
    hideMessage()
  }
  
  useEffect(()  => {
    if(!isFetchingServiceLoaded){
      return;
    }
    const sysEnt='account'
    privilegesRef?.current?.getPrivileges(sysEnt).then(privilegesO=>{
      if(privilegesO && privilegesO?.privileges?.length>0){
        let mapPriv = {};
        privilegesO?.privileges?.forEach(x => {
          const key=x?.replace(`${sysEnt}_`, '')
          mapPriv={...mapPriv, [key]:true}
        });
        setIsPrivileged(mapPriv)
        setIsPrivileges(true)
      }
      if(privilegesO && privilegesO?.sideBarPrivileges?.length>0){
        setPrivilegesSideBar(privilegesO?.sideBarPrivileges)
      }
    })
  }, [isFetchingServiceLoaded])

  useEffect(() => {
    loadPages()
  },[isPrivileged])

  useEffect(() => {
    loadPages()
  },[routePage])

  const loadPages = () => {
    if(isPrivileged && Object.keys(isPrivileged).length>0){
      if(isGeneral){
        allRef?.current?.loadPages()
      }
      if(isViewDetails){
        viewDetailsRef?.current?.loadPages()
      }
      if(isLog){
        logRef?.current?.loadPages()
      }
      if(isLogAccounting){
        logAccountingRef?.current?.loadPages()
      }
      if(isVendorsDebts){
        vendorDebtsRef?.current?.loadPages()
      }
    }
  }


  return <DashboardLayout component='Account' privilegesSideBar={privilegesSideBar} locale={locale} setLocale={setLocale}>
    <GeneralFetchingService generalFetchingServiceRef={generalFetchingServiceGeneralRef} prefix='general' generalRef={generalRef} />
    <GeneralFetchingService generalFetchingServiceRef={generalFetchingServiceAllRef} prefix='account' generalRef={generalRef} />
    <GeneralFetchingService generalFetchingServiceRef={generalFetchingServiceDetailsRef} prefix='account_details' generalRef={generalRef} />
    <Privileges generalFetchingServiceRef={generalFetchingServiceAllRef} privilegesRef={privilegesRef}></Privileges>
    {isPrivileges && <div className={styles.Account} data-testid="Account">
      <h1>{t('Financial Account')}</h1>
      <TopBar routePage={routePage} isPrivileged={isPrivileged} handleTabChange={handleTabChange} setRoutePage={setRoutePage} isVisible={isTopBarButtonsVisible} />
      <RenderErrorMessage name='general' messages={generalErrorMessage!=null?{'general':generalErrorMessage}:{}} alertDisplay={true} isShown={isShownGeneralError} setIsShownGeneralError={setIsShownGeneralError} isDissmiss={true} handleHideMessage={hideMessage} />
      <RenderSuccessMessage name='general' messages={generalSuccessMessage!=null?{'general':generalSuccessMessage}:{}} alertDisplay={true} isShown={isShownGeneralSuccess} setIsShownGeneralSuccess={setIsShownGeneralSuccess} isDissmiss={true} handleHideMessage={hideMessage} />
      {(isGeneral && isPrivileged['view_all']) && <All page={page} data={dataItems} viewBtnHandle={viewBtnHandle} viewDetailsBtnHandle={viewDetailsBtnHandle} setDataItems={setDataItems} generalFetchingServiceRef={generalFetchingServiceAllRef} allRef={allRef} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {(isView && isPrivileged['view_item']) && <View id={params.id} generalFetchingServiceRef={generalFetchingServiceAllRef} handleTabChange={handleTabChange} isPrivileged={isPrivileged} locale={locale} />}
      {(isViewDetails && isPrivileged['view_details']) && <ViewDetails data={dataItems} viewDetailsRef={viewDetailsRef} id={params.id} editBtnHandle={editDetailsBtnHandle} setDataItems={setDataItems} deleteHandle={deleteDetailsHandle} generalFetchingServiceRef={generalFetchingServiceDetailsRef} handleTabChange={handleTabChange} isPrivileged={isPrivileged} locale={locale} />}
      {(isAccounting && isPrivileged['manage_accounting']) && <Accounting  page={page} data={dataItems} viewDetailsBtnHandle={viewDetailsBtnHandle} setDataItems={setDataItems} generalFetchingServiceRef={generalFetchingServiceAllRef} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} generalFetchingServiceGeneralRef={generalFetchingServiceGeneralRef} />}
      {(isVendorsDebts && isPrivileged['manage_vendor_debts']) && <VendorsDebts page={page} data={dataItems} setDataItems={setDataItems} generalFetchingServiceRef={generalFetchingServiceAllRef} vendorDebtsRef={vendorDebtsRef} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {(isLog && isPrivileged['view_log']) && <Logs generalFetchingServiceRef={generalFetchingServiceAllRef} logRef={logRef} isPrivileged={isPrivileged} locale={locale} />}
      {(isLogAccounting && isPrivileged['view_log']) && <LogsAccounting generalFetchingServiceRef={generalFetchingServiceAllRef} logRef={logAccountingRef} isPrivileged={isPrivileged} locale={locale} />}
    </div>}
    {(!isPrivileges || 
      (isGeneral && !isPrivileged['view_all']) ||
      (isView && !isPrivileged['view_item']) ||
      (isViewDetails && !isPrivileged['view_details']) ||
      (isLog && !isPrivileged['view_log']) ||
      (isLogAccounting && !isPrivileged['view_log'])
    )&& <NoPrivilegesMessage />}
  </DashboardLayout>
};

AccountComponents.propTypes = {};

AccountComponents.defaultProps = {};

export default AccountComponents;
