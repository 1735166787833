import React from 'react';
import PropTypes from 'prop-types';
import styles from './TopBar.module.css';

import { useTranslation } from 'react-i18next';

import {FaRegObjectGroup} from 'react-icons/fa';
import {BiMessageAltAdd} from 'react-icons/bi';
import {AiFillDelete} from 'react-icons/ai';
import {GrFormView} from 'react-icons/gr';
import {IoLogoBuffer} from 'react-icons/io';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';


const TopBar = ({handleTabChange, isPrivileged, routePage, isVisible}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const isGeneral = routePage == 'General';
  const isAdd = routePage == 'Add';
  const isEdit = routePage == 'Edit';
  const isDeleted = routePage == 'Deleted';
  const isView = routePage == 'View';
  const isLog = routePage == 'Log';
  const isOpenChat = routePage == 'OpenChat';
  const isViewChat = routePage == 'ViewChat';
  const isSendEmail = routePage == 'SendEmail';
  const isSendSMS = routePage == 'SendSMS';

  
  return <nav className={styles.TopBar + " bg-white list-group list-group-horizontal"} data-testid="TopBar">
      {isPrivileged['view_all'] && <button
          className={`list-group-item py-2 ripple ${isGeneral?"list-group-item-dark":''} TopBarButton `}
          onClick={() => handleTabChange('General')}>
        <FaRegObjectGroup />&nbsp; <span>{t('Chats')}</span>
      </button>}
      {(isPrivileged['open_chat'] && (prefix=='company' || prefix=='vendor')) && <button
          className={`list-group-item py-2 ripple ${isOpenChat?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('OpenChat')}>
        <BiMessageAltAdd />&nbsp; <span>{`${t('Start Chat')} ${t('with')} ${t(prefix=='company'?'admin':prefix=='vendor'?'the company':'')}`}</span>
      </button>}
      {isPrivileged['view_chat'] && (isVisible['ViewChat'] || isViewChat) && <button
          className={`list-group-item py-2 ripple ${isViewChat?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('ViewChat')}>
        <BiMessageAltAdd />&nbsp; <span>{`${t('View Chat')}`}</span>
      </button>}
      {(isPrivileged['send_email']) && <button
          className={`list-group-item py-2 ripple ${isSendEmail?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('SendEmail')}>
        <BiMessageAltAdd />&nbsp; <span>{t('Send Email')}</span>
      </button>}
      {(isPrivileged['send_sms']) && <button
          className={`list-group-item py-2 ripple ${isSendSMS?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('SendSMS')}>
        <BiMessageAltAdd />&nbsp; <span>{t('Send SMS')}</span>
      </button>}
      {isPrivileged['view_log'] && <button
          className={`list-group-item py-2 ripple ${isLog?"list-group-item-dark":''} TopBarButton`}
          onClick={() => handleTabChange('Log')}>
        <IoLogoBuffer />&nbsp; <span>{t('Log')}</span>
      </button>}
  </nav>
};

TopBar.propTypes = {};

TopBar.defaultProps = {};

export default TopBar;
