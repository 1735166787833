import React, {useEffect, useState, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './VendorsDebts.module.css';

import {AiFillEdit} from 'react-icons/ai';
import {AiFillDelete} from 'react-icons/ai';
import {MdSelectAll, MdTabUnselected, MdPayments} from 'react-icons/md';
import {BsReceipt} from 'react-icons/bs';
import {GiPayMoney} from 'react-icons/gi';

import Pagination from '../../Common/Pagination/Pagination';
import $ from 'jquery';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import ToolsBar from './ToolsBar/ToolsBar';
import { useTranslation } from 'react-i18next';


import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import { Link } from 'react-router-dom';
import AddDebt from '../AddDebt/AddDebt';
import PayDebt from '../PayDebt/PayDebt';
import ViewPayments from '../ViewPayments/ViewPayments';

const VendorsDebts = ({page, data, deleteHandle, setDataItems, generalFetchingServiceRef, vendorDebtsRef, isPrivileged, locale, showMessage}) => {
  const { t, i18n } = useTranslation();

  const toolsBarRef = useRef();
  const paginationRef= useRef();
  const tableRef= useRef();
  const viewPaymentsRef= useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [selecetedItems, setSelectedItems] = useState([])
  const [isSelectAllBtn, setIsSelectAllBtn] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [dataLength, setDataLength] = useState(0)

  const [isAddDebt, setIsAddDebt] = useState(false)
  const [addDebtFieldsErrors, setAddDebtFieldsErrors] = useState([])
  const [isPayDebt, setIsPayDebt] = useState(false)
  const [payDebtItem, setPayDebtItem] = useState(null)
  const [payDebtFieldsErrors, setPayDebtFieldsErrors] = useState([])

  const [isViewPayments, setIsViewPayments] = useState(false)
  const [viewPaymentsDebtItemId, setViewPaymentsDebtItemId] = useState(null)
  

  let isDataLoaded=false
  const selectedDataAction = () => {
    if(selecetedItems.length>0){
      toolsBarRef?.current?.setIsSelectItems(true)
    }else{
      toolsBarRef?.current?.setIsSelectItems(false)
    }

    if(selecetedItems?.length==data?.length){
      setIsSelectAllBtn(false)
      $("#selectAllBtn").removeClass('btn-primary')
      $("#selectAllBtn").addClass('btn-warning')
    }else{
      setIsSelectAllBtn(true)
      $("#selectAllBtn").removeClass('btn-warning')
      $("#selectAllBtn").addClass('btn-primary')
    }
  }
  useEffect(()=>{
    selectedDataAction()
    isDataLoaded=true
  },[selecetedItems, data])
  useEffect(()=>{
    if(isDataLoaded){
      selectedDataAction()
    }
  },[selecetedItems])

  const handleOnBeforePrint = () => {
    setIsPrinting(true)
    $('.hidableColumn').removeClass(styles.hidableColumn)
    return Promise.resolve();
  }
  const handleOnAfterPrint = () => {
    setIsPrinting(false)
    $('.hidableColumn').addClass(styles.hidableColumn)
  }

  const setSelectedItem = (selectedId, value) => {
    if(value){
      if(selecetedItems.indexOf(selectedId)==-1){
        setSelectedItems([...selecetedItems, selectedId])
      }
    }else{
      if(selecetedItems.indexOf(selectedId)!=-1){
        setSelectedItems(selecetedItems.filter((x)=>x!=selectedId))
      }
    }
  }
  const handleOnChangeSelect= (e) => {
    let selectedId=parseInt(e.target.getAttribute('tag'))
    let value=e.target.checked
    setSelectedItem(selectedId, value)
  }

  const handleWhenAddDebtHidden = () =>{
    setIsAddDebt(false)
  }

  const handleWhenPayDebtHidden = () =>{
    setIsPayDebt(false)
  }

  const handleWhenViewPaymentsHidden = () =>{
    setIsViewPayments(false)
  }

  const handleOnAddDebt = (data, isEmpty, hideCallback) => {
    if(isEmpty){
      return;
    }
    generalFetchingServiceRef?.current?.add(data, 'vendor_debts')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          showMessage(true, responseData?.message)
          toolsBarRef?.current?.loadPages()
        }else{
          showMessage(false, responseData?.message)
        }
        setIsAddDebt(false)
        hideCallback()
      }).catch(error => {
        console.log(error)
        let response=error.response
        if(response.status==422){
          setAddDebtFieldsErrors(response.data.errors)
        }
      })
  }

  const handleOnPayDebt = (data, isEmpty, hideCallback) => {
    if(isEmpty){
      return;
    }
    generalFetchingServiceRef?.current?.update(data, [data?.vendor_debt_id],'vendor_debts/pay')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          showMessage(true, responseData?.message)
          toolsBarRef?.current?.loadPages()
        }else{
          showMessage(false, responseData?.message)
        }
        setIsPayDebt(false)
        hideCallback()
      }).catch(error => {
        console.log(error)
        let response=error.response
        if(response.status==422){
          setPayDebtFieldsErrors(response.data.errors)
        }
      })
  }

  const deleteDebtsHandle=(ids)=>{
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t('Delete Debts Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html((selecetedItems?.length>0 || ids)?t('Do you want to delete debts for selected items?'):t('Do you want to delete debts for all items?'))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      deleteDebtsApply(ids?ids:(selecetedItems?.length?selecetedItems:null))

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }

  const deleteDebtsApply=(ids)=>{
    generalFetchingServiceRef?.current?.update(null, ids, 'vendor_debts/delete')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          showMessage(true, responseData?.message)
          toolsBarRef?.current?.loadPages()
        }else{
          showMessage(false, responseData?.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }


  const downloadVendorDebtsReceipt = (id) => {
    generalFetchingServiceRef?.current?.Download({id}, [id],'vendor_debts/download_debt_receipt')
      .then(response => {
        let responseData=response?.data
        if(responseData){
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(responseData)
          link.download = "Debt_"+id+"_Receipt.docx"
          link.click()
        }else{
          showMessage(false, responseData?.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const downloadPaymentReceipt = (id) => {
    generalFetchingServiceRef?.current?.Download({id}, [id],'vendor_debts/download_payment_receipt')
      .then(response => {
        let responseData=response?.data
        if(responseData){
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(responseData)
          link.download = "Payment_"+id+"_Receipt.docx"
          link.click()
        }else{
          showMessage(false, responseData?.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const deletePaymentHandle = (id) => {
    generalFetchingServiceRef?.current?.update({id}, [id],'vendor_debts/delete_payment')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          showMessage(true, responseData?.message)
          viewPaymentsRef?.current?.updateTable()
          toolsBarRef?.current?.loadPages()
        }else{
          showMessage(false, responseData?.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  useImperativeHandle(vendorDebtsRef,()=>({
    setDataTotalLength(value){
      setDataLength(value)
    },
    deleteSelectedItems(){
      deleteHandle(selecetedItems, paginationRef)
    },
    clearSelectedItems(){
      setSelectedItems([])
      $('.'+styles.VendorsDebts).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
    },
    loadPages(){
      toolsBarRef?.current?.loadPages()
    },
    showUpdateModal(){
      setIsUpdatingValues(true)
    },
    handleAddVendorDebt(){
      setIsAddDebt(true)
    },
    handlePayVendorDebts(item){
      setPayDebtItem(item)
      setIsPayDebt(true)
    },
    handleDeleteVendorDebts(ids){
      deleteDebtsHandle(ids)
    },
    handleDownloadVendorDebtsReceipt(id){
      downloadVendorDebtsReceipt(id)
    },
    handleViewVendorDebtsPayments(id){
      setViewPaymentsDebtItemId(id)
      setIsViewPayments(true)
    },
    handleDeletePayment(ids){
      deletePaymentHandle(ids)
    },
    handleDownloadPaymentReceipt(id){
      downloadPaymentReceipt(id)
    },
  }))

  const handleSelectAllClick = () => {
    if(selecetedItems?.length==data?.length){
      $('.'+styles.VendorsDebts).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
      setSelectedItems([])
    }else{
      $('.'+styles.VendorsDebts).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', true)
      setSelectedItems([...data?.map(x=>x.id)])
    }
  }

  

  const dataService = (links, isAll, filtersO) => generalFetchingServiceRef?.current?.getAll(links, isAll, filtersO, 'vendor_debts')
  const filtersDataService = () => generalFetchingServiceRef?.current?.getFiltersData(null, 'vendor_debts')
  const excelService = (filters, specificItems) => generalFetchingServiceRef?.current?.ExportExcel(filters, 'VendorsDebts', specificItems)


  return <div className={styles.VendorsDebts + ""} data-testid="VendorsDebts">
    <ToolsBar toolsBarRef={toolsBarRef} vendorDebtsRef={vendorDebtsRef} paginationRef={paginationRef} isPrivileged={isPrivileged}
     ViewItemsButtons={{'Delete': true}} identifier='AccountVendorsDebts' filtersDataService={filtersDataService} selecetedItems={selecetedItems}
     printComponentRef={tableRef} printDocumentTitle='Accounts' handleOnAfterPrint={handleOnAfterPrint} handleOnBeforePrint={handleOnBeforePrint}
     locale={locale} page={page} dataLength={dataLength}
     />

    <AddDebt locale={locale} isPrivileged={isPrivileged} show={isAddDebt} generalFetchingServiceRef={generalFetchingServiceRef} handleWhenHidden={handleWhenAddDebtHidden} handleOnConfirm={handleOnAddDebt} fieldsErrorsIn={addDebtFieldsErrors} />
    <PayDebt locale={locale} isPrivileged={isPrivileged} show={isPayDebt} generalFetchingServiceRef={generalFetchingServiceRef} handleWhenHidden={handleWhenPayDebtHidden} handleOnConfirm={handleOnPayDebt} fieldsErrorsIn={payDebtFieldsErrors} payDebtItem={payDebtItem} />
    <ViewPayments locale={locale} isPrivileged={isPrivileged} show={isViewPayments} generalFetchingServiceRef={generalFetchingServiceRef} handleWhenHidden={handleWhenViewPaymentsHidden} handleOnConfirm={null} payDebtItemId={viewPaymentsDebtItemId} vendorDebtsRef={vendorDebtsRef} viewPaymentsRef={viewPaymentsRef} />
  
    

    {data?.length > 0 &&
    <table id="tableToPrint" ref={tableRef} cellPadding="0" cellSpacing="0" border="0" className={"datatable table table-striped table-bordered table-hover"}>
      <thead>
        <tr>
          {!isPrinting && <th className='hideOnPrint'>
            <button id="selectAllBtn" className='btn btn-primary' onClick={handleSelectAllClick}>
              {isSelectAllBtn && <MdSelectAll id="SelectAll" />}
              {!isSelectAllBtn && <MdTabUnselected id="UnSelectAll" />}
            </button>
          </th>}
          <th>{t('Id')}</th>
          <th>{t('User Name')}</th>
          <th>{t('Amount')}</th>
          <th>{t('Lend Date')}</th>
          <th>{t('Payment Date')}</th>
          <th>{t('Is Payment Required?')}</th>
          <th>{t('Is Paid?')}</th>
          {!isPrinting && <th>{t('Actions')}</th>}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, id) => {
          return <tr key={id}>
            {!isPrinting && <td className='hideOnPrint'><input type={'checkbox'} tag={item?.id} name={styles.CheckBoxDataTable} className={styles.CheckBoxDataTable} onChange={handleOnChangeSelect} /></td>}
            <td>{item?.id}</td>
            <td><Link to={`/${prefix}/dashboard/vendor/view/${item?.vendor?.id}`}>{item?.display_name}</Link></td>
            <td>{item?.amount}</td>
            <td>{(item?.lend_date?(moment(item?.lend_date).local().locale(locale).format('DD/MM/yyyy h:mm:ss a')):'')}</td>
            <td>{(item?.payment_date?(moment(item?.payment_date).local().locale(locale).format('DD/MM/yyyy h:mm:ss a')):'')}</td>
            <td>{t(item?.is_payment_required?'true':'false')}</td>
            <td>{t(item?.is_paid?'true':'false')}</td>
            {!isPrinting && <td>
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropDownActionsBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {t('Do an action')}
              </button>
              <div className={`dropdown-menu ${locale=='ar'?'text-right':'text-left'}`} aria-labelledby="dropDownActionsBtn">
                {<button className="dropdown-item " href="#" onClick={()=>vendorDebtsRef?.current?.handleDeleteVendorDebts([item.id])}><AiFillDelete /> {t('Delete')}</button>}
                {<button className="dropdown-item " href="#" onClick={()=>vendorDebtsRef?.current?.handlePayVendorDebts(item)}><GiPayMoney /> {t('Pay')}</button>}
                {<button className="dropdown-item " href="#" onClick={()=>vendorDebtsRef?.current?.handleViewVendorDebtsPayments(item?.id)}><MdPayments /> {t('View Payments')}</button>}
                {<button className="dropdown-item " href="#" onClick={()=>vendorDebtsRef?.current?.handleDownloadVendorDebtsReceipt(item.id)}><BsReceipt /> {t('Download Debt Receipt')}</button>}
              </div>
            </div>
            </td>}
          </tr>
        })}
      </tbody>
    </table>
    }

    <br/>{data?.length == 0 && t("No items to display")}

    <Pagination toolsBarRef={toolsBarRef} service={dataService} excelService={excelService} data={data} setData={setDataItems} paginationRef={paginationRef} locale={locale} />
  </div>
};

VendorsDebts.propTypes = {};

VendorsDebts.defaultProps = {};

export default VendorsDebts;
