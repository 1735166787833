import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './UpdateAdd.module.css';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../Common/ModalComponent/ModalComponent';
import RenderErrorMessage from '../../../Common/RenderErrorMessage/RenderErrorMessage';
import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import { useAuth } from '../../../Common/Auth/AuthHelpers/Auth';
import GeneralHelpers from '../../../../Services/GeneralHelpers';

import $ from 'jquery'

const UpdateAdd = ({id, updateOneItem, oneItemDate, isAdd, locale, show, generalFetchingServiceRef, handleWhenHidden, handleOnConfirm, isPrivileged}) => {
  const { t, i18n } = useTranslation();

  const auth=useAuth();

  const [fieldsErrors, setFieldsErrors] = useState([]);


  const [employeesData, setEmployeesData] = useState([])
  const [employeesOptions, setEmployeesOptions] = useState([])

  const [leaveApproveStatusData, setLeaveApproveStatusData] = useState([])
  const [leaveApproveStatusOptions, setLeaveApproveStatusOptions] = useState([])

  const [isAttendedData, setIsAttendedData] = useState([])
  const [isAttendedOptions, setIsAttendedOptions] = useState([])

  const [isLeaveData, setIsLeaveData] = useState([])
  const [isLeaveOptions, setIsLeaveOptions] = useState([])

  const [employee_name, setEmployeeName] = useState('');
  const [employee_id, setEmployeeId] = useState(null);
  const [is_attended, setIsAttended] = useState(null);
  const [is_leave, setIsLeave] = useState(null);
  const [leave_approve_status_id, setLeaveApproveStatusId] = useState(null);
  const [employee_notes, setEmployeeNotes] = useState('');
  const [approver_notes, setApproverNotes] = useState('');
  const [date, setDate] = useState(isAdd?Date.now():null);
  const [isEmployee, setIsEmployee] = useState(false);
  
  const [error_message, setErrorMessage] = useState(null);

  const handleChangeEmployeeId=(value)=>{
    setEmployeeId(value)
  }
  const handleChangeIsAttended=(value)=>{
    setIsAttended(value)
    if(value=='True'){
      setIsLeave('False')
      setLeaveApproveStatusId(null)
      setApproverNotes('')
    }
  }
  const handleChangeIsLeave=(value)=>{
    setIsLeave(value)
    if(value=='False'){
      setLeaveApproveStatusId(null)
      setApproverNotes('')
    }
  }
  const handleChangeLeaveApproveStatus=(value)=>{
    setLeaveApproveStatusId(value)
  }
  const handleChangeEmployeeNotes=(e)=>{
    setEmployeeNotes(e.target.value)
  }
  const handleChangeApproverNotes=(e)=>{
    setApproverNotes(e.target.value)
  }
  const handleChangeDate=(value)=>{
    setDate(value?new Date(value):null)
  }

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData('attendance')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let employees=data?.employees
          let employeesOptions=employees?.map(x => {return {'value':x.id, 'name': x?.display_name}})
          setEmployeesData(employees)
          setEmployeesOptions(employeesOptions)

          let leaveApproveStatus=data?.leave_approve_status
          let leaveApproveStatusOptionsO=leaveApproveStatus?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setLeaveApproveStatusData(leaveApproveStatus)
          setLeaveApproveStatusOptions(leaveApproveStatusOptionsO)

          const trueFalse=['True', 'False']

          const isAttendedOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsAttendedData(trueFalse)
          setIsAttendedOptions(isAttendedOptions)

          const isLeaveOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsLeaveData(trueFalse)
          setIsLeaveOptions(isLeaveOptions)

          if(data?.employee){
            setIsEmployee(true)
            setEmployeeData(data?.employee)
          }

        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let dateF=date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(date)).locale("es").format('yyyy-MM-DD hh:mm:ss'):null
    let data={
      employee_id:parseInt(employee_id),
      leave_approve_status_id:leave_approve_status_id?parseInt(leave_approve_status_id):null,
      is_attended: is_attended?is_attended=='True':false,
      is_leave: is_leave?is_leave=='True':false,
      employee_notes:employee_notes.trim().length>0?employee_notes:null,
      approver_notes:approver_notes.trim().length>0?approver_notes:null,
      date: dateF
    };
    return data;
  }

  const isEmptyObject = (obj) => {
    return !obj.employee_id && !obj.leave_approve_status_id &&
    !obj.is_attended && !obj.is_leave &&
    !obj.employee_notes && !obj.date
  }

  const resetData = () => {
    setEmployeeId(null)
    setIsAttended(null)
    setIsLeave(null)
    setLeaveApproveStatusId(null)
    setEmployeeNotes('')
    setApproverNotes('')
    setDate(null)
    setFieldsErrors([])
    setErrorMessage(null)
    setEmployeeName(null)
  }

  const handleConfirm = (hideCallback) => {
    const obj=getObject()
    handleOnConfirm(obj, isEmptyObject(obj), isAdd, function(isSuccess, error, errorMessage,){
      if(!isSuccess){
        if(error){
          let response=error?.response
          if(response.status==422){
            setFieldsErrors(response?.data?.errors)
          }
        }else{
          setErrorMessage(errorMessage)
        }
        
      }else{
        resetData()
        hideCallback()
      }
      window.scrollTo(0, 0)
    })

  }

  const handleHidden = () => {
    handleWhenHidden()
    resetData()
  }

  const setEmployeeData=(data)=>{
    setEmployeeId(data?.id)
    setEmployeeName(`${data?.user?.first_name} ${data?.user?.last_name}`)
  }

  const setAttendanceData=(data)=>{
    let isAttended=data?.is_attended?'True':'False'
    setIsAttended(isAttended)

    if(isPrivileged['attendance_request_leave'] && isAttended=='False'){
      setIsLeave(data?.is_leave?'True':'False')
      setLeaveApproveStatusId(data?.leave_approve_status_id)
    }

    setEmployeeNotes(data?.employee_notes?data?.employee_notes:'')

    if(isPrivileged['attendance_approve_leave'] && isAttended=='False'){
      setApproverNotes(data?.approver_notes?data?.approver_notes:'')

    }
  }

  const getById = (id) => {
    generalFetchingServiceRef?.current?.getById(id)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setEmployeeData(data[0])
          }
        }).catch(error => {
          console.log(error)
        })
  }

  const getAttendanceById = (id) => {
    generalFetchingServiceRef?.current?.getById(id, 'attendance')
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setEmployeeData(data[0]?.employee)
            setAttendanceData(data[0])
          }
        }).catch(error => {
          console.log(error)
        })
  }


  useEffect(()=>{
    if(show){
      getAllData()
      if(id){
        getById(id)
      }
      if(updateOneItem){
        getAttendanceById(updateOneItem)
      }
    }
  },[show])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <ModalComponent locale={locale} show={show} handleWhenHidden={handleHidden} handleOnConfirmDelayed={handleConfirm} title={`${t(isAdd?'Add':'Update')} ${t('Attendance or Leave')}`} confirmBtnText={t(isAdd?'Add':'Update')} cancelBtnText={t('Cancel')} className={styles.Update} data-testid="Update">
    <div className={`${styles.FieldsContainer} ${locale=='ar'?'text-right':'text-left'}`}>

      <RenderErrorMessage name='error_message' messages={{error_message}} alertDisplay={true} />


      {isPrivileged['manage'] && isAdd && !id && <div className={`form-group`}>
        <label htmlFor="employee_id">{t('Employee')}</label>
        <RenderErrorMessage name='employee_id' messages={fieldsErrors} />
        <RenderErrorMessage name='data.employee_id' messages={fieldsErrors} />
        <div>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={employeesOptions}
            onChange={handleChangeEmployeeId}
            value={employee_id}
            placeholder={`${t('Select an')} ${t('employee')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </div>
      </div>}

      {!isAdd && employee_id && <div className={`form-group`}>
        <label htmlFor="employee_id">{t('Employee')}</label>
        <div className='card'>
          {employee_name}
        </div>
      </div>}

        <div className={`form-group`}>
          <label htmlFor="is_attended">{t('Is Attended')}</label>
          <RenderErrorMessage name='is_attended' messages={fieldsErrors} />
          <RenderErrorMessage name='data.is_attended' messages={fieldsErrors} />
          <div>
            <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={isAttendedOptions}
              onChange={handleChangeIsAttended}
              value={is_attended}
              placeholder={`${t('Select if')} ${t('attended')}`}
              search={true}
              filterOptions={fuzzySearch}
              disabled={!isEmployee && !isAdd}
              />
          </div>
        </div>

        {isPrivileged['attendance_request_leave'] && (is_attended && (is_attended=='False')) && <div className={`form-group`}>
          <label htmlFor="is_leave">{t('Apply for a leave?')}</label>
          <RenderErrorMessage name='is_leave' messages={fieldsErrors} />
          <RenderErrorMessage name='data.is_leave' messages={fieldsErrors} />
          <div>
            <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={isLeaveOptions}
              onChange={handleChangeIsLeave}
              value={is_leave}
              placeholder={`${t('Select if')} ${t('request to leave')}`}
              search={true}
              filterOptions={fuzzySearch}
              disabled={!isEmployee && !isAdd}
              />
          </div>
        </div>}

        {isPrivileged['attendance_approve_leave'] && (is_attended && (is_attended=='False')) && <div className={`form-group`}>
          <label htmlFor="leave_approve_status_id">{t('Leave Status')}</label>
          <RenderErrorMessage name='leave_approve_status_id' messages={fieldsErrors} />
          <RenderErrorMessage name='data.leave_approve_status_id' messages={fieldsErrors} />
          <div>
            <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={leaveApproveStatusOptions}
              onChange={handleChangeLeaveApproveStatus}
              value={leave_approve_status_id}
              placeholder={`${t('Select a')} ${t('leave status')}`}
              search={true}
              filterOptions={fuzzySearch}
              />
          </div>
        </div>}

        {isPrivileged['attendance_request_leave'] && isEmployee && ((is_leave && is_attended) && (is_attended=='False' && is_leave=='True')) && <div className={`form-group`}>
          <label htmlFor="employee_notes">{t('Employee Notes')}</label>
          <RenderErrorMessage name='employee_notes' messages={fieldsErrors} />
          <RenderErrorMessage name='data.employee_notes' messages={fieldsErrors} />
          <div>
            <textarea
            className={styles.TextArea}
            value={employee_notes}
            onChange={handleChangeEmployeeNotes}
            ></textarea>
          </div>
        </div>}

        {isPrivileged['attendance_approve_leave'] && (is_attended && (is_attended=='False')) && <div className={`form-group`}>
          <label htmlFor="approver_notes">{t('Approver Notes')}</label>
          <RenderErrorMessage name='approver_notes' messages={fieldsErrors} />
          <RenderErrorMessage name='data.approver_notes' messages={fieldsErrors} />
          <div>
            <textarea
            className={styles.TextArea}
            value={approver_notes}
            onChange={handleChangeApproverNotes}
            ></textarea>
          </div>
        </div>}

        {(isPrivileged['attendance_add']) && isAdd && <div className={`form-group`}>
        <label htmlFor="date">{t('Date')}</label>
        <RenderErrorMessage name='date' messages={fieldsErrors} />
        <RenderErrorMessage name='data.date' messages={fieldsErrors} />
        <DatePicker wrapperClassName={styles.datePickerWrap} maxDate={Date.now()}
              selected={date} onChange={handleChangeDate} placeholderText={t('Date')} />
      </div>}

      {isPrivileged['attendance_update'] && !isAdd && oneItemDate && <div className={`form-group`}>
        <label htmlFor="date">{t('Date')}</label>
        <div className='card'>
          {oneItemDate && moment(oneItemDate).local().locale(locale).format('yyyy-MM-DD')}
        </div>
      </div>}


    </div>
  </ModalComponent>
};

UpdateAdd.propTypes = {};

UpdateAdd.defaultProps = {};

export default UpdateAdd;
