import React from 'react';
import PropTypes from 'prop-types';
import styles from './Footer.module.css';
import { FaFacebook } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaGoogle } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { FaGithub } from 'react-icons/fa';


const Footer = () => (
  <footer className={styles.Footer+" bg-dark text-center text-white"}>
    
    <div className="container p-4">
      <section className="mb-4">
        
        <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button"><FaFacebook /></a>
  
        
        <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button"><FaTwitter /></a>
  
        
        <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button"><FaGoogle /></a>
  
        
        <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button"><FaInstagram /></a>
  
        
        <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button"><FaLinkedin /></a>
        
        <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button"><FaGithub /></a>
      </section>
      
  
      <section className="mb-4">
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt distinctio earum
          repellat quaerat voluptatibus placeat nam, commodi optio pariatur est quia magnam
          eum harum corrupti dicta, aliquam sequi voluptate quas.
        </p>
      </section>
      
  
      
      <section className="">
        
        <div className="row">
          
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">Links</h5>
  
            <ul className="list-unstyled mb-0">
              <li>
                <a href="#!" className="text-white">Link 1</a>
              </li>
              <li>
                <a href="#!" className="text-white">Link 2</a>
              </li>
              <li>
                <a href="#!" className="text-white">Link 3</a>
              </li>
              <li>
                <a href="#!" className="text-white">Link 4</a>
              </li>
            </ul>
          </div>
          
  
          
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">Links</h5>
  
            <ul className="list-unstyled mb-0">
              <li>
                <a href="#!" className="text-white">Link 1</a>
              </li>
              <li>
                <a href="#!" className="text-white">Link 2</a>
              </li>
              <li>
                <a href="#!" className="text-white">Link 3</a>
              </li>
              <li>
                <a href="#!" className="text-white">Link 4</a>
              </li>
            </ul>
          </div>
          
  
          
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">Links</h5>
  
            <ul className="list-unstyled mb-0">
              <li>
                <a href="#!" className="text-white">Link 1</a>
              </li>
              <li>
                <a href="#!" className="text-white">Link 2</a>
              </li>
              <li>
                <a href="#!" className="text-white">Link 3</a>
              </li>
              <li>
                <a href="#!" className="text-white">Link 4</a>
              </li>
            </ul>
          </div>
          
  
          
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">Links</h5>
  
            <ul className="list-unstyled mb-0">
              <li>
                <a href="#!" className="text-white">Link 1</a>
              </li>
              <li>
                <a href="#!" className="text-white">Link 2</a>
              </li>
              <li>
                <a href="#!" className="text-white">Link 3</a>
              </li>
              <li>
                <a href="#!" className="text-white">Link 4</a>
              </li>
            </ul>
          </div>
          
        </div>
        
      </section>
      
    </div>
    
  
    
    <div className="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
      © 2022 Copyright: &nbsp;
      <a className="text-white" target="_blank" href="https://abualhasan.dev/">abualhasan developer</a>
    </div>
    
  </footer>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
