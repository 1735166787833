import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './AddEdit.module.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';

import { useTranslation } from 'react-i18next';

import $ from 'jquery'
import GeneralHelpers from '../../../Services/GeneralHelpers';


const AddEdit = ({id, handleAddEdit, generalFetchingServiceRef, locale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [fieldsErrors, setFieldsErrors] = useState([]);
  

  const [name, setName] = useState("")
  const [discount, setDiscount] = useState("")
  const [description, setDescription] = useState("")
  const [is_enabled, setIsEnabled] = useState(null)

  const [isEnabledData, setIsEnabledData] = useState([])
  const [isEnabledOptions, setIsEnabledOptions] = useState([])

  const [user_role_id, setRoleId] = useState(null)
  const [rolesData, setRolesData] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);

  const [payment_type_id, setPaymentTypeId] = useState(null)
  const [paymentTypesData, setPaymentTypesData] = useState([]);
  const [paymentTypesOptions, setPaymentTypesOptions] = useState([]);

  const [addAmountFor7Days, setAddAmountFor7Days] = useState("")
  const [addAmountFor30Days, setAddAmountFor30Days] = useState("")
  const [addAmountFor180Days, setAddAmountFor180Days] = useState("")
  const [addAmountFor365Days, setAddAmountFor365Days] = useState("")

  const [featuresLimit, setFeaturesLimit] = useState("")

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data
          
          const roles=data?.roles
          const rolesOptions=roles?.map(x => {return {'value':x?.id, 'name': x?.name}})
          setRolesData(roles)
          setRolesOptions(rolesOptions)

          const paymentTypes=data?.paymentTypes
          const paymentTypesOptions=paymentTypes?.map(x => {return {'value':x?.id, 'name': t(x?.name)}})
          setPaymentTypesData(paymentTypes)
          setPaymentTypesOptions(paymentTypesOptions)


          const trueFalse=['True', 'False']

          const isEnabledOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsEnabledData(trueFalse)
          setIsEnabledOptions(isEnabledOptions)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let data={
      features_limit:featuresLimit,
      add_amount_for_7_days:addAmountFor7Days,
      add_amount_for_30_days:addAmountFor30Days,
      add_amount_for_180_days:addAmountFor180Days,
      add_amount_for_365_days:addAmountFor365Days,
      name,
      discount,
      description,
      user_role_id,
      is_enabled:is_enabled?is_enabled=='True':null,
      payment_type_id
    };
    return data;
  }
  const handleAddBtnClick=()=>{
    let item=getObject()
    handleAddEdit(item, setFieldsErrors, resetData)
    window.scrollTo(0, 0);
  }
  
  const handleEditBtnClick=()=>{
    let item=getObject()
    item.id=id
    handleAddEdit(item, setFieldsErrors)
    window.scrollTo(0, 0);
  }

  const resetData = () => {
    setName('')
    setDiscount('')
    setDescription('')
    setRoleId(null)
    setIsEnabled(null)
    setPaymentTypeId(null)
    setAddAmountFor7Days(null)
    setAddAmountFor30Days(null)
    setAddAmountFor180Days(null)
    setAddAmountFor365Days(null)
    setFeaturesLimit(null)
  }

  const handleChangeName=(e)=>{
    setName(e.target.value)
  }
  const handleChangeDiscount=(e)=>{
    setDiscount(e.target.value)
  }
  const handleChangeDescription=(e)=>{
    setDescription(e.target.value)
  }
  const handleChangeRole = (value) => {
    setRoleId(value)
  }
  const handleChangeIsEnabled = (value) => {
    setIsEnabled(value)
  }
  const handleChangePaymentTypeId = (value) => {
    setPaymentTypeId(value)
  }
  const handleChangeAddAmountFor7Days = (e) => {
    setAddAmountFor7Days(e.target.value)
  }
  const handleChangeAddAmountFor30Days = (e) => {
    setAddAmountFor30Days(e.target.value)
  }
  const handleChangeAddAmountFor180Days = (e) => {
    setAddAmountFor180Days(e.target.value)
  }
  const handleChangeAddAmountFor365Days = (e) => {
    setAddAmountFor365Days(e.target.value)
  }
  const handleChangeFeaturesLimit = (e) => {
    setFeaturesLimit(e.target.value)
  }



  
  const setAllData=(data)=>{
    let item=data
    setName(item?.name)
    setDiscount(item?.discount)
    setDescription(item?.description)
    setRoleId(item?.user_role_id)
    setIsEnabled(item?.is_enabled?'True':'False')
    setPaymentTypeId(item?.payment_type_id)
    setAddAmountFor7Days(item?.add_amount_for_7_days)
    setAddAmountFor30Days(item?.add_amount_for_30_days)
    setAddAmountFor180Days(item?.add_amount_for_180_days)
    setAddAmountFor365Days(item?.add_amount_for_365_days)
    setFeaturesLimit(item?.features_limit)
  }

  const getById = (id) => {
    generalFetchingServiceRef?.current?.getById(id)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setAllData(data)
          }
        }).catch(error => {
          console.log(error)
        })
  }
  useEffect(()=>{
    getAllData()
    if(id){
      getById(id)
    }
  },[])


  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectRole}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectRole}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectIsEnabled}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectIsEnabled}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <div className={styles.AddEdit + ` ${locale=='ar'?'text-right':'text-left'}`} data-testid="AddEdit">
    <div className="form-group">
      <label htmlFor="name">{t('Name')}</label>
      <RenderErrorMessage name='name' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('Name')}`} id="name" onChange={handleChangeName} value={name} />
    </div>
    
    <div className="form-group">
      <label htmlFor="discount">{t('Discount')}</label>
      <RenderErrorMessage name='discount' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('Discount')}`} id="discount" autoComplete="off" onChange={handleChangeDiscount} value={discount} />
    </div>

    <div className="form-group">
      <label htmlFor="description">{t('Description')}</label>
      <RenderErrorMessage name='description' messages={fieldsErrors} />
      <textarea type="text" className={`form-control ${styles.TextArea}`} placeholder={`${t('Enter a')} ${t('Description')}`} id="description" autoComplete="off" onChange={handleChangeDescription} value={description} />
    </div>

    <div className="form-group">
      <label htmlFor="user_role_id">{t('User Role')}</label>
      <RenderErrorMessage name='user_role_id' messages={fieldsErrors} />
      <SelectSearch id={styles.SelectRole}
                      onFocus={handleOnFocusSelect}
                      options={rolesOptions}
                      onChange={handleChangeRole}
                      value={user_role_id}
                      placeholder={t('User Role')}
                      search={true}
                      filterOptions={fuzzySearch}
                      autoFocus={false}
                      />
    </div>

    <div className="form-group">
      <label htmlFor="payment_type_id">{t('Payment Type')}</label>
      <RenderErrorMessage name='payment_type_id' messages={fieldsErrors} />
      <SelectSearch id={styles.SelectRole}
                      onFocus={handleOnFocusSelect}
                      options={paymentTypesOptions}
                      onChange={handleChangePaymentTypeId}
                      value={payment_type_id}
                      placeholder={t('Payment Type')}
                      search={true}
                      filterOptions={fuzzySearch}
                      autoFocus={false}
                      />
    </div>

    <div className="form-group">
      <label htmlFor="is_enabled">{t('Is Enabled?')}</label>
      <RenderErrorMessage name='is_enabled' messages={fieldsErrors} />
      <SelectSearch id={styles.SelectIsEnabled}
                      onFocus={handleOnFocusSelect}
                      options={isEnabledOptions}
                      onChange={handleChangeIsEnabled}
                      value={is_enabled}
                      placeholder={t('Is Enabled?')}
                      search={true}
                      filterOptions={fuzzySearch}
                      autoFocus={false}
                      />
    </div>

    <br/>
    <br/>

    <div className="form-group">
      <label htmlFor="add_amount_for_7_days">{t('Amount Added or Subtracted for 7 Days')}</label>
      <RenderErrorMessage name='add_amount_for_7_days' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter an')} ${t('amount')}`} id="add_amount_for_7_days" autoComplete="off" onChange={handleChangeAddAmountFor7Days} value={addAmountFor7Days} />
    </div>

    <div className="form-group">
      <label htmlFor="add_amount_for_30_days">{t('Amount Added or Subtracted for 30 Days')}</label>
      <RenderErrorMessage name='add_amount_for_30_days' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter an')} ${t('amount')}`} id="add_amount_for_30_days" autoComplete="off" onChange={handleChangeAddAmountFor30Days} value={addAmountFor30Days} />
    </div>

    <div className="form-group">
      <label htmlFor="add_amount_for_180_days">{t('Amount Added or Subtracted for 180 Days')}</label>
      <RenderErrorMessage name='add_amount_for_180_days' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter an')} ${t('amount')}`} id="add_amount_for_180_days" autoComplete="off" onChange={handleChangeAddAmountFor180Days} value={addAmountFor180Days} />
    </div>

    <div className="form-group">
      <label htmlFor="add_amount_for_365_days">{t('Amount Added or Subtracted for 365 Days')}</label>
      <RenderErrorMessage name='add_amount_for_365_days' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter an')} ${t('amount')}`} id="add_amount_for_365_days" autoComplete="off" onChange={handleChangeAddAmountFor365Days} value={addAmountFor365Days} />
    </div>

    <div className="form-group">
      <label htmlFor="features_limit">{t('Features Limit')}</label>
      <RenderErrorMessage name='features_limit' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('number')}`} id="features_limit" autoComplete="off" onChange={handleChangeFeaturesLimit} value={featuresLimit} />
    </div>
    

    <br/>
    <br/>
    <div className="form-group text-center">
      {!id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleAddBtnClick}>{t('Add')}</button>}
      {id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleEditBtnClick}>{t('Edit')}</button>}
    </div>

  </div>
};

AddEdit.propTypes = {};

AddEdit.defaultProps = {};

export default AddEdit;
