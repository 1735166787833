import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './SendOffer.module.css';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../Common/ModalComponent/ModalComponent';
import RenderErrorMessage from '../../../Common/RenderErrorMessage/RenderErrorMessage';
import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../../Services/GeneralHelpers';
import $ from 'jquery'

const SendOffer = ({id, locale, show, generalFetchingServiceRef, handleWhenHidden, handleOnConfirm, isPrivileged, fieldsErrorsIn, handleTabChange}) => {
  const { t, i18n } = useTranslation();

  const [fieldsErrors, setFieldsErrors] = useState([]);

  const [companies_offers_transfer_type_id, setCompaniesOffersTransferTypeId] = useState(null)
  const [companiesOffersTransferTypeData, setCompaniesOffersTransferTypeData] = useState([])
  const [companiesOffersTransferTypeOptions, setCompaniesOffersTransferTypeOptions] = useState([])

  const [governoratesData, setGovernoratesData] = useState([])
  const [governoratesOptions, setGovernoratesOptions] = useState([])

  const [districtsData, setDistrictsData] = useState([])
  const [districtsOptions, setDistrictsOptions] = useState([])

  const [neighborhoodsData, setNeighborhoodsData] = useState([])
  const [neighborhoodsOptions, setNeighborhoodsOptions] = useState([])

  const [title, setTitle] = useState('')
  const [details, setDetails] = useState('')
  const [max_orders_number, setMaxOrdersNumber] = useState('')
  const [min_orders_number, setMinOrdersNumber] = useState('')
  const [transfer_price, setTransferPrice] = useState('')
  const [governorate_id, setGovernorateId] = useState(null)
  const [district_id, setDistrictId] = useState(null)
  const [neighborhood_id, setNeighborhoodId] = useState(null)
  
  
  const handleChangeTitle=(e)=>{
    setTitle(e.target.value)
  }
  const handleChangeDetails=(e)=>{
    setDetails(e.target.value)
  }
  const handleChangeMaxOrdersNumber=(e)=>{
    setMaxOrdersNumber(e.target.value)
  }
  const handleChangeMinOrdersNumber=(e)=>{
    setMinOrdersNumber(e.target.value)
  }
  const handleChangeTransferPrice=(e)=>{
    setTransferPrice(e.target.value)
  }
  const handleChangeGovernorate=(value)=>{
    const districtsOptions=getDistrictsOptions(value)
    setDistrictsOptions(districtsOptions)
    setGovernorateId(value)
  }
  const handleChangeDistrict=(value)=>{
    const neighborhoodsOptions=getNeighborhoodsOptions(value)
    setNeighborhoodsOptions(neighborhoodsOptions)
    setDistrictId(value)
  }
  const handleChangeNeighborhood=(value)=>{
    setNeighborhoodId(value)
  }
  const handleChangeCompaniesOffersTransferTypeId=(value)=>{
    setCompaniesOffersTransferTypeId(value)
  }

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData('offers/send')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let transferTypes=data?.transferTypes
          let transferTypesOptions=transferTypes?.map(x => {return {'value':x.id, 'name': t(x?.display_name)}})
          setCompaniesOffersTransferTypeData(transferTypes)
          setCompaniesOffersTransferTypeOptions(transferTypesOptions)

          let governorates=data?.governorates
          let governoratesOptions=governorates?.map(x => {return {'value':x.id, 'name': x.name}})
          setGovernoratesData(governorates)
          setGovernoratesOptions(governoratesOptions)

          let districts=data?.districts
          setDistrictsData(districts)

          let neighborhoods=data?.neighborhoods
          setNeighborhoodsData(neighborhoods)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  useEffect(()=>{
    if(id){
      getOfferSentData(id)
    }
  }, [governoratesData, districtsData, neighborhoodsData])

  const getOfferSentData=(id)=>{
    generalFetchingServiceRef?.current?.getAllData(`offer/sent/${id}`)
    .then(response => {
      let responseData=response?.data
      if(responseData && responseData?.status==1){
        let data=responseData?.data
        setTitle(data?.title)
        setDetails(data?.details)
        setMaxOrdersNumber(data?.max_orders_number)
        setMinOrdersNumber(data?.min_orders_number)
        setTransferPrice(data?.transfer_price)
        setCompaniesOffersTransferTypeId(data?.companies_offers_transfer_type_id)
        setTimeout(() => {
          handleChangeGovernorate(data?.governorate_id)
          setTimeout(() => {
            handleChangeDistrict(data?.district_id)
            setTimeout(() => {
              setNeighborhoodId(data?.neighborhood_id)
            }, 250)
          }, 250)
        }, 250)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const getDistrictsOptions = (value) =>{
    return districtsData?.filter(x => x.governorate_id == value).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const getNeighborhoodsOptions = (value) =>{
    return neighborhoodsData?.filter(x => x.district_id == value).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const getObject = () =>{
    let data={
      title,
      details,
      max_orders_number,
      min_orders_number,
      transfer_price,
      companies_offers_transfer_type_id,
      governorate_id,
      district_id,
      neighborhood_id,
    };
    return data;
  }

  const isEmptyObject = (obj) => {
    return !obj.companies_offers_transfer_type_id && !obj.governorate_id && !obj.district_id && !obj.neighborhood_id &&
    (!obj.title || obj.title?.trim().length==0) &&
    (!obj.details || obj.details?.trim().length==0) &&
    (!obj.max_orders_number || obj.max_orders_number?.trim().length==0) &&
    (!obj.min_orders_number || obj.min_orders_number?.trim().length==0) &&
    (!obj.transfer_price || obj.transfer_price?.trim().length==0)
  }

  const resetData = () => {
    setTitle('')
    setDetails('')
    setMaxOrdersNumber('')
    setMinOrdersNumber('')
    setTransferPrice('')
    setCompaniesOffersTransferTypeId(null)
    setGovernorateId(null)
    setDistrictId(null)
    setNeighborhoodId(null)
  }

  const handleConfirm = (hideCallback) => {
    const obj=getObject()
    handleOnConfirm(obj, isEmptyObject(obj), hideCallback,  resetData, id)
  }

  const handleHidden = () => {
    if(!id){
      handleWhenHidden()
      resetData()
    }else{
      handleTabChange('SentOffers')
    }
  }

  
  useEffect(()=>{
    if(show){
      getAllData()
    }
  },[show])

  useEffect(()=>{
    setFieldsErrors(fieldsErrorsIn)
  },[fieldsErrorsIn])
  
  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <ModalComponent locale={locale} show={show} 
    handleWhenHidden={handleHidden} handleOnConfirm={handleConfirm} title={!id?t('Send Offer to company'):t('Edit Sent Offer to company')} 
    confirmBtnText={!id?t('Send Offer'):t('Edit Offer')} cancelBtnText={t('Cancel')} className={styles.SendOffer} data-testid="SendOffer"
    isDelayedConfirm={true}>
    <div className={`${styles.FieldsContainer} ${locale=='ar'?'text-right':'text-left'}`}>

     

      <div className="form-group">
        <label htmlFor="title">{t('Title')}</label>
        <RenderErrorMessage name='data.title' messages={fieldsErrors} />
        <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('title')}`} id="title" autoComplete="off" onChange={handleChangeTitle} value={title} />
      </div>

      <div className="form-group">
        <label htmlFor="details">{t('Details')}</label>
        <RenderErrorMessage name='data.details' messages={fieldsErrors} />
        <textarea
          className={styles.TextArea}
          value={details}
          onChange={handleChangeDetails}
          ></textarea>
      </div>

      <div className="form-group">
        <label htmlFor="max_orders_number">{t('Max Orders Number')}</label>
        <RenderErrorMessage name='data.max_orders_number' messages={fieldsErrors} />
        <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('number')}`} id="max_orders_number" autoComplete="off" onChange={handleChangeMaxOrdersNumber} value={max_orders_number} />
      </div>

      <div className="form-group">
        <label htmlFor="min_orders_number">{t('Min Orders Number')}</label>
        <RenderErrorMessage name='data.min_orders_number' messages={fieldsErrors} />
        <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('number')}`} id="min_orders_number" autoComplete="off" onChange={handleChangeMinOrdersNumber} value={min_orders_number} />
      </div>


      <div className={`form-group`}>
          <label htmlFor="companies_offers_transfer_type_id">{t('Transfer Type')}</label>
          <RenderErrorMessage name='data.companies_offers_transfer_type_id' messages={fieldsErrors} />
          <div>
          <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={companiesOffersTransferTypeOptions}
              onChange={handleChangeCompaniesOffersTransferTypeId}
              value={companies_offers_transfer_type_id}
              placeholder={`${t('Select a')} ${t('type')}`}
              search={true}
              filterOptions={fuzzySearch}
              />
          </div>
        </div>

      <div className="form-group">
        <label htmlFor="tittransfer_pricele">{t('Transfer Price')}</label>
        <RenderErrorMessage name='data.transfer_price' messages={fieldsErrors} />
        <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('price')}`} id="transfer_price" autoComplete="off" onChange={handleChangeTransferPrice} value={transfer_price} />
      </div>
      


      <div className='card p-2'>
        <label>{t('Receivment Address')}</label>
        <div className={`form-group`}>
          <label htmlFor="governorate_id">{t('Governorate')}</label>
          <RenderErrorMessage name='data.governorate_id' messages={fieldsErrors} />
          <div>
          <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={governoratesOptions}
              onChange={handleChangeGovernorate}
              value={governorate_id}
              placeholder={`${t('Select a')} ${t('governorate')}`}
              search={true}
              filterOptions={fuzzySearch}
              />
          </div>
        </div>

        <div className={`form-group`}>
          <label htmlFor="district_id">{t('District')}</label>
          <RenderErrorMessage name='data.district_id' messages={fieldsErrors} />
          <div>
          <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={districtsOptions}
              onChange={handleChangeDistrict}
              value={district_id}
              placeholder={`${t('Select a')} ${t('district')}`}
              search={true}
              filterOptions={fuzzySearch}
              />
          </div>
        </div>

        <div className={`form-group`}>
          <label htmlFor="data.neighborhood_id">{t('Neighborhood')}</label>
          <RenderErrorMessage name='neighborhood_id' messages={fieldsErrors} />
          <div>
            <SelectSearch id={styles.Select}
              onFocus={handleOnFocusSelect}
              options={neighborhoodsOptions}
              onChange={handleChangeNeighborhood}
              value={neighborhood_id}
              placeholder={`${t('Select a')} ${t('neighborhood')}`}
              search={true}
              filterOptions={fuzzySearch}
              />
          </div>
        </div>
      </div>

    </div>
  </ModalComponent>
};

SendOffer.propTypes = {};

SendOffer.defaultProps = {};

export default SendOffer;
