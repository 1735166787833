import React, {useEffect, useState, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './ViewDetails.module.css';

import {AiFillEdit} from 'react-icons/ai';
import {AiFillDelete} from 'react-icons/ai';
import {MdSelectAll} from 'react-icons/md';
import {MdTabUnselected} from 'react-icons/md';
import {GrFormView, GrDocumentUpdate} from 'react-icons/gr';

import Pagination from '../../Common/Pagination/Pagination';
import $ from 'jquery';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import ToolsBar from './ToolsBar/ToolsBar';
import { useTranslation } from 'react-i18next';


import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import { Link } from 'react-router-dom';

const ViewDetails = ({id, page, data, viewBtnHandle, viewDetailsBtnHandle, editBtnHandle, deleteHandle, setDataItems, generalFetchingServiceRef, viewDetailsRef, isPrivileged, locale, showMessage}) => {
  const { t, i18n } = useTranslation();

  const toolsBarRef = useRef();
  const paginationRef= useRef();
  const tableRef= useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [selecetedItems, setSelectedItems] = useState([])
  const [isSelectAllBtn, setIsSelectAllBtn] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [isUpdatingValues, setIsUpdatingValues] = useState(false)
  const [updateOneItem, setUpdateOneItem] = useState(null)
  const [dataLength, setDataLength] = useState(0)
  

  let isDataLoaded=false
  const selectedDataAction = () => {
    if(selecetedItems.length>0){
      toolsBarRef?.current?.setIsSelectItems(true)
    }else{
      toolsBarRef?.current?.setIsSelectItems(false)
    }

    if(selecetedItems?.length==data?.length){
      setIsSelectAllBtn(false)
      $("#selectAllBtn").removeClass('btn-primary')
      $("#selectAllBtn").addClass('btn-warning')
    }else{
      setIsSelectAllBtn(true)
      $("#selectAllBtn").removeClass('btn-warning')
      $("#selectAllBtn").addClass('btn-primary')
    }
  }
  useEffect(()=>{
    selectedDataAction()
    isDataLoaded=true
  },[selecetedItems, data])
  useEffect(()=>{
    if(isDataLoaded){
      selectedDataAction()
    }
  },[selecetedItems])

  const handleOnBeforePrint = () => {
    setIsPrinting(true)
    $('.hidableColumn').removeClass(styles.hidableColumn)
    return Promise.resolve();
  }
  const handleOnAfterPrint = () => {
    setIsPrinting(false)
    $('.hidableColumn').addClass(styles.hidableColumn)
  }

  const setSelectedItem = (selectedId, value) => {
    if(value){
      if(selecetedItems.indexOf(selectedId)==-1){
        setSelectedItems([...selecetedItems, selectedId])
      }
    }else{
      if(selecetedItems.indexOf(selectedId)!=-1){
        setSelectedItems(selecetedItems.filter((x)=>x!=selectedId))
      }
    }
  }
  const handleOnChangeSelect= (e) => {
    let selectedId=parseInt(e.target.getAttribute('tag'))
    let value=e.target.checked
    setSelectedItem(selectedId, value)
  }

  const handleWhenUpdatingHidden = () =>{
    setIsUpdatingValues(false)
    setUpdateOneItem(null)
  }

  useImperativeHandle(viewDetailsRef,()=>({
    setDataTotalLength(value){
      setDataLength(value)
    },
    getAccountId(){
      return parseInt(id);
    },
    deleteSelectedItems(){
      deleteHandle(selecetedItems, paginationRef)
    },
    clearSelectedItems(){
      setSelectedItems([])
      $('.'+styles.All).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
    },
    loadPages(){
      toolsBarRef?.current?.loadPages()
    },
    showUpdateModal(){
      setIsUpdatingValues(true)
    }
  }))

  const handleSelectAllClick = () => {
    if(selecetedItems?.length==data?.length){
      $('.'+styles.ViewDetails).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
      setSelectedItems([])
    }else{
      $('.'+styles.ViewDetails).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', true)
      setSelectedItems([...data?.map(x=>x.id)])
    }
  }

  const dataService = (links, isAll, filtersO) => generalFetchingServiceRef?.current?.getAll(links, isAll, filtersO, `${id}/all`)
  const filtersDataService = (filters) => generalFetchingServiceRef?.current?.getFiltersData(filters,`${id}`)
  const excelService = (filters, specificItems) => generalFetchingServiceRef?.current?.ExportExcel(filters, 'All', specificItems)


  return <div className={styles.ViewDetails + ""} data-testid="ViewDetails">
    <ToolsBar toolsBarRef={toolsBarRef} viewDetailsRef={viewDetailsRef} paginationRef={paginationRef} isPrivileged={isPrivileged}
     ViewItemsButtons={{'Delete': true}} identifier='AccountViewDetails' filtersDataService={filtersDataService} selecetedItems={selecetedItems}
     printComponentRef={tableRef} printDocumentTitle='Accounts' handleOnAfterPrint={handleOnAfterPrint} handleOnBeforePrint={handleOnBeforePrint}
     locale={locale} page={page} dataLength={dataLength}
     />

    {data?.length > 0 &&
    <table id="tableToPrint" ref={tableRef} cellPadding="0" cellSpacing="0" border="0" className={"datatable table table-striped table-bordered table-hover"}>
      <thead>
        <tr>
          {!isPrinting && <th className='hideOnPrint'>
            <button id="selectAllBtn" className='btn btn-primary' onClick={handleSelectAllClick}>
              {isSelectAllBtn && <MdSelectAll id="SelectAll" />}
              {!isSelectAllBtn && <MdTabUnselected id="UnSelectAll" />}
            </button>
          </th>}
          <th>{t('Id')}</th>
          <th>{t('Voucher Number')}</th>
          <th>{t('Financial Action')}</th>
          <th>{t('Amount')}</th>
          <th className={styles.hidableColumn + ' hidableColumn'}>{t('Details')}</th>
          {/* {!isPrinting && <th>{t('Actions')}</th>} */}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, id) => {
          return <tr key={id}>
            {!isPrinting && <td className='hideOnPrint'><input type={'checkbox'} tag={item?.id} name={styles.CheckBoxDataTable} className={styles.CheckBoxDataTable} onChange={handleOnChangeSelect} /></td>}
            <td>{item?.id}</td>
            <td>{item?.voucher_number}</td>
            <td>{t(item?.action?.name)}</td>
            <td>{item?.amount}</td>
            <td className={styles.hidableColumn + ' hidableColumn'}>{t(item?.details)}</td>
            {/* {!isPrinting && <td>
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropDownActionsBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {t('Do an action')}
              </button>
              <div className={`dropdown-menu ${locale=='ar'?'text-right':'text-left'}`} aria-labelledby="dropDownActionsBtn">
              </div>
            </div>
            </td>} */}
          </tr>
        })}
      </tbody>
    </table>
    }

    <br/>{data?.length == 0 && t("No items to display")}

    <Pagination toolsBarRef={toolsBarRef} service={dataService} excelService={excelService} data={data} setData={setDataItems} paginationRef={paginationRef} locale={locale} />
  </div>
};

ViewDetails.propTypes = {};

ViewDetails.defaultProps = {};

export default ViewDetails;
