import React, { useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ViewPayments.module.css';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../Common/ModalComponent/ModalComponent';
import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";


import {AiFillEdit} from 'react-icons/ai';
import {AiFillDelete} from 'react-icons/ai';
import {BsReceipt} from 'react-icons/bs';

import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../Services/GeneralHelpers';
import $ from 'jquery'


const ViewPayments = ({locale, show, generalFetchingServiceRef, handleWhenHidden, handleOnConfirm, isPrivileged, fieldsErrorsIn, payDebtItemId, vendorDebtsRef, viewPaymentsRef}) => {
  const { t, i18n } = useTranslation();

  const [fieldsErrors, setFieldsErrors] = useState([]);

  const [payments, setPayments] = useState([])
  const [payDebtItem, setPayDebtItem] = useState(0)
  
  
  useImperativeHandle(viewPaymentsRef,()=>({
    updateTable(){
      getAllPayments()
      getAllData()
    }
  }))

  const getObject = () =>{
    let paymentDateF=payment_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(payment_date)).locale("es").format('yyyy-MM-DD'):null
    let data={
      vendor_debt_id:payDebtItemId,
      amount,
      payment_date:paymentDateF
    };
    return data;
  }

  const getAllPayments=()=>{
    generalFetchingServiceRef?.current?.update({vendor_debt_id: payDebtItemId}, null, 'vendor_debts/payments')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let payments=data?.payments
          setPayments(payments)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData('vendor_debts/debt_item/'+payDebtItemId)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data
          setPayDebtItem(data)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  

  const isEmptyObject = (obj) => {
    return !obj.vendor_debt_id && !obj.payment_date &&
    (!obj.amount && obj.amount?.trim().length==0)
  }

  const resetData = () => {
    setPayments([])
  }

  const handleHidden = () => {
    handleWhenHidden()
    resetData()
  }
  
  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  useEffect(()=>{
    if(show){
      getAllPayments()
      getAllData()
    }
  }, [show])

  return <ModalComponent locale={locale} show={show} 
    handleWhenHidden={handleHidden} handleOnConfirm={null} title={t('Payments View')} 
    confirmBtnText={t('Pay Debt')} cancelBtnText={t('Hide')} className={styles.ViewPayments} data-testid="ViewPayments"
    isDelayedConfirm={true}>
    <div className={`${styles.FieldsContainer} ${locale=='ar'?'text-right':'text-left'}`}>

    <div id={styles.HorizontalContainerFlex}>
      <div className="form-group">
        <label htmlFor="id" style={{'textAlign': 'center', 'width':'100%'}}>{t('Id')}</label>
        <RenderErrorMessage name='id' messages={fieldsErrors} />
        <div className='card p-2 text-center'>{payDebtItem?.id}</div>
      </div>
    </div>

    <div id={styles.HorizontalContainer}>
      <div className="form-group">
        <label htmlFor="vendor_id" style={{'textAlign': 'center'}}>{t('Vendor')}</label>
        <RenderErrorMessage name='vendor_id' messages={fieldsErrors} />
        <div className='card p-2'>{payDebtItem?.display_name}</div>
      </div>

      <div className="form-group">
        <label htmlFor="debt_amount" style={{'textAlign': 'center'}}>{t('Debt Amount')}</label>
        <RenderErrorMessage name='debt_amount' messages={fieldsErrors} />
        <div className='card p-2'>{payDebtItem?.amount}</div>
      </div>

      <div className="form-group">
        <label htmlFor="paid_amount" style={{'textAlign': 'center'}}>{t('Paid Amount')}</label>
        <RenderErrorMessage name='paid_amount' messages={fieldsErrors} />
        <div className='card p-2'>{payDebtItem?.paid_amount}</div>
      </div>
    </div>


    {payments?.length > 0 &&
    <div>
      <table id="tableToPrint" cellPadding="0" cellSpacing="0" border="0" className={"datatable table table-striped table-bordered table-hover"}>
        <thead>
          <tr>
            <th>{t('Id')}</th>
            <th>{t('Amount')}</th>
            <th>{t('Payment Date')}</th>
            <th>{t('Actions')}</th>
          </tr>
        </thead>
        <tbody className={styles.PaymentsContainer}>
          {payments?.map((item, id) => {
            return <tr key={id}>
              <td>{item?.id}</td>
              <td>{item?.amount}</td>
              <td>{(item?.payment_date?(moment(item?.payment_date).local().locale(locale).format('DD/MM/yyyy h:mm:ss a')):'')}</td>
              <td>
              <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropDownActionsBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {t('Do an action')}
                </button>
                <div className={`dropdown-menu ${locale=='ar'?'text-right':'text-left'}`} aria-labelledby="dropDownActionsBtn">
                  {<button className="dropdown-item " href="#" onClick={()=>vendorDebtsRef?.current?.handleDeletePayment(item.id)}><AiFillDelete /> {t('Delete')}</button>}
                  {<button className="dropdown-item " href="#" onClick={()=>vendorDebtsRef?.current?.handleDownloadPaymentReceipt(item.id)}><BsReceipt /> {t('Download Payment Receipt')}</button>}
                </div>
              </div>
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
    }
    
     
    </div>
  </ModalComponent>
};

ViewPayments.propTypes = {};

ViewPayments.defaultProps = {};

export default ViewPayments;
