import React,{useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './Login.module.css';
import Layout from '../../Layout/Layout';
import RenderErrorMessage from '../../RenderErrorMessage/RenderErrorMessage';
import AuthService from '../../../../Services/AuthService';
import { useAuth } from '../AuthHelpers/Auth';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardLayout from '../../../Dashboard/DashboardLayout/DashboardLayout';
import { useTranslation } from 'react-i18next';

const Login = ({locale, setLocale}) => {
  const { t, i18n } = useTranslation();

  const [emailOrPhone, setEmailOrPhone] = useState(null);
  const [password, setPassword] = useState(null);
  const [fieldsErrors, setFieldsErrors] = useState([]);
  const [generalErrorMessage, setGeneralErrorMessage] = useState(null);
  const [isShownGeneralError, setIsShownGeneralError] = useState(false);
  const auth = useAuth();
  const location= useLocation();
  const navigate = useNavigate();

  const authServiceRef = useRef();


  const HandleEmailOrPhoneInput=(e)=>{
    setEmailOrPhone(e.target.value)
  }
  const HandlePasswordInput=(e)=>{
    setPassword(e.target.value)
  }
  const tryToLogin=(data)=>{
    const userInfo=data.user_info
    const redirectPath= location.state?.path || '/' + userInfo.role.name+'/dashboard'
    if(!!parseInt(data.status)){
      auth.login(data)
      navigate(redirectPath, {replace: true})
    }else{
      setGeneralErrorMessage(data.message)
    }
  }

  const OnSignIn=()=>{
      setFieldsErrors([])
      setGeneralErrorMessage(null)
      authServiceRef?.current?.Login({emailOrPhone, password})
            .then(response => {
              if(response.data.status==1){
                tryToLogin(response.data)
              }else{
                setGeneralErrorMessage(response.data?.message)
              }
            }).catch(error => {
              let response=error.response
              if(response.status==422){
                setFieldsErrors(response.data.errors)
              }
            })
  }

  const hideMessage = () => {
    setGeneralErrorMessage(null)
  }
  

  const handleKeyDown = (e) => {
    if(e.keyCode==13){
      OnSignIn()
    }
  }

  return <DashboardLayout component='Login' locale={locale} setLocale={setLocale} isSidebar={false}>
    <AuthService authServiceRef={authServiceRef} />
    <div className={styles.Login} data-testid="Login">
      <div className={styles.topContainer}>
        <h1>{t('Login')}</h1>
        <RenderErrorMessage name='general' messages={generalErrorMessage!=null?{'general':generalErrorMessage}:{}} alertDisplay={true} isShown={isShownGeneralError} setIsShownGeneralError={setIsShownGeneralError} isDissmiss={true} handleHideMessage={hideMessage} />
      </div>
      <br />
      <RenderErrorMessage name='email' messages={fieldsErrors} />
      <RenderErrorMessage name='phone' messages={fieldsErrors} />
      <input type='text' name='email-or-phone' 
            className='form-control' placeholder={t('Email or Phone')} autoFocus
            onChange={HandleEmailOrPhoneInput}
            onKeyDown={handleKeyDown}
            />
      <br />
      <RenderErrorMessage name='password' messages={fieldsErrors} />
      <input type='password' name='password'
            className='form-control' placeholder={t('Password')}
            onChange={HandlePasswordInput}
            onKeyDown={handleKeyDown}
            />
      <br />
      <RenderErrorMessage name='general' messages={generalErrorMessage!=null?{'general':generalErrorMessage}:{}} alertDisplay={true} />
      <br />
      <div className={styles.leftContainer}>
        <a href="/auth/forgot-password">{t('Forgot Password?')}</a>
      </div>
      <br/>
      <button className={styles.loginButton + ' btn btn-primary'}
              onClick={()=>OnSignIn()}>{t('Login')}</button>
      <br/>
    </div>
  </DashboardLayout>
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
