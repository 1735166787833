import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './PayDebt.module.css';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../Common/ModalComponent/ModalComponent';
import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../Services/GeneralHelpers';
import $ from 'jquery'

const PayDebt = ({locale, show, generalFetchingServiceRef, handleWhenHidden, handleOnConfirm, isPrivileged, fieldsErrorsIn, payDebtItem}) => {
  const { t, i18n } = useTranslation();

  const [fieldsErrors, setFieldsErrors] = useState([]);


  const [amount, setAmount] = useState('')
  const [payment_date, setPaymentDate] = useState(null)
  
  const handleChangeAmount=(e)=>{
    setAmount(e.target.value)
  }
  const handleChangePaymentDate=(value)=>{
    setPaymentDate(value)
  }

  const getObject = () =>{
    let paymentDateF=payment_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(payment_date)).locale("es").format('yyyy-MM-DD'):null
    let data={
      vendor_debt_id:payDebtItem?.id,
      amount,
      payment_date:paymentDateF
    };
    return data;
  }

  const isEmptyObject = (obj) => {
    return !obj.vendor_debt_id && !obj.payment_date &&
    (!obj.amount && obj.amount?.trim().length==0)
  }

  const resetData = () => {
    setAmount('')
    setPaymentDate(null)
  }

  const handleConfirm = (hideCallback) => {
    const obj=getObject()
    handleOnConfirm(obj, isEmptyObject(obj), hideCallback)
    resetData()
  }

  const handleHidden = () => {
    handleWhenHidden()
    resetData()
  }

  useEffect(()=>{
    setFieldsErrors(fieldsErrorsIn)
  },[fieldsErrorsIn])
  
  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <ModalComponent locale={locale} show={show} 
    handleWhenHidden={handleHidden} handleOnConfirm={handleConfirm} title={t('Pay Vendor Debt')} 
    confirmBtnText={t('Pay Debt')} cancelBtnText={t('Cancel')} className={styles.PayDebt} data-testid="PayDebt"
    isDelayedConfirm={true}>
    <div className={`${styles.FieldsContainer} ${locale=='ar'?'text-right':'text-left'}`}>


    
    
      <div className="form-group">
        <label htmlFor="vendor_id">{t('Vendor')}</label>
        <RenderErrorMessage name='vendor_id' messages={fieldsErrors} />
        <div className='card p-2'>{payDebtItem?.display_name}</div>
      </div>

      <div className="form-group">
        <label htmlFor="debt_amount">{t('Debt Amount')}</label>
        <RenderErrorMessage name='debt_amount' messages={fieldsErrors} />
        <div className='card p-2'>{payDebtItem?.amount}</div>
      </div>

      <div className="form-group">
        <label htmlFor="paid_amount">{t('Paid Amount')}</label>
        <RenderErrorMessage name='paid_amount' messages={fieldsErrors} />
        <div className='card p-2'>{payDebtItem?.paid_amount}</div>
      </div>

      {!payDebtItem?.is_paid && <div>
        <div className="form-group">
          <label htmlFor="data.amount">{t('Payment Amount')}</label>
          <RenderErrorMessage name='data.amount' messages={fieldsErrors} />
          <input type="number" className="form-control" placeholder={`${t('Enter an')} ${t('amount')}`} id="amount" autoComplete="off" onChange={handleChangeAmount} value={amount} />
        </div>

        <div className="form-group">
          <label htmlFor="data.payment_date">{t('Payment Date')}</label>
          <RenderErrorMessage name='data.payment_date' messages={fieldsErrors} />
          <div>
            <DatePicker selected={payment_date} onChange={handleChangePaymentDate} />
          </div>
        </div>
      </div>}
    </div>
  </ModalComponent>
};

PayDebt.propTypes = {};

PayDebt.defaultProps = {};

export default PayDebt;
