import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './Layout.module.css';

import Navigation from './Navigation/Navigation';
import Footer from './Footer/Footer';

import $ from 'jquery';

const Layout = ({children, backgroundColor, centerVertically, centerHorizontally, locale, setLocale, topBarColor, siteColor, siteFont, openChats, isPopedUP, prefix}) => {

    useEffect(() => {
      $(".divLoader").hide()
    }, [])

    return <div className="Layout" style={{ backgroundColor: backgroundColor?backgroundColor:siteColor, fontFamily: siteFont=='auto'?'':siteFont}} dir={locale=='ar'?'rtl':'ltr'}>
      {!isPopedUP && <Navigation locale={locale} setLocale={setLocale} topBarColor={topBarColor} openChats={openChats} prefix={prefix} />}
      <main className={`${styles.main} ${centerVertically?styles.centerVertically:''} ${centerHorizontally?styles.centerHorizontally:''}`}>{children}</main>
      {!isPopedUP && <Footer topBarColor={topBarColor} />}
    </div>
};

Layout.propTypes = {
  backgroundColor: PropTypes.string,
  centerVertically: PropTypes.bool,
  centerHorizontally: PropTypes.bool
};

Layout.defaultProps = {
  backgroundColor:undefined,
  centerVertically: false,
  centerHorizontally: false
};

export default Layout;
