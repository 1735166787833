import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './Statistics.module.css';
import 'react-select-search/style.css'

import DashboardLayout from '../Dashboard/DashboardLayout/DashboardLayout';

import $ from 'jquery';

import { BsShopWindow } from 'react-icons/bs';
import { CiPercent } from 'react-icons/ci';
import { FaMailBulk } from 'react-icons/fa';
import { FaBuilding } from 'react-icons/fa';
import { GiTakeMyMoney, GiProfit, GiPayMoney, GiBuyCard } from 'react-icons/gi';
import { MdOutlineWork, MdRealEstateAgent } from 'react-icons/md';
import { RiAdminFill } from 'react-icons/ri';


import TotalService from '../../Services/TotalService';
import SelectSearch,{fuzzySearch} from 'react-select-search';
import Privileges from '../Common/Privileges/Privileges';

import { useTranslation } from 'react-i18next';
import NoPrivilegesMessage from '../Common/NoPrivilegesMessage/NoPrivilegesMessage';

import DatePicker from "react-datepicker";

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../Services/GeneralHelpers';
import GeneralFetchingService from '../../Services/GeneralFetchingService';
import RenderErrorMessage from '../Common/RenderErrorMessage/RenderErrorMessage';
import RenderSuccessMessage from '../Common/RenderSuccessMessage/RenderSuccessMessage';


const Statistics = ({locale, setLocale}) => {
  const { t, i18n } = useTranslation();

  const [isLoaded, setIsLoaded] = useState(false)
  const [isPrivileges, setIsPrivileges] = useState(false)
  const [privilegesSideBar, setPrivilegesSideBar] = useState([])
  const [isPrivileged, setIsPrivileged] = useState({})

  const [companiesData, setCompaniesData] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [isCompanySelected, setIsCompanySelected] = useState(false)

  const [isFilters, setIsFilters] = useState(false)

  const totalServiceRef = useRef();
  const privilegesRef= useRef();
  const generalFetchingServiceRef = useRef();

  let [mail,setMail] = useState(null)

  let [vendor,setVendor] = useState(null)

  let [agent,setAgent] = useState(null)

  let [commissary,setCommissary] = useState(null)

  let [customer,setCustomer] = useState(null)

  let [employee,setEmployee] = useState(null)

  let [mail_money,setMailTotalMoney] = useState(null)

  let [company_profit,setCompaniesProfit] = useState(null)

  let [employee_salaries,setEmployeesTotalSalaries] = useState(null)


  const [from_date, setFromDateFilter] = useState(null);
  const [to_date, setToDateFilter] = useState(null);

  const [fromGovernoratesFilter, setFromGovernoratesFilter] = useState([]);
  const [fromGovernoratesData, setFromGovernoratesData] = useState([])
  const [fromGovernoratesOptions, setFromGovernoratesOptions] = useState([])

  const [toGovernoratesFilter, setToGovernoratesFilter] = useState([]);
  const [toGovernoratesData, setToGovernoratesData] = useState([])
  const [toGovernoratesOptions, setToGovernoratesOptions] = useState([])

  const [fromDistrictsFilter, setFromDistrictsFilter] = useState([]);
  const [fromDistrictsData, setFromDistrictsData] = useState([])
  const [fromDistrictsOptions, setFromDistrictsOptions] = useState([])
  
  const [toDistrictsFilter, setToDistrictsFilter] = useState([]);
  const [toDistrictsData, setToDistrictsData] = useState([])
  const [toDistrictsOptions, setToDistrictsOptions] = useState([])

  const [fromNeighborhoodsFilter, setFromNeighborhoodsFilter] = useState([]);
  const [fromNeighborhoodsData, setFromNeighborhoodsData] = useState([])
  const [fromNeighborhoodsOptions, setFromNeighborhoodsOptions] = useState([])
  
  const [toNeighborhoodsFilter, setToNeighborhoodsFilter] = useState([]);
  const [toNeighborhoodsData, setToNeighborhoodsData] = useState([])
  const [toNeighborhoodsOptions, setToNeighborhoodsOptions] = useState([])

  const [generalErrorMessage, setGeneralErrorMessage] = useState(null);
  const [generalSuccessMessage, setGeneralSuccessMessage] = useState(null);
  const [isShownGeneralError, setIsShownGeneralError] = useState(false);
  const [isShownGeneralSuccess, setIsShownGeneralSuccess] = useState(false);
  

  const handleChangeFromDate = (value) => {
    setFromDateFilter(new Date(value))
  }
  const handleChangeToDate = (value) => {
    setToDateFilter(new Date(value))
  }

  const handleChangeFromGovernorate = (values) => {
    const fromDistrictsOptions=getFromDistrictsOptions(values)
    setFromDistrictsOptions(fromDistrictsOptions)
    setFromGovernoratesFilter(values)
  }
  const handleChangeToGovernorate = (values) => {
    const toDistrictsOptions=getToDistrictsOptions(values)
    setToDistrictsOptions(toDistrictsOptions)
    setToGovernoratesFilter(values)
  }
  const handleChangeFromDistrict = (values) => {
    const fromNeighborhoodsOptions=getFromNeighborhoodsOptions(values)
    setFromNeighborhoodsOptions(fromNeighborhoodsOptions)
    setFromDistrictsFilter(values)
  }
  const handleChangeToDistrict = (values) => {
    const toNeighborhoodsOptions=getToNeighborhoodsOptions(values)
    setToNeighborhoodsOptions(toNeighborhoodsOptions)
    setToDistrictsFilter(values)
  }
  const handleChangeFromNeighborhood = (values) => {
    setFromNeighborhoodsFilter(values)
  }
  const handleChangeToNeighborhood = (values) => {
    setToNeighborhoodsFilter(values)
  }

  const getFromDistrictsOptions = (values) =>{
    if(values.length==0){
      return []
    }
    return fromDistrictsData?.filter(x => values.indexOf(x.governorate_id)!=-1).map(x => {return {'value':x.id, 'name': x.name}})
  }
  const getToDistrictsOptions = (values) =>{
    if(values.length==0){
      return []
    }
    return toDistrictsData?.filter(x => values.indexOf(x.governorate_id)!=-1).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const getFromNeighborhoodsOptions = (values) =>{
    if(values.length==0){
      return []
    }
    return fromNeighborhoodsData?.filter(x => values.indexOf(x.district_id)!=-1).map(x => {return {'value':x.id, 'name': x.name}})
  }
  const getToNeighborhoodsOptions = (values) =>{
    if(values.length==0){
      return []
    }
    return toNeighborhoodsData?.filter(x => values.indexOf(x.district_id)!=-1).map(x => {return {'value':x.id, 'name': x.name}})
  }


  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let companies=data?.companies
          let companiesOptions=companies?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setCompaniesData(companies)
          setCompaniesOptions(companiesOptions)

          const fromGovernorates=data?.from_governorates
          const fromGovernoratesOptions=fromGovernorates?.map(x => {return {'value':x.id, 'name': x.name}})
          setFromGovernoratesData(fromGovernorates)
          setFromGovernoratesOptions(fromGovernoratesOptions)

          const fromDistricts=data?.from_districts
          setFromDistrictsData(fromDistricts)

          const fromNeighborhoods=data?.from_neighborhoods
          setFromNeighborhoodsData(fromNeighborhoods)

          const toGovernorates=data?.to_governorates
          const toGovernoratesOptions=toGovernorates?.map(x => {return {'value':x.id, 'name': x.name}})
          setToGovernoratesData(toGovernorates)
          setToGovernoratesOptions(toGovernoratesOptions)

          const toDistricts=data?.to_districts
          setToDistrictsData(toDistricts)

          const toNeighborhoods=data?.to_neighborhoods
          setToNeighborhoodsData(toNeighborhoods)

        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getStatistics=(filters)=>{
    generalFetchingServiceRef?.current?.getAllData('statistics', filters)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          setMail(data?.mail)
          setVendor(data?.vendor)
          setAgent(data?.agent)
          setCommissary(data?.commissary)
          setCustomer(data?.customer)
          setEmployee(data?.employee)
          setMailTotalMoney(data?.mail_money)
          setCompaniesProfit(data?.company_profit)
          setEmployeesTotalSalaries(data?.employee_salaries)
        }
      }).catch(error => {
        console.log(error)
      })
  }
  const handleClickClearFilters = () => {
    setSelectedCompany(null)
    setFromGovernoratesFilter([])
    setToGovernoratesFilter([])
    setFromGovernoratesFilter([])
    setToGovernoratesFilter([])
    setFromDistrictsFilter([])
    setToDistrictsFilter([])
    setFromNeighborhoodsFilter([])
    setToNeighborhoodsFilter([])
    setFromDateFilter(null)
    setToDateFilter(null)
    setIsFilters(false)

    getStatistics()
  }

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    getStatistics(filters)
    setIsFilters(true)
  }

  const getFilters = (filtersS) => {
    const startDate=(filtersS && filtersS?.start_date)?filtersS?.start_date:from_date?from_date:null
    const endDate=(filtersS && filtersS?.end_date)?filtersS?.end_date:to_date?to_date:null
    let filters={
      company_id: (filtersS && filtersS?.company_id)?filtersS?.company_id:selectedCompany,
      from_governorates: (filtersS && filtersS?.from_governorates && filtersS?.from_governorates?.length>0)?filtersS?.from_governorates:fromGovernoratesFilter,
      from_districts: (filtersS && filtersS?.from_districts && filtersS?.from_districts?.length>0)?filtersS?.from_districts:fromDistrictsFilter,
      from_neighborhoods: (filtersS && filtersS?.from_neighborhoods && filtersS?.from_neighborhoods?.length>0)?filtersS?.from_neighborhoods:fromNeighborhoodsFilter,
      to_governorates: (filtersS && filtersS?.to_governorates && filtersS?.to_governorates?.length>0)?filtersS?.to_governorates:toGovernoratesFilter,
      to_districts: (filtersS && filtersS?.to_districts && filtersS?.to_districts?.length>0)?filtersS?.to_districts:toDistrictsFilter,
      to_neighborhoods: (filtersS && filtersS?.to_neighborhoods && filtersS?.to_neighborhoods?.length>0)?filtersS?.to_neighborhoods:toNeighborhoodsFilter,
      start_date: startDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(startDate)).locale('es').format('yyyy-MM-DD'):null,
      end_date: endDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(endDate)).locale('es').format('yyyy-MM-DD'):null,
    }

    return filters
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.company_id===null && 
      (filters.from_governorates===null || filters?.from_governorates.length==0) &&
      (filters.to_governorates===null || filters?.to_governorates.length==0) &&
      (filters.from_districts===null || filters?.from_districts.length==0) &&
      (filters.from_neighborhoods===null || filters?.from_neighborhoods.length==0) &&
      (filters.to_districts===null || filters?.to_districts.length==0) &&
      (filters.to_neighborhoods===null || filters?.to_neighborhoods.length==0) &&
      filters.start_date===null && filters.end_date===null
    );
  } 


  
  const handleCompanySelectChange = (value) => {
    setIsCompanySelected(value!==null)    
    setSelectedCompany(value)
  }

  const hideMessage = () => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
  }

  const sysEnt='statistics'
  useEffect(() => {
    CheckSubscription()
    privilegesRef?.current?.getPrivileges(sysEnt).then(privilegesO=>{
      if(privilegesO && privilegesO?.privileges?.length>0){
        let mapPriv = {};
        privilegesO?.privileges?.forEach(x => {
          const key=x?.replace(`${sysEnt}_`, '')
          mapPriv={...mapPriv, [key]:true}
        });
        setIsPrivileged(mapPriv)
        setIsPrivileges(true)
        setIsLoaded(true)

        setTimeout(()=>{
          HideDropdownBoxes()
        }, 500)
      }
      if(privilegesO && privilegesO?.sideBarPrivileges?.length>0){
        setPrivilegesSideBar(privilegesO?.sideBarPrivileges)
      }
    })
  },[])

  useEffect(()=>{
    getAllData()
    getStatistics()
  },[isPrivileges])

  const CheckSubscription = () => {
    generalFetchingServiceRef?.current?.getAllData('check_subscription', null)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==0){
          showMessage(false, responseData?.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const showMessage = (isSuccess, message) => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
    if(isSuccess){
      setGeneralSuccessMessage(message)
      setIsShownGeneralSuccess(true)
    }else{
      setGeneralErrorMessage(message)
      setIsShownGeneralError(true)
    }
    window.scrollTo(0, 0)
  }


  const handleBlurMultiple = () => {
    $(`#searchToolsBar_Statistics`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }
  const handleFocusMultiple = () => {
    $(`#searchToolsBar_Statistics`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','')
    setTimeout(()=>{
      $(`#searchToolsBar_Statistics`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  const handleBlurMultiple1 = () => {
    $(`#searchToolsBar_Statistics`).find(`div[id='${styles.SelectMultiple1}'] .select-search__select`).css('display','none')
  }
  const handleFocusMultiple1 = () => {
    $(`#searchToolsBar_Statistics`).find(`div[id='${styles.SelectMultiple1}'] .select-search__select`).css('display','')
    setTimeout(()=>{
      $(`#searchToolsBar_Statistics`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }


  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectCompany}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectCompany}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  const HideDropdownBoxes=()=>{
    $(`#searchToolsBar_Statistics`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
    $(`#searchToolsBar_Statistics`).find(`div[id='${styles.SelectMultiple1}'] .select-search__select`).css('display','none')
  }

  return <DashboardLayout component='Statistics' privilegesSideBar={privilegesSideBar} locale={locale} setLocale={setLocale}>
    <TotalService totalServiceRef={totalServiceRef} />
    <GeneralFetchingService generalFetchingServiceRef={generalFetchingServiceRef} prefix='total' />
    <Privileges totalServiceRef={totalServiceRef} privilegesRef={privilegesRef}></Privileges>
    <RenderErrorMessage name='general' messages={generalErrorMessage!=null?{'general':generalErrorMessage}:{}} alertDisplay={true} isShown={isShownGeneralError} setIsShownGeneralError={setIsShownGeneralError} isDissmiss={false} handleHideMessage={hideMessage} />
    <RenderSuccessMessage name='general' messages={generalSuccessMessage!=null?{'general':generalSuccessMessage}:{}} alertDisplay={true} isShown={isShownGeneralSuccess} setIsShownGeneralSuccess={setIsShownGeneralSuccess} isDissmiss={true} handleHideMessage={hideMessage} />
    {Object.keys(isPrivileged)?.length > 0 && <div className={styles.Statistics} data-testid="Statistics">
    <div className="container-fluid">
      <section>
        <div className="row">
          <div className="col-12 mt-3 mb-1">
            <h5 className="text-uppercase">{t('General Statistics')}</h5>
            <p></p>
          </div>
        </div>
        <br/>
          
        {isPrivileged['company'] && companiesData && <div 
        id={`searchToolsBar_Statistics`}
        className={styles.searchToolsBar + ` row d-flex justify-content-center align-items-center`}
        dir={locale=='ar'?'rtl':'ltr'}>
           <div className={styles.selectsGroup}>
              <div className={styles.subSelectsGroup}>
                <SelectSearch id={styles.SelectCompany}
                  onFocus={handleOnFocusSelect}
                  options={companiesOptions}
                  onChange={handleCompanySelectChange}
                  value={selectedCompany}
                  placeholder={t('Select a company')}
                  search={true}
                  filterOptions={fuzzySearch}
                  />
              

                  <SelectSearch id={styles.SelectMultiple}
                    options={fromGovernoratesOptions}
                    onBlur={handleBlurMultiple}
                    onFocus={handleFocusMultiple}
                    onChange={handleChangeFromGovernorate}
                    value={fromGovernoratesFilter}
                    placeholder={t('Governorates From')}
                    search={true}
                    filterOptions={fuzzySearch}
                    multiple={true}
                    autoFocus={false}
                    />
              </div>

              <div className={styles.subSelectsGroup}>
                <SelectSearch id={styles.SelectMultiple}
                    options={fromDistrictsOptions}
                    onBlur={handleBlurMultiple}
                    onFocus={handleFocusMultiple}
                    onChange={handleChangeFromDistrict}
                    value={fromDistrictsFilter}
                    placeholder={t('Districts From')}
                    search={true}
                    filterOptions={fuzzySearch}
                    multiple={true}
                    autoFocus={false}
                    />

                <SelectSearch id={styles.SelectMultiple}
                  options={fromNeighborhoodsOptions}
                  onBlur={handleBlurMultiple}
                  onFocus={handleFocusMultiple}
                  onChange={handleChangeFromNeighborhood}
                  value={fromNeighborhoodsFilter}
                  placeholder={t('Neighborhoods From')}
                  search={true}
                  filterOptions={fuzzySearch}
                  multiple={true}
                  autoFocus={false}
                  />
              </div>
            </div>

           <div className={styles.selectsGroup}>
              <div className={styles.subSelectsGroup}>
              <SelectSearch id={styles.SelectMultiple1}
                  options={toGovernoratesOptions}
                  onBlur={handleBlurMultiple1}
                  onFocus={handleFocusMultiple1}
                  onChange={handleChangeToGovernorate}
                  value={toGovernoratesFilter}
                  placeholder={t('Governorates To')}
                  search={true}
                  filterOptions={fuzzySearch}
                  multiple={true}
                  autoFocus={false}
                  />

                <SelectSearch id={styles.SelectMultiple1}
                      options={toDistrictsOptions}
                      onBlur={handleBlurMultiple1}
                      onFocus={handleFocusMultiple1}
                      onChange={handleChangeToDistrict}
                      value={toDistrictsFilter}
                      placeholder={t('Districts To')}
                      search={true}
                      filterOptions={fuzzySearch}
                      multiple={true}
                      autoFocus={false}
                      />

                <SelectSearch id={styles.SelectMultiple1}
                  options={toNeighborhoodsOptions}
                  onBlur={handleBlurMultiple1}
                  onFocus={handleFocusMultiple1}
                  onChange={handleChangeToNeighborhood}
                  value={toNeighborhoodsFilter}
                  placeholder={t('Neighborhoods To')}
                  search={true}
                  filterOptions={fuzzySearch}
                  multiple={true}
                  autoFocus={false}
                  />
              </div>
            </div>

            <div className={styles.selectsGroup}>
              <div className={styles.subSelectsGroup}>
                <DatePicker wrapperClassName={styles.datePickerWrap} 
                  selected={from_date} onChange={handleChangeFromDate} placeholderText={t('Start Date')} />
                <DatePicker wrapperClassName={styles.datePickerWrap} 
                  selected={to_date} onChange={handleChangeToDate} placeholderText={t('End Date')} />
              </div>
            </div>

            <br />
            <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
              <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
                {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
                <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
              </div>
            </div>
          </div>}
        <br/>
        <br/>
        <div className="row">
        {(isPrivileged['employee_admin'] && (employee!=undefined && employee!=null)) &&<div className="col-xl-3 col-sm-6 col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between px-md-1">
                  <div className="align-self-center">
                    <RiAdminFill className="text-secondary" size={67} />
                  </div>
                  <div className="text-end">
                    <h3>{employee}</h3>
                    <p className="mb-0">{t('Admin Employees')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </div>
        <div className="row">
          {isPrivileged['company'] && companiesData && <div className="col-xl-3 col-sm-6 col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between px-md-1">
                  <div className="align-self-center">
                    <FaBuilding className="text-warning" size={67} />
                  </div>
                  <div className="text-end">
                    <h3>{companiesData?.length}</h3>
                    <p className="mb-0">{t('Companies')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>}

          {(isPrivileged['employee'] && (employee!=undefined && employee!=null)) &&<div className="col-xl-3 col-sm-6 col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between px-md-1">
                  <div className="align-self-center">
                    <MdOutlineWork className="text-secondary" size={67} />
                  </div>
                  <div className="text-end">
                    <h3>{employee}</h3>
                    <p className="mb-0">{t('Employees')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </div>
        <div className="row">
          {(isPrivileged['vendor'] && (vendor!=undefined && vendor!=null)) &&<div className="col-xl-3 col-sm-6 col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between px-md-1">
                  <div className="align-self-center">
                    <BsShopWindow className="text-danger" size={67} />
                  </div>
                  <div className="text-end">
                    <h3>{vendor}</h3>
                    <p className="mb-0">{t('Vendors')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>}


          {(isPrivileged['agent'] && (agent!=undefined && agent!=null)) &&<div className="col-xl-3 col-sm-6 col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between px-md-1">
                  <div className="align-self-center">
                    <MdRealEstateAgent className="text-secondary" size={67} />
                  </div>
                  <div className="text-end">
                    <h3>{agent}</h3>
                    <p className="mb-0">{t('Agents')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>}


          {(isPrivileged['commissary'] && (commissary!=undefined && commissary!=null)) &&<div className="col-xl-3 col-sm-6 col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between px-md-1">
                  <div className="align-self-center">
                    <CiPercent className="text-success" size={67} />
                  </div>
                  <div className="text-end">
                    <h3>{commissary}</h3>
                    <p className="mb-0">{t('Commissaries')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>}

          {(isPrivileged['customer'] && (commissary!=undefined && commissary!=null)) &&<div className="col-xl-3 col-sm-6 col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between px-md-1">
                  <div className="align-self-center">
                    <GiBuyCard className="text-success" size={67} />
                  </div>
                  <div className="text-end">
                    <h3>{customer}</h3>
                    <p className="mb-0">{t('Customers')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>}


          

        </div>
        <div className="row">
          {(isPrivileged['mail'] && (mail!=undefined && mail!=null)) && <div className="col-xl-3 col-sm-6 col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between px-md-1">
                  <div className="align-self-center">
                    <FaMailBulk className="text-info" size={67} />
                  </div>
                  <div className="text-end">
                    <h3>{mail}</h3>
                    <p className="mb-0">{t('Mails')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>}

          {(isPrivileged['mail_money'] && (mail_money!=undefined && mail_money!=null)) &&<div className="col-xl-3 col-sm-6 col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between px-md-1">
                  <div className="align-self-center">
                    <GiTakeMyMoney className="text-success" size={67} />
                  </div>
                  <div className="text-end">
                    <h3>{mail_money}</h3>
                    <p className="mb-0">{t('Mails total money')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>}



          {(isPrivileged['company_profit'] && (company_profit!=undefined && company_profit!=null)) &&<div className="col-xl-3 col-sm-6 col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between px-md-1">
                  <div className="align-self-center">
                    <GiProfit className="text-warning" size={67} />
                  </div>
                  <div className="text-end">
                    <h3>{company_profit}</h3>
                    <p className="mb-0">{t('Companies total profits')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>}


          {(isPrivileged['employee_salaries'] && (employee_salaries!=undefined && employee_salaries!=null)) &&<div className="col-xl-3 col-sm-6 col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between px-md-1">
                  <div className="align-self-center">
                    <GiPayMoney className="text-danger" size={67} />
                  </div>
                  <div className="text-end">
                    <h3>{employee_salaries}</h3>
                    <p className="mb-0">{t('Employees total salaries')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>}


        </div>
      </section>
    </div>
    </div>}

    {isLoaded && Object.keys(isPrivileged)?.length == 0 && <NoPrivilegesMessage />}
  </DashboardLayout>
};

Statistics.propTypes = {};

Statistics.defaultProps = {};

export default Statistics;
