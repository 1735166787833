import React, {useEffect, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './AddEdit.module.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import {GrFormAdd} from 'react-icons/gr';
import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';

import { useTranslation } from 'react-i18next';
import GeneralHelpers from '../../../Services/GeneralHelpers';
import $ from 'jquery';


const AddEdit = ({id, editSource, handleAddEdit, generalFetchingServiceRef, locale, openPopUpCustomerAdd, addEditRef}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [fieldsErrors, setFieldsErrors] = useState([]);


  const [mailStatusData, setMailStatusData] = useState([])
  const [mailStatusOptions, setMailStatusOptions] = useState([])

  const [mailPaymentMethodData, setPaymentMethodData] = useState([])
  const [mailPaymentMethodOptions, setPaymentMethodOptions] = useState([])

  const [companiesData, setCompaniesData] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])

  

  const [customersData, setCustomersData] = useState([])
  const [customersOptions, setCustomersOptions] = useState([])

  const [vendorsData, setVendorsData] = useState([])
  const [vendorsOptions, setVendorsOptions] = useState([])

  const [agentsData, setAgentsData] = useState([])
  const [agentsOptions, setAgentsOptions] = useState([])

  const [commissariesData, setCommissariesData] = useState([])
  const [commissariesOptions, setCommissariesOptions] = useState([])

  const [governoratesFromData, setGovernoratesFromData] = useState([])
  const [governoratesFromOptions, setGovernoratesFromOptions] = useState([])

  const [districtsFromData, setDistrictsFromData] = useState([])
  const [districtsFromOptions, setDistrictsFromOptions] = useState([])

  const [neighborhoodsFromData, setNeighborhoodsFromData] = useState([])
  const [neighborhoodsFromOptions, setNeighborhoodsFromOptions] = useState([])

  const [governoratesToData, setGovernoratesToData] = useState([])
  const [governoratesToOptions, setGovernoratesToOptions] = useState([])

  const [districtsToData, setDistrictsToData] = useState([])
  const [districtsToOptions, setDistrictsToOptions] = useState([])

  const [neighborhoodsToData, setNeighborhoodsToData] = useState([])
  const [neighborhoodsToOptions, setNeighborhoodsToOptions] = useState([])

  const [shippingTypeData, setShippingTypeData] = useState([])
  const [shippingTypeOptions, setShippingTypeOptions] = useState([])

  const [shippingFactorData, setShippingFactorData] = useState([])
  const [shippingFactorOptions, setShippingFactorOptions] = useState([])

  const [code, setCode] = useState("")
  const [name, setName] = useState("")
  const [price, setPrice] = useState("")
  const [shipping_type, setShippingType] = useState(null)
  const [shipping_price_source, setShippingPriceSource] = useState("")
  const [shipping_price, setShippingPrice] = useState("")
  const [shipping_factor, setShippingFactor] = useState(null)
  const [shipping_total, setShippingTotal] = useState(0)
  const [size, setSize] = useState("")
  const [mail_status_id, setMailStatusId] = useState(null)
  const [mail_payment_method_id, setMailPaymentMethodId] = useState(null)
  const [company_id, setCompanyId] = useState(null)
  const [customer_id, setCustomerId] = useState(null)
  const [vendor_id, setVendorId] = useState(null)
  const [agent_id, setAgentId] = useState(null)
  const [commissary_id, setCommissaryId] = useState(null)
  const [from_governorate_id, setFromGovernorateId] = useState(null)
  const [to_governorate_id, setToGovernorateId] = useState(null)
  const [from_district_id, setFromDistrictId] = useState(null)
  const [to_district_id, setToDistrictId] = useState(null)
  const [from_neighborhood_id, setFromNeighborhoodId] = useState(null)
  const [to_neighborhood_id, setToNeighborhoodId] = useState(null)
  const [from_address, setFromAddress] = useState("")
  const [to_address, setToAddress] = useState("")
  const [shipping_date, setShippingDate] = useState(new Date());
  const [postpone_date, setPostponeDate] = useState(new Date());
  const [partially_delivered_value, setPartiallyDeliveredValue] = useState('');
  const [is_money_received, setIsMoneyReceived] = useState(false);
  const [shipping_commission_value, setShippingCommissionValue] = useState(0);
  
  const [isPartiallyDeliveredVisible, setIsPartiallyDeliveredVisible] = useState(false);

  useImperativeHandle(addEditRef,()=>({
    refreshData(){
      getCompanyData(company_id)
    },
  }))

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData(editSource=='all'?'':editSource)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let mailStatus=data?.mailStatus
          let mailStatusOptions=mailStatus?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setMailStatusData(mailStatus)
          setMailStatusOptions(mailStatusOptions)

          let mailPaymentMethods=data?.mailPaymentMethods
          let mailPaymentMethodsOptions=mailPaymentMethods?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setPaymentMethodData(mailPaymentMethods)
          setPaymentMethodOptions(mailPaymentMethodsOptions)
          
          let companies=data?.companies
          let companiesOptions=companies?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setCompaniesData(companies)
          setCompaniesOptions(companiesOptions)

          let governorates_from=data?.governorates
          let governoratesFromOptions=governorates_from?.map(x => {return {'value':x.id, 'name': x.name}})
          setGovernoratesFromData(governorates_from)
          setGovernoratesFromOptions(governoratesFromOptions)

          let districts_from=data?.districts
          setDistrictsFromData(districts_from)

          let neighborhoods_from=data?.neighborhoods
          setNeighborhoodsFromData(neighborhoods_from)

          let governorates_to=data?.governorates
          let governoratesToOptions=governorates_to?.map(x => {return {'value':x.id, 'name': x.name}})
          setGovernoratesToData(governorates_to)
          setGovernoratesToOptions(governoratesToOptions)

          let districts_to=data?.districts
          setDistrictsToData(districts_to)

          let neighborhoods_to=data?.neighborhoods
          setNeighborhoodsToData(neighborhoods_to)

          let shippingFactors=[1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10]
          let shippingFactorOptions=shippingFactors?.map(x => {return {'value':x, 'name': x+''}})
          setShippingFactorData(shippingFactors)
          setShippingFactorOptions(shippingFactorOptions)

          let shippingTypes=[
            {value: 'internal_center', name: 'Internal Governorate _ Center'},
            {value: 'internal_margin', name: 'Internal Governorate _ Margin'},
            {value: 'outer', name: 'Outer Governorate'},
          ]
          let shippingTypesOptions=shippingTypes?.map(x => {return {'value':x?.value, 'name': t(x?.name)}})
          setShippingTypeData(shippingTypes)
          setShippingTypeOptions(shippingTypesOptions)
          

          if(id){
            getById(id)
          }
        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getCompanyData=(companyId)=>{
    generalFetchingServiceRef?.current?.getById(companyId, 'company')
      .then(response => {
        console.log(response)
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let customers=data?.customers
          let customersOptions=customers?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setCustomersData(customers)
          setCustomersOptions(customersOptions)

          let vendors=data?.vendors
          let vendorsOptions=vendors?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setVendorsData(vendors)
          setVendorsOptions(vendorsOptions)

          let agents=data?.agents
          let agentsOptions=agents?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setAgentsData(agents)
          setAgentsOptions(agentsOptions)

          let commissaries=data?.commissaries
          let commissariesOptions=commissaries?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setCommissariesData(commissaries)
          setCommissariesOptions(commissariesOptions)
        }
      }).catch(error => {
        console.log(error)
      })
  }
  

  const getObject = () =>{
    let shippingDateF=shipping_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(shipping_date)).locale("es").format('yyyy-MM-DD'):null
    let postponeDateF=postpone_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(postpone_date)).locale("es").format('yyyy-MM-DD'):null
    let data={
      code,
      name,
      price,
      shipping_type,
      shipping_price_source,
      shipping_price,
      shipping_factor,
      shipping_total,
      shipping_commission_value,
      size,
      mail_status_id:mailStatusData.filter(x => x.id == parseInt(mail_status_id))[0]?.id,
      mail_payment_method_id:mailPaymentMethodData.filter(x => x.id == parseInt(mail_payment_method_id))[0]?.id,
      company_id:(prefix=='company')?(auth?.user?.user_info?.company?.id):(companiesData.filter(x => x.id == parseInt(company_id))[0]?.id),
      customer_id:customersData.filter(x => x.id == parseInt(customer_id))[0]?.id,
      vendor_id:vendorsData.filter(x => x.id == parseInt(vendor_id))[0]?.id,
      agent_id:agentsData.filter(x => x.id == parseInt(agent_id))[0]?.id,
      commissary_id:commissariesData.filter(x => x.id == parseInt(commissary_id))[0]?.id,
      from_governorate_id:governoratesFromData.filter(x => x.id == parseInt(from_governorate_id))[0]?.id,
      to_governorate_id:governoratesToData.filter(x => x.id == parseInt(to_governorate_id))[0]?.id,
      from_district_id:districtsFromData.filter(x => x.id == parseInt(from_district_id))[0]?.id,
      to_district_id:districtsToData.filter(x => x.id == parseInt(to_district_id))[0]?.id,
      from_neighborhood_id:neighborhoodsFromData.filter(x => x.id == parseInt(from_neighborhood_id))[0]?.id,
      to_neighborhood_id:neighborhoodsToData.filter(x => x.id == parseInt(to_neighborhood_id))[0]?.id,
      from_address,
      to_address,
      shipping_date:shippingDateF,
      postpone_date: postponeDateF,
      partially_delivered_value,
    };
    return data;
  }
  const handleAddBtnClick=()=>{
    let item=getObject()
    handleAddEdit(item, setFieldsErrors, resetData, editSource)
    window.scrollTo(0, 0);
  }
  
  const handleEditBtnClick=()=>{
    let item=getObject()
    item.id=id
    handleAddEdit(item, setFieldsErrors, null, editSource)
    window.scrollTo(0, 0);
  }

  const resetData = () => {
    setCode('')
    setName('')
    setPrice('')
    setShippingType(null)
    setShippingPriceSource('')
    setShippingPrice('')
    setShippingFactor(null)
    setShippingTotal(0)
    setSize('')
    setMailStatusId(null)
    setMailPaymentMethodId(null)
    setCompanyId(null)
    setCustomerId(null)
    setVendorId(null)
    setAgentId(null)
    setCommissaryId(null)
    setFromGovernorateId(null)
    setToGovernorateId(null)
    setFromDistrictId(null)
    setToDistrictId(null)
    setFromNeighborhoodId(null)
    setToNeighborhoodId(null)
    setFromAddress('')
    setToAddress('')
    setShippingDate(null)
    setPostponeDate(null)
    setPartiallyDeliveredValue('')

    setVendorsOptions(null)
    setAgentsOptions(null)
    setCommissariesOptions(null)
    setCustomersOptions(null)
  }

  useEffect(()=>{
    setShippingData()
  }, [company_id])

  useEffect(()=>{
    setShippingData()
  }, [vendor_id])

  useEffect(()=>{
    setShippingData()
  }, [shipping_type])

  const setShippingData = () =>{
    if(!company_id){
      return;
    }

    let company=companiesData.filter(x => x.id==company_id)
    if(company && company?.length>0){
      company=company[0]
      switch(shipping_type){
        case 'internal_center':
          setShippingPrice(company?.shipping_price_internal_center)
          break;
        case 'internal_margin':
          setShippingPrice(company?.shipping_price_internal_margin)
          break;
        case 'outer':
          setShippingPrice(company?.shipping_price_outer)
          break;
      }
    }
    
    
    let PriceSource='Company'
    if(vendor_id){
      let vendor=vendorsData.filter(x => x.id==vendor_id)
      if(vendor && vendor?.length>0){
        vendor=vendor[0]
        switch(shipping_type){
          case 'internal_center':
            if(vendor?.shipping_price_internal_center){
              setShippingPrice(vendor?.shipping_price_internal_center)
              PriceSource='Vendor'
            }
            break;
          case 'internal_margin':
            if(vendor?.shipping_price_internal_margin){
              setShippingPrice(vendor?.shipping_price_internal_margin)
              PriceSource='Vendor'
            }
            break;
          case 'outer':
            if(vendor?.shipping_price_outer){
              setShippingPrice(vendor?.shipping_price_outer)
              PriceSource='Vendor'
            }
            break;
        }
      }
    }

    setShippingPriceSource(PriceSource)
  }

  useEffect(()=>{
    getShippingCommisionValue()
  }, [shipping_type])

  useEffect(()=>{
    getShippingCommisionValue()
  }, [agent_id])

  useEffect(()=>{
    getShippingCommisionValue()
  }, [commissary_id])

  useEffect(()=>{
    calcShippingTotal()
  }, [shipping_price])

  useEffect(()=>{
    calcShippingTotal()
  }, [shipping_factor])
  
  const calcShippingTotal = () => {
    if(shipping_price && shipping_factor){
      setShippingTotal(shipping_factor*shipping_price)
    }
  }

  const getDistrictsFromOptions = (value) =>{
    return districtsFromData?.filter(x => x.governorate_id == value).map(x => {return {'value':x.id, 'name': x.name}})
  }
  const getDistrictsToOptions = (value) =>{
    return districtsToData?.filter(x => x.governorate_id == value).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const getNeighborhoodsFromOptions = (value) =>{
    return neighborhoodsFromData?.filter(x => x.district_id == value).map(x => {return {'value':x.id, 'name': x.name}})
  }
  const getNeighborhoodsToOptions = (value) =>{
    return neighborhoodsToData?.filter(x => x.district_id == value).map(x => {return {'value':x.id, 'name': x.name}})
  }
 
  const handleChangeCode=(e)=>{
    setCode(e.target.value)
  }
  const handleChangeName=(e)=>{
    setName(e.target.value)
  }
  const handleChangePrice=(e)=>{
    setPrice(e.target.value)
  }
  const handleChangeShippingType=(value)=>{
    setShippingType(value)
    getAllData()
  }
  const handleChangeShippingFactor=(value)=>{
    setShippingFactor(value)
  }
  const handleChangeSize=(e)=>{
    setSize(e.target.value)
  }
  const handleChangeMailStatus=(value)=>{
    setMailStatusId(value)
    const status=mailStatusData?.filter(x => x.id==value)[0]
    if(status?.name=='received_partially'){
      setIsPartiallyDeliveredVisible(true)
    }else{
      setIsPartiallyDeliveredVisible(false)
    }
  }
  const handleChangeMailPaymentMethod=(value)=>{
    setMailPaymentMethodId(value)
  }
  const handleChangeCompany=(value)=>{
    setCompanyId(value)
    getCompanyData(value)
  }
  const handleChangeCustomer=(value)=>{
    setCustomerId(value)
  }
  const handleChangeVendor=(value)=>{
    setVendorId(value)
  }
  const handleChangeAgent=(value)=>{
    setAgentId(value)
    setCommissaryId(null)
  }
  const handleChangeCommissary=(value)=>{
    setCommissaryId(value)
    setAgentId(null)
  }
  const handleChangeFromGovernorate=(value)=>{
    const districtsFromOptions=getDistrictsFromOptions(value)
    setDistrictsFromOptions(districtsFromOptions)
    setFromGovernorateId(value)
  }
  const handleChangeToGovernorate=(value)=>{
    const districtsToOptions=getDistrictsToOptions(value)
    setDistrictsToOptions(districtsToOptions)
    setToGovernorateId(value)
  }
  const handleChangeFromDistrict=(value)=>{
    const neighborhoodsFromOptions=getNeighborhoodsFromOptions(value)
    setNeighborhoodsFromOptions(neighborhoodsFromOptions)
    setFromDistrictId(value)
  }
  const handleChangeToDistrict=(value)=>{
    const neighborhoodsToOptions=getNeighborhoodsToOptions(value)
    setNeighborhoodsToOptions(neighborhoodsToOptions)
    setToDistrictId(value)
  }
  const handleChangeFromNeighborhood=(value)=>{
    setFromNeighborhoodId(value)
  }
  const handleChangeToNeighborhood=(value)=>{
    setToNeighborhoodId(value)
  }
  const handleChangeFromAddress=(e)=>{
    setFromAddress(e.target.value)
  }
  const handleChangeToAddress=(e)=>{
    setToAddress(e.target.value)
  }
  const handleChangeShippingDate=(date)=>{
    setShippingDate(new Date(date))
  }
  const handleChangePostponeDate=(date)=>{
    setPostponeDate(new Date(date))
  }
  const handleChangePartiallyDeliveredValue=(e)=>{
    setPartiallyDeliveredValue(e.target.value)
  }

  const setAllData=(data)=>{
    let item=editSource=='all'?data[0]:data
    setCode(item.code)
    setName(item.name)
    setPrice(item.price)
    setShippingType(item.shipping_type)
    setShippingPriceSource(item.shipping_price_source)
    setShippingPrice(item.shipping_price)
    setShippingFactor(item.shipping_factor)
    setShippingTotal(item.shipping_total)
    setSize(item.size)
    setMailStatusId(item.mail_status_id)
    setMailPaymentMethodId(item.mail_payment_method_id)
    setCompanyId(item.company_id)
    setCustomerId(item.customer_id)
    setVendorId(item.vendor_id)
    setAgentId(item.agent_id)
    setCommissaryId(item.commissary_id)
    setFromGovernorateId(item.from_governorate_id)
    setToGovernorateId(item.to_governorate_id)
    setFromDistrictId(item.from_district_id)
    setToDistrictId(item.to_district_id)
    setFromNeighborhoodId(item.from_neighborhood_id)
    setToNeighborhoodId(item.to_neighborhood_id)
    setFromAddress(item.from_address)
    setToAddress(item.to_address)
    setShippingDate(new Date(item.shipping_date))
    setPostponeDate(new Date(item.postpone_date))
    setPartiallyDeliveredValue(item.partially_delivered_value)
    setIsMoneyReceived(item.is_money_received)
    setShippingCommissionValue(item.shipping_commission_value)
    getCompanyData(item?.company_id)
  }

  let isLoaded=false
  const getById = (id) => {
    generalFetchingServiceRef?.current?.getById(id, editSource=='all'?'':editSource)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setAllData(data)
          }
        }).catch(error => {
          console.log(error)
        })
  }
  useEffect(()=>{
    getAllData()
    if(prefix=='company'){
      let companyId=auth?.user?.user_info?.company?.id
      if(companyId){
        setCompanyId(companyId)
        getCompanyData(companyId)
      }
    }
    if(prefix=='employee'){
      let companyId=auth?.user?.user_info?.employee?.company?.id
      if(companyId){
        setCompanyId(companyId)
      }
    }
  },[])

  useEffect(()=>{
    if(!isLoaded){
      if(id){
        const districtsFromOptions=getDistrictsFromOptions(from_governorate_id)
        setDistrictsFromOptions(districtsFromOptions)
        const districtsToOptions=getDistrictsToOptions(to_governorate_id)
        setDistrictsToOptions(districtsToOptions)

        const neighborhoodsFromOptions=getNeighborhoodsFromOptions(from_governorate_id)
        setNeighborhoodsFromOptions(neighborhoodsFromOptions)
        const neighborhoodsToOptions=getNeighborhoodsToOptions(to_governorate_id)
        setNeighborhoodsToOptions(neighborhoodsToOptions)
      }
      isLoaded=true
    }
  },[from_governorate_id, to_governorate_id])



  const getShippingCommisionValue = () => {
    if(shipping_type!==null && (agent_id!==null || commissary_id!==null)){
      let person=null
      if(agent_id!==null){
        person=agentsData.filter(x => x.id==agent_id)
      }
      if(commissary_id!==null){
        person=commissariesData.filter(x => x.id==commissary_id)
      }
      if(person && person?.length>0){
        person=person[0]
        switch(shipping_type){
          case 'internal_center':
            setShippingCommissionValue(person?.shipping_price_internal_center)
            break;
          case 'internal_margin':
            setShippingCommissionValue(person?.shipping_price_internal_margin)
            break;
          case 'outer':
            setShippingCommissionValue(person?.shipping_price_outer)
            break;
        }
      }
    }
  }

  const generateCode = () => {
    generalFetchingServiceRef?.current?.getAllData('generate_code')
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setCode(data)
          }
        }).catch(error => {
          console.log(error)
        })
  }

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <div className={styles.AddEdit + ` ${locale=='ar'?'text-right':'text-left'}`} data-testid="AddEdit">

    <div className="form-group">
      <label htmlFor="code">{t('Mail Code')}</label>
      <RenderErrorMessage name='code' messages={fieldsErrors} />
      <div className={styles.RowContainer}>
        <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('code')}`} id="code" onChange={handleChangeCode} value={code}
        disabled={id!==null && is_money_received==true}
        title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''} />
        <button className='btn btn-primary' onClick={generateCode}>{t('Generate')}</button>
      </div>
    </div>

    <div className="form-group">
      <label htmlFor="name">{t('Name')}</label>
      <RenderErrorMessage name='name' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('name')}`} id="name" onChange={handleChangeName} value={name}
      disabled={id!==null && is_money_received==true}
      title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''} />
    </div>

    {(prefix!='company') && <div className="form-group">
      <label htmlFor="company_id">{t('Company')}</label>
      <RenderErrorMessage name='company_id' messages={fieldsErrors} />
      <div>
      <SelectSearch id={styles.Select}
        onFocus={handleOnFocusSelect}
        options={companiesOptions}
        onChange={handleChangeCompany}
        value={company_id}
        placeholder={`${t('Select a')} ${t('company')}`}
        search={true}
        filterOptions={fuzzySearch}
        disabled={id!==null && is_money_received==true}
        title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
        />
      </div> 
    </div>}

    <div className="form-group">
      <label htmlFor="vendor_id">{t('Vendor')}</label>
      <RenderErrorMessage name='vendor_id' messages={fieldsErrors} />
      <div>
        <SelectSearch id={styles.Select}
          onFocus={handleOnFocusSelect}
          options={vendorsOptions}
          onChange={handleChangeVendor}
          value={vendor_id}
          placeholder={`${t('Select a')} ${t('vendor')}`}
          search={true}
          filterOptions={fuzzySearch}
          disabled={id!==null && is_money_received==true}
          title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
          />
      </div>
    </div>

    <div className="form-group">
      <label htmlFor="agent_id">{t('Agent')}</label>
      <RenderErrorMessage name='agent_id' messages={fieldsErrors} />
      <div>
        <SelectSearch id={styles.Select}
          onFocus={handleOnFocusSelect}
          options={agentsOptions}
          onChange={handleChangeAgent}
          value={agent_id}
          placeholder={`${t('Select an')} ${t('agent')}`}
          search={true}
          filterOptions={fuzzySearch}
          disabled={id!==null && is_money_received==true}
          title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
          />
      </div>
    </div>

    <div className="form-group">
      <label htmlFor="commissary_id">{t('Commissary')}</label>
      <RenderErrorMessage name='commissary_id' messages={fieldsErrors} />
      <div>
        <SelectSearch id={styles.Select}
          onFocus={handleOnFocusSelect}
          options={commissariesOptions}
          onChange={handleChangeCommissary}
          value={commissary_id}
          placeholder={`${t('Select a')} ${t('commissary')}`}
          search={true}
          filterOptions={fuzzySearch}
          disabled={id!==null && is_money_received==true}
          title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
          />
      </div>
    </div>

    <div className="form-group">
      <label htmlFor="customer_id">{t('Customer')}</label>
      <RenderErrorMessage name='customer_id' messages={fieldsErrors} />
      <div className='d-flex flex-row'>
        <SelectSearch id={styles.Select}
          onFocus={handleOnFocusSelect}
          options={customersOptions}
          onChange={handleChangeCustomer}
          value={customer_id}
          placeholder={`${t('Select a')} ${t('customer')}`}
          search={true}
          filterOptions={fuzzySearch}
          disabled={id!==null && is_money_received==true}
          title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
          />
          &nbsp;
          &nbsp;
          <button className='btn btn-secondary' onClick={() => {
            openPopUpCustomerAdd()
          }}>+</button>
      </div>
      
    </div>

    <div className="form-group">
      <label htmlFor="price">{t('Price')}</label>
      <RenderErrorMessage name='price' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('price')}`} id="price" onChange={handleChangePrice} value={price}
        disabled={id!==null && is_money_received==true}
        title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
      />
    </div>

    <div className="form-group">
      <label htmlFor="shipping_type">{t('Shipping Type')}</label>
      <RenderErrorMessage name='shipping_type' messages={fieldsErrors} />
      <div>
        <SelectSearch id={styles.Select}
          onFocus={handleOnFocusSelect}
          options={shippingTypeOptions}
          onChange={handleChangeShippingType}
          value={shipping_type}
          placeholder={`${t('Select a')} ${t('type')}`}
          search={true}
          filterOptions={fuzzySearch}
          disabled={id!==null && is_money_received==true}
          title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
          />
      </div>
    </div>

    <div className="form-group">
      <label htmlFor="shipping_price_source">{t('Shipping Price Source')}</label>
      <RenderErrorMessage name='shipping_price_source' messages={fieldsErrors} />
      <div className='card p-2 bg-dark text-light'>{shipping_price_source?t(shipping_price_source):t('none')}</div>
    </div>

    <div className="form-group">
      <label htmlFor="shipping_price">{t('Shipping Price')}</label>
      <RenderErrorMessage name='shipping_price' messages={fieldsErrors} />
      <div className='card p-2 bg-dark text-light'>{shipping_price?t(shipping_price):t('none')}</div>
    </div>

    <div className="form-group">
      <label htmlFor="shipping_factor">{t('Shipping Price Factor')}</label>
      <RenderErrorMessage name='shipping_factor' messages={fieldsErrors} />
      <div>
        <SelectSearch id={styles.Select}
          onFocus={handleOnFocusSelect}
          options={shippingFactorOptions}
          onChange={handleChangeShippingFactor}
          value={shipping_factor}
          placeholder={`${t('Select a')} ${t('factor')}`}
          search={true}
          filterOptions={fuzzySearch}
          disabled={id!==null && is_money_received==true}
          title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
          />
      </div>
    </div>

    <div className="form-group">
      <label htmlFor="shipping_total">{t('Shipping Total')}</label>
      <RenderErrorMessage name='shipping_total' messages={fieldsErrors} />
      <div className='card p-2 bg-dark text-light'>{shipping_total}</div>
    </div>

    <div className="form-group">
      <label htmlFor="shipping_commission_value">{`${t('Shipping Commission Value')} (${t('for agent or commissary')})`}</label>
      <RenderErrorMessage name='shipping_commission_value' messages={fieldsErrors} />
      <div className='card p-2 bg-dark text-light'>{shipping_commission_value}</div>
    </div>
       

    <div className="form-group">
      <label htmlFor="size">{`${t('Size')} (${t('cm3')})`}</label>
      <RenderErrorMessage name='size' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('size')}`} id="size" onChange={handleChangeSize} value={size}
      disabled={id!==null && is_money_received==true}
      title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''} />
    </div>
     
    <div className="form-group">
      <label htmlFor="mail_status_id">{t('Mail Status')}</label>
      <RenderErrorMessage name='mail_status_id' messages={fieldsErrors} />
      <div>
        <SelectSearch id={styles.Select}
          onFocus={handleOnFocusSelect}
          options={mailStatusOptions}
          onChange={handleChangeMailStatus}
          value={mail_status_id}
          placeholder={`${t('Select a')} ${t('mail status')}`}
          search={true}
          filterOptions={fuzzySearch}
          disabled={id!==null && is_money_received==true}
          title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
          />
      </div>
    </div>

    {isPartiallyDeliveredVisible && <div className="form-group">
      <label htmlFor="partially_delivered_value">{t('Partially Delivered Value')}</label>
      <RenderErrorMessage name='partially_delivered_value' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('value')}`} id="partially_delivered_value" onChange={handleChangePartiallyDeliveredValue} value={partially_delivered_value}
        disabled={id!==null && is_money_received==true}
        title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
      />
    </div>}

    <div className="form-group">
      <label htmlFor="mail_payment_method_id">{t('Payment Method')}</label>
      <RenderErrorMessage name='mail_payment_method_id' messages={fieldsErrors} />
      <div>
        <SelectSearch id={styles.Select}
          onFocus={handleOnFocusSelect}
          options={mailPaymentMethodOptions}
          onChange={handleChangeMailPaymentMethod}
          value={mail_payment_method_id}
          placeholder={`${t('Select a')} ${t('payment method')}`}
          search={true}
          filterOptions={fuzzySearch}
          disabled={id!==null && is_money_received==true}
          title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
          />
      </div>
    </div>


    <div className={styles.AddressGroup+" form-group card"} style={{'padding':'10px'}}>
      <label>{t('From')}</label>
      <div style={{'padding':'20px'}}>
        <RenderErrorMessage name='from_governorate_id' messages={fieldsErrors} />
        <RenderErrorMessage name='from_district_id' messages={fieldsErrors} />
        <RenderErrorMessage name='from_neighborhood_id' messages={fieldsErrors} />
        <RenderErrorMessage name='from_address' messages={fieldsErrors} />
        <span>
          <label htmlFor="from_governorate_id">{t('governorate')}</label>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={governoratesFromOptions}
            onChange={handleChangeFromGovernorate}
            value={from_governorate_id}
            placeholder={`${t('Select a')} ${t('governorate')}`}
            search={true}
            filterOptions={fuzzySearch}
            disabled={id!==null && is_money_received==true}
            title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
            />
        </span><br/>
        <span>
          <label htmlFor="from_district_id">{t('district')}</label>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={districtsFromOptions}
            onChange={handleChangeFromDistrict}
            value={from_district_id}
            placeholder={`${t('Select a')} ${t('district')}`}
            search={true}
            filterOptions={fuzzySearch}
            disabled={id!==null && is_money_received==true}
            title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
            />
        </span><br/>
        <span>
          <label htmlFor="from_neighborhood_id">{t('neighborhood')}</label>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={neighborhoodsFromOptions}
            onChange={handleChangeFromNeighborhood}
            value={from_neighborhood_id}
            placeholder={`${t('Select a')} ${t('neighborhood')}`}
            search={true}
            filterOptions={fuzzySearch}
            disabled={id!==null && is_money_received==true}
            title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
            />
        </span><br/>
        <span>
          <label htmlFor="from_address">{t('address')}</label> <br/>
          <input type={'text'} value={from_address} onChange={handleChangeFromAddress} 
            disabled={id!==null && is_money_received==true}
            title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
            />
        </span>
      </div>
    </div>

    <div className={styles.AddressGroup+" form-group card"} style={{'padding':'10px'}}>
      <label>{t('To')}</label>
      <RenderErrorMessage name='to_governorate_id' messages={fieldsErrors} />
      <RenderErrorMessage name='to_district_id' messages={fieldsErrors} />
      <RenderErrorMessage name='to_address' messages={fieldsErrors} />
      <div style={{'padding':'20px'}}>
        <span>
          <label htmlFor="to_governorate_id">{t('governorate')}</label>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={governoratesToOptions}
            onChange={handleChangeToGovernorate}
            value={to_governorate_id}
            placeholder={`${t('Select a')} ${t('governorate')}`}
            search={true}
            filterOptions={fuzzySearch}
            disabled={id!==null && is_money_received==true}
            title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
            />
        </span><br/>
        <span>
          <label htmlFor="to_district_id">{t('district')}</label>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={districtsToOptions}
            onChange={handleChangeToDistrict}
            value={to_district_id}
            placeholder={`${t('Select a')} ${t('district')}`}
            search={true}
            filterOptions={fuzzySearch}
            disabled={id!==null && is_money_received==true}
            title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
            />
        </span><br/>
        <span>
          <label htmlFor="to_neighborhood_id">{t('neighborhood')}</label>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={neighborhoodsToOptions}
            onChange={handleChangeToNeighborhood}
            value={to_neighborhood_id}
            placeholder={`${t('Select a')} ${t('neighborhood')}`}
            search={true}
            filterOptions={fuzzySearch}
            disabled={id!==null && is_money_received==true}
            title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
            />
        </span><br/>
        <span>
          <label htmlFor="to_address">{t('address')}</label><br/>
          <input type={'text'} value={to_address} onChange={handleChangeToAddress} 
            disabled={id!==null && is_money_received==true}
            title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
            />
        </span>
      </div>
    </div>

    <div className="form-group">
      <label htmlFor="shipping_date">{t('Shipping Date')}</label>
      <RenderErrorMessage name='shipping_date' messages={fieldsErrors} />
      <div>
        <DatePicker selected={shipping_date} onChange={handleChangeShippingDate} 
            disabled={id!==null && is_money_received==true}
            title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}
            />
      </div>
    </div>

    {(mail_status_id == mailStatusData?.filter(x => x?.name=='delayed')[0]?.id) && <div className="form-group">
      <label htmlFor="postpone_date">{t('Postpone Date')}</label>
      <RenderErrorMessage name='postpone_date' messages={fieldsErrors} />
      <div>
        <DatePicker selected={postpone_date} onChange={handleChangePostponeDate} 
          disabled={id!==null && is_money_received==true}
          title={(id && is_money_received)?t('The mail\'s money is received, you shouldn\'t edit it!'):''}/>
      </div>
    </div>}


    <div className="form-group text-center">
      {!id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleAddBtnClick}>{t('Add')}</button>}
      {id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={()=> is_money_received?'':handleEditBtnClick()}>{t('Edit')}</button>}
    </div>

  </div>
};

AddEdit.propTypes = {};

AddEdit.defaultProps = {};

export default AddEdit;
