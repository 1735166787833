import React, {useState, useImperativeHandle, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';
import {AiFillPrinter} from 'react-icons/ai';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../../Services/GeneralHelpers';
import ReactToPrint from 'react-to-print';

import { useTranslation } from 'react-i18next';

const ToolsBar = ({toolsBarRef, printComponentRef, logRef, paginationRef, identifier, filtersDataService, isPrivileged, locale}) => {
  const { t, i18n } = useTranslation();

  const [from_date, setFromDateFilter] = useState(null);
  const [to_date, setToDateFilter] = useState(null);

  const [userFilter, setUserFilter] = useState(null);
  const [userData, setUserData] = useState([])
  const [userOptions, setUserOptions] = useState([])

  const [actionFilter, setActionFilter] = useState(null);
  const [actionData, setActionData] = useState([])
  const [actionOptions, setActionOptions] = useState([])

  const [accountingFilter, setAccountingFilter] = useState(null);
  const [accountingsData, setAccountingsData] = useState([])
  const [accountingsOptions, setAccountingsOptions] = useState([])


  const [isFilters, setIsFilters] = useState(false)
  const [isPages, setIsPagesA] = useState(false)
  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      logRef?.current?.setDataTotalLength(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
      }else{
        paginationRef?.current?.loadPages()
      }
    }
  }))

  const handleChangeFromDate = (value) => {
    setFromDateFilter(new Date(value))
  }
  const handleChangeToDate = (value) => {
    setToDateFilter(new Date(value))
  }
  const handleChangeUser = (value) => {
    setUserFilter(value)
  }
  const handleChangeAction = (value) => {
    setActionFilter(value)
  }
  const handleChangeAccounting = (value) => {
    setAccountingFilter(value)
  }
  
  

  const handleBlurUser = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectUser}'] .select-search__select`).css('display','none')
  }
  const handleFocusUser = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectUser}'] .select-search__select`).css('display','')
    setTimeout(()=>{
      $(`#searchToolsBar_${identifier}`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }
  const handleBlurAction = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectAction}'] .select-search__select`).css('display','none')
  }
  const handleFocusAction = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectAction}'] .select-search__select`).css('display','')
    setTimeout(()=>{
      $(`#searchToolsBar_${identifier}`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }
  const handleBlurAccounting = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectAccounting}'] .select-search__select`).css('display','none')
  }
  const handleFocusAccounting = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectAccounting}'] .select-search__select`).css('display','')
    setTimeout(()=>{
      $(`#searchToolsBar_${identifier}`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          const users=data?.users
          const usersOptions=users?.map(x => {return {'value':x?.id, 'name': x?.display_name}})
          setUserData(users)
          setUserOptions(usersOptions)

          const actions=data?.actions
          const actionsOptions=actions?.map(x => {return {'value':x?.id, 'name': x?.display_name}})
          setActionData(actions)
          setActionOptions(actionsOptions)

          const accountings=data?.accountings
          const accountingsOptions=accountings?.map(x => {return {'value':x, 'name': x}})
          setAccountingsData(accountings)
          setAccountingsOptions(accountingsOptions)

        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    const startDate=(filtersS && filtersS?.start_date)?filtersS?.start_date:from_date?from_date:null
    const endDate=(filtersS && filtersS?.end_date)?filtersS?.end_date:to_date?to_date:null
    return {
      user_id: (filtersS && filtersS?.user_id)?filtersS?.user_id:userFilter,
      system_action_id: (filtersS && filtersS?.system_action_id)?filtersS?.system_action_id:actionFilter,
      entity_value_id: (filtersS && filtersS?.entity_value_id)?filtersS?.entity_value_id:accountingFilter,
      start_date: startDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(startDate)).locale('es').format('yyyy-MM-DD'):null,
      end_date: endDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(endDate)).locale('es').format('yyyy-MM-DD'):null,
    }
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.user_id===null && filters.system_action_id===null && filters.entity_value_id===null &&
      filters.start_date===null && filters.end_date===null
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setUserFilter(null)
    setActionFilter(null)
    setAccountingFilter(null)
    setFromDateFilter(null)
    setToDateFilter(null)

    setIsFilters(false)

    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
  }

  const loadFiltersApply = (filtersO) => {
    const userId=filtersO?.user_id
    if(userId){
      setUserFilter(userId)
    }

    const systemActionId=filtersO?.system_action_id
    if(systemActionId){
      setActionFilter(systemActionId)
    }

    const entityValueId=filtersO?.entity_value_id
    if(entityValueId){
      setAccountingFilter(entityValueId)
    }

    const startDate=filtersO?.start_date
    if(startDate){
      setFromDateFilter(new Date(startDate))
    }

    const endDate=filtersO?.end_date
    if(endDate){
      setToDateFilter(new Date(endDate))
    }
    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }

  useEffect(()=>{
    getFiltersData()
  },[])




  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
          <SelectSearch id={styles.SelectUser}
                  options={userOptions}
                  onBlur={handleBlurUser}
                  onFocus={handleFocusUser}
                  onChange={handleChangeUser}
                  value={userFilter}
                  placeholder={t('User')}
                  search={true}
                  filterOptions={fuzzySearch}
                  autoFocus={false}
                  />


            <SelectSearch id={styles.SelectAction}
                  options={actionOptions}
                  onBlur={handleBlurAction}
                  onFocus={handleFocusAction}
                  onChange={handleChangeAction}
                  value={actionFilter}
                  placeholder={t('Action')}
                  search={true}
                  filterOptions={fuzzySearch}
                  autoFocus={false}
                  />
          </div>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectAccounting}
                  options={accountingsOptions}
                  onBlur={handleBlurAccounting}
                  onFocus={handleFocusAccounting}
                  onChange={handleChangeAccounting}
                  value={accountingFilter}
                  placeholder={t('Voucher Number')}
                  search={true}
                  filterOptions={fuzzySearch}
                  autoFocus={false}
                  />
            </div>
        </div>

        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={from_date} onChange={handleChangeFromDate} placeholderText={t('Start Date')} />
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={to_date} onChange={handleChangeToDate} placeholderText={t('End Date')} />
          </div>
        </div>
        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      {isPrivileged['print_log'] && 
        <ReactToPrint
          trigger={() => <button className={styles.ToolsBarButton+' btn btn-info'} title={t('Print')}><AiFillPrinter /></button>}
          content={() => printComponentRef?.current}
          documentTitle={'Accounts_Log'}
        />
      }
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
