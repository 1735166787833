import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ModalComponent.module.css';

import $ from 'jquery';

const ModalComponent = ({children, locale, show, title, handleWhenHidden, handleOnConfirm, handleOnConfirmDelayed, confirmBtnText='Confirm', cancelBtnText='Cancel', isDelayedConfirm=false, isConfirmVisible=true}) => {
  
  const handleHidden = () => {
    handleWhenHidden()
    $(`.${styles.Modal}`).removeClass('fade').modal('hide');
    $(`.${styles.Modal}`).modal('dispose')
  }

  const handleConfirm = () => {
    handleOnConfirm(()=>{
      if(isDelayedConfirm){
        $(`.${styles.Modal}`).removeClass('fade').modal('hide');
        $(`.${styles.Modal}`).modal('dispose')
      }
    })
    if(!isDelayedConfirm){
      $(`.${styles.Modal}`).removeClass('fade').modal('hide');
      $(`.${styles.Modal}`).modal('dispose')
    }
  }

  const handleConfirmDelayed = () => {
    handleOnConfirmDelayed(()=>{
      $(`.${styles.Modal}`).removeClass('fade').modal('hide');
      $(`.${styles.Modal}`).modal('dispose')
    })
  }
  
  const handleOnConfirmCheck = () => {
    if(handleOnConfirmDelayed!==undefined && handleOnConfirmDelayed!==null){
      handleConfirmDelayed()
    }else{
      handleConfirm()
    }
  }

  useEffect(() => {
    if(show){
      $(`.${styles.Modal}`).modal({
        keyboard:true,
        focus:true,
        show:true,
        backdrop:'static'
      })
    }
  }, [show])

  return <div className={styles.ModalComponent} data-testid="ModalComponent">
     {show && <div className={`${styles.Modal} modal`} tabIndex="-1" role="dialog">
      <div className='modal-dialog modal-xl' role="document" dir={locale=='ar'?'rtl':'ltr'}>
        <div className="modal-content">
          <table className={`modal-header ${styles.ModalHeader}`}>
            <tbody>
              <tr>
                <td className={locale=='ar'?'text-right':'text-left'}>
                  <h5 className="modal-title">{title}</h5>
                </td>
                <td className={locale=='ar'?'text-left':'text-right'}>
                  <button type="button" className={`close ${styles.ModalClose}`} data-dismiss="modal" aria-label="Close" onClick={handleHidden}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div className={`${styles.ModalBody} modal-body`}>
          {children}
          </div>
          <div className="modal-footer">
            {((handleOnConfirm || handleOnConfirmDelayed) && isConfirmVisible) && <button type="button" className="btn btn-primary" id="confirmBtn" onClick={()=>handleOnConfirmCheck()}>{confirmBtnText}</button>}
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleHidden}>{cancelBtnText}</button>
          </div>
        </div>
      </div>
    </div>}
  </div>
};

ModalComponent.propTypes = {};

ModalComponent.defaultProps = {};

export default ModalComponent;
