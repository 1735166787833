import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './UpdateAdd.module.css';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../../Common/ModalComponent/ModalComponent';
import RenderErrorMessage from '../../../Common/RenderErrorMessage/RenderErrorMessage';
import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import { useAuth } from '../../../Common/Auth/AuthHelpers/Auth';
import GeneralHelpers from '../../../../Services/GeneralHelpers';

import $ from 'jquery'

const UpdateAdd = ({id, updateOneItem, oneItemDate, isAdd, locale, show, generalFetchingServiceRef, handleWhenHidden, handleOnConfirm, isPrivileged}) => {
  const { t, i18n } = useTranslation();

  const auth=useAuth();

  const [fieldsErrors, setFieldsErrors] = useState([]);


  const [employeesData, setEmployeesData] = useState([])
  const [employeesOptions, setEmployeesOptions] = useState([])

  const [employeesSalaryDeductionTypeData, setEmployeesSalaryDeductionTypeData] = useState([])
  const [employeesSalaryDeductionTypeOptions, setEmployeesSalaryDeductionTypeOptions] = useState([])


  const [employee_name, setEmployeeName] = useState('');
  const [employee_id, setEmployeeId] = useState(null);
  const [value, setValue] = useState('');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [employees_salary_deduction_type_id, setemployeesSalaryDeductionTypeId] = useState(null);
  const [isEmployee, setIsEmployee] = useState(false);
  
  const [error_message, setErrorMessage] = useState(null);

  const handleChangeEmployeeId=(value)=>{
    setEmployeeId(value)
  }
  const handleChangeYear=(e)=>{
    setYear(e.target.value)
  }
  const handleChangeMonth=(e)=>{
    setMonth(e.target.value)
  }
  const handleChangeValue=(e)=>{
    setValue(e.target.value)
  }
  const handleChangeDate=(value)=>{
    setDate(value?new Date(value):null)
  }
  const handleChangeEmployeesSalaryDeductionType=(value)=>{
    setemployeesSalaryDeductionTypeId(value)
  }

  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData('salary_deduction')
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let employees=data?.employees
          let employeesOptions=employees?.map(x => {return {'value':x.id, 'name': x?.display_name}})
          setEmployeesData(employees)
          setEmployeesOptions(employeesOptions)

          let employeesSalaryDeductionType=data?.employees_salary_deduction_type
          let employeesSalaryDeductionTypeOptionsO=employeesSalaryDeductionType?.map(x => {return {'value':x.id, 'name': t(x.name)}})
          setEmployeesSalaryDeductionTypeData(employeesSalaryDeductionType)
          setEmployeesSalaryDeductionTypeOptions(employeesSalaryDeductionTypeOptionsO)

          if(data?.employee){
            setIsEmployee(true)
            setEmployeeData(data?.employee)
          }

        }
      }).catch(error => {
        console.log(error)
      })
  }

  const getObject = () =>{
    let data={
      employee_id:parseInt(employee_id),
      value:(value && value.trim().length>0)?value:null,
      year:(year && year.trim().length>0)?year:null,
      month:(month && month.trim().length>0)?month:null,
      employees_salary_deduction_type_id:employees_salary_deduction_type_id?parseInt(employees_salary_deduction_type_id):null,
    };
    return data;
  }

  const isEmptyObject = (obj) => {
    return !obj.employee_id && !obj.value && !obj.employees_salary_deduction_type_id &&
    !obj.year && !obj.month
  }

  const resetData = () => {
    setEmployeeId(null)
    setYear('')
    setMonth('')
    setValue('')
    setDate(null)
    setemployeesSalaryDeductionTypeId(null)
    setFieldsErrors([])
    setErrorMessage(null)
    setEmployeeName(null)
  }

  const handleConfirm = (hideCallback) => {
    const obj=getObject()
    handleOnConfirm(obj, isEmptyObject(obj), isAdd, function(isSuccess, error, errorMessage,){
      if(!isSuccess){
        if(error){
          let response=error?.response
          if(response.status==422){
            setFieldsErrors(response?.data?.errors)
          }
        }else{
          setErrorMessage(errorMessage)
        }
        
      }else{
        resetData()
        hideCallback()
      }
      window.scrollTo(0, 0)
    })

  }

  const handleHidden = () => {
    handleWhenHidden()
    resetData()
  }

  const setEmployeeData=(data)=>{
    setEmployeeId(data?.id)
    setEmployeeName(`${data?.user?.first_name} ${data?.user?.last_name}`)
  }

  const setSalaryDeductionData=(data)=>{
    setYear(data?.year)
    setMonth(data?.month)
    setValue(data?.value?data?.value:'')
    setemployeesSalaryDeductionTypeId(data?.employees_salary_deduction_type_id)
  }

  const getById = (id) => {
    generalFetchingServiceRef?.current?.getById(id)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setEmployeeData(data[0])
          }
        }).catch(error => {
          console.log(error)
        })
  }

  const getSalaryDeductionById = (id) => {
    generalFetchingServiceRef?.current?.getById(id, 'salary_deduction')
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setEmployeeData(data[0]?.employee)
            setSalaryDeductionData(data[0])
          }
        }).catch(error => {
          console.log(error)
        })
  }


  useEffect(()=>{
    if(show){
      getAllData()
      if(id){
        getById(id)
      }
      if(updateOneItem){
        getSalaryDeductionById(updateOneItem)
      }
    }
  },[show])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.Select}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.Select}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <ModalComponent locale={locale} show={show} handleWhenHidden={handleHidden} handleOnConfirmDelayed={handleConfirm} title={`${t(isAdd?'Add':'Update')} ${t('Salary Deduction')}`} confirmBtnText={t(isAdd?'Add':'Update')} cancelBtnText={t('Cancel')} className={styles.Update} data-testid="Update">
    <div className={`${styles.FieldsContainer} ${locale=='ar'?'text-right':'text-left'}`}>

      <RenderErrorMessage name='error_message' messages={{error_message}} alertDisplay={true} />


      {isPrivileged['manage'] && isAdd && !id && <div className={`form-group`}>
        <label htmlFor="employee_id">{t('Employee')}</label>
        <RenderErrorMessage name='employee_id' messages={fieldsErrors} />
        <RenderErrorMessage name='data.employee_id' messages={fieldsErrors} />
        <div>
          <SelectSearch id={styles.Select}
            onFocus={handleOnFocusSelect}
            options={employeesOptions}
            onChange={handleChangeEmployeeId}
            value={employee_id}
            placeholder={`${t('Select an')} ${t('employee')}`}
            search={true}
            filterOptions={fuzzySearch}
            />
        </div>
      </div>}

      {!isAdd && employee_id && <div className={`form-group`}>
        <label htmlFor="employee_id">{t('Employee')}</label>
        <div className='card'>
          {employee_name}
        </div>
      </div>}


      {<div className={`form-group`}>
        <label htmlFor="year">{t('Year')}</label>
        <RenderErrorMessage name='year' messages={fieldsErrors} />
        <RenderErrorMessage name='data.year' messages={fieldsErrors} />
        <div>
          <input
          type={'number'}
          className={styles.SelectInput}
          value={year}
          onChange={handleChangeYear}
          disabled={(!isEmployee && !isAdd)}
          ></input>
        </div>
      </div>}

      {<div className={`form-group`}>
        <label htmlFor="month">{t('Month')}</label>
        <RenderErrorMessage name='month' messages={fieldsErrors} />
        <RenderErrorMessage name='data.month' messages={fieldsErrors} />
        <div>
          <input
          type={'number'}
          className={styles.SelectInput}
          value={month}
          onChange={handleChangeMonth}
          disabled={(!isEmployee && !isAdd)}
          ></input>
        </div>
      </div>}

      {<div className={`form-group`}>
      <label htmlFor="employees_salary_deduction_type_id">{t('Deduction Type')}</label>
      <RenderErrorMessage name='employees_salary_deduction_type_id' messages={fieldsErrors} />
      <RenderErrorMessage name='data.employees_salary_deduction_type_id' messages={fieldsErrors} />
      <div>
        <SelectSearch id={styles.Select}
          onFocus={handleOnFocusSelect}
          options={employeesSalaryDeductionTypeOptions}
          onChange={handleChangeEmployeesSalaryDeductionType}
          value={employees_salary_deduction_type_id}
          placeholder={`${t('Select a')} ${t('status')}`}
          search={true}
          filterOptions={fuzzySearch}
          disabled={(!isEmployee && !isAdd)}
          />
      </div>
    </div>}
      

      {<div className={`form-group`}>
        <label htmlFor="value">{t('Value')}</label>
        <RenderErrorMessage name='value' messages={fieldsErrors} />
        <RenderErrorMessage name='data.value' messages={fieldsErrors} />
        <div>
          <input
          type={'number'}
          className={styles.SelectInput}
          value={value}
          onChange={handleChangeValue}
          disabled={(!isEmployee && !isAdd)}
          ></input>
        </div>
      </div>}

      


    </div>
  </ModalComponent>
};

UpdateAdd.propTypes = {};

UpdateAdd.defaultProps = {};

export default UpdateAdd;
