import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './Mail.module.css';
import DashboardLayout from '../Dashboard/DashboardLayout/DashboardLayout';
import TopBar from './TopBar/TopBar';
import { useParams, useNavigate } from 'react-router-dom';
import All from './All/All';
import AddEdit from './AddEdit/AddEdit';
import GeneralFetchingService from '../../Services/GeneralFetchingService';

import { useTranslation } from 'react-i18next';

import $ from 'jquery';
import RenderErrorMessage from '../Common/RenderErrorMessage/RenderErrorMessage';
import RenderSuccessMessage from '../Common/RenderSuccessMessage/RenderSuccessMessage';
import { useAuth } from '../Common/Auth/AuthHelpers/Auth';

import Privileges from '../Common/Privileges/Privileges';
import View from './View/View';
import NoPrivilegesMessage from '../Common/NoPrivilegesMessage/NoPrivilegesMessage';
import Deleted from './Deleted/Deleted';
import Logs from './Logs/Logs';
import Cart from './Cart/Cart';

import PopUpModal from '../Common/PopUpModal/PopUpModal';

const MailComponents = ({page, locale, setLocale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name
  const navigate = useNavigate();

  const isGeneral = page=='General';
  const isView = page=='View';
  const isAdd = page=='Add';
  const isEdit = page=='Edit';
  const isDeletd= page=='Deleted';
  const isLog= page=='Log';
  const isCart= page=='Cart';
  const isCartView= page=='CartView';
  const isCartEdit= page=='CartEdit';

  const params = useParams();
  const [routePage, setRoutePage] = useState(page)

  const [dataItems, setDataItems] = useState([])
  const [popUpTitle, setPopUpTitle] = useState('')
  const [popUpSrc, setPopUpSrc] = useState('')
  const [popUpShow, setPopUpShow] = useState(false)
  
  
  const [isPrivileges, setIsPrivileges] = useState(false)
  const [privilegesSideBar, setPrivilegesSideBar] = useState([])
  const [isPrivileged, setIsPrivileged] = useState({})
  const [isTopBarButtonsVisible, setIsTopBarButtonsVisible] = useState({'View':false, 'ViewCart': false, 'Add':true, 'Edit': false})
  

  const [generalErrorMessage, setGeneralErrorMessage] = useState(null);
  const [generalSuccessMessage, setGeneralSuccessMessage] = useState(null);
  const [isShownGeneralError, setIsShownGeneralError] = useState(false);
  const [isShownGeneralSuccess, setIsShownGeneralSuccess] = useState(false);


  
  const allRef = useRef();
  const generalFetchingServiceRef = useRef();
  const privilegesRef= useRef();
  const deletedRef= useRef();
  const logRef= useRef();
  const cartRef= useRef();
  const addEditRef= useRef();
  
  const showMessage = (isSuccess, message) => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
    if(isSuccess){
      setGeneralSuccessMessage(message)
      setIsShownGeneralSuccess(true)
    }else{
      setGeneralErrorMessage(message)
      setIsShownGeneralError(true)
    }
    window.scrollTo(0, 0)
  }

  const hideMessage = () => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
  }

  const deleteApply = (ids, paginationRef, customAPi) => {
    generalFetchingServiceRef?.current?.delete(ids, customAPi=='all'?'':customAPi)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          paginationRef?.current?.loadPages()
          showMessage(true, responseData?.message)
        }else{
          showMessage(false, responseData?.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }
  const deleteHandle=(ids, paginationRef, customAPi)=>{
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t('Delete Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html(t('Do you want to delete the items?'))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      deleteApply(ids, paginationRef, customAPi)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }

  const restoreDeletedApply = (ids, paginationRef) => {
    generalFetchingServiceRef?.current?.restoreDeleted(ids)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          paginationRef?.current?.loadPages()
          showMessage(true, responseData?.message)
        }else{
          showMessage(false, responseData?.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }
  const restoreDeletedHandle=(ids, paginationRef)=>{
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t('Restore Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html(t('Do you want to restore deleted items?'))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      restoreDeletedApply(ids, paginationRef)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }

  const editBtnHandle = (id, source) => {
    handleTabChange(source=='cart'?'CartEdit':'Edit', id)
  }

  const viewBtnHandle = (id, source) => {
    handleTabChange(source=='cart'?'CartView':'View', id)
  }

  const handleAddEdit=(item, setFieldsErrors, resetData, editSource)=>{
    if(!item?.id){
      generalFetchingServiceRef?.current?.add(item, 'cart')
        .then(response => {
          if(response?.response){
            response=response?.response
          }
          const responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setDataItems([...dataItems, data])
            resetData()
            setFieldsErrors([])
            handleTabChange('Cart')
            showMessage(true, responseData?.message)
          }else{
            showMessage(false, responseData?.message)
          }
        }).catch(error => {
          let response=error?.response
          if(response.status==422){
            setFieldsErrors(response?.data?.errors)
          }
        })
    }else{
      generalFetchingServiceRef?.current?.edit(item, editSource=='all'?'':editSource)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setDataItems(dataItems.map(x => {
              if(x.id==data.id){
                return {...data}
              }
            }))
            showMessage(true, responseData?.message)
          }else{
            showMessage(false, responseData?.message)
          }
        }).catch(error => {
          console.log(error)
          let response=error.response
          if(response.status==422){
            setFieldsErrors(response.data.errors)
          }
        })
    }
  }

  const handlePublishMail=(ids)=>{
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t('Publish Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html(t('Do you want to publish items?'))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      handlePublishMailApply(ids)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
    
  }
  const handlePublishMailApply=(ids)=>{
    generalFetchingServiceRef?.current?.publish(ids, 'cart')
    .then(response => {
      let responseData=response?.data
      if(responseData && responseData?.status==1){
        let data=responseData?.data
        handleTabChange('General')
        showMessage(true, responseData?.message)
      }else{
        showMessage(false, responseData?.message)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const receiveMoneyHandle=(id)=>{
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t('Receive Money Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html(t('Do you want to confirm mail\'s money receivement?'))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      receiveMoneyHandleApply(id)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }
  const receiveMoneyHandleApply=(id)=>{
    generalFetchingServiceRef?.current?.update([], [id], 'receive_money')
    .then(response => {
      let responseData=response?.data
      if(responseData && responseData?.status==1){
        showMessage(true, responseData?.message)
        allRef?.current?.loadPages()
      }else{
        showMessage(false, responseData?.message)
      }
    }).catch(error => {
      console.log(error)
    })
  }
  
  

  const handleTabChange=(pageR, id=null)=>{
    if(pageR){
      switch(pageR){
        case 'General':
          navigate(`/${prefix}/dashboard/mail`)
          setIsTopBarButtonsVisible({'View':false, 'ViewCart': false,  'EditCart': false, 'Add':true, 'Edit': false})
          break;
        case 'Add':
          navigate(`/${prefix}/dashboard/mail/add`)
          setIsTopBarButtonsVisible({'View':false, 'ViewCart': false,  'EditCart': false, 'Add':true, 'Edit': false})
          break;
        case 'Deleted':
          navigate(`/${prefix}/dashboard/mail/deleted`)
          setIsTopBarButtonsVisible({'View':false, 'ViewCart': false,  'EditCart': false, 'Add':true, 'Edit': false})
          break;
        case 'View':
          if(id){
            navigate(`/${prefix}/dashboard/mail/view/${id}`)
          }
          setIsTopBarButtonsVisible({'View':true, 'ViewCart': false,  'EditCart': false, 'Add':true, 'Edit': false})
          break;
        case 'Edit':
          if(id){
            navigate(`/${prefix}/dashboard/mail/edit/${id}`)
          }
          setIsTopBarButtonsVisible({'View':false, 'ViewCart': false,  'EditCart': false, 'Add':false, 'Edit': true})
          break;
        case 'Log':
            navigate(`/${prefix}/dashboard/mail/log`)
            setIsTopBarButtonsVisible({'View':false, 'ViewCart': false,  'EditCart': false, 'Add':true, 'Edit': false})
            break;
        case 'Cart':
          navigate(`/${prefix}/dashboard/mail/cart`)
          setIsTopBarButtonsVisible({'View':false, 'ViewCart': false,  'EditCart': false, 'Add':true, 'Edit': false})
          break;
        case 'CartView':
          if(id){
            navigate(`/${prefix}/dashboard/mail/view/cart/${id}`)
          }
          setIsTopBarButtonsVisible({'View':false, 'ViewCart': true,  'EditCart': false, 'Add':true, 'Edit': false})
          break;
        case 'CartEdit':
          if(id){
            navigate(`/${prefix}/dashboard/mail/edit/cart/${id}`)
          }
          setIsTopBarButtonsVisible({'View':false, 'ViewCart': false,  'EditCart': true, 'Add':false, 'Edit': false})
          break;
      }
      setRoutePage(pageR)
    }
    hideMessage()
  }
  
  useEffect(()  => {
    const sysEnt='mail'
    privilegesRef?.current?.getPrivileges(sysEnt).then(privilegesO=>{
      if(privilegesO && privilegesO?.privileges?.length>0){
        let mapPriv = {};
        privilegesO?.privileges?.forEach(x => {
          const key=x?.replace(`${sysEnt}_`, '')
          mapPriv={...mapPriv, [key]:true}
        });
        setIsPrivileged(mapPriv)
        setIsPrivileges(true)
      }
      if(privilegesO && privilegesO?.sideBarPrivileges?.length>0){
        setPrivilegesSideBar(privilegesO?.sideBarPrivileges)
      }
    })
  }, [])

  useEffect(() => {
    loadPages()
  },[isPrivileged])

  useEffect(() => {
    loadPages()
  },[routePage])

  const loadPages = () => {
    if(isPrivileged && Object.keys(isPrivileged).length>0){
      if(isGeneral){
        allRef?.current?.loadPages()
      }
      if(isDeletd){
        deletedRef?.current?.loadPages()
      }
      if(isLog){
        logRef?.current?.loadPages()
      }
      if(isCart){
        cartRef?.current?.loadPages()
      }
    }
  }

  const openPopUpCustomerAdd = () => {
    setPopUpTitle(t('Add Customer'))
    setPopUpSrc(`/${prefix}/dashboard/popup/customer/add`)
    setPopUpShow(true)
  }
  const handleOnPopUpCustomerAddHidden = () => {
    setPopUpShow(false)
    addEditRef?.current?.refreshData()
  }

  return <DashboardLayout component='Mail' privilegesSideBar={privilegesSideBar} locale={locale} setLocale={setLocale}>
    <GeneralFetchingService generalFetchingServiceRef={generalFetchingServiceRef} prefix='mail' />
    <Privileges generalFetchingServiceRef={generalFetchingServiceRef} privilegesRef={privilegesRef}></Privileges>
    {isPrivileges && <div className={styles.Mail} data-testid="Mail">
      <h1>{t('Mail')}</h1>
      <PopUpModal title={popUpTitle} show={popUpShow} src={popUpSrc} handleWhenHidden={handleOnPopUpCustomerAddHidden} generalFetchingServiceRef={generalFetchingServiceRef} />
      <TopBar routePage={routePage} isPrivileged={isPrivileged} handleTabChange={handleTabChange} setRoutePage={setRoutePage} isVisible={isTopBarButtonsVisible} />
      <RenderErrorMessage name='general' messages={generalErrorMessage!=null?{'general':generalErrorMessage}:{}} alertDisplay={true} isShown={isShownGeneralError} setIsShownGeneralError={setIsShownGeneralError} isDissmiss={true} handleHideMessage={hideMessage} />
      <RenderSuccessMessage name='general' messages={generalSuccessMessage!=null?{'general':generalSuccessMessage}:{}} alertDisplay={true} isShown={isShownGeneralSuccess} setIsShownGeneralSuccess={setIsShownGeneralSuccess} isDissmiss={true} handleHideMessage={hideMessage} />
      {(isGeneral && isPrivileged['view_all']) && <All page={page} data={dataItems} viewBtnHandle={viewBtnHandle} editBtnHandle={editBtnHandle} deleteHandle={deleteHandle} receiveMoneyHandle={receiveMoneyHandle} setDataItems={setDataItems} generalFetchingServiceRef={generalFetchingServiceRef} allRef={allRef} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {((isAdd && isPrivileged['add']) || (isEdit && isPrivileged['edit'])) && <AddEdit id={params.id} handleAddEdit={handleAddEdit} editSource='all' generalFetchingServiceRef={generalFetchingServiceRef} locale={locale} openPopUpCustomerAdd={openPopUpCustomerAdd} addEditRef={addEditRef} />}
      {(isView && isPrivileged['view_item']) && <View viewSource={'all'} id={params.id} generalFetchingServiceRef={generalFetchingServiceRef} handleTabChange={handleTabChange} isPrivileged={isPrivileged} locale={locale} />}
      {(isDeletd && isPrivileged['view_deleted']) && <Deleted page={page} allRef={allRef} deletedRef={deletedRef} viewBtnHandle={viewBtnHandle} restoreDeletedHandle={restoreDeletedHandle} generalFetchingServiceRef={generalFetchingServiceRef} isPrivileged={isPrivileged} locale={locale} />}
      {(isLog && isPrivileged['view_log']) && <Logs generalFetchingServiceRef={generalFetchingServiceRef} logRef={logRef} isPrivileged={isPrivileged} locale={locale} />}
      {(isCart && isPrivileged['view_cart']) && <Cart cartRef={cartRef} page={page} data={dataItems} viewBtnHandle={viewBtnHandle} editBtnHandle={editBtnHandle} deleteHandle={deleteHandle} handlePublishMail={handlePublishMail} setDataItems={setDataItems} generalFetchingServiceRef={generalFetchingServiceRef} allRef={allRef} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {(isCartView && isPrivileged['view_cart']) && <View viewSource='cart' id={params.id} generalFetchingServiceRef={generalFetchingServiceRef} handleTabChange={handleTabChange} isPrivileged={isPrivileged} locale={locale} />}
      {((isCartEdit && isPrivileged['edit'])) && <AddEdit id={params.id} handleAddEdit={handleAddEdit} editSource='cart' generalFetchingServiceRef={generalFetchingServiceRef} locale={locale} />}
    </div>}
    {(!isPrivileges || 
      (isGeneral && !isPrivileged['view_all']) ||
      (isAdd && !isPrivileged['add']) ||
      (isEdit && !isPrivileged['edit']) ||
      (isView && !isPrivileged['view_item']) ||
      (isLog && !isPrivileged['view_log'])
    )&& <NoPrivilegesMessage />}
  </DashboardLayout>
};

MailComponents.propTypes = {};

MailComponents.defaultProps = {};

export default MailComponents;
