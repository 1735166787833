import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './SMS.module.css';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import { useTranslation } from 'react-i18next';

import {AiFillPrinter} from 'react-icons/ai';
import ReactToPrint from 'react-to-print';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';

const SMS = ({id, generalFetchingServiceRef, handleTabChange, isPrivileged, locale, showMessage}) => {
  const { t, i18n } = useTranslation();
  
  const [fieldsErrors, setFieldsErrors] = useState([]);

  const [to, setTo] = useState('')
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')

  const tableRef = useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const handleChangeTo=(e)=>{
    setTo(e.target.value)
  }
  const handleChangeTitle=(e)=>{
    setTitle(e.target.value)
  }
  const handleChangeBody=(e)=>{
    setBody(e.target.value)
  }

  const getObject = () =>{
    let data={
      to,
      title,
      body,
    };
    return data;
  }

  const resetData = () =>{
    setTo('')
    setTitle('')
    setBody('')
  }

  const handleSendClick = () => {
    let item=getObject()
    setFieldsErrors([])
    generalFetchingServiceRef?.current?.update(item, [], 'send_sms')
        .then(response => {
          console.log(response)
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            showMessage(true, responseData?.message)
            resetData()
          }else{
            showMessage(false, responseData?.message)
          }
        }).catch(error => {
          console.log(error)
          let response=error.response
          if(response.status==422){
            setFieldsErrors(response.data.errors)
          }
        })
    window.scrollTo(0, 0);
  }


  return <div className={styles.SMS} data-testid="SMS">

    <div className="form-group">
      <label htmlFor="to">{t('To')}</label>
      <RenderErrorMessage name='data.to' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('phone number')}`} id="to" onChange={handleChangeTo} value={to} />
    </div>

    <div className="form-group">
      <label htmlFor="title">{t('Title')}</label>
      <RenderErrorMessage name='data.title' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter the')} ${t('title')}`} id="title" onChange={handleChangeTitle} value={title} />
    </div>


    <div className="form-group">
      <label htmlFor="body">{t('Body')}</label>
      <RenderErrorMessage name='data.body' messages={fieldsErrors} />
      <textarea
              className={styles.TextArea}
              value={body}
              onChange={handleChangeBody}
              ></textarea>
    </div>

    <div className="form-group">
      <button className={styles.SendButton + ' btn btn-primary'} onClick={handleSendClick}>{t('Send')}</button>
    </div>

    

  </div>
};

SMS.propTypes = {};

SMS.defaultProps = {};

export default SMS;
