import {useRef, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';

const Privileges = ({
  totalServiceRef,
  generalFetchingServiceRef,
  privilegesRef
}) => {

  const getServiceRef = (entityName) => {
    switch(entityName){
      case 'statistics':
        return totalServiceRef;
      default:
        return generalFetchingServiceRef;
    }
    return null
  }

  useImperativeHandle(privilegesRef,()=>({
    async getPrivileges(entityName){
      return await getPrivileges(entityName)
    }
  }))


  const getPrivileges = async (entityName) => {
    let response
    try {
      response=await getServiceRef(entityName)?.current?.getPrivileges()
    } catch (error) {
      console.log(error)
      return null
    }
    if(!response){
      return null
    }

    const responseData=response?.data
    if(responseData?.status == 1){
      const privilegesO=responseData?.data
      if(privilegesO?.privileges?.length>0 || privilegesO?.sideBarPrivileges?.length>0){
        return privilegesO
      }
    }
    return null
  }

  return <span></span>
};

Privileges.propTypes = {};

Privileges.defaultProps = {};

export default Privileges;
