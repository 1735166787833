import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../../Services/GeneralHelpers';
import ToolsItemsBar from '../../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({dataLength, page, toolsBarRef, vendorDebtsRef, deletedRef, paginationRef, printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale}) => {
  const { t, i18n } = useTranslation();

  const isGeneral = page=='General';
  const isDeleted = page=='Deleted';

  const [from_lend_date, setLendFromDateFilter] = useState(null);
  const [to_lend_date, setLendToDateFilter] = useState(null);

  const [from_payment_date, setPaymentFromDateFilter] = useState(null);
  const [to_payment_date, setPaymentToDateFilter] = useState(null);

  const [vendorFilter, setVendorFilter] = useState(null);
  const [vendorsData, setVendorsData] = useState([])
  const [vendorsOptions, setVendorsOptions] = useState([])

  const [isPaidFilter, setIsPaidFilter] = useState(null);
  const [isPaidData, setIsPaidData] = useState([])
  const [isPaidOptions, setIsPaidOptions] = useState([])

  const [isPaymentRequiredFilter, setIsPaymentRequiredFilter] = useState(null);
  const [isPaymentRequiredData, setIsPaymentRequiredData] = useState([])
  const [isPaymentRequiredOptions, setIsPaymentRequiredOptions] = useState([])
  
  const [isFilters, setIsFilters] = useState(false)
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      vendorDebtsRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
      }else{
        paginationRef?.current?.loadPages()
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('VendorsDebts', selecetedItems)
    },
  }))

  const handleChangeLendFromDate = (value) => {
    setLendFromDateFilter(value)
  }
  const handleChangeLendToDate = (value) => {
    setLendToDateFilter(value)
  }
  const handleChangePaymentFromDate = (value) => {
    setPaymentFromDateFilter(value)
  }
  const handleChangePaymentToDate = (value) => {
    setPaymentToDateFilter(value)
  }
  const handleChangeVendor = (value) => {
    setVendorFilter(value)
  }
  const handleChangeIsPaid = (value) => {
    setIsPaidFilter(value)
  }
  const handleChangeIsPaymentRequired = (value) => {
    setIsPaymentRequiredFilter(value)
  }

  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          const vendors=data?.vendors
          const vendorsOptions=vendors?.map(x => {return {'value':x?.id, 'name': x?.display_name}})
          setVendorsData(vendors)
          setVendorsOptions(vendorsOptions)

          const trueFalse=['True', 'False']

          const isPaidOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsPaidData(trueFalse)
          setIsPaidOptions(isPaidOptions)

          const isPaymentRequiredOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsPaymentRequiredData(trueFalse)
          setIsPaymentRequiredOptions(isPaymentRequiredOptions)
        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    const fromLendDate=(filtersS && filtersS?.from_lend_date)?filtersS?.from_lend_date:from_lend_date?from_lend_date:null
    const toLendDate=(filtersS && filtersS?.to_lend_date)?filtersS?.to_lend_date:to_lend_date?to_lend_date:null
    const fromPaymentDate=(filtersS && filtersS?.from_payment_date)?filtersS?.from_payment_date:from_payment_date?from_payment_date:null
    const toPaymentDate=(filtersS && filtersS?.to_payment_date)?filtersS?.to_payment_date:to_payment_date?to_payment_date:null
    let result={
      vendor_id: (filtersS && filtersS?.vendor_id)?filtersS?.vendor_id:vendorFilter,
      from_lend_date: fromLendDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(fromLendDate)).locale('es').format('yyyy-MM-DD'):null,
      to_lend_date: toLendDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(toLendDate)).locale('es').format('yyyy-MM-DD'):null,
      from_payment_date: fromPaymentDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(fromPaymentDate)).locale('es').format('yyyy-MM-DD'):null,
      to_payment_date: toPaymentDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(toPaymentDate)).locale('es').format('yyyy-MM-DD'):null,
    };
    if(isPaidFilter){
      result={...result, is_paid: (filtersS && filtersS?.is_paid)?filtersS?.is_paid:(isPaidFilter=='True')}
    }
    if(isPaymentRequiredFilter){
      result={...result, is_payment_required: (filtersS && filtersS?.is_payment_required)?filtersS?.is_payment_required:(isPaymentRequiredFilter=='True')}
    }
    return result
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.vendor_id===null && 
      (filters.is_paid==undefined || filters.is_paid==null) &&
      (filters.is_payment_required==undefined || filters.is_payment_required==null) &&
      (filters.from_lend_date===null && filters.to_lend_date===null) &&
      (filters.from_payment_date===null && filters.to_payment_date===null)
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setLendFromDateFilter(null)
    setLendToDateFilter(null)
    setPaymentFromDateFilter(null)
    setPaymentToDateFilter(null)
    setVendorFilter(null)
    setIsPaidFilter(null)
    setIsPaymentRequiredFilter(null)
    setIsFilters(false)

    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
  }

  const loadFiltersApply = (filtersO) => {
    const vendorId=filtersO?.vendor_id
    if(vendorId){
      setVendorFilter(vendorId)
    }

    const from_lend_date=filtersO?.from_lend_date
    if(from_lend_date){
      setLendFromDateFilter(new Date(from_lend_date))
    }

    const to_lend_date=filtersO?.to_lend_date
    if(to_lend_date){
      setLendToDateFilter(new Date(to_lend_date))
    }


    const from_payment_date=filtersO?.from_payment_date
    if(from_payment_date){
      setPaymentFromDateFilter(new Date(from_payment_date))
    }

    const to_payment_date=filtersO?.to_payment_date
    if(to_payment_date){
      setPaymentToDateFilter(new Date(to_payment_date))
    }

    const is_paid=filtersO?.is_paid
    if(is_paid){
      setIsPaidFilter(is_paid)
    }

    const is_payment_required=filtersO?.is_payment_required
    if(is_payment_required){
      setIsPaymentRequiredFilter(is_payment_required)
    }

    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }

  const HideDropdownBoxes=()=>{
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }

  useEffect(()=>{
    getFiltersData()
    setTimeout(()=>{
      HideDropdownBoxes()
    },200)
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectIsPaid}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectIsPaid}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
    
    $(`div[id='${styles.SelectVendor}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectVendor}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={from_lend_date} onChange={handleChangeLendFromDate} placeholderText={`${t('Start Date')} (${t('Lend')})`} />
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={to_lend_date} onChange={handleChangeLendToDate} placeholderText={`${t('End Date')} (${t('Lend')})`} />
          </div>

          <div className={styles.subSelectsGroup}>
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={from_payment_date} onChange={handleChangePaymentFromDate} placeholderText={`${t('Start Date')} (${t('Payment')})`} />
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={to_payment_date} onChange={handleChangePaymentToDate} placeholderText={`${t('End Date')} (${t('Payment')})`} />
          </div>
        </div>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
          <SelectSearch id={styles.SelectVendor}
                onFocus={handleOnFocusSelect}
                options={vendorsOptions}
                onChange={handleChangeVendor}
                value={vendorFilter}
                placeholder={t('Vendor')}
                search={true}
                filterOptions={fuzzySearch}
                />

            <SelectSearch id={styles.SelectIsPaid}
                    onFocus={handleOnFocusSelect}
                    options={isPaidOptions}
                    onChange={handleChangeIsPaid}
                    value={isPaidFilter}
                    placeholder={t('Is Paid?')}
                    search={true}
                    filterOptions={fuzzySearch}
                    autoFocus={false}
                    />
          </div>

          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectIsPaid}
                    onFocus={handleOnFocusSelect}
                    options={isPaymentRequiredOptions}
                    onChange={handleChangeIsPaymentRequired}
                    value={isPaymentRequiredFilter}
                    placeholder={t('Is Payment Required?')}
                    search={true}
                    filterOptions={fuzzySearch}
                    autoFocus={false}
                    />
          </div>
        </div>
        
        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar page={page} dataLength={dataLength} vendorDebtsRef={vendorDebtsRef} deletedRef={deletedRef} isDeleted={isDeleted} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} />
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
