import PropTypes from 'prop-types';
import styles from './Pagination.module.css';

import React, {useEffect, useState, useImperativeHandle} from 'react';
import parse from "html-react-parser";
import GeneralHelpers from '../../../Services/GeneralHelpers';
import { useTranslation } from 'react-i18next';

const Pagination = ({data, service, excelService, setData, paginationRef, toolsBarRef, deletedRef, locale}) => {
  const { t, i18n } = useTranslation();

  const [pages, setPages] = useState([])
  const [isPages, setIsPages] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [lastFilters, setLastFilters] = useState(null)

  useImperativeHandle(paginationRef,()=>({
    loadPagesWithFilters(filters){
      loadPages(filters)
      setLastFilters(filters)
    },
    loadPages(){
      loadPages()
      setLastFilters(null)
    },
    togglePagination(){
      if(isPages){
        GeneralHelpers.setHashVariable('page', null)
        GeneralHelpers.setHashVariable('all','', true)
      }else{
        GeneralHelpers.setHashVariable('all',null)
        GeneralHelpers.setHashVariable('page', '1')
      }
      setIsPages(!isPages)
      loadPages(lastFilters)
    },
    downloadExcel(fileName, selectedItems, customApi){
      excelService(lastFilters, selectedItems?(selectedItems?.length>0?selectedItems:null):null)
      .then(response => {
        const type = response.headers['content-type']
        // const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const blob=response?.data
        if(blob){
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${fileName}.xlsx`
          link.click()
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }))

  const loadPages = (filtersO) => {
    const pageNumber=GeneralHelpers.getHashVariable('page')
    const isAllVar=GeneralHelpers.getHashVariable('all')
    const isAll=isAllVar?false:GeneralHelpers.isHashVariableExists('all')?true:false
    
    changePageLink(null, pageNumber, isAll, filtersO)
  }

  const changePageLink= (link, pageNumber, isAll, filtersO) => {
    const params=link?link.substring(link.indexOf('?'), link.length):null
    const urlParams = params?new URLSearchParams(params):null;
    let pageNumberHash=urlParams?urlParams.get('page'):null
    if(pageNumberHash!=null){
      GeneralHelpers.setHashVariable('page', pageNumberHash)
    }
    applyPageLink(link, pageNumber, isAll, filtersO)
  }
  const applyPageLink = (link, pageNumber, isAll, filtersO) =>{
    service(link, isAll, filtersO)
    .then(response => {
      let responseData=response?.data
      if(responseData && responseData?.status==1){
        let total=responseData?.total
        let pagesData=responseData?.data
        if(pagesData?.first_page_url){
          let pagesD={
            first_page_url: pagesData?.first_page_url, 
            last_page_url: pagesData?.last_page_url,
            links: pagesData?.links
          }
          setPages(pagesD)
          setIsPages(true)

          let data=pagesData?.data
          setData(data)

          if(link==null && pageNumber!=null && !isLoaded){
            if(parseInt(pageNumber)>0 && parseInt(pageNumber) <= pagesD.links?.length-2){
              applyPageLink(pagesD.links[pageNumber]?.url, null, isAll, filtersO)
            }else{
              GeneralHelpers.setHashVariable('page', '1')
            }
            setIsLoaded(true)
          }
        }else{
          let data=pagesData
          setData(data)
        }
        
        if(toolsBarRef){
          toolsBarRef?.current?.setDataTotalLength(total);
        }
        if(deletedRef){
          deletedRef?.current?.setDeletedItemsLength(total);
        }

        if(filtersO){
          let filtersH=encodeURI(JSON.stringify(GeneralHelpers.cleanObject(filtersO)));
          GeneralHelpers.setHashVariable('filters', filtersH)
        }
      }
    }).catch(error => {
      console.log(error)
    })
  }

  
  useEffect(()=>{
    toolsBarRef?.current?.setIsPages(isPages)
  },[isPages])

  return <nav aria-label="Page navigation" className={styles.paginationContainer} dir={locale=='ar'?'rtl':'ltr'}>
      {isPages &&  data?.length>0 && <ul className="pagination">
        <li className="page-item">
          <button className="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true" onClick={()=>changePageLink(pages?.first_page_url)}>&lsaquo;</span>
          </button>
        </li>
        {pages?.links?.map((link, id) => {
          return <li key={id} className={"page-item "+(link?.url!==null?'':'disabled') +' '+(link?.active?'active':'')}><button onClick={()=>link?.active?false:changePageLink(link?.url)} className="page-link">{t(parse(link?.label))}</button></li>
        })}
        <li className="page-item">
          <button className="page-link" href="#" aria-label="Next">
            <span aria-hidden="true" onClick={()=>changePageLink(pages?.last_page_url)}>&rsaquo;</span>
          </button>
        </li>
      </ul>}
    </nav>
}


Pagination.propTypes = {};

Pagination.defaultProps = {};

export default Pagination;
