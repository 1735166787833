import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './ViewItem.module.css';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import { useTranslation } from 'react-i18next';

import {AiFillPrinter} from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import Notes from '../../Common/Notes/Notes';


import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

const ViewItem = ({id, generalFetchingServiceRef, viewSource, handleTabChange, isPrivileged, locale}) => {
  const { t, i18n } = useTranslation();
  
  const [data, setData] = useState(null)

  const tableRef = useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name


  const GetData = () => {
    generalFetchingServiceRef?.current?.getById(id, viewSource=='all'?'':viewSource)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setData(data[0])
          }
        }).catch(error => {
          console.log(error)
        })
  }

  useEffect(()=>{
    GetData()
  },[])

  return <div className={styles.ViewItem} data-testid="ViewItem">
    <table ref={tableRef} className={styles.table+' table table-bordered table-hover'}>
      <thead className='table-active'>
        <tr>
          <th scope='col' className={styles.header_label}>{t('Property')}</th>
          <th scope='col' className={styles.header_label}>{t('Value')}</th>
        </tr>
      </thead>
      <tbody>
      <tr className="form-group">
          <td><label className={styles.label}>{t('Id')}</label></td>
          <td>{data?.id}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Item Name')}</label></td>
          <td>{data?.item_name}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Item Price')}</label></td>
          <td>{data?.item_price}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Wrapping Price')}</label></td>
          <td>{data?.wrapping_price}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Item Description')}</label></td>
          <td>{data?.item_description}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Is Damagable?')}</label></td>
          <td>{data?.is_damagable?t('true'):t('false')}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Is Breakable?')}</label></td>
          <td>{data?.is_breakable?t('true'):t('false')}</td>
        </tr>
        
        <tr className="form-group">
          <td><label className={styles.label}>{t('Is Wrappable?')}</label></td>
          <td>{data?.is_wrappable?t('true'):t('false')}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Is Out?')}</label></td>
          <td>{data?.is_out?t('true'):t('false')}</td>
        </tr>
      </tbody>
    </table>

    <br />
    <div className={styles.ToolsButtons}>
      <button className='btn btn-primary' onClick={()=>{
        handleTabChange(viewSource=='cart'?'Cart':viewSource=='item'?'CartItem':'Storage')
        window.scrollTo(0, 0)
      }}>{t(`Go to ${viewSource}`)}</button>

      {isPrivileged['print_view'] && 
        <ReactToPrint
          trigger={() => <button className={styles.ToolsBarButton+' btn btn-info'} title={t('Print')}><AiFillPrinter /></button>}
          content={() => tableRef?.current}
          documentTitle={'mail_'+data?.name}
        />
        
      }
    </div>
    <br />
    <br />
      {isPrivileged['view_notes'] && (viewSource=='all') && <Notes id={id} serviceGet={generalFetchingServiceRef?.current?.GetNotes} serviceAdd={generalFetchingServiceRef?.current?.AddNote} serviceDelete={generalFetchingServiceRef?.current?.DeleteNote} isPrivileged={isPrivileged} locale={locale} />}
    <br />
    <br />
  </div>
};

ViewItem.propTypes = {};

ViewItem.defaultProps = {};

export default ViewItem;
