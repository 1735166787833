import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './AddEditItem.module.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import $ from 'jquery'

import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';

import { useTranslation } from 'react-i18next';


const AddEditItem = ({id, editSource, handleAddEdit, generalFetchingServiceRef, locale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [fieldsErrors, setFieldsErrors] = useState([]);

  
  const [storageCartsData, setStorageCatrsData] = useState([])
  const [storageCartsOptions, setStorageCatrsOptions] = useState([])

  const [isDamagableData, setIsDamagableData] = useState([])
  const [isDamagableOptions, setIsDamagableOptions] = useState([])
  
  const [isBreakableData, setIsBreakableData] = useState([])
  const [isBreakableOptions, setIsBreakableOptions] = useState([])

  const [isWrappableData, setIsWrappableData] = useState([])
  const [isWrappableOptions, setIsWrappableOptions] = useState([])
  
  const [storage_cart_id, setStorageCartId] = useState(null)
  const [item_name, setItemName] = useState('')
  const [item_price, setItemPrice] = useState('')
  const [wrapping_price, setWrappingPrice] = useState('')
  const [item_description, setItemDescription] = useState('')
  const [is_damagable, setIsDamagable] = useState(null)
  const [is_breakable, setIsBreakable] = useState(null)
  const [is_wrappable, setIsWrappable] = useState(null)
  
  const getAllData=()=>{
    generalFetchingServiceRef?.current?.getAllData(editSource=='all'?'':editSource)
      .then(response => {

        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          const storageCarts=data?.storageCarts
          const storageCartsOptions=storageCarts?.map(x => {return {'value':x?.id, 'name': t('Storage')+' '+t('Id')+' ('+x?.id+')'}})
          setStorageCatrsData(storageCarts)
          setStorageCatrsOptions(storageCartsOptions)
         
          const trueFalse=['True', 'False']

          const isDamagableOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsDamagableData(trueFalse)
          setIsDamagableOptions(isDamagableOptions)

          const isBreakableOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsBreakableData(trueFalse)
          setIsBreakableOptions(isBreakableOptions)

          const isWrappableOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsWrappableData(trueFalse)
          setIsWrappableOptions(isWrappableOptions)

          if(id){
            getById(id)
          }
        }
      }).catch(error => {
        console.log(error)
      })
  }
  const getObject = () =>{
    let data={
      storage_cart_id,
      item_name,
      item_price:parseFloat(item_price),
      wrapping_price:parseFloat(wrapping_price),
      item_description,
      is_damagable:is_damagable?is_damagable=='True':false,
      is_breakable:is_breakable?is_breakable=='True':false,
      is_wrappable:is_wrappable?is_wrappable=='True':false,
    };
    return data;
  }
  const handleAddBtnClick=()=>{
    let item=getObject()
    handleAddEdit(item, setFieldsErrors, resetData, editSource)
    window.scrollTo(0, 0);
  }
  
  const handleEditBtnClick=()=>{
    let item=getObject()
    item.id=id
    handleAddEdit(item, setFieldsErrors, null, editSource)
    window.scrollTo(0, 0);
  }

  const resetData = () => {
    setStorageCartId(null)
    setItemName('')
    setItemPrice('')
    setWrappingPrice('')
    setItemDescription('')
    setIsBreakable(null)
    setIsDamagable(null)
    setIsWrappable(null)
  }

  const handleChangeStorageCartId = (value) => {
    setStorageCartId(value)
  }
  const handleChangeIsBreakable = (value) => {
    setIsBreakable(value)
  }
  const handleChangeIsDamagable = (value) => {
    setIsDamagable(value)
  }
  const handleChangeIsWrappable = (value) => {
    setIsWrappable(value)
  }
  const handleChangeItemName=(e)=>{
    setItemName(e.target.value)
  }
  const handleChangeItemPrice=(e)=>{
    setItemPrice(e.target.value)
  }
  const handleChangeWrappingPrice=(e)=>{
    setWrappingPrice(e.target.value)
  }
  const handleChangeItemDescription=(e)=>{
    setItemDescription(e.target.value)
  }
  
  
  const setAllData=(data)=>{
    let item=data[0]
    setStorageCartId(item?.storage_cart_id)
    setIsBreakable(item?.is_breakable)
    setIsDamagable(item?.is_damagable)
    setIsWrappable(item?.is_wrappable)
    setItemName(item?.item_name)
    setItemPrice(item?.item_price)
    setWrappingPrice(item?.wrapping_price)
    setItemDescription(item?.item_description)
  }

  const getById = (id) => {
    generalFetchingServiceRef?.current?.getById(id, editSource=='all'?'':editSource)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setAllData(data)
          }
        }).catch(error => {
          console.log(error)
        })
  }
  useEffect(()=>{
    getAllData()
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectVendor}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectVendor}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <div className={styles.AddEditItem + ` ${locale=='ar'?'text-right':'text-left'}`} data-testid="AddEditItem">
    <div className="form-group">
      <label htmlFor="storage_cart_id">{t('Storage Cart')}</label>
      <RenderErrorMessage name='storage_cart_id' messages={fieldsErrors} />
      <SelectSearch id={styles.SelectVendor}
                onFocus={handleOnFocusSelect}
                options={storageCartsOptions}
                onChange={handleChangeStorageCartId}
                value={storage_cart_id}
                placeholder={t('Storage Cart')}
                search={true}
                filterOptions={fuzzySearch}
                />
    </div>

    <div className="form-group">
      <label htmlFor="is_damagable">{t('Is Damagable?')}</label>
      <RenderErrorMessage name='is_damagable' messages={fieldsErrors} />
      <SelectSearch id={styles.SelectVendor}
                onFocus={handleOnFocusSelect}
                options={isDamagableOptions}
                onChange={handleChangeIsDamagable}
                value={is_damagable}
                placeholder={t('Is Damagable?')}
                search={true}
                filterOptions={fuzzySearch}
                />
    </div>

    <div className="form-group">
      <label htmlFor="is_breakable">{t('Is Breakable?')}</label>
      <RenderErrorMessage name='is_breakable' messages={fieldsErrors} />
      <SelectSearch id={styles.SelectVendor}
                onFocus={handleOnFocusSelect}
                options={isBreakableOptions}
                onChange={handleChangeIsBreakable}
                value={is_breakable}
                placeholder={t('Is Breakable?')}
                search={true}
                filterOptions={fuzzySearch}
                />
    </div>

    <div className="form-group">
      <label htmlFor="is_wrappable">{t('Is Wrappable?')}</label>
      <RenderErrorMessage name='is_wrappable' messages={fieldsErrors} />
      <SelectSearch id={styles.SelectVendor}
                onFocus={handleOnFocusSelect}
                options={isWrappableOptions}
                onChange={handleChangeIsWrappable}
                value={is_wrappable}
                placeholder={t('Is Wrappable?')}
                search={true}
                filterOptions={fuzzySearch}
                />
    </div>

    <div className="form-group">
      <label htmlFor="wrapping_price">{t('Wrapping Price')}</label>
      <RenderErrorMessage name='wrapping_price' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('price')}`} id="wrapping_price" onChange={handleChangeWrappingPrice} value={wrapping_price} />
    </div>

    <div className="form-group">
      <label htmlFor="item_name">{t('Item Name')}</label>
      <RenderErrorMessage name='item_name' messages={fieldsErrors} />
      <input type="text" className="form-control" placeholder={`${t('Enter a')} ${t('name')}`} id="item_name" onChange={handleChangeItemName} value={item_name} />
    </div>

    <div className="form-group">
      <label htmlFor="item_price">{t('Item Price')}</label>
      <RenderErrorMessage name='item_price' messages={fieldsErrors} />
      <input type="number" className="form-control" placeholder={`${t('Enter a')} ${t('price')}`} id="item_price" onChange={handleChangeItemPrice} value={item_price} />
    </div>

    <div className={`form-group`}>
      <label htmlFor="item_description">{t('Item Description')}</label>
      <RenderErrorMessage name='item_description' messages={fieldsErrors} />
      <div>
        <textarea
        className={styles.TextArea}
        value={item_description}
        onChange={handleChangeItemDescription}
        ></textarea>
      </div>
    </div>
    
    
    <div className="form-group text-center">
      {!id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleAddBtnClick}>{t('Add')}</button>}
      {id && <button className={styles.AddEditBtn + ' btn btn-primary'} onClick={handleEditBtnClick}>{t('Edit')}</button>}
    </div>

  </div>
};

AddEditItem.propTypes = {};

AddEditItem.defaultProps = {};

export default AddEditItem;
