import React, {useEffect, useState, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './Logs.module.css';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import Pagination from '../../Common/Pagination/Pagination';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import ToolsBar from './ToolsBar/ToolsBar';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';


const Logs = ({logRef, generalFetchingServiceRef, isPrivileged, locale}) => {
  const { t, i18n } = useTranslation();

  const toolsBarRef = useRef();
  const paginationRef = useRef();
  const tableRef = useRef();
  
  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name
  
  const [logs, setLogs] = useState([])

  useImperativeHandle(logRef,()=>({
    loadPages(){
      toolsBarRef?.current?.loadPages()
    }
  }))

  const dataService = (links, isAll, filtersO) => generalFetchingServiceRef?.current?.getLogs(links, isAll, filtersO)
  const filtersDataService = () => generalFetchingServiceRef?.current?.getFiltersLogData()

  return <div className={styles.Deleted + ""} data-testid="Deleted">
    <ToolsBar toolsBarRef={toolsBarRef} locale={locale}
      generalFetchingServiceRef={generalFetchingServiceRef} paginationRef={paginationRef}
      identifier='CompanyLog' filtersDataService={filtersDataService} 
      printComponentRef={tableRef} isPrivileged={isPrivileged}
      />
      
    <table ref={tableRef} cellPadding="0" cellSpacing="0" border="0" className={"datatable table table-striped table-bordered table-hover"}>
      <thead>
        <tr>
           <th>{t('Id')}</th>
           <th>{t('User')}</th>
           <th>{t('Action')}</th>
           <th>{t('Company')}</th>
           <th className={styles.hidableColumn}>{t('Details')}</th>
           <th>{t('Date')}</th>
        </tr>
      </thead>
      <tbody>
        {logs.map((item, id) => {
          return <tr key={item?.id}>
            <td>{item?.id}</td>
            <td><Link to={`/${prefix}/dashboard/user/view/${item?.user?.id}`}>{item?.user?.first_name+' '+item?.user?.last_name}</Link></td>
            <td>{item?.action?.display_name}</td>
            <td><Link to={`/${prefix}/dashboard/company/view/${item?.entity_value_id}`}>Company {item?.entity_value_id}</Link></td>
            <td>{item?.details}</td>
            <td>{moment(item?.created_at).local().locale(locale).format('DD/MM/yyyy h:mm:ss a')}</td>
          </tr>
        })}
      </tbody>
    </table>
    
    {logs?.length == 0 && t("No items to display")}

    
    <Pagination toolsBarRef={toolsBarRef} logRef={logRef} service={dataService} data={logs} setData={setLogs} paginationRef={paginationRef} locale={locale} />
  </div>
};

Logs.propTypes = {};

Logs.defaultProps = {};

export default Logs;
