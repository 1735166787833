import React, {useEffect, useState, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './CompaniesSubscriptions.module.css';

import {MdSelectAll, MdTabUnselected, MdCheckBoxOutlineBlank, MdCheckBox} from 'react-icons/md';
import {FaRegHandRock} from 'react-icons/fa';

import Pagination from '../../Common/Pagination/Pagination';
import $ from 'jquery';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import ToolsBar from './ToolsBar/ToolsBar';
import { useTranslation } from 'react-i18next';


import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import UpdateAdd from './UpdateAdd/UpdateAdd';
import { Link } from 'react-router-dom';

const CompaniesSubscriptions = ({id, page, editBtnHandle, deleteHandle, generalFetchingServiceRef, companiesSubscriptionsRef, isPrivileged, locale, showMessage}) => {
  const { t, i18n } = useTranslation();

  const toolsBarRef = useRef();
  const paginationRef= useRef();
  const tableRef= useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name

  const [data, setDataItems] = useState([])
  
  const [selecetedItems, setSelectedItems] = useState([])
  const [isSelectAllBtn, setIsSelectAllBtn] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [isAddItem, setIsAddItem] = useState(false)
  const [isAddingUpdatingValues, setIsAddingUpdatingValues] = useState(false)
  const [updateOneItem, setUpdateOneItem] = useState(null)
  const [dataLength, setDataLength] = useState(0)
  

  let isDataLoaded=false
  const selectedDataAction = () => {
    if(selecetedItems.length>0){
      toolsBarRef?.current?.setIsSelectItems(true)
    }else{
      toolsBarRef?.current?.setIsSelectItems(false)
    }

    if(selecetedItems?.length==data?.length){
      setIsSelectAllBtn(false)
      $("#selectAllBtn").removeClass('btn-primary')
      $("#selectAllBtn").addClass('btn-warning')
    }else{
      setIsSelectAllBtn(true)
      $("#selectAllBtn").removeClass('btn-warning')
      $("#selectAllBtn").addClass('btn-primary')
    }
  }
  useEffect(()=>{
    selectedDataAction()
    isDataLoaded=true
  },[selecetedItems, data])
  useEffect(()=>{
    if(isDataLoaded){
      selectedDataAction()
    }
  },[selecetedItems])

  const handleOnBeforePrint = () => {
    setIsPrinting(true)
    $('.hidableColumn').removeClass(styles.hidableColumn)
    return Promise.resolve();
  }
  const handleOnAfterPrint = () => {
    setIsPrinting(false)
    $('.hidableColumn').addClass(styles.hidableColumn)
  }

  const setSelectedItem = (selectedId, value) => {
    if(value){
      if(selecetedItems.indexOf(selectedId)==-1){
        setSelectedItems([...selecetedItems, selectedId])
      }
    }else{
      if(selecetedItems.indexOf(selectedId)!=-1){
        setSelectedItems(selecetedItems.filter((x)=>x!=selectedId))
      }
    }
  }
  const handleOnChangeSelect= (e) => {
    let selectedId=parseInt(e.target.getAttribute('tag'))
    let value=e.target.checked
    setSelectedItem(selectedId, value)
  }

  useImperativeHandle(companiesSubscriptionsRef,()=>({
    setDataTotalLength(value){
      setDataLength(value)
    },
    deleteSelectedItems(){
      deleteHandle(selecetedItems, paginationRef, 'feature')
    },
    clearSelectedItems(){
      setSelectedItems([])
      $('.'+styles.CompaniesSubscriptions).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
    },
    loadPages(){
      toolsBarRef?.current?.loadPages()
    },
    showUpdateAddAttendanceModal(isAdd, id=null){
      setIsAddItem(isAdd)
      if(id){
        setUpdateOneItem(id)
      }
      setIsAddingUpdatingValues(true)
    }
  }))

  const handleSelectAllClick = () => {
    if(selecetedItems?.length==data?.length){
      $('.'+styles.CompaniesSubscriptions).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', false)
      setSelectedItems([])
    }else{
      $('.'+styles.CompaniesSubscriptions).find(`input[name="${styles.CheckBoxDataTable}"]`).prop('checked', true)
      setSelectedItems([...data?.map(x=>x.id)])
    }
  }

  const handleWhenUpdatingHidden = () =>{
    setIsAddingUpdatingValues(false)
    setUpdateOneItem(null)
  }

  const handleEnableSubscription = (isEnable, company_id, subscription_id) => {
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t(`${isEnable?'Enabling':'Disabling'} Confirmation`))
    $("#ConfirmModal").find(".modal-body p").html(t(`Do you want to ${isEnable?'enable':'disable'} the subscription?`))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      handleEnableSubscriptionApply(isEnable, company_id, subscription_id)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }
  const handleEnableSubscriptionApply = (isEnable, company_id, subscription_id) => {
    generalFetchingServiceRef?.current?.update({response:isEnable}, [company_id, subscription_id], 'enable')
    .then(response => {
      let responseData=response?.data
      if(responseData && responseData?.status==1){
        showMessage(true, responseData?.message)
        paginationRef?.current?.loadPages();
      }else{
        showMessage(false, responseData?.message)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const handleUnsubscribe = (company_id, subscription_id) => {
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t('Unsubscribing Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html(t(`Do you want to unsubscribe the company?`))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      handleUnsubscribeApply(company_id, subscription_id)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }
  const handleUnsubscribeApply = (company_id, subscription_id) => {
    generalFetchingServiceRef?.current?.update({response:false}, [company_id, subscription_id], 'subscribe')
    .then(response => {
      let responseData=response?.data
      if(responseData && responseData?.status==1){
        showMessage(true, responseData?.message)
        paginationRef?.current?.loadPages();
      }else{
        showMessage(false, responseData?.message)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  

  const dataService = (links, isAll, filtersO) => generalFetchingServiceRef?.current?.getAll(links, isAll, filtersO, 'companies_subscriptions')
  const filtersDataService = () => generalFetchingServiceRef?.current?.getFiltersData(null, 'companies_subscriptions')
  const excelService = (filters, specificItems) => generalFetchingServiceRef?.current?.ExportExcel(filters, 'CompaniesSubscriptions', specificItems,`${id}/feature`)


  return <div className={styles.CompaniesSubscriptions + ""} data-testid="CompaniesSubscriptions">
    <ToolsBar toolsBarRef={toolsBarRef} companiesSubscriptionsRef={companiesSubscriptionsRef} paginationRef={paginationRef} isPrivileged={isPrivileged}
     ViewItemsButtons={{'Delete': true}} identifier='SubscriptionCompaniesSubscriptions' filtersDataService={filtersDataService} selecetedItems={selecetedItems}
     printComponentRef={tableRef} printDocumentTitle='Subscriptions' handleOnAfterPrint={handleOnAfterPrint} handleOnBeforePrint={handleOnBeforePrint}
     locale={locale} page={page} dataLength={dataLength}
     />

    {/* <UpdateAdd locale={locale} isPrivileged={isPrivileged} isAdd={isAddItem} show={isAddingUpdatingValues} generalFetchingServiceRef={generalFetchingServiceRef} updateOneItem={updateOneItem} handleWhenHidden={handleWhenUpdatingHidden} handleOnConfirm={handleOnUpdateAddConfirm} /> */}
    
    {data?.length > 0 &&
    <table id="tableToPrint" ref={tableRef} cellPadding="0" cellSpacing="0" border="0" className={"datatable table table-striped table-bordered table-hover"}>
      <thead>
        <tr>
          {!isPrinting && <th className='hideOnPrint'>
            <button id="selectAllBtn" className='btn btn-primary' onClick={handleSelectAllClick}>
              {isSelectAllBtn && <MdSelectAll id="SelectAll" />}
              {!isSelectAllBtn && <MdTabUnselected id="UnSelectAll" />}
            </button>
          </th>}
          <th>{t('Company Name')}</th>
          <th>{t('Company Holder Name')}</th>
          <th className={styles.hidableColumn + ' hidableColumn'}>{t('Email')}</th>
          <th className={styles.hidableColumn + ' hidableColumn'}>{t('Phone')}</th>
          <th>{t('Subscription')}</th>
          <th>{t('Expiary Date')}</th>
          <th>{t('Is Enabled?')}</th>
          {!isPrinting && <th>{t('Actions')}</th>}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, id) => {
          return <tr key={id}>
            {!isPrinting && <td className='hideOnPrint'><input type={'checkbox'} tag={item?.id} name={styles.CheckBoxDataTable} className={styles.CheckBoxDataTable} onChange={handleOnChangeSelect} /></td>}
            <td><Link to={`/${prefix}/dashboard/company/view/${item?.user?.id}`} className="dropdown-item ">{item?.name}</Link></td>
            <td>{item?.holder_name}</td>
            <td className={styles.hidableColumn + ' hidableColumn'}>{item?.user?.email}</td>
            <td className={styles.hidableColumn + ' hidableColumn'}>{item?.user?.phone}</td>
            <td>{item?.subscription?.name}</td>
            <td>{(item?.user?.expiary_date?(moment(item?.user?.expiary_date).local().locale(locale).format('DD/MM/yyyy h:mm:ss a')):'')}</td>
            <td>{item?.is_enabled_subscription?t('true'):t('false')}</td>
            {!isPrinting && <td>
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropDownActionsBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {t('Do an action')}
              </button>
              <div className={`dropdown-menu ${locale=='ar'?'text-right':'text-left'}`} aria-labelledby="dropDownActionsBtn">
                {(item?.is_enabled_subscription==1) && <button className="dropdown-item btn btn-primary" onClick={()=>handleEnableSubscription(false, item?.id, item?.subscription?.id)}><MdCheckBoxOutlineBlank /> {t('Disable')}</button>}
                {(item?.is_enabled_subscription==0) && <button className="dropdown-item btn btn-primary" onClick={()=>handleEnableSubscription(true, item?.id, item?.subscription?.id)}><MdCheckBox /> {t('Enable')}</button>}
                <button className="dropdown-item btn btn-danger" onClick={()=>handleUnsubscribe(item?.id, item?.subscription?.id)}><FaRegHandRock /> {t('Unsubcribe')}</button>
              </div>
            </div>
            </td>}
          </tr>
        })}
      </tbody>
    </table>
    }

    <br/>{data?.length == 0 && t("No items to display")}

    <Pagination toolsBarRef={toolsBarRef} service={dataService} excelService={excelService} data={data} setData={setDataItems} paginationRef={paginationRef} locale={locale} />
  </div>
};

CompaniesSubscriptions.propTypes = {};

CompaniesSubscriptions.defaultProps = {};

export default CompaniesSubscriptions;
