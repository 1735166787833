import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../Services/GeneralHelpers';
import ToolsItemsBar from '../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({dataLength, page, toolsBarRef, allRef, deletedRef, paginationRef, printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale, dataSumService}) => {
  const { t, i18n } = useTranslation();

  const isGeneral = page=='General';
  const isDeleted = page=='Deleted';

  const [from_buy_date, setBuyFromDateFilter] = useState(null);
  const [to_buy_date, setBuyToDateFilter] = useState(null);

  const [companyFilter, setCompanyFilter] = useState(null);
  const [companiesData, setCompaniesData] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])

  const [actionTitleFilter, setActionTitleFilter] = useState("");
  const [companyAccountantFilter, setCompanyAccountantFilter] = useState("");
  const [authorizedToActFilter, setAuthorizedToActFilter] = useState("");

  const [assetTypeFilter, setAssetTypeFilter] = useState(null);
  const [assetTypesData, setAssetTypesData] = useState([])
  const [assetTypesOptions, setAssetTypesOptions] = useState([])

  const [assetActionFilter, setAssetActionFilter] = useState(null);
  const [assetActionsData, setAssetActionsData] = useState([])
  const [assetActionsOptions, setAssetActionsOptions] = useState([])

  const [buySum, setBuySum] = useState(0);
  const [sellSum, setSellSum] = useState(0);


  const [isFilters, setIsFilters] = useState(false)
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      allRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
        GetSumData(filtersH)
      }else{
        paginationRef?.current?.loadPages()
        GetSumData(null)
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('assets', selecetedItems)
    },
    showUpdateModal(){
      if(isGeneral){
        allRef?.current?.showUpdateModal()
      }
    }
  }))

  const GetSumData = (filters) => {
    if(dataSumService){
      dataSumService(null, true, filters)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          setBuySum(responseData?.data?.buySum)
          setSellSum(responseData?.data?.sellSum)
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }

  const handleChangeBuyFromDate = (value) => {
    setBuyFromDateFilter(value)
  }
  const handleChangeBuyToDate = (value) => {
    setBuyToDateFilter(value)
  }
  const handleChangeCompany = (value) => {
    setCompanyFilter(value)
  }
  const handleChangeActionTitle = (e) => {
    setActionTitleFilter(e.target.value)
  }
  const handleChangeCompanyAccountant = (e) => {
    setCompanyAccountantFilter(e.target.value)
  }
  const handleChangeAuthorizedToAct = (e) => {
    setAuthorizedToActFilter(e.target.value)
  }
  const handleChangeIsActivated = (value) => {
    setIsActivatedFilter(value)
  }
  const handleChangeAssetType = (values) => {
    setAssetTypeFilter(values)
  }
  const handleChangeAssetAction = (value) => {
    setAssetActionFilter(value)
  }

  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let companies=data?.companies
          let companiesOptions=companies?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setCompaniesData(companies)
          setCompaniesOptions(companiesOptions)

          let assetActions=data?.assetActions
          let assetActionsOptions=assetActions?.map(x => {return {'value':x.id, 'name': t(x.name)}})
          setAssetActionsData(assetActions)
          setAssetActionsOptions(assetActionsOptions)

          let assetTypes=data?.assetTypes
          let assetTypesOptions=assetTypes?.map(x => {return {'value':x.id, 'name': t(x.name)}})
          setAssetTypesData(assetTypes)
          setAssetTypesOptions(assetTypesOptions)
        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    let fromBuyDateF=from_buy_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(from_buy_date)).locale("es").format('yyyy-MM-DD'):null
    let toBuyDateF=to_buy_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(to_buy_date)).locale("es").format('yyyy-MM-DD'):null
    let result={
      company_id: (filtersS && filtersS?.company_id)?filtersS?.company_id:companyFilter,
      asset_action_id: (filtersS && filtersS?.asset_action_id)?filtersS?.asset_action_id:assetActionFilter,
      asset_type_id: (filtersS && filtersS?.asset_type_id)?filtersS?.asset_type_id:assetTypeFilter,
      company_accountant:companyAccountantFilter,
      authorized_to_act:authorizedToActFilter,
      action_title:actionTitleFilter,
      from_buy_date: fromBuyDateF?moment.utc(GeneralHelpers.FixDateAddCurrentTime(fromBuyDateF)).locale('es').format('yyyy-MM-DD'):null,
      to_buy_date: toBuyDateF?moment.utc(GeneralHelpers.FixDateAddCurrentTime(toBuyDateF)).locale('es').format('yyyy-MM-DD'):null,
    };
    return result
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.company_id===null &&
      filters.asset_action_id===null &&
      filters.asset_type_id===null &&
      (filters.company_accountant===null || filters.company_accountant.trim().length==0) &&
      (filters.authorized_to_act===null || filters.authorized_to_act.trim().length==0) &&
      (filters.action_title===null || filters.action_title.trim().length==0) &&
      (filters.from_buy_date===null && filters.from_buy_date===null) &&
      (filters.to_buy_date===null && filters.to_buy_date===null)
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    GetSumData(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setBuyFromDateFilter(null)
    setBuyToDateFilter(null)
    setActionTitleFilter('')
    setCompanyAccountantFilter('')
    setAuthorizedToActFilter('')
    setAssetActionFilter(null)
    setAssetTypeFilter(null)
    setCompanyFilter(null)
    setIsFilters(false)

    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
    GetSumData()
  }

  const loadFiltersApply = (filtersO) => {
    const action_title=filtersO?.action_title
    if(action_title){
      setActionTitleFilter(action_title)
    }

    const authorized_to_act=filtersO?.authorized_to_act
    if(authorized_to_act){
      setAuthorizedToActFilter(authorized_to_act)
    }

    const asset_action_id=filtersO?.asset_action_id
    if(asset_action_id){
      setAssetActionFilter(asset_action_id)
    }

    const asset_type_id=filtersO?.asset_type_id
    if(asset_type_id){
      setAssetTypeFilter(asset_type_id)
    }

    const from_buy_date=filtersO?.from_buy_date
    if(from_buy_date){
      setBuyFromDateFilter(new Date(from_buy_date))
    }

    const to_buy_date=filtersO?.to_buy_date
    if(to_buy_date){
      setBuyToDateFilter(new Date(to_buy_date))
    }

    const company_accountant=filtersO?.company_accountant
    if(company_accountant){
      setCompanyAccountantFilter(company_accountant)
    }

    const companyId=filtersO?.company_id
    if(companyId){
      setCompanyFilter(companyId)
    }
    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }

  const HideDropdownBoxes=()=>{
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }

  useEffect(()=>{
    getFiltersData()
    setTimeout(()=>{
      HideDropdownBoxes()
    },200)
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectIsActivated}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectIsActivated}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectAssetType}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectAssetType}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectCompany}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectCompany}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>
        <div className={styles.selectsGroup}>
        <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectInput}
                  onFocus={handleOnFocusSelect}
                  options={companiesOptions}
                  onChange={handleChangeCompany}
                  value={companyFilter}
                  placeholder={t('Company')}
                  search={true}
                  filterOptions={fuzzySearch}
                  />

            <input className={styles.SelectInput}
              onChange={handleChangeCompanyAccountant}
              value={companyAccountantFilter}
              placeholder={t('Company Accountant')}
              />
          </div>

          <div className={styles.subSelectsGroup}>
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={from_buy_date} onChange={handleChangeBuyFromDate} placeholderText={`${t('Start Date')} (${t('Buy')})`} />
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={to_buy_date} onChange={handleChangeBuyToDate} placeholderText={`${t('End Date')} (${t('Buy')})`} />
          </div>

        </div>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectInput}
                        onFocus={handleOnFocusSelect}
                        options={assetTypesOptions}
                        onChange={handleChangeAssetType}
                        value={assetTypeFilter}
                        placeholder={t('Asset Type')}
                        search={true}
                        filterOptions={fuzzySearch}
                        autoFocus={false}
                        />

            <SelectSearch id={styles.SelectInput}
                      onFocus={handleOnFocusSelect}
                      options={assetActionsOptions}
                      onChange={handleChangeAssetAction}
                      value={assetActionFilter}
                      placeholder={t('Asset Action')}
                      search={true}
                      filterOptions={fuzzySearch}
                      autoFocus={false}
                      />
                    
          </div>
        </div>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <input className={styles.SelectInput}
                onChange={handleChangeActionTitle}
                value={actionTitleFilter}
                placeholder={t('Action Title')}
                />

            <input className={styles.SelectInput}
              onChange={handleChangeAuthorizedToAct}
              value={authorizedToActFilter}
              placeholder={t('Authorized to act')}
              />
          </div>
        </div>
        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar dataLength={dataLength} allRef={allRef} deletedRef={deletedRef} isDeleted={isDeleted} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} buySum={buySum} sellSum={sellSum} />
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
