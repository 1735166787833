import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './Chat.module.css';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import { useTranslation } from 'react-i18next';

import {AiFillPrinter} from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import Notes from '../../Common/Notes/Notes';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import Messages from './Messages/Messages';

const Chat = ({id, generalFetchingServiceRef, handleTabChange, isPrivileged, locale}) => {
  const { t, i18n } = useTranslation();
  
  const [data, setData] = useState(null)

  const tableRef = useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name


  const GetData = () => {
    generalFetchingServiceRef?.current?.getById(id)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            if(data?.length>0){
              setData(data[0])
            }
          }
        }).catch(error => {
          console.log(error)
        })
  }

  useEffect(()=>{
    GetData()
  },[])

  return <div className={styles.Chat} data-testid="Chat">
    <Messages id={id} serviceGet={generalFetchingServiceRef?.current?.GetNotes} serviceAdd={generalFetchingServiceRef?.current?.AddNote} serviceDelete={generalFetchingServiceRef?.current?.DeleteNote} isPrivileged={isPrivileged} locale={locale} />
  </div>
};

Chat.propTypes = {};

Chat.defaultProps = {};

export default Chat;
