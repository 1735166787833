import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../Services/GeneralHelpers';
import ToolsItemsBar from '../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({dataLength, page, toolsBarRef, allRef, deletedRef, paginationRef, printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale, dataSumService}) => {
  const { t, i18n } = useTranslation();

  const isGeneral = page=='General';
  const isDeleted = page=='Deleted';

  const [from_expense_date, setExpenseFromDateFilter] = useState(null);
  const [to_expense_date, setExpenseToDateFilter] = useState(null);

  const [companyFilter, setCompanyFilter] = useState(null);
  const [companiesData, setCompaniesData] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])

  const [typeFilter, setTypeFilter] = useState("");
  const [addressFilter, setAddressFilter] = useState("");
  const [companyAccountantFilter, setCompanyAccountantFilter] = useState("");
  const [authorizedToExpenseFilter, setAuthorizedToExpenseFilter] = useState("");

  const [expenseSum, setExpenseSum] = useState(0);


  const [isFilters, setIsFilters] = useState(false)
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      allRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
        GetSumData(filtersH)
      }else{
        paginationRef?.current?.loadPages()
        GetSumData(null)
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('expenses', selecetedItems)
    },
    showUpdateModal(){
      if(isGeneral){
        allRef?.current?.showUpdateModal()
      }
    }
  }))

  const GetSumData = (filters) => {
    if(dataSumService){
      dataSumService(null, true, filters)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          setExpenseSum(responseData?.data?.expenseSum)
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }

  const handleChangeExpenseFromDate = (value) => {
    setExpenseFromDateFilter(value)
  }
  const handleChangeExpenseToDate = (value) => {
    setExpenseToDateFilter(value)
  }
  const handleChangeCompany = (value) => {
    setCompanyFilter(value)
  }
  const handleChangeAddress = (e) => {
    setAddressFilter(e.target.value)
  }
  const handleChangeCompanyAccountant = (e) => {
    setCompanyAccountantFilter(e.target.value)
  }
  const handleChangeAuthorizedToExpense = (e) => {
    setAuthorizedToExpenseFilter(e.target.value)
  }
  const handleChangeType = (e) => {
    setTypeFilter(e.target.value)
  }

  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          let companies=data?.companies
          let companiesOptions=companies?.map(x => {return {'value':x.id, 'name': x.display_name}})
          setCompaniesData(companies)
          setCompaniesOptions(companiesOptions)

        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    let fromExpenseDateF=from_expense_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(from_expense_date)).locale("es").format('yyyy-MM-DD'):null
    let toExpenseDateF=to_expense_date?moment.utc(GeneralHelpers.FixDateAddCurrentTime(to_expense_date)).locale("es").format('yyyy-MM-DD'):null
    let result={
      company_id: (filtersS && filtersS?.company_id)?filtersS?.company_id:companyFilter,
      type:typeFilter,
      company_accountant:companyAccountantFilter,
      authorized_to_expense:authorizedToExpenseFilter,
      address:addressFilter,
      from_expense_date: fromExpenseDateF?moment.utc(GeneralHelpers.FixDateAddCurrentTime(fromExpenseDateF)).locale('es').format('yyyy-MM-DD'):null,
      to_expense_date: toExpenseDateF?moment.utc(GeneralHelpers.FixDateAddCurrentTime(toExpenseDateF)).locale('es').format('yyyy-MM-DD'):null,
    };
    return result
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.company_id===null &&
      (filters.type===null || filters.type.trim().length==0) &&
      (filters.company_accountant===null || filters.company_accountant.trim().length==0) &&
      (filters.authorized_to_expense===null || filters.authorized_to_expense.trim().length==0) &&
      (filters.address===null || filters.address.trim().length==0) &&
      (filters.from_expense_date===null && filters.from_expense_date===null) &&
      (filters.to_expense_date===null && filters.to_expense_date===null)
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    GetSumData(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setExpenseFromDateFilter(null)
    setExpenseToDateFilter(null)
    setAddressFilter('')
    setCompanyAccountantFilter('')
    setAuthorizedToExpenseFilter('')
    setTypeFilter('')
    setCompanyFilter(null)
    setIsFilters(false)

    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
    GetSumData()
  }

  const loadFiltersApply = (filtersO) => {
    const address=filtersO?.address
    if(address){
      setAddressFilter(address)
    }

    const authorized_to_expense=filtersO?.authorized_to_expense
    if(authorized_to_expense){
      setAuthorizedToExpenseFilter(authorized_to_expense)
    }

    const expense_action_id=filtersO?.expense_action_id
    if(expense_action_id){
      setExpenseActionFilter(expense_action_id)
    }

    const expense_type_id=filtersO?.expense_type_id
    if(expense_type_id){
      setTypeFilter(expense_type_id)
    }

    const from_expense_date=filtersO?.from_expense_date
    if(from_expense_date){
      setExpenseFromDateFilter(new Date(from_expense_date))
    }

    const to_expense_date=filtersO?.to_expense_date
    if(to_expense_date){
      setExpenseToDateFilter(new Date(to_expense_date))
    }

    const company_accountant=filtersO?.company_accountant
    if(company_accountant){
      setCompanyAccountantFilter(company_accountant)
    }

    const companyId=filtersO?.company_id
    if(companyId){
      setCompanyFilter(companyId)
    }
    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }

  const HideDropdownBoxes=()=>{
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }

  useEffect(()=>{
    getFiltersData()
    setTimeout(()=>{
      HideDropdownBoxes()
    },200)
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectIsActivated}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectIsActivated}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectType}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectType}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectCompany}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectCompany}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>
        <div className={styles.selectsGroup}>
        <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectInput}
                  onFocus={handleOnFocusSelect}
                  options={companiesOptions}
                  onChange={handleChangeCompany}
                  value={companyFilter}
                  placeholder={t('Company')}
                  search={true}
                  filterOptions={fuzzySearch}
                  />

            <input className={styles.SelectInput}
              onChange={handleChangeCompanyAccountant}
              value={companyAccountantFilter}
              placeholder={t('Company Accountant')}
              />
          </div>

          <div className={styles.subSelectsGroup}>
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={from_expense_date} onChange={handleChangeExpenseFromDate} placeholderText={`${t('Start Date')} (${t('Expense')})`} />
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={to_expense_date} onChange={handleChangeExpenseToDate} placeholderText={`${t('End Date')} (${t('Expense')})`} />
          </div>

        </div>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <input className={styles.SelectInput}
              onChange={handleChangeType}
              value={typeFilter}
              placeholder={t('Expense Type')}
              />

            <input className={styles.SelectInput}
                onChange={handleChangeAddress}
                value={addressFilter}
                placeholder={t('Address')}
                />
          </div>
        </div>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <input className={styles.SelectInput}
              onChange={handleChangeAuthorizedToExpense}
              value={authorizedToExpenseFilter}
              placeholder={t('Authorized to expense')}
              />
          </div>
        </div>
        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar dataLength={dataLength} allRef={allRef} deletedRef={deletedRef} isDeleted={isDeleted} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} expenseSum={expenseSum} />
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
