import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './Storage.module.css';
import DashboardLayout from '../Dashboard/DashboardLayout/DashboardLayout';
import TopBar from './TopBar/TopBar';
import { useParams, useNavigate } from 'react-router-dom';
import GeneralFetchingService from '../../Services/GeneralFetchingService';

import { useTranslation } from 'react-i18next';

import $ from 'jquery';
import RenderErrorMessage from '../Common/RenderErrorMessage/RenderErrorMessage';
import RenderSuccessMessage from '../Common/RenderSuccessMessage/RenderSuccessMessage';
import { useAuth } from '../Common/Auth/AuthHelpers/Auth';

import Privileges from '../Common/Privileges/Privileges';
import NoPrivilegesMessage from '../Common/NoPrivilegesMessage/NoPrivilegesMessage';

import All from './All/All';
import View from './View/View';
import Deleted from './Deleted/Deleted';

import Cart from '../Mail/Cart/Cart';
import ViewCart from './ViewCart/ViewCart';
import DeletedCart from './DeletedCart/DeletedCart';

import ViewItem from './ViewItem/ViewItem';
import DeletedItem from './DeletedItem/DeletedItem';

import Logs from './Logs/Logs';
import CartItems from './CartItems/CartItems';
import StorageCart from './StorageCart/StorageCart';
import AddEditItem from './AddEditItem/AddEditItem';
import AddEditCart from './AddEditCart/AddEditCart';
import AddEdit from './AddEdit/AddEdit';

const StorageComponents = ({page, locale, setLocale}) => {
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name
  const navigate = useNavigate();
  
  const isLog= page=='Log';

  const params = useParams();
  const [routePage, setRoutePage] = useState(page)

  const isStorage = routePage == 'Storage';
  const isAddStorage = routePage == 'AddStorage';
  const isEditStorage = routePage == 'EditStorage';
  const isDeletedStorage = routePage == 'DeletedStorage';
  const isViewStorage = routePage == 'ViewStorage';

  const isCart = routePage == 'Cart';
  const isAddCart = routePage == 'AddCart';
  const isEditCart = routePage == 'EditCart';
  const isDeletedCart = routePage == 'DeletedCart';
  const isViewCart = routePage == 'ViewCart';

  const isItem = routePage == 'CartItem';
  const isAddItem = routePage == 'AddItem';
  const isEditItem = routePage == 'EditItem';
  const isDeletedItem = routePage == 'DeletedItem';
  const isViewItem = routePage == 'ViewItem';

  const [dataItems, setDataItems] = useState([])
  

  const [isPrivileges, setIsPrivileges] = useState(false)
  const [privilegesSideBar, setPrivilegesSideBar] = useState([])
  const [isPrivileged, setIsPrivileged] = useState({})
  const [isTopBarButtonsVisible, setIsTopBarButtonsVisible] = useState({
    'Add':true,
    'View':false,
    'Edit':false,
    'Deleted':true,
    'Storage':true,
    'ViewStorage':false, 
    'AddStorage':false, 
    'EditStorage': false,
    'DeletedStorage': false,
    'Cart':true,
    'ViewCart':false, 
    'AddCart':false, 
    'EditCart': false,
    'DeletedCart': false,
    'CartItem':true,
    'ViewItem':false, 
    'AddItem':false, 
    'EditItem': false,
    'DeletedItem': false,
  })
  

  const [generalErrorMessage, setGeneralErrorMessage] = useState(null);
  const [generalSuccessMessage, setGeneralSuccessMessage] = useState(null);
  const [isShownGeneralError, setIsShownGeneralError] = useState(false);
  const [isShownGeneralSuccess, setIsShownGeneralSuccess] = useState(false);


  
  const storageRef = useRef();
  const cartRef = useRef();
  const itemRef = useRef();
  const generalFetchingServiceRef = useRef();
  const privilegesRef= useRef();
  const deletedRef= useRef();
  const deletedCartRef= useRef();
  const deletedItemRef= useRef();
  const logRef= useRef();
  
  const showMessage = (isSuccess, message) => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
    if(isSuccess){
      setGeneralSuccessMessage(message)
      setIsShownGeneralSuccess(true)
    }else{
      setGeneralErrorMessage(message)
      setIsShownGeneralError(true)
    }
    window.scrollTo(0, 0)
  }

  const hideMessage = () => {
    setGeneralSuccessMessage(null)
    setGeneralErrorMessage(null)
  }

  const deleteApply = (ids, paginationRef, customAPi) => {
    generalFetchingServiceRef?.current?.delete(ids, customAPi=='all'?'':customAPi)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          paginationRef?.current?.loadPages()
          showMessage(true, responseData?.message)
        }else{
          showMessage(false, responseData?.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }
  const deleteHandle=(ids, paginationRef, customAPi)=>{
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t('Delete Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html(`${t('Do you want to delete the items?')} <br/> <br/> <div style='color:red;'>${t('Note that all related items will be deleted!')}</div>`)
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      deleteApply(ids, paginationRef, customAPi)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }

  const restoreDeletedApply = (ids, paginationRef, customAPi) => {
    generalFetchingServiceRef?.current?.restoreDeleted(ids, customAPi=='all'?'':customAPi)
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          paginationRef?.current?.loadPages()
          showMessage(true, responseData?.message)
        }else{
          showMessage(false, responseData?.message)
        }
      }).catch(error => {
        console.log(error)
      })
  }
  const restoreDeletedHandle=(ids, paginationRef, customAPi)=>{
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t('Restore Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html(`${t('Do you want to restore deleted items?')} <br/> <br/> <div style='color:red;'>${t('Note that all related items will be restored!')}</div>`)
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      restoreDeletedApply(ids, paginationRef, customAPi)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
  }

  const editBtnHandle = (id, source) => {
    handleTabChange(source=='cart'?'EditCart':source=='item'?'EditItem':'EditStorage', id)
  }

  const viewBtnHandle = (id, source) => {
    handleTabChange(source=='cart'?'ViewCart':source=='item'?'ViewItem':'ViewStorage', id)
  }

  const handleAddEdit=(item, setFieldsErrors, resetData, editSource)=>{
    if(!item?.id){
      generalFetchingServiceRef?.current?.add(item, editSource=='all'?'':editSource)
        .then(response => {
          if(response?.response){
            response=response?.response
          }
          const responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setDataItems([...dataItems, data])
            resetData()
            setFieldsErrors([])
            handleTabChange(editSource=='all'?'Storage':editSource=='cart'?'Cart':'CartItem')
            showMessage(true, responseData?.message)
          }else{
            showMessage(false, responseData?.message)
          }
        }).catch(error => {
          let response=error?.response
          if(response.status==422){
            setFieldsErrors(response?.data?.errors)
          }
        })
    }else{
      generalFetchingServiceRef?.current?.edit(item, editSource=='all'?'':editSource)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            setDataItems(dataItems.map(x => {
              if(x.id==data.id){
                return {...data}
              }
            }))
            showMessage(true, responseData?.message)
          }else{
            showMessage(false, responseData?.message)
          }
        }).catch(error => {
          console.log(error)
          let response=error.response
          if(response.status==422){
            setFieldsErrors(response.data.errors)
          }
        })
    }
  }

  const handlePublishStorage=(ids)=>{
    $("#ConfirmModal").find(".modal-body p").css('text-align', locale=='ar'?'right':'left')
    $("#ConfirmModal").find(".modal-title").html(t('Publish Confirmation'))
    $("#ConfirmModal").find(".modal-body p").html(t('Do you want to publish items?'))
    $("#ConfirmModal").find("#confirmBtn").html(t('Confirm'))
    $("#ConfirmModal").find("#cancelBtn").html(t('Cancel'))
    $("#ConfirmModal").find("#confirmBtn").removeAttr('onclick').off('click').on('click',function(e){
      handlePublishStorageApply(ids)

      $("#ConfirmModal").removeClass('fade').modal('hide');
      $("#ConfirmModal").modal('dispose')
    })
    $("#ConfirmModal").modal({
      keyboard:true,
      focus:true,
      show:true
    })
    
  }
  const handlePublishStorageApply=(ids)=>{
    generalFetchingServiceRef?.current?.publish(ids, 'cart')
    .then(response => {
      let responseData=response?.data
      if(responseData && responseData?.status==1){
        let data=responseData?.data
        showMessage(true, responseData?.message)
      }else{
        showMessage(false, responseData?.message)
      }
    }).catch(error => {
      console.log(error)
    })
  }
  
  

  const handleTabChange=(pageR, id=null, isLoad)=>{
    if(pageR){
      switch(pageR){
        case 'Storage':
          if(!isLoad){
            navigate(`/${prefix}/dashboard/storage`)
          }
          setIsTopBarButtonsVisible({
            'Add':true,
            'View':false,
            'Edit':false,
            'Deleted':true,
            'Storage':true,
            'ViewStorage':false, 
            'AddStorage':false, 
            'EditStorage': false,
            'DeletedStorage': false,
            'Cart':true,
            'ViewCart':false, 
            'AddCart':false, 
            'EditCart': false,
            'DeletedCart': false,
            'CartItem':true,
            'ViewItem':false, 
            'AddItem':false, 
            'EditItem': false,
            'DeletedItem': false,
          })
          break;
        case 'AddStorage':
          if(!isLoad){
            navigate(`/${prefix}/dashboard/storage/add`)
          }
          setIsTopBarButtonsVisible({
            'Add':true,
            'View':false,
            'Edit':false,
            'Deleted':true,
            'Storage':false,
            'ViewStorage':false, 
            'AddStorage':true, 
            'EditStorage': false,
            'DeletedStorage': false,
            'Cart':false,
            'ViewCart':false, 
            'AddCart':true, 
            'EditCart': false,
            'DeletedCart': false,
            'CartItem':false,
            'ViewItem':false, 
            'AddItem':true, 
            'EditItem': false,
            'DeletedItem': false,
          })
          break;
        case 'EditStorage':
          if(id){
            if(!isLoad){
              navigate(`/${prefix}/dashboard/storage/edit/${id}`)
            }
            setIsTopBarButtonsVisible({
              'Add':false,
              'View':false,
              'Edit':true,
              'Deleted':true,
              'Storage':false,
              'ViewStorage':false, 
              'AddStorage':false, 
              'EditStorage': true,
              'DeletedStorage': false,
              'Cart':false,
              'ViewCart':false, 
              'AddCart':false, 
              'EditCart': false,
              'DeletedCart': false,
              'CartItem':false,
              'ViewItem':false, 
              'AddItem':false, 
              'EditItem': false,
              'DeletedItem': false,
            })
          }
          break;
        case 'DeletedStorage':
          if(!isLoad){
            navigate(`/${prefix}/dashboard/storage/deleted`)
          }
          setIsTopBarButtonsVisible({
            'Add':true,
            'View':false,
            'Edit':false,
            'Deleted':true,
            'Storage':false,
            'ViewStorage':false, 
            'AddStorage':false, 
            'EditStorage': false,
            'DeletedStorage': true,
            'Cart':false,
            'ViewCart':false, 
            'AddCart':false, 
            'EditCart': false,
            'DeletedCart': true,
            'CartItem':false,
            'ViewItem':false, 
            'AddItem':false, 
            'EditItem': false,
            'DeletedItem': true,
          })
          break;
        case 'ViewStorage':
          if(id){
            if(!isLoad){
              navigate(`/${prefix}/dashboard/storage/view/${id}`)
            }
            setIsTopBarButtonsVisible({
              'Add':true,
              'View':false,
              'Edit':false,
              'Deleted':true,
              'Storage':false,
              'ViewStorage':true, 
              'AddStorage':false, 
              'EditStorage': false,
              'DeletedStorage': false,
              'Cart':false,
              'ViewCart':false, 
              'AddCart':false, 
              'EditCart': false,
              'DeletedCart': false,
              'CartItem':false,
              'ViewItem':false, 
              'AddItem':false, 
              'EditItem': false,
              'DeletedItem': false,
            })
          }
          break;

          


          
        case 'Cart':
          if(!isLoad){
            navigate(`/${prefix}/dashboard/storage/cart`)
          }
            setIsTopBarButtonsVisible({
              'Add':true,
              'View':false,
              'Edit':false,
              'Deleted':true,
              'Storage':true,
              'ViewStorage':false, 
              'AddStorage':false, 
              'EditStorage': false,
              'DeletedStorage': false,
              'Cart':true,
              'ViewCart':false, 
              'AddCart':false, 
              'EditCart': false,
              'DeletedCart': false,
              'CartItem':true,
              'ViewItem':false, 
              'AddItem':false, 
              'EditItem': false,
              'DeletedItem': false,
            })
            break;
          case 'AddCart':
            if(!isLoad){
              navigate(`/${prefix}/dashboard/storage/cart/add`)
            }
            setIsTopBarButtonsVisible({
              'Add':true,
              'View':false,
              'Edit':false,
              'Deleted':true,
              'Storage':false,
              'ViewStorage':false, 
              'AddStorage':true, 
              'EditStorage': false,
              'DeletedStorage': false,
              'Cart':false,
              'ViewCart':false, 
              'AddCart':true, 
              'EditCart': false,
              'DeletedCart': false,
              'CartItem':false,
              'ViewItem':false, 
              'AddItem':true, 
              'EditItem': false,
              'DeletedItem': false,
            })
            break;
          case 'EditCart':
            if(id){
              if(!isLoad){
                navigate(`/${prefix}/dashboard/storage/cart/edit/${id}`)
              }
              setIsTopBarButtonsVisible({
                'Add':false,
                'View':false,
                'Edit':true,
                'Deleted':true,
                'Storage':false,
                'ViewStorage':false, 
                'AddStorage':false, 
                'EditStorage': false,
                'DeletedStorage': false,
                'Cart':false,
                'ViewCart':false, 
                'AddCart':false, 
                'EditCart': true,
                'DeletedCart': false,
                'CartItem':false,
                'ViewItem':false, 
                'AddItem':false, 
                'EditItem': false,
                'DeletedItem': false,
              })
            }
            break;
          case 'DeletedCart':
            if(!isLoad){
              navigate(`/${prefix}/dashboard/storage/cart/deleted`)
            }
            setIsTopBarButtonsVisible({
              'Add':true,
              'View':false,
              'Edit':false,
              'Deleted':true,
              'Storage':false,
              'ViewStorage':false, 
              'AddStorage':false, 
              'EditStorage': false,
              'DeletedStorage': true,
              'Cart':false,
              'ViewCart':false, 
              'AddCart':false, 
              'EditCart': false,
              'DeletedCart': true,
              'CartItem':false,
              'ViewItem':false, 
              'AddItem':false, 
              'EditItem': false,
              'DeletedItem': true,
            })
            break;
          case 'ViewCart':
            if(id){
              if(!isLoad){
                navigate(`/${prefix}/dashboard/storage/cart/view/${id}`)
              }
              setIsTopBarButtonsVisible({
                'Add':true,
                'View':false,
                'Edit':false,
                'Deleted':true,
                'Storage':false,
                'ViewStorage':false, 
                'AddStorage':false, 
                'EditStorage': false,
                'DeletedStorage': false,
                'Cart':false,
                'ViewCart':true, 
                'AddCart':false, 
                'EditCart': false,
                'DeletedCart': false,
                'CartItem':false,
                'ViewItem':false, 
                'AddItem':false, 
                'EditItem': false,
                'DeletedItem': false,
              })
            }
            break;




        case 'CartItem':
          if(!isLoad){
            navigate(`/${prefix}/dashboard/storage/item`)
          }
          setIsTopBarButtonsVisible({
            'Add':true,
            'View':false,
            'Edit':false,
            'Deleted':true,
            'Storage':true,
            'ViewStorage':false, 
            'AddStorage':false, 
            'EditStorage': false,
            'DeletedStorage': false,
            'Cart':true,
            'ViewCart':false, 
            'AddCart':false, 
            'EditCart': false,
            'DeletedCart': false,
            'CartItem':true,
            'ViewItem':false, 
            'AddItem':false, 
            'EditItem': false,
            'DeletedItem': false,
          })
          break;
        case 'AddItem':
          if(!isLoad){
            navigate(`/${prefix}/dashboard/storage/item/add`)
          }
          setIsTopBarButtonsVisible({
            'Add':true,
            'View':false,
            'Edit':false,
            'Deleted':true,
            'Storage':false,
            'ViewStorage':false, 
            'AddStorage':true, 
            'EditStorage': false,
            'DeletedStorage': false,
            'Cart':false,
            'ViewCart':false, 
            'AddCart':true, 
            'EditCart': false,
            'DeletedCart': false,
            'CartItem':false,
            'ViewItem':false, 
            'AddItem':true, 
            'EditItem': false,
            'DeletedItem': false,
          })
          break;
        case 'EditItem':
          if(id){
            if(!isLoad){
              navigate(`/${prefix}/dashboard/storage/item/edit/${id}`)
            }
            setIsTopBarButtonsVisible({
              'Add':false,
              'View':false,
              'Edit':true,
              'Deleted':true,
              'Storage':false,
              'ViewStorage':false, 
              'AddStorage':false, 
              'EditStorage': false,
              'DeletedStorage': false,
              'Cart':false,
              'ViewCart':false, 
              'AddCart':false, 
              'EditCart': false,
              'DeletedCart': false,
              'CartItem':false,
              'ViewItem':false, 
              'AddItem':false, 
              'EditItem': true,
              'DeletedItem': false,
            })
          }
          break;
        case 'DeletedItem':
          if(!isLoad){
            navigate(`/${prefix}/dashboard/storage/item/deleted`)
          }
          setIsTopBarButtonsVisible({
            'Add':true,
            'View':false,
            'Edit':false,
            'Deleted':true,
            'Storage':false,
            'ViewStorage':false, 
            'AddStorage':false, 
            'EditStorage': false,
            'DeletedStorage': true,
            'Cart':false,
            'ViewCart':false, 
            'AddCart':false, 
            'EditCart': false,
            'DeletedCart': true,
            'CartItem':false,
            'ViewItem':false, 
            'AddItem':false, 
            'EditItem': false,
            'DeletedItem': true,
          })
          break;
        case 'ViewItem':
          if(id){
            if(!isLoad){
              navigate(`/${prefix}/dashboard/storage/item/view/${id}`)
            }
            setIsTopBarButtonsVisible({
              'Add':true,
              'View':false,
              'Edit':false,
              'Deleted':true,
              'Storage':false,
              'ViewStorage':false, 
              'AddStorage':false, 
              'EditStorage': false,
              'DeletedStorage': false,
              'Cart':false,
              'ViewCart':false, 
              'AddCart':false, 
              'EditCart': false,
              'DeletedCart': false,
              'CartItem':false,
              'ViewItem':true, 
              'AddItem':false, 
              'EditItem': false,
              'DeletedItem': false,
            })
          }
          break;
          
        
        case 'Log':
          if(!isLoad){
            navigate(`/${prefix}/dashboard/storage/log`)
          }
          setIsTopBarButtonsVisible({
            'Add':true,
            'View':false,
            'Edit':false,
            'Deleted':true,
            'Storage':false,
            'ViewStorage':false, 
            'AddStorage':false, 
            'EditStorage': false,
            'DeletedStorage': false,
            'Cart':false,
            'ViewCart':false, 
            'AddCart':false, 
            'EditCart': false,
            'DeletedCart': false,
            'CartItem':false,
            'ViewItem':false, 
            'AddItem':false, 
            'EditItem': false,
            'DeletedItem': false,
          })
          break;
      }
      setRoutePage(pageR)
    }
    hideMessage()
  }
  
  useEffect(()  => {
    const sysEnt='storage'
    privilegesRef?.current?.getPrivileges(sysEnt).then(privilegesO=>{
      if(privilegesO && privilegesO?.privileges?.length>0){
        let mapPriv = {};
        privilegesO?.privileges?.forEach(x => {
          const key=x?.replace(`${sysEnt}_`, '')
          mapPriv={...mapPriv, [key]:true}
        });
        setIsPrivileged(mapPriv)
        setIsPrivileges(true)
      }
      if(privilegesO && privilegesO?.sideBarPrivileges?.length>0){
        setPrivilegesSideBar(privilegesO?.sideBarPrivileges)
      }
    })
  }, [])

  useEffect(() => {
    loadPages()
  },[isPrivileged])

  useEffect(() => {
    loadPages()
    handleTabChange(routePage, null, true)
  },[routePage])

  const loadPages = () => {
    if(isPrivileged && Object.keys(isPrivileged).length>0){
      if(isStorage){
        storageRef?.current?.loadPages()
      }
      if(isDeletedStorage){
        deletedRef?.current?.loadPages()
      }

      if(isCart){
        cartRef?.current?.loadPages()
      }
      if(isDeletedCart){
        deletedCartRef?.current?.loadPages()
      }

      if(isItem){
        itemRef?.current?.loadPages()
      }
      if(isDeletedItem){
        deletedItemRef?.current?.loadPages()
      }

      if(isLog){
        logRef?.current?.loadPages()
      }
    }
  }


  return <DashboardLayout component='Storage' privilegesSideBar={privilegesSideBar} locale={locale} setLocale={setLocale}>
    <GeneralFetchingService generalFetchingServiceRef={generalFetchingServiceRef} prefix='storage' />
    <Privileges generalFetchingServiceRef={generalFetchingServiceRef} privilegesRef={privilegesRef}></Privileges>
    {isPrivileges && <div className={styles.Storage} data-testid="Storage">
      <h1>{t('Storage')}</h1>
      <TopBar routePage={routePage} isPrivileged={isPrivileged} handleTabChange={handleTabChange} setRoutePage={setRoutePage} isVisible={isTopBarButtonsVisible} />
       <RenderErrorMessage name='general' messages={generalErrorMessage!=null?{'general':generalErrorMessage}:{}} alertDisplay={true} isShown={isShownGeneralError} setIsShownGeneralError={setIsShownGeneralError} isDissmiss={true} handleHideMessage={hideMessage} />
      <RenderSuccessMessage name='general' messages={generalSuccessMessage!=null?{'general':generalSuccessMessage}:{}} alertDisplay={true} isShown={isShownGeneralSuccess} setIsShownGeneralSuccess={setIsShownGeneralSuccess} isDissmiss={true} handleHideMessage={hideMessage} />
      
      {(isStorage && isPrivileged['view_storages']) && <All page={page} data={dataItems} viewBtnHandle={viewBtnHandle} editBtnHandle={editBtnHandle} deleteHandle={deleteHandle} setDataItems={setDataItems} generalFetchingServiceRef={generalFetchingServiceRef} storageRef={storageRef} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {((isAddStorage && isPrivileged['add']) || (isEditStorage && isPrivileged['edit'])) && <AddEdit id={params.id} handleAddEdit={handleAddEdit} editSource='all' generalFetchingServiceRef={generalFetchingServiceRef} locale={locale} />}
      {(isViewStorage && isPrivileged['view_storages']) && <View viewSource={'all'} id={params.id} generalFetchingServiceRef={generalFetchingServiceRef} handleTabChange={handleTabChange} isPrivileged={isPrivileged} locale={locale} />}
      {(isDeletedStorage && isPrivileged['delete']) && <Deleted page={page} deletedRef={deletedRef} viewBtnHandle={viewBtnHandle} restoreDeletedHandle={restoreDeletedHandle} generalFetchingServiceRef={generalFetchingServiceRef} isPrivileged={isPrivileged} locale={locale} />}
      
      {(isCart && isPrivileged['view_carts']) && <StorageCart page={page} data={dataItems} viewBtnHandle={viewBtnHandle} editBtnHandle={editBtnHandle} deleteHandle={deleteHandle} setDataItems={setDataItems} generalFetchingServiceRef={generalFetchingServiceRef} cartRef={cartRef} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {((isAddCart && isPrivileged['add']) || (isEditCart && isPrivileged['edit'])) && <AddEditCart id={params.id} handleAddEdit={handleAddEdit} editSource='cart' generalFetchingServiceRef={generalFetchingServiceRef} locale={locale} />}
      {(isViewCart && isPrivileged['view_carts']) && <ViewCart viewSource={'cart'} id={params.id} generalFetchingServiceRef={generalFetchingServiceRef} handleTabChange={handleTabChange} isPrivileged={isPrivileged} locale={locale} />}
      {(isDeletedCart && isPrivileged['delete']) && <DeletedCart page={page} deletedRef={deletedCartRef} viewBtnHandle={viewBtnHandle} restoreDeletedHandle={restoreDeletedHandle} generalFetchingServiceRef={generalFetchingServiceRef} isPrivileged={isPrivileged} locale={locale} />}
      
      {(isItem && isPrivileged['view_items']) && <CartItems page={page} data={dataItems} viewBtnHandle={viewBtnHandle} editBtnHandle={editBtnHandle} deleteHandle={deleteHandle} setDataItems={setDataItems} generalFetchingServiceRef={generalFetchingServiceRef} itemRef={itemRef} isPrivileged={isPrivileged} locale={locale} showMessage={showMessage} />}
      {((isAddItem && isPrivileged['add']) || (isEditItem && isPrivileged['edit'])) && <AddEditItem id={params.id} handleAddEdit={handleAddEdit} editSource='item' generalFetchingServiceRef={generalFetchingServiceRef} locale={locale} />}
      {(isViewItem && isPrivileged['view_items']) && <ViewItem viewSource={'item'} id={params.id} generalFetchingServiceRef={generalFetchingServiceRef} handleTabChange={handleTabChange} isPrivileged={isPrivileged} locale={locale} />}
      {(isDeletedItem && isPrivileged['delete']) && <DeletedItem page={page} deletedRef={deletedItemRef} viewBtnHandle={viewBtnHandle} restoreDeletedHandle={restoreDeletedHandle} generalFetchingServiceRef={generalFetchingServiceRef} isPrivileged={isPrivileged} locale={locale} />}
      
     
      {(isLog && isPrivileged['view_log']) && <Logs generalFetchingServiceRef={generalFetchingServiceRef} logRef={logRef} isPrivileged={isPrivileged} locale={locale} />}
    </div>}
    {(!isPrivileges || 
      (isStorage && !isPrivileged['view_storages']) ||
      (isAddStorage && !isPrivileged['add']) ||
      (isEditStorage && !isPrivileged['edit']) ||
      (isViewStorage && !isPrivileged['view_item']) ||
      (isDeletedStorage && !isPrivileged['view_deleted_storages']) ||

      (isCart && !isPrivileged['view_carts']) ||
      (isAddCart && !isPrivileged['add']) ||
      (isEditCart && !isPrivileged['edit']) ||
      (isViewCart && !isPrivileged['view_item']) ||
      (isDeletedCart && !isPrivileged['view_deleted_carts']) ||

      (isItem && !isPrivileged['view_items']) ||
      (isAddItem && !isPrivileged['add']) ||
      (isEditItem && !isPrivileged['edit']) ||
      (isViewItem && !isPrivileged['view_item']) ||
      (isDeletedItem && !isPrivileged['view_deleted_items']) ||

      (isLog && !isPrivileged['view_log'])
    )&& <NoPrivilegesMessage />}
  </DashboardLayout>
};

StorageComponents.propTypes = {};

StorageComponents.defaultProps = {};

export default StorageComponents;
