import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './RenderErrorMessage.module.css';

const RenderErrorMessage = ({name, messages, alertDisplay=false, isShown=true, handleHideMessage, isDissmiss=false}) => {
  
  return messages[name] && isShown && <div className={styles.error + (alertDisplay?' alert alert-danger alert-dismissible':'')}>
      {messages[name]}
      {isDissmiss && <button type="button" className="close" aria-label="Close" onClick={handleHideMessage}>
        <span aria-hidden="true">&times;</span>
      </button>}
    </div>
};

RenderErrorMessage.propTypes = {};

RenderErrorMessage.defaultProps = {};

export default RenderErrorMessage;
