import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../../Services/GeneralHelpers';
import ToolsItemsBar from '../../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({id, dataLength, page, toolsBarRef, allRef, attendanceRef, deletedRef, paginationRef, printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale}) => {
  const { t, i18n } = useTranslation();

  const isGeneral = page=='General';
  const isDeleted = page=='Deleted';
  const isAttendance = page=='Attendance';

  const [from_date, setFromDateFilter] = useState(null);
  const [to_date, setToDateFilter] = useState(null);

  const [employeeFilter, setEmployeeFilter] = useState(id?id:null);
  const [employeesData, setEmployeesData] = useState([])
  const [employeesOptions, setEmployeesOptions] = useState([])

  const [isAttendedFilter, setIsAttendedFilter] = useState(null);
  const [isAttendedData, setIsAttendedData] = useState([])
  const [isAttendedOptions, setIsAttendedOptions] = useState([])

  const [isLeaveFilter, setIsLeaveFilter] = useState(null);
  const [isLeaveData, setIsLeaveData] = useState([])
  const [isLeaveOptions, setIsLeaveOptions] = useState([])

  const [leaveStatusFilter, setLeaveStatusFilter] = useState(null);
  const [leaveStatusData, setLeaveStatusData] = useState([])
  const [leaveStatusOptions, setLeaveStatusOptions] = useState([])

  const [isFilters, setIsFilters] = useState(false)
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      allRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        filtersH.employee_id=id;
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
      }else{
        if(id){
          const filters={employee_id:id}
          paginationRef?.current?.loadPagesWithFilters(getFilters(filters))
        }else{
          paginationRef?.current?.loadPages()
        }
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('employees_attendance', selecetedItems)
    },
    showUpdateAddAttendanceModal(isAdd){
      if(isAttendance){
        attendanceRef?.current?.showUpdateAddModal(isAdd)
      }
    }
  }))

  const handleChangeFromDate = (value) => {
    setFromDateFilter(value)
  }
  const handleChangeToDate = (value) => {
    setToDateFilter(value)
  }
  
  const handleChangeIsAttended = (value) => {
    setIsAttendedFilter(value)
  }
  const handleChangeIsLeave = (value) => {
    setIsLeaveFilter(value)
  }

  const handleChangeLeaveStatus = (value) => {
    setLeaveStatusFilter(value)
  }
  
  const handleChangeEmployee = (value) => {
    setEmployeeFilter(value)
  }

  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          const employees=data?.employees
          const employeesOptions=employees?.map(x => {return {'value':x?.id, 'name': x?.display_name}})
          setEmployeesData(employees)
          setEmployeesOptions(employeesOptions)

          const leave_approve_status=data?.leave_approve_status
          const leaveApproveStatusOptions=leave_approve_status?.map(x => {return {'value':x?.id, 'name': x?.display_name}})
          setLeaveStatusData(leave_approve_status)
          setLeaveStatusOptions(leaveApproveStatusOptions)

          const trueFalse=['True', 'False']

          const isAttendedOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsAttendedData(trueFalse)
          setIsAttendedOptions(isAttendedOptions)

          const isLeaveOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsLeaveData(trueFalse)
          setIsLeaveOptions(isLeaveOptions)

        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    const fromDate=(filtersS && filtersS?.from_date)?filtersS?.from_date:from_date?from_date:null
    const toDate=(filtersS && filtersS?.to_date)?filtersS?.to_date:to_date?to_date:null
    let result={
      employee_id: (filtersS && filtersS?.employee_id)?filtersS?.employee_id:employeeFilter,
      leave_approve_status_id: (filtersS && filtersS?.leave_approve_status_id)?filtersS?.leave_approve_status_id:leaveStatusFilter,

      from_date: fromDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(fromDate)).locale('es').format('yyyy-MM-DD'):null,
      to_date: toDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(toDate)).locale('es').format('yyyy-MM-DD'):null,
    };
    if(isAttendedFilter){
      result={...result, is_attended: (filtersS && filtersS?.is_attended)?filtersS?.is_attended:(isAttendedFilter=='True')}
    }
    if(isLeaveFilter){
      result={...result, is_leave: (filtersS && filtersS?.is_leave)?filtersS?.is_leave:(isLeaveFilter=='True')}
    }
    return result
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.employee_id===null && filters.leave_approve_status_id===null &&
      (filters.is_attended==undefined || filters.is_attended==null) &&
      (filters.is_leave==undefined || filters.is_leave==null) &&
      filters.from_date===null && filters.to_date===null
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setFromDateFilter(null)
    setToDateFilter(null)
    setIsAttendedFilter(null)
    setIsLeaveFilter(null)
    setEmployeeFilter(null)
    setLeaveStatusFilter(null)

    setIsFilters(false)
    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
  }

  const loadFiltersApply = (filtersO) => {
    const employee_id=filtersO?.employee_id
    if(employee_id){
      setEmployeeFilter(employee_id)
    }

    const from_date=filtersO?.from_date
    if(from_date){
      setFromDateFilter(new Date(from_date))
    }

    const to_date=filtersO?.to_date
    if(to_date){
      setToDateFilter(new Date(to_date))
    }

    const is_attended=filtersO?.is_attended
    if(is_attended){
      setIsAttendedFilter(is_attended?'True':'False')
    }

    const is_leave=filtersO?.is_leave
    if(is_leave){
      setIsLeaveFilter(is_leave?'True':'False')
    }

    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }

  const HideDropdownBoxes=()=>{
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }

  useEffect(()=>{
    getFiltersData()
    setTimeout(()=>{
      HideDropdownBoxes()
    },200)
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectEmployee}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectEmployee}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectIsAttended}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectIsAttended}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectIsLeave}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectIsLeave}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectLeaveStatus}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectLeaveStatus}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={from_date} onChange={handleChangeFromDate} placeholderText={t('Start Date')} />
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={to_date} onChange={handleChangeToDate} placeholderText={t('End Date')} />
          </div>
          {isPrivileged['manage'] && !id && <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectEmployee}
                      onFocus={handleOnFocusSelect}
                      options={employeesOptions}
                      onChange={handleChangeEmployee}
                      value={employeeFilter}
                      placeholder={t('Employee')}
                      search={true}
                      filterOptions={fuzzySearch}
                      />
          </div>}
        </div>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectIsAttended}
                    onFocus={handleOnFocusSelect}
                    options={isAttendedOptions}
                    onChange={handleChangeIsAttended}
                    value={isAttendedFilter}
                    placeholder={t('Is Attended?')}
                    search={true}
                    filterOptions={fuzzySearch}
                    autoFocus={false}
                    />

            <SelectSearch id={styles.SelectIsLeave}
                    onFocus={handleOnFocusSelect}
                    options={isLeaveOptions}
                    onChange={handleChangeIsLeave}
                    value={isLeaveFilter}
                    placeholder={t('Requested Leave?')}
                    search={true}
                    filterOptions={fuzzySearch}
                    autoFocus={false}
                    />
          </div>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectLeaveStatus}
                      onFocus={handleOnFocusSelect}
                      options={leaveStatusOptions}
                      onChange={handleChangeLeaveStatus}
                      value={leaveStatusFilter}
                      placeholder={t('Leave Status')}
                      search={true}
                      filterOptions={fuzzySearch}
                      autoFocus={false}
                      />
          </div>
        </div>

        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar dataLength={dataLength} allRef={allRef} deletedRef={deletedRef} attendanceRef={attendanceRef} page={page} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} />
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
