import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import GeneralHelpers from '../../../Services/GeneralHelpers';
import ToolsItemsBar from '../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({dataLength, page, toolsBarRef, allRef, deletedRef, paginationRef, printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale}) => {
  const { t, i18n } = useTranslation();

  const isGeneral = page=='General';
  const isDeleted = page=='Deleted';

  const [isFilters, setIsFilters] = useState(false);
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  const [titleFilter, setTitleFilter] = useState("");
  const [maxOrdersNumberFilter, setMaxOrdersNumberFilter] = useState("");
  const [minOrdersNumberFilter, setMinOrdersNumberFilter] = useState("");
  const [transferPriceFilter, setTransferPriceFilter] = useState("");
  
  const [governoratesFilter, setGovernoratesFilter] = useState(null);
  const [governoratesData, setGovernoratesData] = useState([])
  const [governoratesOptions, setGovernoratesOptions] = useState([])

  const [districtsFilter, setDistrictsFilter] = useState(null);
  const [districtsData, setDistrictsData] = useState([])
  const [districtsOptions, setDistrictsOptions] = useState([])

  const [neighborhoodsFilter, setNeighborhoodsFilter] = useState(null)
  const [neighborhoodsData, setNeighborhoodsData] = useState([])
  const [neighborhoodsOptions, setNeighborhoodsOptions] = useState([])


  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      allRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
      }else{
        paginationRef?.current?.loadPages()
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('CompanyOffers', selecetedItems)
    },
    showUpdateModal(){
      if(isGeneral){
        allRef?.current?.showUpdateModal()
      }
    }
  }))


  const handleChangeTitle = (e) => {
    setTitleFilter(e.target.value)
  }
  const handleChangeMaxOrdersNumber = (e) => {
    setMaxOrdersNumberFilter(e.target.value)
  }
  const handleChangeMinOrdersNumber = (e) => {
    setMinOrdersNumberFilter(e.target.value)
  }
  const handleChangeTransferPrice = (e) => {
    setTransferPriceFilter(e.target.value)
  }
  const handleChangeGovernorate=(values)=>{
    const districtsOptions=getDistrictsOptions(values)
    setDistrictsOptions(districtsOptions)
    setGovernoratesFilter(values)
  }
  const handleChangeDistrict=(values)=>{
    const neighborhoodsOptions=getNeighborhoodsOptions(values)
    setNeighborhoodsOptions(neighborhoodsOptions)
    setDistrictsFilter(values)
  }
  const handleChangeNeighborhood=(value)=>{
    setNeighborhoodsFilter(value)
  }

  const handleBlurMultiple = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }
  const handleFocusMultiple = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','')
    setTimeout(()=>{
      $(`#searchToolsBar_${identifier}`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  const getDistrictsOptions = (values) =>{
    if(values.length==0){
      return []
    }
    return districtsData?.filter(x => values.indexOf(x.governorate_id)!=-1).map(x => {return {'value':x.id, 'name': x.name}})
  }
  const getNeighborhoodsOptions = (values) =>{
    if(values.length==0){
      return []
    }
    return neighborhoodsData?.filter(x => values.indexOf(x.district_id)!=-1).map(x => {return {'value':x.id, 'name': x.name}})
  }
  

  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          const governorates=data?.governorates
          const governoratesOptions=governorates?.map(x => {return {'value':x.id, 'name': x.name}})
          setGovernoratesData(governorates)
          setGovernoratesOptions(governoratesOptions)

          const districts=data?.districts
          setDistrictsData(districts)

          let neighborhoods=data?.neighborhoods
          setNeighborhoodsData(neighborhoods)

        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    let result={
      title: (filtersS && filtersS?.title)?filtersS?.title:titleFilter,
      max_orders_number: (filtersS && filtersS?.max_orders_number)?filtersS?.max_orders_number:maxOrdersNumberFilter,
      min_orders_number: (filtersS && filtersS?.min_orders_number)?filtersS?.min_orders_number:minOrdersNumberFilter,
      transfer_price: (filtersS && filtersS?.transfer_price)?filtersS?.transfer_price:transferPriceFilter,
      governorates: (filtersS && filtersS?.governorates && filtersS?.governorates?.length>0)?filtersS?.governorates:governoratesFilter,
      districts: (filtersS && filtersS?.districts && filtersS?.districts?.length>0)?filtersS?.districts:districtsFilter,
      neighborhoods: (filtersS && filtersS?.neighborhoods && filtersS?.neighborhoods?.length>0)?filtersS?.neighborhoods:neighborhoodsFilter,
    };
    return result
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.title.length===0 && 
      filters.max_orders_number.length===0 &&  
      filters.min_orders_number.length===0 &&
      filters.transfer_price.length===0 &&
      (filters.governorates===null || filters?.governorates.length==0) &&
      (filters.districts===null || filters?.districts.length==0) &&
      (filters.neighborhoods===null || filters?.neighborhoods.length==0)
    );
  }

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setTitleFilter('')
    setMaxOrdersNumberFilter('')
    setMinOrdersNumberFilter('')
    setTransferPriceFilter('')
    setGovernoratesFilter([])
    setDistrictsFilter([])
    setNeighborhoodsFilter([])
    setIsFilters(false)

    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
  }

  const loadFiltersApply = (filtersO) => {
    const title=filtersO?.title
    if(title){
      setTitleFilter(title)
    }

    const max_orders_number=filtersO?.max_orders_number
    if(max_orders_number){
      setMaxOrdersNumberFilter(max_orders_number)
    }

    const min_orders_number=filtersO?.min_orders_number
    if(min_orders_number){
      setMinOrdersNumberFilter(min_orders_number)
    }

    const transfer_price=filtersO?.transfer_price
    if(transfer_price){
      setTransferPriceFilter(transfer_price)
    }

    const governoratesIds=filtersO?.governorates
    if(governoratesIds && governoratesIds.length>0){
      handleChangeGovernorate(governoratesIds)
    }

    const districtsIds=filtersO?.districts
    if(districtsIds && districtsIds.length>0){
      setDistrictsFilter(districtsIds)
    }

    const neighborhoodsIds=filtersO?.districts
    if(neighborhoodsIds && neighborhoodsIds.length>0){
      setNeighborhoodsFilter(neighborhoodsIds)
    }

    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }

  const HideDropdownBoxes=()=>{
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }

  useEffect(()=>{
    getFiltersData()
    setTimeout(()=>{
      HideDropdownBoxes()
    },200)
  },[])

  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <input className={styles.SelectInput}
                onChange={handleChangeTitle}
                value={titleFilter}
                placeholder={t('Title')}
                />

            <input className={styles.SelectInput}
                type='number'
                onChange={handleChangeTransferPrice}
                value={transferPriceFilter}
                placeholder={t('Transfer Price')}
                />
          </div>
          <div className={styles.subSelectsGroup}>
            <input className={styles.SelectInput}
                type='number'
                onChange={handleChangeMaxOrdersNumber}
                value={maxOrdersNumberFilter}
                placeholder={t('Max Orders Number')}
                />

            <input className={styles.SelectInput}
                type='number'
                onChange={handleChangeMinOrdersNumber}
                value={minOrdersNumberFilter}
                placeholder={t('Min Orders Number')}
                />
          </div>
        </div>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectMultiple}
                  options={governoratesOptions}
                  onBlur={handleBlurMultiple}
                  onFocus={handleFocusMultiple}
                  onChange={handleChangeGovernorate}
                  value={governoratesFilter}
                  placeholder={t('Governorates')}
                  search={true}
                  filterOptions={fuzzySearch}
                  multiple={true}
                  autoFocus={false}
                  />

            <SelectSearch id={styles.SelectMultiple}
                  options={districtsOptions}
                  onBlur={handleBlurMultiple}
                  onFocus={handleFocusMultiple}
                  onChange={handleChangeDistrict}
                  value={districtsFilter}
                  placeholder={t('Districts')}
                  search={true}
                  filterOptions={fuzzySearch}
                  multiple={true}
                  autoFocus={false}
                  />

            <SelectSearch id={styles.SelectMultiple}
                  options={neighborhoodsOptions}
                  onBlur={handleBlurMultiple}
                  onFocus={handleFocusMultiple}
                  onChange={handleChangeNeighborhood}
                  value={neighborhoodsFilter}
                  placeholder={t('Neighborhoods')}
                  search={true}
                  filterOptions={fuzzySearch}
                  multiple={true}
                  autoFocus={false}
                  />
          </div>
        </div>
        
        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar dataLength={dataLength} allRef={allRef} deletedRef={deletedRef} isDeleted={isDeleted} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} />
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
