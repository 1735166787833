import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './View.module.css';
import { useAuth } from '../../Common/Auth/AuthHelpers/Auth';
import { useTranslation } from 'react-i18next';

import {AiFillPrinter} from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import Notes from '../../Common/Notes/Notes';


import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'
import { Link } from 'react-router-dom';

const View = ({id, generalFetchingServiceRef, viewSource, handleTabChange, isPrivileged, locale}) => {
  const { t, i18n } = useTranslation();
  
  const [data, setData] = useState(null)

  const tableRef = useRef();

  const auth = useAuth();
  const prefix=auth?.user?.user_info?.role?.name


  const GetData = () => {
    generalFetchingServiceRef?.current?.getById(id, viewSource=='all'?'':viewSource)
        .then(response => {
          let responseData=response?.data
          if(responseData && responseData?.status==1){
            let data=responseData?.data
            if(data?.length>0 && viewSource=='all'){
              setData(data[0])
            }
            if(viewSource=='cart'){
              setData(data)
            }
          }
        }).catch(error => {
          console.log(error)
        })
  }

  const GetShippingTypeName = (value) => {
    let shippingTypes=[
      {value: 'internal_center', name: 'Internal Governorate _ Center'},
      {value: 'internal_margin', name: 'Internal Governorate _ Margin'},
      {value: 'outer', name: 'Outer Governorate'},
    ]
    return shippingTypes?.filter(x => x?.value==value)[0]?.name;
  }

  useEffect(()=>{
    GetData()
  },[])

  return <div className={styles.View} data-testid="View">
    <table ref={tableRef} className={styles.table+' table table-bordered table-hover'}>
      <thead className='table-active'>
        <tr>
          <th scope='col' className={styles.header_label}>{t('Property')}</th>
          <th scope='col' className={styles.header_label}>{t('Value')}</th>
        </tr>
      </thead>
      <tbody>
        <tr className="form-group">
          <td><label className={styles.label}>{t('Id')}</label></td>
          <td>{data?.id}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Code')}</label></td>
          <td>{data?.code}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Name')}</label></td>
          <td>{data?.name}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Price')}</label></td>
          <td>{data?.price}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Size')}</label></td>
          <td>{data?.size}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Status')}</label></td>
          <td>{data?.mail_status?.display_name}</td>
        </tr>

        {(viewSource=='all' && data?.partially_delivered_value) && <tr className="form-group">
          <td><label className={styles.label}>{t('Partially Delivered Value')}</label></td>
          <td>{data?.partially_delivered_value}</td>
        </tr>}

        <tr className="form-group">
          <td><label className={styles.label}>{t('Company')}</label></td>
          <td>{data?.company?.name}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Vendor')}</label></td>
          <td>{data?.vendor?.user?.first_name+' '+data?.vendor?.user?.last_name}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Agent')}</label></td>
          <td>{data?.agent_id? data?.agent?.user?.first_name+' '+data?.agent?.user?.last_name:''}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Commissary')}</label></td>
          <td>{data?.commissary_id? data?.commissary?.user?.first_name+' '+data?.commissary?.user?.last_name:''}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Customer')}</label></td>
          <td>{data?.customer_id? data?.customer?.name:''}</td>
        </tr>



        <tr className="form-group">
          <td><label className={styles.label}>{t('Shipping Type')}</label></td>
          <td>{t(GetShippingTypeName(data?.shipping_type))}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Shipping Price Source')}</label></td>
          <td>{t(data?.shipping_price_source)}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Shipping Price')}</label></td>
          <td>{data?.shipping_price}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Shipping Price Factor')}</label></td>
          <td>{data?.shipping_factor}</td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Shipping Total')}</label></td>
          <td>{data?.shipping_total}</td>
        </tr> 
        
        <tr className="form-group">
          <td><label className={styles.label}>{`${t('Shipping Commission Value')} (${t('for agent or commissary')})`}</label></td>
          <td>{data?.shipping_commission_value}</td>
        </tr>

        {(viewSource=='all' && data?.is_money_received!==null) && <tr className="form-group">
          <td><label className={styles.label}>{t('Is Money Received?')}</label></td>
          <td>{data?.is_money_received?t('true'):t('false')}</td>
        </tr>}

        <tr className="form-group">
          <td><label className={styles.label}>{t('From')}</label></td>
          <td>
            <div>{data?.from_governorate?.name}</div>
            <div>{data?.from_district?.name}</div>
            <div>{data?.from_address}</div>
          </td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('To')}</label></td>
          <td>
            <div>{data?.to_governorate?.name}</div>
            <div>{data?.to_district?.name}</div>
            <div>{data?.to_address}</div>
          </td>
        </tr>

        <tr className="form-group">
          <td><label className={styles.label}>{t('Shipping Date')}</label></td>
          <td>{moment(data?.shipping_date).local().locale(locale).format('DD/MM/yyyy')}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />

    <div className='alert bg-dark text-light'>{t('Mail Log')}</div>
    {(data?.log && Object.keys(data?.log).length>0) && <table className='table' border={1}>
      <thead className='table-active'>
        <th>{t('User')}</th>
        <th>{t('Action')}</th>
        <th>{t('Details')}</th>
        <th>{t('Date')}</th>
      </thead>
      <tbody>
        {Object.keys(data?.log).map((logId)=>{
          const log=data?.log[logId]
          return <tr>
            <td><Link to={`/${prefix}/dashboard/user/view/${log?.user?.id}`}>{log?.user?.first_name+' '+log?.user?.last_name}</Link></td>
            <td>{log?.action?.display_name}</td>
            <td>{log?.details}</td>
            <td>{moment(log?.created_at).local().locale(locale).format('DD/MM/yyyy h:mm:ss a')}</td>
          </tr>
        })}
      </tbody>
    </table>}

    <br />
    <div className={styles.ToolsButtons}>
      <button className='btn btn-primary' onClick={()=>{
        handleTabChange(viewSource=='cart'?'Cart':'General')
        window.scrollTo(0, 0)
      }}>{t(`Go to ${viewSource}`)}</button>

      {isPrivileged['print_view'] && 
        <ReactToPrint
          trigger={() => <button className={styles.ToolsBarButton+' btn btn-info'} title={t('Print')}><AiFillPrinter /></button>}
          content={() => tableRef?.current}
          documentTitle={'mail_'+data?.name}
        />
        
      }
    </div>
    <br />
    <br />
      {isPrivileged['view_notes'] && (viewSource=='all') && <Notes id={id} serviceGet={generalFetchingServiceRef?.current?.GetNotes} serviceAdd={generalFetchingServiceRef?.current?.AddNote} serviceDelete={generalFetchingServiceRef?.current?.DeleteNote} isPrivileged={isPrivileged} locale={locale} />}
    <br />
    <br />
  </div>
};

View.propTypes = {};

View.defaultProps = {};

export default View;
