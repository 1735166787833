import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './PopUpModal.module.css';
import { useTranslation } from 'react-i18next';
import ModalComponent from '../../Common/ModalComponent/ModalComponent';
import RenderErrorMessage from '../../Common/RenderErrorMessage/RenderErrorMessage';
import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";


import "react-datepicker/dist/react-datepicker.css";
import 'react-select-search/style.css'
import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

const PopUpModal = ({locale, show, title, generalFetchingServiceRef, handleWhenHidden, src}) => {
  const { t, i18n } = useTranslation();

  const [fieldsErrors, setFieldsErrors] = useState([]);

  useEffect(()=>{
    
  },[show])

  return <ModalComponent locale={locale} show={show} title={title} cancelBtnText={t('Hide')} handleWhenHidden={handleWhenHidden} className={styles.PopUpModal} data-testid="PopUpModal">
    <iframe src={src} className={styles.Frame}></iframe>
  </ModalComponent>
};

PopUpModal.propTypes = {};

PopUpModal.defaultProps = {};

export default PopUpModal;
