import React, {useState, useImperativeHandle, useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './ToolsBar.module.css';

import 'react-select-search/style.css'

import SelectSearch,{fuzzySearch} from 'react-select-search';
import DatePicker from "react-datepicker";

import $ from 'jquery';
import {GoThreeBars} from 'react-icons/go';

import * as moment from 'moment';
import 'moment/locale/es'
import 'moment/locale/ar'

import GeneralHelpers from '../../../Services/GeneralHelpers';
import ToolsItemsBar from '../ToolsItemsBar/ToolsItemsBar';
import { useTranslation } from 'react-i18next';


const ToolsBar = ({dataLength, page, toolsBarRef, allRef, cartRef, deletedRef, paginationRef, printComponentRef, printDocumentTitle, handleOnBeforePrint, handleOnAfterPrint, isPrivileged, ViewItemsButtons, identifier, filtersDataService, selecetedItems, locale}) => {
  const { t, i18n } = useTranslation();

  const isGeneral = page=='General';
  const isDeleted = page=='Deleted';
  const isCart = page=='Cart';

  const [from_date, setFromDateFilter] = useState(null);
  const [to_date, setToDateFilter] = useState(null);

  const [mailStatusFilter, setMailStatusFilter] = useState(null);
  const [mailStatusData, setMailStatusData] = useState([])
  const [mailStatusOptions, setMailStatusOptions] = useState([])

  const [companyFilter, setCompanyFilter] = useState(null);
  const [companiesData, setCompaniesData] = useState([])
  const [companiesOptions, setCompaniesOptions] = useState([])

  const [vendorFilter, setVendorFilter] = useState(null);
  const [vendorsData, setVendorsData] = useState([])
  const [vendorsOptions, setVendorsOptions] = useState([])

  const [agentFilter, setAgentFilter] = useState(null);
  const [agentsData, setAgentsData] = useState([])
  const [agentsOptions, setAgentsOptions] = useState([])

  const [commissaryFilter, setCommissaryFilter] = useState(null);
  const [commissariesData, setCommissariesData] = useState([])
  const [commissariesOptions, setCommissariesOptions] = useState([])

  const [customerFilter, setCustomerFilter] = useState(null);
  const [customersData, setCustomersData] = useState([])
  const [customersOptions, setCustomersOptions] = useState([])

  const [fromGovernoratesFilter, setFromGovernoratesFilter] = useState([]);
  const [fromGovernoratesData, setFromGovernoratesData] = useState([])
  const [fromGovernoratesOptions, setFromGovernoratesOptions] = useState([])

  const [toGovernoratesFilter, setToGovernoratesFilter] = useState([]);
  const [toGovernoratesData, setToGovernoratesData] = useState([])
  const [toGovernoratesOptions, setToGovernoratesOptions] = useState([])

  const [fromDistrictsFilter, setFromDistrictsFilter] = useState([]);
  const [fromDistrictsData, setFromDistrictsData] = useState([])
  const [fromDistrictsOptions, setFromDistrictsOptions] = useState([])
  
  const [toDistrictsFilter, setToDistrictsFilter] = useState([]);
  const [toDistrictsData, setToDistrictsData] = useState([])
  const [toDistrictsOptions, setToDistrictsOptions] = useState([])

  const [fromNeighborhoodsFilter, setFromNeighborhoodsFilter] = useState([]);
  const [fromNeighborhoodsData, setFromNeighborhoodsData] = useState([])
  const [fromNeighborhoodsOptions, setFromNeighborhoodsOptions] = useState([])
  
  const [toNeighborhoodsFilter, setToNeighborhoodsFilter] = useState([]);
  const [toNeighborhoodsData, setToNeighborhoodsData] = useState([])
  const [toNeighborhoodsOptions, setToNeighborhoodsOptions] = useState([])

  const [is_money_received, setIsMoneyReceived] = useState(null);
  const [isMoneyReceivedData, setIsMoneyReceivedData] = useState([])
  const [isMoneyReceivedOptions, setIsMoneyReceivedOptions] = useState([])

  const [code, setCode] = useState(null);
  const [codeData, setCodeData] = useState([])
  const [codeOptions, setCodeOptions] = useState([])

  const [isFilters, setIsFilters] = useState(false)
  const [isPages, setIsPagesA] = useState(false)
  const [isSelectItems, setIsSelectItemsA] = useState(false)

  
  useImperativeHandle(toolsBarRef,()=>({
    setDataTotalLength(value){
      allRef?.current?.setDataTotalLength(value)
    },
    setIsSelectItems(value){
      setIsSelectItemsA(value)
    },
    setIsPages(value){
      setIsPagesA(value)
    },
    loadPages(){
      const filtersH=JSON.parse(decodeURI(GeneralHelpers.getHashVariable('filters')))
      if(filtersH){
        loadFiltersApply(filtersH);
        paginationRef?.current?.loadPagesWithFilters(getFilters(filtersH))
      }else{
        paginationRef?.current?.loadPages()
      }
    },
    downloadExcel(){
      paginationRef?.current?.downloadExcel('Mails', selecetedItems)
    },
    showUpdateModal(){
      if(isGeneral){
        allRef?.current?.showUpdateModal()
      }
      if(isCart){
        cartRef?.current?.showUpdateModal()
      }
    }
  }))

  const handleChangeFromDate = (value) => {
    setFromDateFilter(new Date(value))
  }
  const handleChangeToDate = (value) => {
    setToDateFilter(new Date(value))
  }
  const handleChangeFromGovernorate = (values) => {
    const fromDistrictsOptions=getFromDistrictsOptions(values)
    setFromDistrictsOptions(fromDistrictsOptions)
    setFromGovernoratesFilter(values)
  }
  const handleChangeToGovernorate = (values) => {
    const toDistrictsOptions=getToDistrictsOptions(values)
    setToDistrictsOptions(toDistrictsOptions)
    setToGovernoratesFilter(values)
  }
  const handleChangeFromDistrict = (values) => {
    const fromNeighborhoodsOptions=getFromNeighborhoodsOptions(values)
    setFromNeighborhoodsOptions(fromNeighborhoodsOptions)
    setFromDistrictsFilter(values)
  }
  const handleChangeToDistrict = (values) => {
    const toNeighborhoodsOptions=getToNeighborhoodsOptions(values)
    setToNeighborhoodsOptions(toNeighborhoodsOptions)
    setToDistrictsFilter(values)
  }
  const handleChangeFromNeighborhood = (values) => {
    setFromNeighborhoodsFilter(values)
  }
  const handleChangeToNeighborhood = (values) => {
    setToNeighborhoodsFilter(values)
  }
  const handleChangeMailStatus = (value) => {
    setMailStatusFilter(value)
  }
  const handleChangeCustomer = (value) => {
    setCustomerFilter(value)
  }
  const handleChangeCompany = (value) => {
    setCompanyFilter(value)
  }
  const handleChangeVendor = (value) => {
    setVendorFilter(value)
  }
  const handleChangeAgent = (value) => {
    setAgentFilter(value)
  }
  const handleChangeCommissary = (value) => {
    setCommissaryFilter(value)
  }
  const handleChangeIsMoneyReceived = (value) => {
    setIsMoneyReceived(value)
  }
  const handleChangeCode = (value) => {
    setCode(value)
  }


  const handleBlurMultiple = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }
  const handleFocusMultiple = () => {
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','')
    $(`#searchToolsBar_${identifier}`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`#searchToolsBar_${identifier}`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }


  const getFromDistrictsOptions = (values) =>{
    if(values.length==0){
      return []
    }
    return fromDistrictsData?.filter(x => values.indexOf(x.governorate_id)!=-1).map(x => {return {'value':x.id, 'name': x.name}})
  }
  const getToDistrictsOptions = (values) =>{
    if(values.length==0){
      return []
    }
    return toDistrictsData?.filter(x => values.indexOf(x.governorate_id)!=-1).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const getFromNeighborhoodsOptions = (values) =>{
    if(values.length==0){
      return []
    }
    return fromNeighborhoodsData?.filter(x => values.indexOf(x.district_id)!=-1).map(x => {return {'value':x.id, 'name': x.name}})
  }
  const getToNeighborhoodsOptions = (values) =>{
    if(values.length==0){
      return []
    }
    return toNeighborhoodsData?.filter(x => values.indexOf(x.district_id)!=-1).map(x => {return {'value':x.id, 'name': x.name}})
  }

  const getFiltersData=()=>{
    filtersDataService()
      .then(response => {
        let responseData=response?.data
        if(responseData && responseData?.status==1){
          let data=responseData?.data

          const mailStatus=data?.mailStatus
          const mailStatusOptions=mailStatus?.map(x => {return {'value':x?.id, 'name': x?.display_name}})
          setMailStatusData(mailStatus)
          setMailStatusOptions(mailStatusOptions)

          const companies=data?.companies
          const companiesOptions=companies?.map(x => {return {'value':x?.id, 'name': x?.display_name}})
          setCompaniesData(companies)
          setCompaniesOptions(companiesOptions)

          const customers=data?.customers
          const customersOptions=customers?.map(x => {return {'value':x?.id, 'name': x?.first_name+' '+x?.last_name}})
          setCustomersData(customers)
          setCustomersOptions(customersOptions)

          const agents=data?.agents
          const agentsOptions=agents?.map(x => {return {'value':x.id, 'name': x?.display_name}})
          setAgentsData(agents)
          setAgentsOptions(agentsOptions)

          const vendors=data?.vendors
          const vendorsOptions=vendors?.map(x => {return {'value':x.id, 'name': x?.display_name}})
          setVendorsData(vendors)
          setVendorsOptions(vendorsOptions)

          const commissaries=data?.commissaries
          const commissariesOptions=commissaries?.map(x => {return {'value':x.id, 'name': x?.display_name}})
          setCommissariesData(commissaries)
          setCommissariesOptions(commissariesOptions)

          const fromGovernorates=data?.from_governorates
          const fromGovernoratesOptions=fromGovernorates?.map(x => {return {'value':x.id, 'name': x.name}})
          setFromGovernoratesData(fromGovernorates)
          setFromGovernoratesOptions(fromGovernoratesOptions)

          const fromDistricts=data?.from_districts
          setFromDistrictsData(fromDistricts)

          const fromNeighborhoods=data?.from_neighborhoods
          setFromNeighborhoodsData(fromNeighborhoods)

          const toGovernorates=data?.to_governorates
          const toGovernoratesOptions=toGovernorates?.map(x => {return {'value':x.id, 'name': x.name}})
          setToGovernoratesData(toGovernorates)
          setToGovernoratesOptions(toGovernoratesOptions)

          const toDistricts=data?.to_districts
          setToDistrictsData(toDistricts)

          const toNeighborhoods=data?.to_neighborhoods
          setToNeighborhoodsData(toNeighborhoods)

          const trueFalse=['True', 'False']
          const isMoneyReceivedOptions=trueFalse?.map(x => {return {'value':x, 'name': t(x)}})
          setIsMoneyReceivedData(trueFalse)
          setIsMoneyReceivedOptions(isMoneyReceivedOptions)


          const codes=data?.codes
          const codesOptions=codes?.map(x => {return {'value':x, 'name': x}})
          setCodeData(codes)
          setCodeOptions(codesOptions)
        }
      }).catch(error => {
        console.log(error)
      })
  }


  const getFilters = (filtersS) => {
    const startDate=(filtersS && filtersS?.start_date)?filtersS?.start_date:from_date?from_date:null
    const endDate=(filtersS && filtersS?.end_date)?filtersS?.end_date:to_date?to_date:null
    let filters={
      code: (filtersS && filtersS?.code)?filtersS?.code:code,
      mail_status_id: (filtersS && filtersS?.mail_status_id)?filtersS?.mail_status_id:mailStatusFilter,
      customer_id: (filtersS && filtersS?.customer_id)?filtersS?.customer_id:customerFilter,
      company_id: (filtersS && filtersS?.company_id)?filtersS?.company_id:companyFilter,
      vendor_id: (filtersS && filtersS?.vendor_id)?filtersS?.vendor_id:vendorFilter,
      agent_id: (filtersS && filtersS?.agent_id)?filtersS?.agent_id:agentFilter,
      commissary_id: (filtersS && filtersS?.commissary_id)?filtersS?.commissary_id:commissaryFilter,
      from_governorates: (filtersS && filtersS?.from_governorates && filtersS?.from_governorates?.length>0)?filtersS?.from_governorates:fromGovernoratesFilter,
      from_districts: (filtersS && filtersS?.from_districts && filtersS?.from_districts?.length>0)?filtersS?.from_districts:fromDistrictsFilter,
      from_neighborhoods: (filtersS && filtersS?.from_neighborhoods && filtersS?.from_neighborhoods?.length>0)?filtersS?.from_neighborhoods:fromNeighborhoodsFilter,
      to_governorates: (filtersS && filtersS?.to_governorates && filtersS?.to_governorates?.length>0)?filtersS?.to_governorates:toGovernoratesFilter,
      to_districts: (filtersS && filtersS?.to_districts && filtersS?.to_districts?.length>0)?filtersS?.to_districts:toDistrictsFilter,
      to_neighborhoods: (filtersS && filtersS?.to_neighborhoods && filtersS?.to_neighborhoods?.length>0)?filtersS?.to_neighborhoods:toNeighborhoodsFilter,
      start_date: startDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(startDate)).locale('es').format('yyyy-MM-DD'):null,
      end_date: endDate?moment.utc(GeneralHelpers.FixDateAddCurrentTime(endDate)).locale('es').format('yyyy-MM-DD'):null,
    }

    if(is_money_received){
      filters={...filters, is_money_received: (filtersS && filtersS?.is_money_received)?filtersS?.is_money_received:(is_money_received=='True')}
    }

    return filters
  }

  const isEmptyFilters = (filters) => {
    return (
      filters.code===null && filters.company_id===null && filters.mail_status_id===null && filters.customer_id===null && filters.vendor_id===null &&
      filters.agent_id===null && filters.commissary_id===null && 
      (filters.from_governorates===null || filters?.from_governorates.length==0) &&
      (filters.to_governorates===null || filters?.to_governorates.length==0) &&
      (filters.from_districts===null || filters?.from_districts.length==0) &&
      (filters.from_neighborhoods===null || filters?.from_neighborhoods.length==0) &&
      (filters.to_districts===null || filters?.to_districts.length==0) &&
      (filters.to_neighborhoods===null || filters?.to_neighborhoods.length==0) &&
      filters.start_date===null && filters.end_date===null &&
      filters.is_money_received===null
    );
  } 

  const handleClickApplyFilters = () => {
    const filters=getFilters()
    if(isEmptyFilters(filters)){return;}
    paginationRef?.current?.loadPagesWithFilters(filters)
    setIsFilters(true)
  }

  const handleClickClearFilters = () => {
    setCode(null)
    setMailStatusFilter(null)
    setCustomerFilter(null)
    setCompanyFilter(null)
    setVendorFilter(null)
    setAgentFilter(null)
    setCommissaryFilter(null)
    setIsMoneyReceived(null)
    setFromGovernoratesFilter([])
    setToGovernoratesFilter([])
    setFromDistrictsFilter([])
    setToDistrictsFilter([])
    setFromNeighborhoodsFilter([])
    setToNeighborhoodsFilter([])
    setFromDateFilter(null)
    setToDateFilter(null)
    setIsFilters(false)

    GeneralHelpers.setHashVariable('filters', null)
    paginationRef?.current?.loadPages()
  }

  const loadFiltersApply = (filtersO) => {
    const code=filtersO?.code
    if(code){
      setCode(code)
    }

    const mailStatusId=filtersO?.mail_status_id
    if(mailStatusId){
      setMailStatusFilter(mailStatusId)
    }

    const customerId=filtersO?.customer_id
    if(customerId){
      setCustomerFilter(customerId)
    }

    const companyId=filtersO?.company_id
    if(companyId){
      setCompanyFilter(companyId)
    }

    const vendorId=filtersO?.vendor_id
    if(vendorId){
      setVendorFilter(vendorId)
    }

    const agentId=filtersO?.agent_id
    if(agentId){
      setAgentFilter(agentId)
    }

    const commissaryId=filtersO?.commissary_id
    if(commissaryId){
      setCommissaryFilter(commissaryId)
    }

    const fromGovernoratesIds=filtersO?.from_governorates
    if(fromGovernoratesIds && fromGovernoratesIds.length>0){
      handleChangeFromGovernorate(fromGovernoratesIds)
    }

    const toGovernoratesIds=filtersO?.to_governorates
    if(toGovernoratesIds && toGovernoratesIds.length>0){
      handleChangeToGovernorate(toGovernoratesIds)
    }

    const fromDistrictsIds=filtersO?.from_districts
    if(fromDistrictsIds && fromDistrictsIds.length>0){
      setFromDistrictsFilter(fromDistrictsIds)
    }

    const toDistrictsIds=filtersO?.to_districts
    if(toDistrictsIds && toDistrictsIds.length>0){
      setToDistrictsFilter(toDistrictsIds)
    }

    const fromNeighborhoodsIds=filtersO?.from_districts
    if(fromNeighborhoodsIds && fromNeighborhoodsIds.length>0){
      setFromNeighborhoodsFilter(fromNeighborhoodsIds)
    }

    const toNeighborhoodsIds=filtersO?.to_districts
    if(toNeighborhoodsIds && toNeighborhoodsIds.length>0){
      setToNeighborhoodsFilter(toNeighborhoodsIds)
    }

    const startDate=filtersO?.start_date
    if(startDate){
      setFromDateFilter(new Date(startDate))
    }

    const endDate=filtersO?.end_date
    if(endDate){
      setToDateFilter(new Date(endDate))
    }
    
    const is_money_received=filtersO?.is_money_received
    if(is_money_received!==null){
      setIsMoneyReceived(is_money_received?'True':'False')
    }
    setIsFilters(true)
  }

  const handleClickPaginationToggle = () =>{
    paginationRef?.current?.togglePagination()
  }

  const HideDropdownBoxes=()=>{
    $(`#searchToolsBar_${identifier}`).find(`div[id='${styles.SelectMultiple}'] .select-search__select`).css('display','none')
  }

  useEffect(()=>{
    getFiltersData()
  },[])

  const handleOnFocusSelect = () => {
    $(`div[id='${styles.SelectCompany}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectCompany}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectVendor}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectVendor}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectCommissary}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectCommissary}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectCompany}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectCompany}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectMailStatus}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectMailStatus}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectCustomer}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectCustomer}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)

    $(`div[id='${styles.SelectMoneyReceived}']`).find('.select-search__select').css('z-index',999)
    setTimeout(()=>{
      $(`div[id='${styles.SelectMoneyReceived}']`).find('.select-search__option').css('text-align',locale=='ar'?'right':'left')
    },50)
  }

  return <nav id="toolsBar" className={styles.ToolsBar + " bg-white list-group list-group-horizontal navbar navbar-expand-md"} data-testid="ToolsBar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target={`#searchToolsBar_${identifier}`}>
        <GoThreeBars />
      </button>
      <span id={`searchToolsBar_${identifier}`} className={styles.searchToolsBar+' collapse navbar-collapse'}>
        <script>
          (function(){
            setTimeout(()=>{
              HideDropdownBoxes()
            },200)
          })();
        </script>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectMultiple}
                  options={fromGovernoratesOptions}
                  onBlur={handleBlurMultiple}
                  onFocus={handleFocusMultiple}
                  onChange={handleChangeFromGovernorate}
                  value={fromGovernoratesFilter}
                  placeholder={t('Governorates From')}
                  search={true}
                  filterOptions={fuzzySearch}
                  multiple={true}
                  autoFocus={false}
                  />

            <SelectSearch id={styles.SelectMultiple}
                  options={fromDistrictsOptions}
                  onBlur={handleBlurMultiple}
                  onFocus={handleFocusMultiple}
                  onChange={handleChangeFromDistrict}
                  value={fromDistrictsFilter}
                  placeholder={t('Districts From')}
                  search={true}
                  filterOptions={fuzzySearch}
                  multiple={true}
                  autoFocus={false}
                  />

            <SelectSearch id={styles.SelectMultiple}
                  options={fromNeighborhoodsOptions}
                  onBlur={handleBlurMultiple}
                  onFocus={handleFocusMultiple}
                  onChange={handleChangeFromNeighborhood}
                  value={fromNeighborhoodsFilter}
                  placeholder={t('Neighborhoods From')}
                  search={true}
                  filterOptions={fuzzySearch}
                  multiple={true}
                  autoFocus={false}
                  />
          </div>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectMultiple}
                  options={toGovernoratesOptions}
                  onBlur={handleBlurMultiple}
                  onFocus={handleFocusMultiple}
                  onChange={handleChangeToGovernorate}
                  value={toGovernoratesFilter}
                  placeholder={t('Governorates To')}
                  search={true}
                  filterOptions={fuzzySearch}
                  multiple={true}
                  autoFocus={false}
                  />

            <SelectSearch id={styles.SelectMultiple}
                  options={toDistrictsOptions}
                  onBlur={handleBlurMultiple}
                  onFocus={handleFocusMultiple}
                  onChange={handleChangeToDistrict}
                  value={toDistrictsFilter}
                  placeholder={t('Districts To')}
                  search={true}
                  filterOptions={fuzzySearch}
                  multiple={true}
                  autoFocus={false}
                  />

            <SelectSearch id={styles.SelectMultiple}
                  options={toNeighborhoodsOptions}
                  onBlur={handleBlurMultiple}
                  onFocus={handleFocusMultiple}
                  onChange={handleChangeToNeighborhood}
                  value={toNeighborhoodsFilter}
                  placeholder={t('Neighborhoods To')}
                  search={true}
                  filterOptions={fuzzySearch}
                  multiple={true}
                  autoFocus={false}
                  />
            </div>
        </div>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectCompany}
                onFocus={handleOnFocusSelect}
                options={companiesOptions}
                onChange={handleChangeCompany}
                value={companyFilter}
                placeholder={t('Company')}
                search={true}
                filterOptions={fuzzySearch}
                />

            <SelectSearch id={styles.SelectVendor}
                onFocus={handleOnFocusSelect}
                options={vendorsOptions}
                onChange={handleChangeVendor}
                value={vendorFilter}
                placeholder={t('Vendor')}
                search={true}
                filterOptions={fuzzySearch}
                />
          </div>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectAgent}
                onFocus={handleOnFocusSelect}
                options={agentsOptions}
                onChange={handleChangeAgent}
                value={agentFilter}
                placeholder={t('Agent')}
                search={true}
                filterOptions={fuzzySearch}
                />

            <SelectSearch id={styles.SelectCommissary}
                onFocus={handleOnFocusSelect}
                options={commissariesOptions}
                onChange={handleChangeCommissary}
                value={commissaryFilter}
                placeholder={t('Commissary')}
                search={true}
                filterOptions={fuzzySearch}
                />
            </div>
        </div>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={from_date} onChange={handleChangeFromDate} placeholderText={t('Start Date')} />
            <DatePicker wrapperClassName={styles.datePickerWrap} 
              selected={to_date} onChange={handleChangeToDate} placeholderText={t('End Date')} />
          </div>
          
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectMailStatus}
                    onFocus={handleOnFocusSelect}
                    options={mailStatusOptions}
                    onChange={handleChangeMailStatus}
                    value={mailStatusFilter}
                    placeholder={t('Mail Status')}
                    search={true}
                    filterOptions={fuzzySearch}
                    autoFocus={false}
                    />

            <SelectSearch id={styles.SelectCustomer}
                    onFocus={handleOnFocusSelect}
                    options={customersOptions}
                    onChange={handleChangeCustomer}
                    value={customerFilter}
                    placeholder={t('Customer')}
                    search={true}
                    filterOptions={fuzzySearch}
                    autoFocus={false}
                    />
          </div>
        </div>
        <div className={styles.selectsGroup}>
          <div className={styles.subSelectsGroup}>
            <SelectSearch id={styles.SelectMoneyReceived}
                      onFocus={handleOnFocusSelect}
                      options={isMoneyReceivedOptions}
                      onChange={handleChangeIsMoneyReceived}
                      value={is_money_received}
                      placeholder={t('Is Money Received?')}
                      search={true}
                      filterOptions={fuzzySearch}
                      autoFocus={false}
                      />

            <SelectSearch id={styles.SelectCode}
                  options={codeOptions}
                  onChange={handleChangeCode}
                  value={code}
                  placeholder={t('Mail Code')}
                  search={true}
                  filterOptions={fuzzySearch}
                  autoFocus={false}
                  />

          </div>
        </div>
        <div className={styles.selectsGroup} id={styles.ButtonsContainer}>
          <div className={styles.subSelectsGroup}>
            <button className={styles.PaginationBtn + ' btn btn-primary'} onClick={handleClickPaginationToggle}>{isPages?t('View All'):t('View Pages')}</button>
          </div>
          <div className={styles.subSelectsGroup} dir={locale=='ar'?'rtl':'ltr'}>
            {isFilters && <button className={styles.ClearFilterButton + ' btn btn-danger'} onClick={handleClickClearFilters}>{t('Clear Filters')}</button>}
            <button className={styles.ApplyFilterButton + ' btn btn-primary'} onClick={handleClickApplyFilters}>{t('Apply Filters')}</button>
          </div>
        </div>
      </span>
      <br/>
      <ToolsItemsBar dataLength={dataLength} allRef={allRef} cartRef={cartRef} deletedRef={deletedRef} isDeleted={isDeleted} isPrivileged={isPrivileged} ViewItemsButtons={ViewItemsButtons} toolsBarRef={toolsBarRef} isSelectItems={isSelectItems} 
      printComponentRef={printComponentRef} printDocumentTitle={printDocumentTitle} handleOnBeforePrint={handleOnBeforePrint} handleOnAfterPrint={handleOnAfterPrint} isCart={isCart} />
  </nav>
};

ToolsBar.propTypes = {};

ToolsBar.defaultProps = {};

export default ToolsBar;
