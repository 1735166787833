import {useRef, useImperativeHandle, useEffect} from 'react';
import FetchDataService from './FetchDataService';

const GeneralFetchingService = ({generalFetchingServiceRef, prefix, generalRef}) => {
    const fetchDataServiceRef = useRef();

    useEffect(() => {
        if(generalRef){
            generalRef?.current?.fetchingServiceLoaded()
        }
    }, [])

    useImperativeHandle(generalFetchingServiceRef,()=>({
        getAll(link, isAll, filters, customApi){
            let thIndex=0
            if(link){
                thIndex=link?.split("/", 5).join("/").length
            }
            return fetchDataServiceRef?.current?.PostRequest(link?(link?.substring(thIndex)):`/${prefix}/${customApi?customApi:'all'}`, {'filters':filters}, isAll)
        },

        getDeleted(link, isAll, filters, customApi){
            let thIndex=0
            if(link){
                thIndex=link?.split("/", 5).join("/").length
            }
            return fetchDataServiceRef?.current?.PostRequest(link?(link?.substring(thIndex)):`/${prefix}/deleted${customApi?'/'+customApi:''}`, {'filters':filters}, isAll)
        },

        getLogs(link, isAll, filters, customApi){
            let thIndex=0
            if(link){
                thIndex=link?.split("/", 5).join("/").length
            }
            return fetchDataServiceRef?.current?.PostRequest(link?(link?.substring(thIndex)):`/${prefix}/log${customApi?'/'+customApi:''}`, {'filters':filters}, isAll)
        },



        

        getAllData(customApi, filters){
            return fetchDataServiceRef?.current?.PostRequest(`/${prefix}/data/all${customApi?'/'+customApi:''}`, filters?{'filters':filters}:null)
        },

        getFiltersData(filters, customApi){
            return fetchDataServiceRef?.current?.PostRequest(`/${prefix}/data/filters${customApi?'/'+customApi:''}`, filters?{'filters':filters}:null)
        },

        getFiltersDeletedData(customApi){
            return fetchDataServiceRef?.current?.PostRequest(`/${prefix}/data/filters/deleted${customApi?'/'+customApi:''}`, null)
        },

        getFiltersLogData(customApi){
            return fetchDataServiceRef?.current?.PostRequest(`/${prefix}/data/filters/log${customApi?'/'+customApi:''}`, null)
        },



        getById(id, customApi){
            return fetchDataServiceRef?.current?.PostRequest(`/${prefix}/get${customApi?'/'+customApi:''}/${id}`, {id})
        },

        async getPrivileges(){
            return await fetchDataServiceRef?.current?.PostRequestAsync(`/${prefix}/get_privileges`, null)
        },

        add(item, customApi){
            return fetchDataServiceRef?.current?.PostRequest(`/${prefix}/add${customApi?'/'+customApi:''}`, item)
        },

        addWithUpload(item, customApi){
            return fetchDataServiceRef?.current?.PostRequest(`/${prefix}/add${customApi?'/'+customApi:''}`, item, null, true)
        },

        edit(item, customApi){
            return fetchDataServiceRef?.current?.PostRequest(`/${prefix}/edit${customApi?'/'+customApi:''}/${item?.id}`, item)
        },
        
        update(data, ids, customApi){
            return fetchDataServiceRef?.current?.PostRequest(`/${prefix}/update${customApi?'/'+customApi:''}`, {ids, data})
        },

        publish(ids, customApi){
            return fetchDataServiceRef?.current?.PostRequest(`/${prefix}/publish${customApi?'/'+customApi:''}`, {ids, ids})
        },

        delete(ids, customApi){
            return fetchDataServiceRef?.current?.PostRequest(`/${prefix}/delete${customApi?'/'+customApi:''}`, {ids})
        },

        GetNotes(id, customApi){
            return fetchDataServiceRef?.current?.PostRequest(`/${prefix}/notes${customApi?'/'+customApi:''}`, {item_id:id})
        },
        AddNote(id, note, customApi){
            return fetchDataServiceRef?.current?.PostRequest(`/${prefix}/notes/add${customApi?'/'+customApi:''}`, {item_id:id, note})
        },
        DeleteNote(id, noteId, customApi){
            return fetchDataServiceRef?.current?.PostRequest(`/${prefix}/notes/delete${customApi?'/'+customApi:''}`, {item_id:id, note_id:noteId})
        },

        restoreDeleted(ids, customApi){
            return fetchDataServiceRef?.current?.PostRequest(`/${prefix}/restore_deleted${customApi?'/'+customApi:''}`, {ids})
        },

        ExportExcel(filters, apiRequired, specificItems, customApi){
            return fetchDataServiceRef?.current?.PostDownloadRequest(`/${prefix}/export_excel${customApi?'/'+customApi:''}`, {'filters':filters, 'api_required':apiRequired, 'specific_items':specificItems})
        },

        Download(data, ids, customApi){
            return fetchDataServiceRef?.current?.PostDownloadRequest(`/${prefix}/download${customApi?'/'+customApi:''}`, {data, ids})
        },
      }))

      return <span>
        <FetchDataService fetchDataServiceRef={fetchDataServiceRef} />
      </span>
}

export default GeneralFetchingService